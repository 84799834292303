<template lang="pug">
div.d-flex.flex-column
  div.d-flex.flex-column.flex-grow-1
  // ALERT BANNER
  //- b-alert.m-1.pr-2.p-1(:show="true" :dismissible="true" :fade="true" variant="warning" style="font-size: .9rem; margin-bottom: 0 !important; padding-top: 1.3rem !important;") Désormais, lorsque vous validez un document tel qu'un devis, une facture ou autre, celui-ci passera en statut "validé" au lieu de "envoyé" comme c'était le cas précédemment. Vous aurez la possibilité de passer le statut à "envoyé" manuellement lorsque vous le jugerez approprié dans votre organisation.
  HeaderGrid(v-if="showHeaderGrid" :name="name" :uid="uid" userFilter="orderFormCustomerFilters" :dateRanger="dateRanger" :templateMode="templateMode" :template="template"  :dateRangeDefault="dateRangeDefault" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :tvaMode="tvaMode" :selectedRows="selectedRows" @setDates="setDates" @updateTvaMode="updateTvaMode" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @refreshRow="refreshRow" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
  StatCards
      div.cursor-pointer.pl-1.col-card-list-stats(@click="changeTab('all')")
        .card-list-stats(:class="tabActive == 'all' ? 'current' : ''")
          .card-list-stats-title
            div
              span.card-list-stats-title-badge.material-icons.text-primary.mr-50 list
              span.card-list-stats-title-text TOUT
            .card-list-stats-title-badge.bg-primary.text-white {{ getCountDataByStatus("all") }}
          .card-sum
            | {{ getSumDataByStatus("all") }} 
            span {{ tvaMode }}
      div.cursor-pointer.pl-1.col-card-list-stats(v-for="(status, index) of statusList" :key="index" @click="changeTab(status.value, status.type)")
        .card-list-stats(:class="[tabActive == status.value && statusType==status.type ? 'current' : '', 'border-'+status.class]")
          .card-list-stats-subLabel
            span {{ status.subLabel }}
          .card-list-stats-title(:class="status.subLabel && status.subLabel!=='' ? 'pl-25' : ''")
            div
              span.card-list-stats-title-badge.material-icons.mr-25(:class="'text-'+status.class") {{ status.icon }}
              span.card-list-stats-title-text {{ status.customLabel ? status.customLabel : status.label }}
            .d-flex.align-items-center
              ejs-tooltip.mr-50(v-if="status.type == 'billed'" content="Le montant des commandes annulées n'est pas pris en compte, même si leur facturation a déjà commencé.")
                feather-icon(icon="InfoIcon")
              .card-list-stats-title-badge.text-white(:class="'bg-'+status.class") {{ getCountDataByStatus(status.value, status.type) }}
          .card-sum(:class="status.subLabel && status.subLabel!=='' ? 'pl-25' : ''")
            | {{ getSumDataByStatus(status.value, status.type) }} 
            span {{ status.type == 'remaining' || status.type == 'billed' || status.type == 'done' ? 'HT' : tvaMode }}
  .d-flex.w-100.h-100(style="overflow: hidden;")
    .text-center.flex-center.w-100(v-if='isLoadingOrderFormCustomersList || !headerLoaded')
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement de la liste des commandes client...
    sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :tagguerButton="tagguerButton" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows")
  ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
    sidebar-order-form-customer-content(ref='sidebarOrderFormCustomerContent' @refreshRow="refreshRow" @closeSidebar="closeSidebar")
  popup-new-order-form-customer(ref='popupNewOrderFormCustomer')
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { orderFormCustomerStatus } from "@/types/api-orisis/enums/enums";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import StatCards from "@/components/global/grid/StatCards.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import SidebarOrderFormCustomerContent from "@/components/invoice/order-form-customer/SidebarOrderFormCustomerContent.vue";
import PopupNewOrderFormCustomer from "@/components/invoice/order-form-customer/PopupNewOrderFormCustomer.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarOrderFormCustomerContent,
    PopupNewOrderFormCustomer,
    SyncGrid,
    StatCards,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  props: {
    origine: {
      type: String,
      default: "",
    },
    loadData: {
      type: Boolean,
      default: true,
    },
    showHeaderGrid: {
      type: Boolean,
      default: true,
    },
    showCardStats: {
      type: Boolean,
      default: true,
    },
    tab: {
      default: "all",
    },
    dateRange: {
      type: Array,
      default: () => [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
    },
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      tabActive: "all",
      statusType: null,
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "orderFormCustomer",
      uid: "orderFormCustomers",
      statusList: orderFormCustomerStatus.filter((el) => !el.notUse),
      remaining: false,
      headerData: [],
      headerLoaded: false,
      tagsSelected: [],
      selectedRows: [],

      tvaMode: "HT",
      dateRanger: true,
      tvaModeButton: true,
      addButton: true,
      searchButton: true,
      tagguerButton: true,
      searchPlaceholder: "Rechercher une commande client",
      template: false,
      templateMode: true,
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "status",
        headerText: "Statut",
        width: 110,
        maxWidth: 110,
        template: "statusTemplate",
        nature: "orderFormCustomerStatus",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filterTemplate: "statusFilterTemplate",
      },
      {
        type: "string",
        field: "documentReference",
        headerText: "Numéro",
        width: 160,
        maxWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "documentDate",
        headerText: "Date",
        width: 95,
        maxWidth: 95,
        template: "dateYmdTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "companyName",
        headerText: "Client",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "affairCode",
        headerText: "Code Affaire",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "affairName",
        headerText: "Affaire",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "description",
        headerText: "Objet",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowGrouping: false,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "stripTagsTemplate",
        filter: { type: "Menu", operator: "contains" },
      },
      {
        type: "string",
        field: "orderFormCustomerTags",
        headerText: "Tags",
        width: "auto",
        minWidth: 200,
        allowFiltering: false,
        allowSorting: false,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "tagsTemplate",
        unavailableExport: true,
      },
      {
        type: "number",
        field: "totalHt",
        headerText: "Total HT",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        allowGrouping: false,
        allowFiltering: true,
        allowAggregate: true,
      },
      {
        type: "number",
        field: "documentTva",
        headerText: "Total TVA",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        showInColumnChooser: true,
        visible: false,
        template: "tvaTemplate",
        format: "formatCurrency",
        allowGrouping: false,
        allowFiltering: true,
        allowAggregate: true,
      },
      {
        type: "number",
        field: "totalTtc",
        headerText: "Total TTC",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        showFooterSum: true,
        allowGrouping: false,
        allowFiltering: true,
        allowAggregate: true,
      },
      {
        type: "string",
        field: "avancementOrderFormCustomer",
        headerText: "Avancement",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        allowGrouping: false,
        showInColumnChooser: true,
        visible: true,
        allowFiltering: false,
        template: "avancementOrderFormCustomerTemplate",
      },
      {
        type: "number",
        field: "remainingToBillHt",
        headerText: "Reste à facturer HT",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        showFooterSum: true,
        allowGrouping: false,
        allowFiltering: true,
        allowAggregate: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "isLoadingOrderFormCustomersList",
      "orderFormCustomersList",
      "orderFormCustomersListTemplates",
      "institutionsList",
      "workspaceSelected",
      "institutionSelected",
      "userParametersList",
    ]),
    dataOrigine() {
      if (this.tagsSelected.length) {
        return this.dataOrderFormCustomersList.filter((elem) =>
          elem[this.name + "Tags"].find((tag) =>
            this.tagsSelected.includes(tag.id)
          )
        );
      } else {
        return this.dataOrderFormCustomersList;
      }
    },
    dataOrderFormCustomersList() {
      if (this.template) {
        return this.orderFormCustomersListTemplates;
      } else {
        return this.orderFormCustomersList;
      }
    },
    dataFiltered() {
      if (this.statusList) {
        if (this.tabActive == "all") {
          if (this.tagsSelected.length) {
            return this.dataOrderFormCustomersList.filter((elem) =>
              elem[this.name + "Tags"].find((tag) =>
                this.tagsSelected.includes(tag.id)
              )
            );
          } else {
            return this.dataOrderFormCustomersList;
          }
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          if (this.tagsSelected.length) {
            if (this.statusType == "remaining") {
              return this.dataOrderFormCustomersList.filter(
                (elem) =>
                  elem.remainingToBillHt > 0 &&
                  statusInfos.query.includes(elem.status) &&
                  elem[this.name + "Tags"].find((tag) =>
                    this.tagsSelected.includes(tag.id)
                  )
              );
            } else if (this.statusType == "done") {
              return this.dataOrderFormCustomersList.filter(
                (elem) =>
                  elem.remainingToBillHt <= 0 ||
                  elem[this.name + "Tags"].find((tag) =>
                    this.tagsSelected.includes(tag.id)
                  )
              );
            } else if (this.statusType == "billed") {
              return this.dataOrderFormCustomersList.filter(
                (elem) =>
                  elem.status == 4 &&
                  elem[this.name + "Tags"].find((tag) =>
                    this.tagsSelected.includes(tag.id)
                  )
              );
            } else if (this.statusType == "order") {
              return this.dataOrderFormCustomersList.filter(
                (elem) =>
                  (elem.status == 2 ||
                    elem.status == 3 ||
                    elem.status == 4 ||
                    elem.status == 7) &&
                  elem[this.name + "Tags"].find((tag) =>
                    this.tagsSelected.includes(tag.id)
                  )
              );
            } else {
              return this.dataOrderFormCustomersList.filter(
                (elem) =>
                  elem[this.name + "Tags"].find((tag) =>
                    this.tagsSelected.includes(tag.id)
                  ) &&
                  (statusInfos.query
                    ? statusInfos.query.includes(elem.status)
                    : elem.status === this.tabActive)
              );
            }
          } else {
            if (this.statusType == "remaining") {
              return this.dataOrderFormCustomersList.filter(
                (elem) =>
                  elem.remainingToBillHt > 0 &&
                  statusInfos.query.includes(elem.status)
              );
            } else if (this.statusType == "done") {
              return this.dataOrderFormCustomersList.filter(
                (elem) => elem.remainingToBillHt <= 0
              );
            } else if (this.statusType == "billed") {
              return this.dataOrderFormCustomersList.filter(
                (elem) => elem.status == 4
              );
            } else if (this.statusType == "order") {
              return this.dataOrderFormCustomersList.filter(
                (elem) =>
                  elem.status == 2 ||
                  elem.status == 3 ||
                  elem.status == 4 ||
                  elem.status == 7
              );
            } else {
              return this.dataOrderFormCustomersList.filter((elem) =>
                statusInfos.query
                  ? statusInfos.query.includes(elem.status)
                  : elem.status === this.tabActive
              );
            }
          }
        }
      } else {
        return [];
      }
    },
  },
  async created() {
    await this.getCompanies({});
    await this.getCollaborators({});
    this.getOrderFormCustomerTags({});
    this.getQuotes({ isTemplate: false, isSigned: false });
  },
  async activated() {
    this.activated();
  },
  async mounted() {
    if (this.origine == "dashbord-gestion") {
      this.activated();
    }
  },
  methods: {
    ...mapActions([
      "getCompanies",
      "getCollaborators",
      "getOrderFormCustomers",
      "getOrderFormCustomerTags",
      "getQuotes",
    ]),
    formatCurrency,
    setColumnsByObject,
    updateTvaMode(val) {
      this.tvaMode = val;
    },

    async activated() {
      this.tabActive = this.tab;
      this.headerLoaded = false;
      let filter = this.userParametersList.find(
        (el) => el.key == "orderFormCustomerFilters"
      );
      if (!this.loadData) {
        if (this.orderFormCustomersList.length) {
          await this.setColumnsByObject(
            this.orderFormCustomersList[0],
            null,
            this
          );
        }
        this.headerLoaded = true;
      } else if (filter) {
        if (JSON.parse(filter.value).dateRange) {
          this.setDates(JSON.parse(filter.value).dateRange);
        } else {
          this.setDates({
            startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
            endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
          });
        }
      } else {
        await this.setDates({
          startDate: dayjs()
            .subtract(3, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
        });
      }
      this.$emit("setStatusList", this.statusList);
      this.getOrderFormCustomers({
        isTemplate: true,
        updateState: false,
      });
    },
    changeTab(tab, statusType = null) {
      this.tabActive = tab;
      this.statusType = statusType;
    },
    toggleModal() {
      this.selectInstitution = this.institutionSelected;
      this.$refs["modal-new-order-form-customer"].toggle(
        "#toggle-btn-new-order-form-customer"
      );
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    async setDates(dates) {
      this.headerLoaded = false;
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getOrderFormCustomers({
          dateFrom: dateRange.startDate,
          dateTo: dateRange.endDate,
        }).then(async (res) => {
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    clickAction() {
      this.$refs.popupNewOrderFormCustomer.toggleModal();
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "template") {
        this.getOrderFormCustomers({
          isTemplate: true,
        });
        this.template = true;
      } else if (args.item.type == "current") {
        this.template = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status, statusType = null) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        if (statusType == "remaining") {
          return this.dataOrigine.filter(
            (elem) =>
              elem.remainingToBillHt > 0 &&
              statusInfos.query.includes(elem.status)
          ).length;
        } else if (statusType == "done") {
          return this.dataOrigine.filter((elem) => elem.remainingToBillHt <= 0)
            .length;
        } else if (statusType == "billed") {
          return this.dataOrigine.filter((elem) => elem.status == 4).length;
        } else if (statusType == "order") {
          return this.dataOrigine.filter(
            (elem) =>
              elem.status == 2 ||
              elem.status == 3 ||
              elem.status == 4 ||
              elem.status == 7
          ).length;
        } else {
          return this.dataOrigine.filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          ).length;
        }
      }
    },
    getSumDataByStatus(status = null, statusType = null) {
      let sum = 0;
      if (status == "all") {
        this.dataOrigine.forEach((obj) => {
          if (this.tvaMode == "TTC") {
            sum += obj["totalTtc"];
          } else {
            sum += obj["totalHt"];
          }
        });
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        this.dataOrigine
          .filter((elem) =>
            statusType == "done"
              ? elem.remainingToBillHt <= 0
              : statusType == "billed"
              ? elem.status == 4
              : statusType == "order"
              ? elem.status == 2 ||
                elem.status == 3 ||
                elem.status == 4 ||
                elem.status == 7
              : statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          )
          .forEach((obj) => {
            if (this.tvaMode == "TTC") {
              if (statusType == "remaining" && obj["remainingToBillHt"] > 0) {
                sum += obj["remainingToBillHt"];
              } else if (
                statusType == "done" &&
                obj["remainingToBillHt"] <= 0
              ) {
                // sum += obj["totalTtc"];
                sum += obj["totalHt"] - obj["remainingToBillHt"];
              } else if (statusType == "billed") {
                sum += obj["totalHt"] - obj["remainingToBillHt"];
              } else if (statusType == "order") {
                sum += obj["totalTtc"];
              } else if (statusType == null) {
                sum += obj["totalTtc"];
              }
            } else {
              if (
                statusType == "remaining" &&
                obj["remainingToBillHt"] > 0 &&
                statusInfos.query.includes(obj["status"])
              ) {
                sum += obj["remainingToBillHt"];
              } else if (
                statusType == "done" &&
                obj["remainingToBillHt"] <= 0
              ) {
                // sum += obj["totalHt"];
                sum += obj["totalHt"] - obj["remainingToBillHt"];
              } else if (statusType == "billed") {
                sum += obj["totalHt"] - obj["remainingToBillHt"];
              } else if (statusType == "order") {
                sum += obj["totalHt"];
              } else if (statusType == null) {
                sum += obj["totalHt"];
              }
            }
          });
      }
      return this.formatCurrency(sum);
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      this.$refs["sidebarOrderFormCustomerContent"].getOrderFormCustomerDetails(
        event.rowData.id
      );
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
