<template lang="pug">
    div
      .border-left.border-right.border-top.rounded-top.content-total
        strong.d-flex.total-row Total net HT
          .ml-auto.price-total
            span.totalWithoutTax {{ formatCurrency(orderFormCustomer.totalHt) }}
        div(v-if="orderFormCustomerOption.isReverseCharge || orderFormCustomer.totalTtc == orderFormCustomer.totalHt")
          .d-flex Autoliquidation
        div(v-else)
          strong.d-flex.total-row.total-with-tax Total TVA
            .ml-auto.price-total
              span.totalWithTax {{ formatCurrency((orderFormCustomer.totalTtc - orderFormCustomer.totalHt).toFixed(2)) }}
      .border-left.border-right
      strong.d-flex.big.px-7.bg-primary.text-white.rounded-bottom.totalOrderFormCustomer TOTAL TTC
        .ml-auto.price-total
          span.totalNetToPay.netToPay {{ formatCurrency(orderFormCustomer.totalTtc) }}
</template>

<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  props: {
    preview: {
      default: false,
    },
    canEditDiscount: {
      required: true,
      default: true,
      type: Boolean,
    },
  },
  components: {
    "v-select": vSelect,
    popper: Popper,
  },
  methods: {
    formatCurrency,
  },
  computed: {
    ...mapGetters(["orderFormCustomer", "orderFormCustomerOption"]),
  },
};
</script>

<style>
.totalOrderFormCustomer {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}

.total-divider {
  border-top: 1px solid #d9d9d9;
  margin: 7px 0 7px 0;
}

.content-total {
  padding: 7px;
}

.error-margin {
  padding: 10px;
  background-color: #f19e9e;
  display: flex;
  align-items: center;
  border-radius: 0.357rem;
}

.price-total {
  white-space: nowrap;
}

.rest-button {
  margin-left: 10px;
  cursor: pointer;
  color: #0c3571;
}
</style>
