<template lang="pug">
div#dashboard
    .d-flex.flex-column
        .content-header.d-flex.justify-content-between.align-items-center.p-1
            h2.content-header-title.m-0.pr-1.mr-1.d-flex
                span.material-icons-outlined.cursor-pointer(@click="setHomePage()" :class="currentHomePage ? 'text-primary' : ''" style="color: #ccc;") home
                span.d-block.d-md-none Gestion
                span.d-none.d-md-block Tableau de bord > Gestion
            .content-header-search-filter.mr-1.d-none.d-md-block(style="flex: 1;")
                .position-relative.w-100.d-flex.align-items-center
                    .d-none.d-lg-block.mr-1.flex
                        DateRanger(@setDates="setDates" userFilter="dashboardGestionFilters" :dateRangeDefault="dateRangeDefault" placeholder="Filtrer par période")
                    ejs-multiselect(cssClass="d-none d-xl-block" id='affairAffairs' name="Affaires" :placeholder="haveLeaseModule ? 'Filtrer par affaire / bail' : 'Filtrer par affaire'" popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="affairsList" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'displayLabel', value: 'id' }" v-model='filterAffairs')
            .content-header-actions
                ejs-dropdownbutton( cssClass="ml-0 e-outline d-none d-lg-block" :select="selectParamerterAction" :items="parametersActionList" iconCss="e-drpdwnbtn-icons e-btn-sb-icons e-icon-field-settings"  :isPrimary="true" )
        b-col(cols='12').d-none.d-md-block.d-xl-none 
            .d-flex.w-100.justify-content-between 
                DateRanger(@setDates="setDates" userFilter="dashboardGestionFilters" :dateRangeDefault="dateRangeDefault" placeholder="Filtrer par période")
                ejs-multiselect(cssClass="ml-1" id='affairAffairs'  name="Affaires" :placeholder="haveLeaseModule ? 'Filtrer par affaire / bail' : 'Filtrer par affaire'" popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="affairsList" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'displayLabel', value: 'id' }" v-model='filterAffairs')
        b-col(cols='12').d-md-none  
            .w-100.mb-50
                DateRanger(@setDates="setDates" :dateRangeDefault="dateRangeDefault" userFilter="dashboardGestionFilters" placeholder="Filtrer par période")
            ejs-multiselect(id='affairAffairs' cssClass='w-100' name="Affaires" :placeholder="haveLeaseModule ? 'Filtrer par affaire / bail' : 'Filtrer par affaire'" popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="affairsList" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'displayLabel', value: 'id' }" v-model='filterAffairs')
    .w-100 
        b-row.m-0.control-section.dashboard-default
            b-col(md="12" style='padding-top: 0px !important;')
                .dashboard-default(v-if="dashboardLoaded")
                    ejs-dashboardlayout#defaultLayout(ref="DashbordInstance" :key="keyRefresh" :cellSpacing='spacing' :cellAspectRatio='aspectRatio' :columns='12' :allowFloating="true" :allowResizing="editDashboard" :allowDragging="editDashboard"  :mediaQuery="'max-width:991px'"  @change="onChangeResizeStop" @resizeStop="onChangeResizeStop" @resizeStart="onChangeResizeStart" @created="onCreatedDashboard")
                        .e-panel(v-for="(panel, indexPanel) of panels" :key="indexPanel" :class="[editDashboard ? 'active' : '', panel.id=='invoices-sent' ? 'card-list-stats-dashboard bg-light-grey p-0' : panel.id=='quotes' ? 'card-list-stats-dashboard bg-white border-turquoise p-0' : panel.id=='orderFormCustomers' || panel.id=='invoices' || panel.id=='orderFormProviders' || panel.id=='invoiceProviders' ? 'card-list-stats-dashboard bg-white border-primary p-0' : '']" :id="panel.id" :data-row='panel.row' :data-col='panel.col' :data-sizeX='panel.sizeX' :data-sizeY='panel.sizeY' :data-minSizeX='panel.minSizeX' :data-minSizeY='panel.minSizeY')
                          .e-panel-container.p-1.cursor-pointer(v-if="panel.id=='quotes' " @click="changeTab('quotes')" :class="tabActive == 'quotes' ? 'bg-light-grey' : ''")
                                span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                .d-flex.justify-content-between.h-100.w-100
                                    div.d-flex.flex-column.align-items-start.h-100.w-100
                                        h4.d-flex.align-items-center.justify-content-between.w-100.mb-0
                                            | Devis à valider
                                            div.bg-turquoise.text-white.text-center(v-if="dataLoaded" style="border-radius:14px; min-width:24px; padding: 0px 5px;") {{ getCountQuotesByStatus }}
                                        div.d-flex.align-items-center.h-100(v-if="!dataLoaded" style="flex:1; min-height: 50px;")
                                            div.number
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                        div.d-flex.align-items-center(v-else style="flex:1")
                                            .h1.font-weight-normal.text-dark {{ getSumQuotesByStatus }}
                          .e-panel-container.p-1.cursor-pointer(v-if="panel.id=='orderFormCustomers' " @click="changeTab('orderFormCustomers')" :class="tabActive == 'orderFormCustomers' ? 'bg-light-grey' : ''")
                                span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                .d-flex.justify-content-between.h-100.w-100
                                    div.d-flex.flex-column.align-items-start.h-100.w-100
                                        h4.d-flex.align-items-center.justify-content-between.w-100.mb-0
                                            | Commandes client à valider
                                            div.bg-primary.text-white.text-center(v-if="dataLoaded" style="border-radius:14px; min-width:24px; padding: 0px 5px;") {{ getCountOrderFormCustomersByStatus }}
                                        div.d-flex.align-items-center.h-100(v-if="!dataLoaded" style="flex:1; min-height: 50px;")
                                            div.number
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                        div.d-flex.align-items-center(v-else style="flex:1")
                                            .h1.font-weight-normal.text-dark {{ getSumOrderFormCustomersByStatus }}
                          .e-panel-container.p-1.cursor-pointer(v-if="panel.id=='invoices' " @click="changeTab('invoices')" :class="tabActive == 'invoices' ? 'bg-light-grey' : ''")
                                span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                .d-flex.justify-content-between.h-100.w-100
                                    div.d-flex.flex-column.align-items-start.h-100.w-100
                                        h4.d-flex.align-items-center.justify-content-between.w-100.mb-0
                                            | Factures à valider
                                            div.bg-primary.text-white.text-center(v-if="dataLoaded" style="border-radius:14px; min-width:24px; padding: 0px 5px;") {{ getCountInvoicesByStatus }}
                                        div.d-flex.align-items-center.h-100(v-if="!dataLoaded" style="flex:1; min-height: 50px;")
                                            div.number
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                        div.d-flex.align-items-center(v-else style="flex:1")
                                            .h1.font-weight-normal.text-dark {{ getSumInvoicesByStatus }}
                          .e-panel-container.p-1.cursor-pointer(v-if="panel.id=='orderFormProviders' " @click="changeTab('orderFormProviders')" :class="tabActive == 'orderFormProviders' ? 'bg-light-grey' : ''")
                                span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                .d-flex.justify-content-between.h-100.w-100
                                    div.d-flex.flex-column.align-items-start.h-100.w-100
                                        h4.d-flex.align-items-center.justify-content-between.w-100.mb-0
                                            | Commandes fournisseur à valider
                                            div.bg-primary.text-white.text-center(v-if="dataLoaded" style="border-radius:14px; min-width:24px; padding: 0px 5px;") {{ getCountOrderFormProvidersByStatus }}
                                        div.d-flex.align-items-center.h-100(v-if="!dataLoaded" style="flex:1; min-height: 50px;")
                                            div.number
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                        div.d-flex.align-items-center(v-else style="flex:1")
                                            .h1.font-weight-normal.text-dark {{ getSumOrderFormProvidersByStatus }}
                          .e-panel-container.p-1.cursor-pointer(v-if="panel.id=='invoiceProviders' " @click="changeTab('invoiceProviders')" :class="tabActive == 'invoiceProviders' ? 'bg-light-grey' : ''")
                                span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                .d-flex.justify-content-between.h-100.w-100
                                    div.d-flex.flex-column.align-items-start.h-100.w-100
                                        h4.d-flex.align-items-center.justify-content-between.w-100.mb-0
                                            | Factures fournisseur à traiter
                                            div.bg-primary.text-white.text-center(v-if="dataLoaded" style="border-radius:14px; min-width:24px; padding: 0px 5px;") {{ getCountInvoiceProvidersByStatus }}
                                        div.d-flex.align-items-center.h-100(v-if="!dataLoaded" style="flex:1; min-height: 50px;")
                                            div.number
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                                b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                span(class="sr-only") Loading...
                                        div.d-flex.align-items-center(v-else style="flex:1")
                                            .h1.font-weight-normal.text-dark {{ getSumInvoiceProvidersByStatus }}
                          .e-panel-container.p-0(v-if="panel.id=='list' ")
                            //- span.material-icons.ml-25.btn-close(@click="onCloseIconHandler(panel.id)") close
                            Quotes(v-if="tabActive == 'quotes' && dataLoaded" :origine="'dashbord-gestion'" :loadData="false" :showCardStats="false" :showHeaderGrid="false" :dateRange="dateRangeDefault" :tab="1" :filterAffairs="filterAffairs" @setTagsSelected="setTagsSelected" @setStatusList="setStatusList" @rowClicked="rowClicked")
                            OrderFormCustomers(v-if="tabActive == 'orderFormCustomers' && dataLoaded" :origine="'dashbord-gestion'" :loadData="false" :showCardStats="false" :showHeaderGrid="false" :dateRange="dateRangeDefault" :tab="2" :filterAffairs="filterAffairs" @setTagsSelected="setTagsSelected" @setStatusList="setStatusList" @rowClicked="rowClicked")
                            Invoices(ref='invoicesList'  v-if="tabActive == 'invoices' && dataLoaded" :origine="'dashbord-gestion'" :loadData="false" :showCardStats="false" :showHeaderGrid="false" :dateRange="dateRangeDefault" :tab="1" :filterAffairs="filterAffairs" @setTagsSelected="setTagsSelected" @setStatusList="setStatusList" @rowClicked="rowClicked")
                            OrderFormProviders(v-if="tabActive == 'orderFormProviders' && dataLoaded" :origine="'dashbord-gestion'" :loadData="false" :showCardStats="false" :showHeaderGrid="false" :dateRange="dateRangeDefault" :tab="2" :filterAffairs="filterAffairs" @setTagsSelected="setTagsSelected" @setStatusList="setStatusList" @rowClicked="rowClicked")
                            InvoicesProvider(ref="invoicesProviderList" v-if="tabActive == 'invoiceProviders' && dataLoaded" :origine="'dashbord-gestion'" :loadData="false" :showCardStats="false" :showHeaderGrid="false" :dateRange="dateRangeDefault" :tab="0" :filterAffairs="filterAffairs" @setTagsSelected="setTagsSelected" @setStatusList="setStatusList" @rowClicked="rowClicked")
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpenInvoices" ref="sidebarInvoice" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpenInvoices")
      sidebar-invoice-content(ref='sidebarInvoiceContent' @refreshRow="refreshRow" @closeSidebar="closeSidebarInvoice")
    popup-new-invoice(ref='popupNewInvoice')
    
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpenInvoicesProvider" ref="sidebarInvoiceProvider" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpenInvoicesProvider")
      sidebar-invoice-provider-content(ref='sidebarInvoiceProviderContent' @refreshGrid="refreshGrid" @closeSidebar="closeSidebarInvoiceProvider")
    popup-import-invoices-provider(ref='PopupImportInvoicesProvider')
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelsDirective,
} from "@syncfusion/ej2-vue-layouts";

import {
  formatNumber,
  formatCurrency,
  replaceNbsps,
} from "@/types/api-orisis/library/FormatOperations.ts";
import {
  quoteStatus,
  orderFormCustomerStatus,
  invoiceStatus,
  orderFormProviderStatus,
  invoiceProviderStatus,
} from "@/types/api-orisis/enums/enums";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import DateRanger from "@/components/global/date-ranger/DateRanger.vue";

import Quotes from "@/views/invoices/Quotes";
import OrderFormCustomers from "@/views/invoices/OrderFormCustomers";
import Invoices from "@/views/invoices/Invoices";
import OrderFormProviders from "@/views/purchase/order-form-provider/OrderFormProviders";
import InvoicesProvider from "@/views/purchase/invoices-provider/InvoicesProvider";

import PopupNewInvoice from "@/components/invoice/PopupNewInvoice.vue";
import SidebarInvoiceContent from "@/components/invoice/SidebarDocumentContent.vue";

import SidebarInvoiceProviderContent from "@/components/purchase/invoice-provider/SidebarInvoiceProviderContent";
import PopupImportInvoicesProvider from "@/components/purchase/invoice-provider/PopupImportInvoicesProvider.vue";

import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";
import { checkFunctionUser } from "@/auth/utils.ts";

export default {
  components: {
    "ejs-dashboardlayout": DashboardLayoutComponent,
    "e-panel": PanelDirective,
    "e-panels": PanelsDirective,
    DateRanger,
    Quotes,
    OrderFormCustomers,
    Invoices,
    OrderFormProviders,
    InvoicesProvider,
    PopupNewInvoice,
    SidebarInvoiceContent,
    SidebarInvoiceProviderContent,
    PopupImportInvoicesProvider,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      currentHomePage: false,
      title: "Tableau de bord de gestion",
      name: "dashboardInvoice",
      tabActive: "invoices",
      status: 0,
      tagsSelected: [],
      selectedRows: [],
      isOpenInvoices: false,
      isOpenInvoicesProvider: false,
      position: "Right",
      type: "Push",
      showBackdrop: true,
      enablePersistence: false,
      closeOnDocumentClick: true,
      dateRangeDefault: [
        new Date(dayjs().startOf("month").format("YYYY-MM-DD")),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")),
      ],
      dateRange: {
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      },
      filterAffairs: [],
      tvaMode: "HT",

      dataLoaded: false,
      statusList: [],
      keyRefresh: 0,

      viewMode: "counted",
      viewType: "amount",
      viewSlot: "month",

      dashboardLoaded: false,
      editDashboard: false,
      aspectRatio: 100 / 80,
      spacing: [10, 10],
      panelsDefault: [
        {
          id: "quotes",
          row: 0,
          col: 0,
          sizeX: 3,
          sizeY: 1,
          minSizeX: 2,
          minSizeY: 1,
        },
        {
          id: "orderFormCustomers",
          row: 1,
          col: 0,
          sizeX: 3,
          sizeY: 1,
          minSizeX: 2,
          minSizeY: 1,
        },
        {
          id: "invoices",
          row: 2,
          col: 0,
          sizeX: 3,
          sizeY: 1,
          minSizeX: 2,
          minSizeY: 1,
        },
        {
          id: "orderFormProviders",
          row: 3,
          col: 0,
          sizeX: 3,
          sizeY: 1,
          minSizeX: 2,
          minSizeY: 1,
        },
        {
          id: "invoiceProviders",
          row: 4,
          col: 0,
          sizeX: 3,
          sizeY: 1,
          minSizeX: 2,
          minSizeY: 1,
        },
        {
          id: "list",
          row: 0,
          col: 3,
          sizeX: 9,
          sizeY: 5,
          minSizeX: 6,
          minSizeY: 3,
        },
      ],
      panels: [],
      parametersActionList: [
        {
          text: "Activer l'édition du tableau de bord",
          iconCss: "e-btn-sb-icons e-icons e-frame-custom",
          type: "enableEditDashboard",
        },
        {
          text: "Réinitiliser mon tableau de bord",
          iconCss: "e-btn-sb-icons e-icons e-reset",
          type: "dashboardGestion",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "affairsList",
      "quotesList",
      "orderFormCustomersList",
      "invoicesList",
      "orderFormProvidersList",
      "invoiceProvidersList",
      "workspaceSelected",
      "userParametersList",
    ]),
    haveLeaseModule() {
      this.checkFunctionUser(37, "all");
    },
    quoteStatusFiltered() {
      return quoteStatus;
    },
    orderFormCustomerStatusFiltered() {
      return orderFormCustomerStatus;
    },
    invoiceStatusFiltered() {
      return invoiceStatus;
    },
    orderFormProviderStatusFiltered() {
      return orderFormProviderStatus;
    },
    invoiceProviderStatusFiltered() {
      return invoiceProviderStatus;
    },

    dataQuotesFiltered() {
      if (this.tagsSelected.length) {
        return this.quotesList.filter((elem) =>
          elem["quoteTags"].find((tag) => this.tagsSelected.includes(tag.id))
        );
      } else {
        return this.quotesList;
      }
    },
    getCountQuotesByStatus() {
      if (this.status == "all") {
        return this.dataQuotesFiltered.length;
      } else {
        let statusInfos = this.quoteStatusFiltered.find(
          (elem) => elem.value == 1
        );
        return this.dataQuotesFiltered.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === 1
        ).length;
      }
    },
    getSumQuotesByStatus() {
      let sum = 0;
      if (this.status == "all") {
        this.dataQuotesFiltered.forEach((obj) => {
          if (this.tvaMode == "TTC") {
            sum += obj["totalTtc"];
          } else {
            sum += obj["totalHt"];
          }
        });
      } else {
        let statusInfos = this.quoteStatusFiltered.find(
          (elem) => elem.value == 1
        );
        this.dataQuotesFiltered
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === 1
          )
          .forEach((obj) => {
            if (this.tvaMode == "TTC") {
              sum += obj["totalTtc"];
            } else {
              sum += obj["totalHt"];
            }
          });
      }
      return this.formatCurrency(sum);
    },

    dataOrderFormCustomersFiltered() {
      if (this.tagsSelected.length) {
        return this.orderFormCustomersList.filter((elem) =>
          elem["orderFormCustomerTags"].find((tag) =>
            this.tagsSelected.includes(tag.id)
          )
        );
      } else {
        return this.orderFormCustomersList;
      }
    },
    getCountOrderFormCustomersByStatus() {
      if (this.status == "all") {
        return this.dataOrderFormCustomersFiltered.length;
      } else {
        let statusInfos = this.orderFormCustomerStatusFiltered.find(
          (elem) => elem.value == 2
        );
        return this.dataOrderFormCustomersFiltered.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === 2
        ).length;
      }
    },
    getSumOrderFormCustomersByStatus() {
      let sum = 0;
      if (this.status == "all") {
        this.dataOrderFormCustomersFiltered.forEach((obj) => {
          if (this.tvaMode == "TTC") {
            sum += obj["totalTtc"];
          } else {
            sum += obj["totalHt"];
          }
        });
      } else {
        let statusInfos = this.orderFormCustomerStatusFiltered.find(
          (elem) => elem.value == 2
        );
        this.dataOrderFormCustomersFiltered
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === 2
          )
          .forEach((obj) => {
            if (this.tvaMode == "TTC") {
              sum += obj["totalTtc"];
            } else {
              sum += obj["totalHt"];
            }
          });
      }
      return this.formatCurrency(sum);
    },

    dataInvoicesFiltered() {
      if (this.tagsSelected.length) {
        return this.invoicesList.filter((elem) =>
          elem["invoiceTags"].find((tag) => this.tagsSelected.includes(tag.id))
        );
      } else {
        return this.invoicesList;
      }
    },
    getCountInvoicesByStatus() {
      if (this.status == "all") {
        return this.dataInvoicesFiltered.length;
      } else {
        let statusInfos = this.invoiceStatusFiltered.find(
          (elem) => elem.value == 1
        );
        return this.dataInvoicesFiltered.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === 1
        ).length;
      }
    },
    getSumInvoicesByStatus(status) {
      let sum = 0;
      if (this.status == "all") {
        this.dataInvoicesFiltered.forEach((obj) => {
          if (this.tvaMode == "TTC") {
            sum += obj["totalTtc"];
          } else {
            sum += obj["totalHt"];
          }
        });
      } else {
        let statusInfos = this.invoiceStatusFiltered.find(
          (elem) => elem.value == 1
        );
        this.dataInvoicesFiltered
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === 1
          )
          .forEach((obj) => {
            if (this.tvaMode == "TTC") {
              sum += obj["totalTtc"];
            } else {
              sum += obj["totalHt"];
            }
          });
      }
      return this.formatCurrency(sum);
    },

    dataOrderFormProvidersFiltered() {
      if (this.tagsSelected.length) {
        return this.orderFormProvidersList.filter((elem) =>
          elem["orderFormCustomerTags"].find((tag) =>
            this.tagsSelected.includes(tag.id)
          )
        );
      } else {
        return this.orderFormProvidersList;
      }
    },
    getCountOrderFormProvidersByStatus() {
      if (this.status == "all") {
        return this.dataOrderFormProvidersFiltered.length;
      } else {
        let statusInfos = this.orderFormProviderStatusFiltered.find(
          (elem) => elem.value == 2
        );
        return this.dataOrderFormProvidersFiltered.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === 2
        ).length;
      }
    },
    getSumOrderFormProvidersByStatus() {
      let sum = 0;
      if (this.status == "all") {
        this.dataOrderFormProvidersFiltered.forEach((obj) => {
          if (this.tvaMode == "TTC") {
            sum += obj["totalTtc"];
          } else {
            sum += obj["totalHt"];
          }
        });
      } else {
        let statusInfos = this.orderFormProviderStatusFiltered.find(
          (elem) => elem.value == 2
        );
        this.dataOrderFormProvidersFiltered
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === 2
          )
          .forEach((obj) => {
            if (this.tvaMode == "TTC") {
              sum += obj["totalTtc"];
            } else {
              sum += obj["totalHt"];
            }
          });
      }
      return this.formatCurrency(sum);
    },

    dataInvoiceProvidersFiltered() {
      if (this.tagsSelected.length) {
        return this.invoiceProvidersList.filter((elem) => {
          return elem["invoiceProviderTags"].find((tag) =>
            this.tagsSelected.includes(tag.id)
          );
        });
      } else {
        return this.invoiceProvidersList;
      }
    },
    getCountInvoiceProvidersByStatus() {
      if (this.status == "all") {
        return this.dataInvoiceProvidersFiltered.length;
      } else {
        let statusInfos = this.invoiceProviderStatusFiltered.find(
          (elem) => elem.value == 0
        );
        return this.dataInvoiceProvidersFiltered.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.invoiceProviderStatus)
            : [0, 1].includes(elem.invoiceProviderStatus)
        ).length;
      }
    },
    getSumInvoiceProvidersByStatus() {
      let sum = 0;
      if (this.status == "all") {
        this.dataInvoiceProvidersFiltered.forEach((obj) => {
          if (this.tvaMode == "TTC") {
            sum += obj["totalTTC"];
          } else {
            sum += obj["totalHT"];
          }
        });
      } else {
        let statusInfos = this.invoiceProviderStatusFiltered.find(
          (elem) => elem.value == 0
        );
        this.dataInvoiceProvidersFiltered
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.invoiceProviderStatus)
              : [0, 1].includes(elem.invoiceProviderStatus)
          )
          .forEach((obj) => {
            if (this.tvaMode == "TTC") {
              sum += obj["totalTTC"];
            } else {
              sum += obj["totalHT"];
            }
          });
      }
      return this.formatCurrency(sum);
    },
  },
  async created() {
    this.getAffairs({});

    if (
      this.userParametersList.find((el) => el.key == "homePage") &&
      this.userParametersList.find((el) => el.key == "homePage").value ==
        this.$route.path
    ) {
      this.currentHomePage = true;
    } else {
      this.currentHomePage = false;
    }
    if (
      this.userParametersList.find((el) => el.key == "dashboardGestion") &&
      this.userParametersList.find((el) => el.key == "dashboardGestion") &&
      JSON.parse(
        this.userParametersList.find((el) => el.key == "dashboardGestion").value
      ) &&
      JSON.parse(
        this.userParametersList.find((el) => el.key == "dashboardGestion").value
      ).panels
    ) {
      this.panels = JSON.parse(
        this.userParametersList.find((el) => el.key == "dashboardGestion").value
      ).panels;
    } else {
      this.panels = JSON.parse(JSON.stringify(this.panelsDefault));
    }
  },
  async activated() {
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler);
    this.keyRefresh++;
    this.dashboardLoaded = true;
    await this.initDashboard();
  },
  methods: {
    ...mapActions([
      "createOrUpdateUserParameter",
      "getAffairs",
      "getQuotes",
      "getInvoices",
      "getInvoicesProviders",
      "getOrderFormCustomers",
    ]),
    checkFunctionUser,
    formatNumber,
    formatCurrency,
    replaceNbsps,
    setHomePage() {
      this.createOrUpdateUserParameter({
        label: "Page d'accueil",
        key: "homePage",
        value: this.$route.path,
      });
      this.currentHomePage = true;
    },
    resizeHandler(e) {
      let newAspectRatio = 0;

      if (window.innerWidth >= 1500) {
        newAspectRatio = 1.45;
      } else if (window.innerWidth >= 1350) {
        newAspectRatio = 1.3;
      } else if (window.innerWidth >= 1200) {
        newAspectRatio = 1;
      } else if (window.innerWidth >= 992) {
        newAspectRatio = 0.8;
      } else if (window.innerWidth >= 850) {
        newAspectRatio = 8;
      } else if (window.innerWidth >= 768) {
        newAspectRatio = 7;
      } else if (window.innerWidth >= 576) {
        newAspectRatio = 7;
      } else if (window.innerWidth < 576) {
        newAspectRatio = 5;
      }

      if (this.aspectRatio !== newAspectRatio) {
        this.aspectRatio = newAspectRatio;
        this.$forceUpdate();
      }
    },
    async changeTab(tab) {
      this.tabActive = tab;
      this.tagsSelected = [];
      this.$forceUpdate();
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setStatusList(val) {
      this.statusList = val;
    },
    async initDashboard() {
      this.dataLoaded = false;
      let filter = this.userParametersList.find(
        (el) => el.key == "dashboardGestionFilters"
      );
      if (filter) {
        if (JSON.parse(filter.value).dateRange) {
          this.setDates(JSON.parse(filter.value).dateRange);
        } else {
          this.setDates({
            startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
            endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
          });
        }
      } else {
        await this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    },
    async setDates(dates) {
      if (dates.startDate) {
        this.dataLoaded = false;

        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        let allPromises = [
          this.getQuotes({
            dateFrom: dateRange.startDate,
            dateTo: dateRange.endDate,
          }),
          this.getOrderFormCustomers({
            dateFrom: dateRange.startDate,
            dateTo: dateRange.endDate,
          }),
          this.getInvoicesProviders({
            dateFrom: dateRange.startDate,
            dateTo: dateRange.endDate,
          }),
          this.getInvoices({
            dateFrom: dateRange.startDate,
            dateTo: dateRange.endDate,
          }),
        ];
        Promise.all(allPromises).then(() => {
          this.dataLoaded = true;
        });
      }
    },
    selectParamerterAction(args) {
      if (args.item.type == "enableEditDashboard") {
        this.editDashboard = true;
        this.parametersActionList.splice(
          this.parametersActionList.findIndex(
            (elem) => elem.type == "enableEditDashboard"
          ),
          1
        );
        this.parametersActionList.unshift({
          text: "Désactiver l'édition du tableau de bord",
          iconCss: "e-btn-sb-icons e-icons e-border-outer",
          type: "disableEditDashboard",
        });
      } else if (args.item.type == "disableEditDashboard") {
        this.editDashboard = false;
        this.parametersActionList.splice(
          this.parametersActionList.findIndex(
            (elem) => elem.type == "disableEditDashboard"
          ),
          1
        );
        this.parametersActionList.unshift({
          text: "Activer l'édition du tableau de bord",
          iconCss: "e-btn-sb-icons e-icons e-frame-custom",
          type: "enableEditDashboard",
        });
      } else if (args.item.type == "dashboardGestion") {
        this.dashboardLoaded = false;
        this.panels = JSON.parse(JSON.stringify(this.panelsDefault));
        this.createOrUpdateUserParameter({
          label: "Paramètres du tableau de bord analytique",
          key: "dashboardGestion",
          value: JSON.stringify({ panels: this.panels }),
        });
        this.$nextTick(() => {
          this.dashboardLoaded = true;
        });
        this.$forceUpdate();
      }
    },
    onChangeResizeStart() {
      this.updatePanelSize(false);
    },
    onChangeResizeStop() {
      this.updatePanelSize(true);
    },
    onCreatedDashboard(args) {
      this.updatePanelSize(true);
    },
    updatePanelSize(loaded = true) {
      var panelElement = document.querySelectorAll(".e-panel");
      for (var i = 0; i < panelElement.length; i++) {
        this.panels.find((el) => el.id == panelElement[i].id).loaded = false;
        this.panels.find((el) => el.id == panelElement[i].id).height =
          panelElement[i].offsetHeight;
        if (loaded) {
          this.panels.find((el) => el.id == panelElement[i].id).loaded = true;
        }
      }
      this.$forceUpdate();
      if (loaded) {
        this.createOrUpdateUserParameter({
          label: "Paramètres du tableau de bord analytique",
          key: "dashboardGestion",
          value: JSON.stringify({
            panels: this.$refs.DashbordInstance.serialize(),
          }),
        });
      }
    },
    onCloseIconHandler(panelId) {
      this.$refs.DashbordInstance.removePanel(panelId);
      this.$forceUpdate();
    },

    rowClicked(event) {
      if (this.tabActive == "invoices") {
        this.isOpenInvoices = true;
        this.$refs["sidebarInvoiceContent"].getDocumentDetails(
          event.rowData.id,
          event.rowData.nature
        );
        this.$refs.sidebarInvoice.toggle();
      } else if (this.tabActive == "invoiceProviders") {
        this.isOpenInvoicesProvider = true;
        this.$refs["sidebarInvoiceProviderContent"].getInvoiceProviderDetails(
          event.rowData.id
        );
        this.$refs.sidebarInvoiceProvider.toggle();
      } else {
      }
    },
    refreshGrid(res) {
      if (this.tabActive == "invoiceProviders") {
        this.$refs[
          "invoicesProviderList"
        ].$refs.overviewgrid.$refs.overviewgrid.refresh();
      } else {
      }
    },
    refreshRow(val) {
      if (this.tabActive == "invoices") {
        this.$refs[
          "invoicesList"
        ].$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
      } else if (this.tabActive == "invoices") {
        this.$refs[
          "invoicesProviderList"
        ].$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
      } else {
      }
    },
    closeSidebarInvoice() {
      this.$refs.sidebarInvoice.hide();
    },
    closeSidebarInvoiceProvider() {
      this.$refs.sidebarInvoiceProvider.hide();
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss">
@import "~@syncfusion/ej2-base/styles/material.css";
@import "~@syncfusion/ej2-vue-layouts/styles/material.css";

.e-panel-container {
  .btn-close {
    display: none;
    position: absolute;
    cursor: pointer;
    right: 0px;
    z-index: 2;
  }
  &:hover {
    .btn-close {
      display: block;
      right: 1rem;
    }
  }
}
.e-dashboardlayout.e-control .cursor-default {
  cursor: default !important;
}
/* DashboardLayout element styles  */
// .dashboard-default #defaultLayout.e-dashboardlayout.e-control .e-panel:hover span {
//     display: block;
// }

.e-dashboardlayout.e-control .e-panel {
  border-radius: 5px;
}

.dashboard-default #defaultLayout .e-panel .e-panel-container .text-align {
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}
// .dashboard-default #defaultLayout #list{
//   z-index: 1001 !important;
// }

.dashboard-default .e-template-icon {
  padding: 10px;
  float: right;
  display: none;
}

.dashboard-default .e-clear-icon::before {
  content: "\e7a7";
  font-size: 12px;
  font-family: "e-icons";
}
.bootstrap5 .dashboard-default .e-clear-icon::before,
.bootstrap5-dark .dashboard-default .e-clear-icon::before {
  content: "\e7e7";
  font-size: 16px;
}

.dashboard-default .e-clear-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.dashboard-default #defaultLayout .e-panel {
  z-index: 2 !important;
  box-shadow: none !important;
  &:hover {
    border: 1px #e0e0e0 solid;
  }
  &.active {
    z-index: 1000 !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15) !important;
  }
  &.box-shadow {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15) !important;
    cursor: pointer !important;
  }
  &.active:hover {
    border: 1px #bdbdbd solid;
  }
}

.dashboard-default #defaultLayout .e-panel .e-panel-container .text-align {
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.dashboard-default .e-template-icon {
  padding: 10px;
  float: right;
  display: none;
}

.dashboard-default .e-clear-icon::before {
  content: "\e7a7";
  font-size: 12px;
  font-family: "e-icons";
}

.bootstrap5 .dashboard-default .e-clear-icon::before,
.bootstrap5-dark .dashboard-default .e-clear-icon::before {
  content: "\e7e7";
  font-size: 16px;
}

.dashboard-default .text-align {
  line-height: 160px;
}

.dashboard-default .e-clear-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}
</style>
