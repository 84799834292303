<template lang="pug">
  div.h-100
    .h-100(v-if='isLoadingOrderFormCustomer || isUpdatingOrderFormCustomer || isLoadingOrderFormCustomerPDF || isCreatingOrderFormCustomer')
      .text-center.flex-center.h-100
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement des détails de la commande client...
    .h-100(v-else)
      .sidebar-header.pt-1.px-2
        div
          h3 Commande client {{ orderFormCustomer.documentReference ? ': ' + orderFormCustomer.documentReference : 'Numéro en attente' }}
        feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' @click.stop='closeSidebar')
      .sidebar-header.pb-1.px-2
        .d-flex.align-items-center
          Status(:statusId="orderFormCustomer.status" nature="orderFormCustomer" :key="orderFormCustomer.id+'_'+orderFormCustomer.status")
          span {{ orderFormCustomer.documentDate ? "En date du : " + dayjs(orderFormCustomer.documentDate).format("DD/MM/YYYY") : "" }}
        .end
      vs-divider.m-0
      .sidebar-action.py-05.px-2
        .d-flex.align-items-center.justify-content-between.w-100
          .d-flex
            b-button-group
              b-button.btn-icon.d-flex(variant='primary' @click="editOrderFormCustomer(orderFormCustomer.id, 'order-form-customer-pdf')" :style="'border-right:1px solid #ccc !important;'")
                feather-icon(icon='EyeIcon')
              b-button.btn-icon.d-flex(v-if="statusCheck.orderFormCustomerEditable.includes(orderFormCustomer.status)" style='border-left:1px solid #ccc !important;' variant='primary' @click="editOrderFormCustomer(orderFormCustomer.id, 'order-form-customer-edit')")
                feather-icon(icon='EditIcon')
                span(style='margin-left:3px') Modifier
              b-button.btn-icon.d-flex(v-if='orderFormCustomer.status >= 3 && orderFormCustomer.path' @click.stop='forceFileDownload(orderFormCustomer.path)' style='border-left:1px solid #ccc !important;' variant='primary')
                feather-icon.align-middle(icon='DownloadIcon')
                span(style='margin-left:3px') Télécharger
            b-dropdown.dropdown-icon-wrapper.ml-50(v-if='!isLoadingOrderFormCustomer && orderFormCustomer.status > 2 && orderFormCustomer.status != 5 && orderFormCustomer.status != 6 && finaleInvoiceExist == 0' variant='success' left='')
              template(#button-content)
                feather-icon.align-middle(icon='FileIcon')
                span(style='margin-left:3px') Facturer
              div(v-if='initialDocument && initialDocument.orderFormCustomerDownPaymentsRequests && situationInvoiceExist <= 1')
                b-dropdown-item(@click='generateDownPaymentInvoice(item)' v-for='(item, index) in initialDocument.orderFormCustomerDownPaymentsRequests' :key='index')
                  | Acompte de {{ formatNumber(item.number) }} {{ downPaymentRequestTypeTranslate(item.downPaymentRequestType) }} {{ item.downPaymentRequestDue == 3 ? item.label : downPaymentRequestDueTranslate(item.downPaymentRequestDue) }}
              b-dropdown-item(:disabled="situationInvoiceExist > 1" @click="generateDownPaymentInvoice()") Facture d'acompte {{ downPaymentInvoiceExist <= 1 ? '' : '#' + downPaymentInvoiceExist }}
              b-dropdown-item(:disabled="lastInvoice ? lastInvoice.status == 0 || lastInvoice.allWorkBilled : false" @click="generateSituationInvoice") Facture de situation {{ situationInvoiceExist <= 1 ? '' : '#' + situationInvoiceExist }}
              b-dropdown-item(:disabled="lastInvoice ? lastInvoice.nature == 3 || lastInvoice.nature == 4 : false" @click="generateFinaleInvoice") Facture finale
            b-dropdown.dropdown-icon-wrapper.ml-50(v-if='isLoadingOrderFormCustomer && orderFormCustomer.status > 2 && orderFormCustomer.status != 5 && orderFormCustomer.status != 6 && finaleInvoiceExist == 0' variant='success' left='')
              template(#button-content)
                span.mr-50 Facturer
                b-spinner(variant="white" small label="Spinning")
          .d-flex.align-items-center.justify-end
            b-button-group
              b-dropdown.dropdown-icon-wrapper(variant='outline-primary' left)
                template(#button-content)
                  //- feather-icon.align-middle(icon='MoreHorizontalIcon')
                  | + d'actions
                // ON PEUT DUPLIQUER UNE COMMANDE
                b-dropdown-item(@click.stop='newOrderFormProvider')
                  | Créer une commande fournisseur
                b-dropdown-divider
                b-dropdown-item(@click.stop='duplicateOrderFormCustomerLocal')
                  | Dupliquer la commande client
                // REVISER LA COMMANDE
                b-dropdown-item(v-if='(orderFormCustomer.status == 3 || orderFormCustomer.status == 7 || (orderFormCustomer.status == 4 && orderFormCustomerReviewAvailable))' @click.stop='reviseOrderFormCustomer')
                  | Réviser la commande client
                //
                  JE PEUX ANNULER MON DEVIS LORSQUE CELUI CI EST AU STATUT EST VALIDE
                b-dropdown-item(v-if='(orderFormCustomer.status == 3 || orderFormCustomer.status == 7 || orderFormCustomer.status == 4)' @click.stop='toggleCancelModal')
                  | Annuler la commande client
                //
                  JE PEUX SUPPRIMER MA COMMANDE LORSQUE CELUI CI EST AU STATUT BROUILLON
                b-dropdown-divider(v-if='(orderFormCustomer.status == 0 || orderFormCustomer.status == 1)')
                b-dropdown-item(v-if='(orderFormCustomer.status == 0 || orderFormCustomer.status == 1)' @click.stop='deleteOrderFormCustomerLocal(orderFormCustomer.id, orderFormCustomer.documentReference)')
                  | Supprimer la commande client
      vs-divider.m-0
      b-row.content-scrollable-sticky.m-0.justify-content-center
          b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
            ul.d-flex.border-bottom-grey-light.px-1
              li
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'details' ? 'current' : ''" @click="tabActive = 'details'")
                  span Détails
              li(v-if="haveGedModule")
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'files' ? 'current' : ''" @click="tabActive = 'files'")
                  span Documents
              li
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'notes' ? 'current' : ''" @click="tabActive = 'notes'")
                  span Notes
                  feather-icon.align-middle.ml-50(v-if="orderFormCustomer.internalNote" icon='MessageCircleIcon')
      .sidebar-content.with-tabs
        div(v-if='tabActive == "details"')
          div(v-if='orderFormCustomer.companyId')
            .d-flex.justify-content-between
              h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@click="editCompany(orderFormCustomer.companyId, 'client')" @mouseover="showCompanyEdit=true" @mouseleave="showCompanyEdit=false" style="flex: 1;")
                span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ orderFormCustomer.companyName }}
                  feather-icon.ml-50(v-show="showCompanyEdit" icon='ExternalLinkIcon')
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Client
            div
              span(v-show='orderFormCustomer.companyAddress') {{ orderFormCustomer.companyAddress }}
                br
              span(v-show='orderFormCustomer.companyAddressComplement') {{ orderFormCustomer.companyAddressComplement }}
                br
              span(v-show='orderFormCustomer.companyZipCode || orderFormCustomer.companyCity') {{ orderFormCustomer.companyZipCode }} {{ orderFormCustomer.companyCity }}
                br
              span(v-show='orderFormCustomer.companyCountry') {{ orderFormCustomer.companyCountry }}
            ul.listTab.mt-1
              li(v-if='orderFormCustomer.companyEmail')
                span Email
                span {{ orderFormCustomer.companyEmail }}
              li(v-if='orderFormCustomer.companyPhoneNumber')
                span Téléphone
                span {{ orderFormCustomer.companyPhoneNumber }}
            vs-divider
          
          div(v-if='orderFormCustomer.affairId')
            .d-flex.justify-content-between.mb-1
                h5.mb-0.d-flex.mb-0.text-primary.cursor-pointer(@click="editAffair(orderFormCustomer.affairId)" @mouseover="showAffairEdit=true" @mouseleave="showAffairEdit=false" style="flex: 1;")
                  span(style='min-width: fit-content; margin-right: -5px')
                    feather-icon(icon='ChevronRightIcon') 
                    | {{ orderFormCustomer.affairName }}
                    feather-icon.ml-50(v-show="showAffairEdit" icon='ExternalLinkIcon')
                .d-flex
                  div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                  div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                    div.px-50
                      small Affaire liée
          vs-divider(v-if='orderFormCustomer.affairId')     
          div(v-if='!orderFormCustomer.isHideTotalPrice')
            TotalOrderFormCustomerFromImport(v-if='orderFormCustomer.usedUrlGed' :canEditDiscount='false')
            TotalOrderTrackingCustomer(v-else)
            vs-divider
          div(v-if='initialDocument && initialDocument.quote')
            .d-flex.justify-content-between.align-items-center
              .d-flex
                span.material-icons.text-gray(style="font-size:20px") description
                div
                  span.ml-50
                    | Sur devis {{ initialDocument.quote.documentReference }}
              ejs-dropdownbutton.dropdownbutton-redirect(:select="onSelectQuote" :items="itemsComputed({id: initialDocument.quote.id, documentReference: initialDocument.quote.documentReference})" iconCss='e-icons e-chevron-down' cssClass='e-caret-hide')
            vs-divider  
          div(v-if='(initialDocument && initialDocument.invoices && initialDocument.invoices.length > 0)')
            .d-flex.justify-content-between
              h5.mb-1.d-flex.align-items-center.mb-0.text-primary
                span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | Documents liés
            div(v-if="initialDocument && initialDocument.invoices && initialDocument.invoices.length > 0")
              .mb-50.d-flex.justify-content-between.align-items-center(v-for='(item, index) in initialDocument.invoices' :key='index')
                .d-flex
                  span.material-icons.text-gray(style="font-size:20px") description
                  div
                    span.ml-50 
                      | {{ natureTranslate(item.nature) }}{{ item.nature == 2 || item.nature == 3 || item.nature == 4 ? (item.documentStep ? ` #${item.documentStep}` : '') : '' }} {{ item.documentReference }} du 
                      span.font-weight-bold {{ formatDate(item.documentDate) }} 
                      | de 
                      span.font-weight-bold {{ formatCurrency(item.totalTtc) }} TTC ({{ statusTranslate(item.status) }})
                ejs-dropdownbutton.dropdownbutton-redirect(:select="onSelectDocument" :items="itemsComputed({id: item.id, documentReference: item.documentReference, nature: item.nature})" iconCss='e-icons e-chevron-down' cssClass='e-caret-hide')
            vs-divider
          HistoryOrderFormCustomer

        div.h-100(v-if='tabActive == "files"')
          ged-viewer-sidebar(:parentFolder="orderFormCustomer.folderId" fromModule="orderFormCustomer")
        div.h-100(v-if='tabActive == "notes"')
          quill-editor(id="text-area-designation" v-model="orderFormCustomer.internalNote" toolbar="essential" @blur="updateOrderFormCustomerWithoutLoading" class="editor quill-fixed" :options="editorOption" style="height: calc(100% - 55px);")               
    popup-new-order-form-provider-from-order-form-customer(ref='popupNewOrderFormProviderFromOrderFormCustomer' :orderFormCustomerId="orderFormCustomer.id") 
    popup-new-down-payment-invoice(:activePopupDownPaymentInvoice='activePopupDownPaymentInvoice' @toggleModalNewDownPaymentInvoice='toggleModalNewDownPaymentInvoice' :preRenderingData='preRenderingData' type="ordeFormCustomer")
    popup-new-situation-invoice(ref='popup-new-situation-invoice' :activePopupSituationInvoice='activePopupSituationInvoice' @toggleModalNewSituationInvoice='toggleModalNewSituationInvoice' type="ordeFormCustomer")
    b-modal(ref='cancel-modal' hide-footer title="Confirmer l'annulation")
      .d-block.text-center
        h3 Confirmer l'annulation
        p
          | L'annulation de cette commande client est définitive, souhaitez-vous continuer ?
      .mt-1.cancel-modal-buttons
        b-button.mr-1(variant='outline-primary' @click='toggleCancelModal') Non
        b-button(variant='danger' @click='cancelOrderFormCustomer') Oui
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";

import dayjs from "dayjs";
import axios from "axios";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import Status from "@/components/global/status/Status";
import TotalOrderFormCustomer from "@/components/invoice/order-form-customer/builder/TotalOrderFormCustomer";
import TotalOrderTrackingCustomer from "@/components/invoice/order-form-customer/builder/TotalOrderTrackingCustomer";

import TotalOrderFormCustomerFromImport from "@/components/invoice/order-form-customer/builder/TotalOrderFormCustomerFromImport";
import HistoryOrderFormCustomer from "@/components/invoice/order-form-customer/HistoryOrderFormCustomer";
import GedViewerSidebar from "@/components/ged/GedViewerSidebar";
import PopupNewDownPaymentInvoice from "@/components/invoice/PopupNewDownPaymentInvoice";
import PopupNewSituationInvoice from "@/components/invoice/PopupNewSituationInvoice";
import { EDocumentStatus } from "@/types/api-orisis/enums/enums";
import { InvoiceOptionModel } from "@/types/api-orisis/models/InvoiceOptionModel";
import { checkFunctionUser } from "@/auth/utils.ts";

import {
  formatCurrency,
  formatNumber,
  formatDate,
} from "@/types/api-orisis/library/FormatOperations.ts";
import {
  natureTranslate,
  downPaymentRequestTypeTranslate,
  downPaymentRequestDueTranslate,
  natureFolderTranslate,
  statusTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";
import { statusCheck } from "@/types/api-orisis/enums/enums";

import PopupNewOrderFormProviderFromOrderFormCustomer from "@/components/invoice/order-form-customer/PopupNewOrderFormProviderFromOrderFormCustomer.vue";

export default {
  data() {
    return {
      InvoiceOptionModel,
      dayjs: dayjs,
      tabActive: "details",
      showCompanyEdit: false,
      showAffairEdit: false,
      activePopupDownPaymentInvoice: false,
      activePopupSituationInvoice: false,
      customerHaveCredits: false,
      preRenderingData: null,
      statusCheck,
      editorOption: {
        placeholder: "Ecrivez une note interne liée à cette commande client...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
          ],
        },
      },
      dataOrigine: {},
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingOrderFormCustomer",
      "isCreatingOrderFormCustomer",
      "orderFormCustomersList",
      "isLoadingOrderFormCustomer",
      "isDeletingOrderFormCustomer",
      "lastInvoice",
      "orderFormCustomerPDF",
      "isLoadingOrderFormCustomerPDF",
      "isLoadingDuplicatingOrderFormCustomer",
      "institutionSettingsActive",
      "workspaceSelected",
      "initialDocument",
      "orderFormCustomerOption",
      "paymentConditionsList",
    ]),
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
    orderFormCustomerDetails: {
      get() {
        return this.$store.getters["getOrderFormCustomerDetails"];
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER_DETAILS", value);
      },
    },
    documentDetails: {
      get() {
        return this.$store.getters["getDocumentDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT_DETAILS", value);
      },
    },
    isLoadingDuplicatingOrderFormCustomer: {
      get() {
        return this.$store.getters["isLoadingDuplicatingOrderFormCustomer"];
      },
      set(value) {
        return this.$store.commit(
          "SET_IS_LOADING_DUPLICATING_ORDER_FORM_CUSTOMER",
          value
        );
      },
    },
    orderFormCustomerReviewAvailable() {
      if (
        this.initialDocument &&
        this.initialDocument.invoices &&
        this.initialDocument.invoices.length > 0
      ) {
        return this.initialDocument.invoices
          .map((elem) => (elem.status === 5 ? true : false))
          .every((elem) => elem === true);
      }
      return false;
    },
    downPaymentInvoiceExist() {
      if (this.initialDocument) {
        return this.initialDocument.invoices &&
          this.initialDocument.invoices.length > 0
          ? this.initialDocument.invoices.filter(
              (item) => item.nature == 2 && item.status != 5
            ).length + 1
          : 0;
      }
      return 0;
    },
    situationInvoiceExist() {
      if (this.initialDocument && this.initialDocument.invoices) {
        let situationInvoice = this.initialDocument.invoices
          .filter(
            (item) => (item.nature == 3 || item.nature == 4) && item.status != 5
          )
          .sort((a, b) => a.documentStep > b.documentStep);
        if (situationInvoice.length > 0) {
          situationInvoice =
            situationInvoice[situationInvoice.length - 1].documentStep + 1;
        } else {
          situationInvoice = 1;
        }
        return situationInvoice;
      }
      return 0;
    },
    finaleInvoiceExist() {
      if (this.initialDocument) {
        return this.initialDocument.invoices &&
          this.initialDocument.invoices.length > 0
          ? this.initialDocument.invoices.filter(
              (item) => item.nature == 1 && item.status != 5
            ).length
          : 0;
      }
      return 0;
    },
    haveGedModule() {
      return (
        this.checkFunctionUser(42, "all") && this.checkFunctionUser(43, "all")
      );
    },
  },
  methods: {
    ...mapActions([
      "getOrderFormCustomerById",
      "getLastOrderFormCustomerReference",
      "updateOrderFormCustomer",
      "deleteOrderFormCustomer",
      "createDeduction",
      "duplicateOrderFormCustomer",
      "createInvoice",
      "createDocumentDetailsToInvoiceIdFromOrderFormCustomerId",
      "createInvoiceOption",
      "getInvoiceOptionDefaultByInvoiceId",
    ]),
    checkFunctionUser,
    formatCurrency,
    formatNumber,
    formatDate,
    downPaymentRequestTypeTranslate,
    downPaymentRequestDueTranslate,
    natureFolderTranslate,
    statusTranslate,
    natureTranslate,
    async updateOrderFormCustomerWithoutLoading() {
      if (
        this.dataOrigine.internalNote !== this.orderFormCustomer.internalNote
      ) {
        this.updateOrderFormCustomer({
          orderFormCustomer: this.orderFormCustomer,
          updateState: false,
        }).then((res) => {
          this.$emit("refreshRow", res);
        });
      }
    },
    onSelectQuote(args) {
      this.closeSidebar();
      if (args.item.text == "Visualiser") {
        this.$router.push({
          name: "edit-quote",
          params: {
            mode: "document-pdf",
            id: args.item.id,
            title: args.item.documentReference
              ? args.item.documentReference
              : "Editer un devis",
            tips:
              "Editer le devis" + args.item.documentReference
                ? " : " + args.item.documentReference
                : "",
            routeOrigine: "quotes",
          },
        });
      } else {
      }
    },
    onSelectDocument(args) {
      this.closeSidebar();
      if (args.item.text == "Visualiser") {
        if (args.item.nature == 0) {
          this.$router.push({
            name: "edit-quote",
            params: {
              mode: "document-pdf",
              id: args.item.id,
              title: args.item.documentReference
                ? args.item.documentReference
                : "Editer un devis",
              tips:
                "Editer le devis" + args.item.documentReference
                  ? " : " + args.item.documentReference
                  : "",
              routeOrigine: "quotes",
            },
          });
        } else if (
          args.item.nature == 1 ||
          args.item.nature == 2 ||
          args.item.nature == 3 ||
          args.item.nature == 4
        ) {
          this.$router.push({
            name: "edit-invoice",
            params: {
              mode: "document-pdf",
              id: args.item.id,
              title: args.item.documentReference
                ? args.item.documentReference
                : "Editer une facture",
              tips:
                "Editer la facture" + args.item.documentReference
                  ? " : " + args.item.documentReference
                  : "",
              routeOrigine: "invoices",
            },
          });
        } else if (args.item.nature == 5 || args.item.nature == 6) {
          this.$router.push({
            name: "edit-credit",
            params: {
              mode: "document-pdf",
              id: args.item.id,
              title: args.item.documentReference
                ? args.item.documentReference
                : "Editer un avoir",
              tips:
                "Editer l'avoir" + args.item.documentReference
                  ? " : " + args.item.documentReference
                  : "",
              routeOrigine: "credits",
            },
          });
        }
      } else {
      }
    },
    itemsComputed(args) {
      return [
        {
          text: "Visualiser",
          id: args.id,
          documentReference: args.documentReference,
          nature: args.nature ? args.nature : null,
        },
        // {
        //   text: 'Télécharger',
        //   id: args.id,
        //   documentReference: args.documentReference,
        //   nature: args.nature ? args.nature : null
        // },
      ];
    },
    async editOrderFormCustomer(id, mode) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-order-form-customer",
        params: {
          mode: mode,
          id: id,
          title: this.orderFormCustomer.documentReference
            ? this.orderFormCustomer.documentReference
            : "Editer une commande client",
          tips:
            "Editer la commande client" +
            this.orderFormCustomer.documentReference
              ? " : " + this.orderFormCustomer.documentReference
              : "",
          routeOrigine: "order-form-customers",
        },
      });
    },
    async showAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "affair",
        params: {
          id: id,
          title: "Affaire : " + this.orderFormCustomer.affairName,
          tips: "Affaire : " + this.orderFormCustomer.affairName,
          routeOrigine: "affairs",
        },
      });
    },
    async editCompany(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-company",
        params: {
          id: id,
          title: "Edit : " + this.orderFormCustomer.companyName,
          tips: "Editer l'entreprise : " + this.orderFormCustomer.companyName,
        },
      });
    },
    async editAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-affair",
        params: {
          id: id,
          title: "Edit : " + this.orderFormCustomer.affairName,
          tips: "Editer l'affaire : " + this.orderFormCustomer.affairName,
        },
      });
    },
    async getOrderFormCustomerDetails(id) {
      if (id != "") {
        await this.getOrderFormCustomerById({ orderFormCustomerId: id });
        this.tabActive = "details";
      }
    },
    refusedOrderFormCustomer() {
      this.$bvModal
        .msgBoxConfirm(
          "En validant, votre commande client passera au statut refusée",
          {
            title: "Attention",
            size: "sm",
            okVariant: "success",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            await this.updateOrderFormCustomer({
              orderFormCustomer: { ...this.orderFormCustomer, status: 5 },
              loading: true,
            }).then((res) => {
              this.$emit("refreshRow", res);
            });
          }
        });
    },
    async reviseOrderFormCustomer() {
      this.$bvModal
        .msgBoxConfirm(
          'En validant, votre commande client sera deverrouillée et repassera au statut "A valider" vous permettant son édition.',
          {
            title: "Attention",
            size: "sm",
            okVariant: "success",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            await this.updateOrderFormCustomer({
              orderFormCustomer: {
                ...this.orderFormCustomer,
                status: 2,
                path: null,
              },
              loading: false,
            }).then((res) => {
              this.$emit("refreshRow", res);
            });
            this.editOrderFormCustomer(
              this.orderFormCustomer.id,
              "order-form-customer-edit"
            );
          }
        });
    },
    duplicateOrderFormCustomerLocal() {
      this.isLoadingDuplicatingOrderFormCustomer = true;
      this.duplicateOrderFormCustomer({
        orderFormCustomerId: this.orderFormCustomer.id,
      }).then(async (res) => {
        this.closeSidebar();
        this.isLoadingDuplicatingOrderFormCustomer = false;
        this.$router.push({
          name: "new-order-form-customer",
          params: {
            id: res.data.id,
            title: "Nouvelle commande client",
            tips: "Nouvelle commande client",
            routeOrigine: "order-form-customers",
          },
        });
      });
    },
    newOrderFormProvider() {
      this.$refs.popupNewOrderFormProviderFromOrderFormCustomer.toggleModal();
    },
    deleteOrderFormCustomerLocal(id, reference) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            'Êtes-vous sûr de vouloir supprimer la commande client "' +
            reference +
            '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteOrderFormCustomer({
              orderFormCustomerIds: [id],
              route: this.$route.name,
            });
            this.closeSidebar();
          }
        });
    },
    generateDownPaymentInvoice(preRenderingData = null) {
      this.closeSidebar();
      this.preRenderingData = preRenderingData;
      if (this.orderFormCustomer.status < 4) {
        this.$bvModal
          .msgBoxConfirm(
            'Pour facturer cette commande client, vous devez la passer en statut "Facturé"',
            {
              title: "Attention",
              size: "sm",
              okVariant: "success",
              okTitle: "Facturer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.updateOrderFormCustomer({
                orderFormCustomer: { ...this.orderFormCustomer, status: 4 },
                loading: false,
              }).then((res) => {
                this.$emit("refreshRow", res);
                this.toggleModalNewDownPaymentInvoice(true);
              });
            }
          });
      } else {
        this.toggleModalNewDownPaymentInvoice(true);
      }
    },
    generateSituationInvoice() {
      this.closeSidebar();
      if (this.orderFormCustomer.status < 4) {
        this.$bvModal
          .msgBoxConfirm(
            'Pour facturer ce devis, vous devez le passer en statut "Facturé"',
            {
              title: "Attention",
              size: "sm",
              okVariant: "success",
              okTitle: "Facturer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.updateOrderFormCustomer({
                orderFormCustomer: { ...this.orderFormCustomer, status: 4 },
                loading: false,
              }).then((res) => {
                this.$emit("refreshRow", res);
                this.toggleModalNewSituationInvoice(true);
              });
            }
          });
      } else {
        if (this.lastInvoice && this.lastInvoice.nature == 4) {
          this.$refs["popup-new-situation-invoice"].createSituationInvoice(4);
        } else {
          this.toggleModalNewSituationInvoice(true);
        }
      }
    },
    async generateFinaleInvoice() {
      this.closeSidebar();
      if (this.orderFormCustomer.status < 3) {
        this.$bvModal
          .msgBoxConfirm(
            'Pour facturer cette commande client, vous devez la passer en statut "Validée"',
            {
              title: "Attention",
              size: "sm",
              okVariant: "success",
              okTitle: "Facturer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then(async (value) => {
            if (value) {
              await this.updateOrderFormCustomer({
                orderFormCustomer: { ...this.orderFormCustomer, status: 4 },
                loading: false,
              }).then(async (res) => {
                this.$emit("refreshRow", res);
                await this.createFinaleInvoice();
              });
            }
          });
      } else {
        await this.createFinaleInvoice();
      }
    },
    toggleModalNewDownPaymentInvoice(bool = false) {
      this.activePopupDownPaymentInvoice = bool;
    },
    toggleModalNewSituationInvoice(bool = false) {
      this.activePopupSituationInvoice = bool;
    },
    async createFinaleInvoice() {
      let invoiceOptionsDefault = InvoiceOptionModel({});
      let invoiceOptions = InvoiceOptionModel({});
      let initialDocument = structuredClone(this.initialDocument);
      let orderFormCustomer = structuredClone(this.orderFormCustomer);
      let initialDocumentOptions = structuredClone(
        this.orderFormCustomerOption
      );
      await this.getInvoiceOptionDefaultByInvoiceId({
        updateState: false,
      }).then((res) => {
        invoiceOptionsDefault = res;
        invoiceOptions = {
          invoiceId: 0,
          ...res,
          calculateMargin: initialDocumentOptions.calculateMargin,
          showDiscount: initialDocumentOptions.showDiscount,
          showDescription: initialDocumentOptions.showDescription,
          id: 0,
        };
      });

      // Le document généré vient d'ORISIS
      documentInvoice.usedUrlGed = false;

      let deduction = null;
      await this.getLastOrderFormCustomerReference({}).then(async (res) => {
        let documentInvoice = structuredClone(orderFormCustomer);
        // Stocker l'id du devis
        documentInvoice.quoteId =
          initialDocument.initialDocumentNature == "quote"
            ? structuredClone(initialDocument.id)
            : structuredClone(initialDocument.quote?.id);
        // Stocker l'id du bon de commande
        documentInvoice.orderFormCustomerId = orderFormCustomer.id;
        // Mettre à 0 l'id de la facture
        documentInvoice.id = 0;
        // Mettre la paymentConditionId
        let paymentCondition = this.paymentConditionsList.find(
          (item) => item.id == invoiceOptionsDefault.paymentConditionDefaultId
        );

        documentInvoice.paymentConditionId = paymentCondition
          ? paymentCondition.id
          : 1;
        // Aller chercher la date de validité dans limitDate
        documentInvoice.limitDate = paymentCondition
          ? dayjs().add(paymentCondition.days, "day").toISOString()
          : dayjs().toISOString();
        // Mettre la nature à 1
        documentInvoice.nature = 1;
        // Ajouter isCounted à false,
        documentInvoice.isCounted = false;
        // Mettre documentDate à la date du jour
        documentInvoice.documentDate = new Date().toISOString();
        // Mettre documentReference à res
        documentInvoice.documentReference = res.data;
        // Mettre le status à 8
        documentInvoice.status = 8;
        // Calculer le prix Ht
        documentInvoice.totalHt = orderFormCustomer.totalHt;
        // Calculer le prix Ttc et le reste à payer pour la facture d'acompte
        documentInvoice.totalTtc = documentInvoice.remainingToPayTtc =
          orderFormCustomer.totalTtc;
        // Mettre à jour le documentStep
        documentInvoice.documentStep = 1;
        // Mettre à jour les paiements
        documentInvoice.path = null;
        documentInvoice.payments = [];
        // Mettre a jour la remise globale si le devis avait une remise globale
        documentInvoice.discountGlobalNumber =
          orderFormCustomer.discountGlobalNumber;
        documentInvoice.discountGlobalType =
          orderFormCustomer.discountGlobalType;
        // Mettre à jour invoiceBTP
        if (orderFormCustomer.orderFormCustomerBtp != null) {
          documentInvoice.invoiceBTP = {
            id: 0,
            workStartDate: orderFormCustomer.orderFormCustomerBtp.workStartDate,
            estimatedDurationNumber:
              orderFormCustomer.orderFormCustomerBtp.estimatedDurationNumber,
            estimatedDurationType:
              orderFormCustomer.orderFormCustomerBtp.estimatedDurationType,
          };
        } else {
          documentInvoice.invoiceBTP = null;
        }
        // Mettre à jour invoiceCEE
        if (orderFormCustomer.orderFormCustomerCee != null) {
          documentInvoice.invoiceCEE = {
            id: 0,
            technicalVisitDate:
              orderFormCustomer.orderFormCustomerCee.technicalVisitDate,
            amountPremium: orderFormCustomer.orderFormCustomerCee.amountPremium,
            precarityType: orderFormCustomer.orderFormCustomerCee.precarityType,
            parcelNumber: orderFormCustomer.orderFormCustomerCee.parcelNumber,
          };
        } else {
          documentInvoice.invoiceCEE = null;
        }

        // Mettre à jour les déductions
        if (orderFormCustomer.deduction) {
          deduction = structuredClone(orderFormCustomer.deduction);
        }
        if (initialDocumentOptions) {
          let baseMessage = "<p>Notes du bon de commande :</p>";
          let tempDivInvoiceOptionsDefault = document.createElement("div");
          tempDivInvoiceOptionsDefault.innerHTML =
            invoiceOptionsDefault?.comment;
          tempDivInvoiceOptionsDefault = tempDivInvoiceOptionsDefault.innerText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\s+/g, "")
            .replace(/[^a-zA-Z0-9]/g, "")
            .toLowerCase();

          let tempDivDocumentInvoice = document.createElement("div");
          tempDivDocumentInvoice.innerHTML = documentInvoice.comments;
          tempDivDocumentInvoice = tempDivDocumentInvoice.innerText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/\s+/g, "")
            .replace(/[^a-zA-Z0-9]/g, "")
            .toLowerCase();

          if (tempDivDocumentInvoice.includes(tempDivInvoiceOptionsDefault)) {
            documentInvoice.comments = documentInvoice.comments;
          } else {
            documentInvoice.comments =
              (documentInvoice?.comments ? baseMessage : "") +
              (invoiceOptionsDefault?.comment
                ? invoiceOptionsDefault?.comment + "<br>"
                : "") +
              (documentInvoice?.comments ? documentInvoice?.comments : "");
            if (structuredClone(documentInvoice.comments).length === 0) {
              documentInvoice.comments = invoiceOptionsDefault.comment;
            }
          }
        }

        // Mettre à jour le documentDetails et mettre à jours les totaux dans le store
        await this.createInvoice({
          invoice: documentInvoice,
        }).then(async (res) => {
          documentInvoice.id = res.data.id;
          await this.createDocumentDetailsToInvoiceIdFromOrderFormCustomerId({
            invoiceId: res.data.id,
            orderFormCustomerId: orderFormCustomer.id,
          });
          await this.createInvoiceOption({
            invoiceOption: { ...invoiceOptions, invoiceId: res.data.id },
          });
          if (deduction)
            await this.applyOrderFormCustomerDeduction(
              deduction,
              res.data.id,
              orderFormCustomer
            );
          this.$router.push({
            name: "new-invoice",
            params: {
              id: res.data.id,
              title: "Nouvelle facture",
              tips: "Nouvelle facture",
              routeOrigine: "invoices",
            },
          });
        });
      });
    },
    async applyOrderFormCustomerDeduction(
      deduction,
      invoiceId,
      orderFormCustomer
    ) {
      let invoiceDeduction = {
        creditId: null,
        holdbackDate: deduction.holdbackDate,
        holdbackNumber: deduction.holdbackNumber,
        holdbackPercent: deduction.holdbackPercent,
        id: 0,
        invoiceId: invoiceId,
        prorataAccountLabel: deduction.prorataAccountLabel,
        prorataAccountPercent: deduction.prorataAccountPercent,
        prorataAccountPrice: deduction.prorataAccountPrice,
        punctualDeductions: deduction.punctualDeductions.map((deduction) => {
          return {
            ...deduction,
            id: 0,
          };
        }),
        quoteId: null,
        orderFormCustomerId: null,
        reviews: deduction.reviews.map((review) => {
          return {
            ...review,
            id: 0,
          };
        }),
      };
      await this.createDeduction({ deduction: invoiceDeduction });
    },
    toggleCancelModal() {
      this.$refs["cancel-modal"].toggle("#toggle-btn");
    },
    cancelOrderFormCustomer() {
      this.updateOrderFormCustomer({
        orderFormCustomer: { ...this.orderFormCustomer, status: 6 },
        loading: false,
      }).then((res) => {
        this.$emit("refreshRow", res);
        this.toggleCancelModal();
      });
    },

    forceFileDownload(donwload) {
      // TODO : ATTENTION FONCTIONNE PAS LOCAL, EXIGE CORS UNCLOCK
      axios({
        url: donwload,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fullUri = document.createElement("a");
        fullUri.href = fileURL;
        fullUri.setAttribute(
          "download",
          this.orderFormCustomer.documentReference
        );
        document.body.appendChild(fullUri);
        fullUri.click();
        URL.revokeObjectURL(fullUri.href);
      });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  components: {
    VuePerfectScrollbar,
    quillEditor,
    Status,
    TotalOrderTrackingCustomer,
    TotalOrderFormCustomer,
    HistoryOrderFormCustomer,
    GedViewerSidebar,
    PopupNewDownPaymentInvoice,
    PopupNewSituationInvoice,
    TotalOrderFormCustomerFromImport,
    PopupNewOrderFormProviderFromOrderFormCustomer,
  },
};
</script>

<style lang="scss" scoped>
.sidebar-action {
  flex-direction: column;
}

.cancel-modal-buttons {
  display: flex;
  justify-content: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;

    .dropdown-content-item {
      padding: 10px 10px 10px 10px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdownbutton-redirect {
  background-color: #ffff;
  border-color: #ffff;
  color: #5c636a;
}
</style>
