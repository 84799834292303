<template lang="pug"> 
div
    b-modal#modal-prevent-closing-new-order-form-provider(ok-title="Nouveau bon de commande fournisseur" hide-footer cancel-title="Annuler" size="lg" ref='modal-new-order-form-provider' title='Nouveau devis' @ok='handleOk' :header-class="'bg-light-primary'" :ok-disabled="!institutionSettingsActive")
        template.d-block(#modal-header="{ close }")
          div.w-100
            button.absolute(type="button" aria-label="Close" class="close" @click="close()") ×
            b-row.my-0
              b-col.py-0.mb-0(cols='12' style="padding:0px !important")
                h3.text-primary Nouveau bon de commande fournisseur
            b-row.content-scrollable-sticky.m-0.justify-content-center
              b-col.content-scrollable-sticky-inner#nav-sticky-horizontal(cols="12" style="padding: 0 !important; height: 100% !important")
                ul.d-flex
                  li
                    a.d-flex.align-center.align-content-center.align-items-center.pl-0.w-100(:class="tabActive == 'blank' ? 'current' : ''" @click="tabActive = 'blank'")
                      span Nouveau
                  li
                    a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'fromOrderFormCustomer' ? 'current' : ''" @click="tabActive = 'fromOrderFormCustomer'")
                      span À partir d'une commande client
        .list-buttom-custom.m-1(v-show="tabActive == 'blank'")
          div.relative.doc-buttom-custom(@click="handleSubmit()")
            img.w-100(:src="getSrcIcon()")
        #order-form-provider-by-order-form-provider(v-show="tabActive == 'fromOrderFormCustomer'")
            .p-1
                b-form-group.mb-1(label='Commande client' label-for='orderFormCustomersList')
                    v-select.w-100#orderFormCustomersList(ref="orderFormCustomersList" :clearable="false" placeholder='Selectionnez une commande client' :closeOnSelect="true" :loading="isLoadingOrderFormCustomersList" :options="orderFormCustomersList" :reduce="(el) => el.id" label="documentReference" :value='orderFormCustomer' v-model='orderFormCustomer' @input="setOrderFormCustomer")
                        template(#option='{ documentReference, description }')
                            div
                                span(style='font-size:16px;white-space:normal') {{ documentReference }}
                                br
                                div(style='white-space:normal')
                                    div.w-100(style='color:#a2a3a8;font-size:13px') {{ description }}
                        template(v-slot:no-options)
                            template Aucun résultat trouvé
                b-form-group.mb-0(label='Fournisseur' label-for='providersList')
                    v-select.w-100#providersList(ref="providersList" :clearable="true" placeholder='Selectionnez un fournisseur'  :closeOnSelect="true" :loading='isLoadingCompaniesList' :options='companiesList.filter(elem => elem.companyType.id == 4 || elem.companyType.id == 8)' :reduce="(el) => el.id" label="name" v-model='provider' :value='provider' @input='setProvider')
                        template(v-slot:no-options='')
                            template  Aucun fournisseur trouv&eacute;
                        li.border-bottom.p-1.py-50(slot='list-header')
                            b-button.w-100.d-flex.justify-content-center.align-items-center(@click='newCompany()' variant='primary' size='sm')
                                | + Nouveau fournisseur
                                span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                                    | open_in_new            
                //ejs-dropdownlist(id='orderFormCustomersList' name="orderFormCustomersList" placeholder='Selectionnez une commande client' popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true'  :dataSource="orderFormCustomersList" :fields="{ text: 'documentReference', value: 'id' }" v-model='orderFormCustomer' required=""  @change="setOrderFormCustomer")
                // ejs-dropdownlist(:footerTemplate="'footerTemplate'" :itemTemplate="'itemTemplate'" popupHeight='300px' :allowFiltering='true' :dataSource='orderFormCustomersList' :fields="{ text: 'label', value: 'id' }" v-model='orderFormCustomer' @change="setOrderFormCustomer")
                    template(v-slot:footerTemplate)
                        div.p-50
                            ejs-button(v-on:click.native="newPeriodicity" :content="'+ Nouvelle commande client'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
                    template(v-slot:itemTemplate="{data}")
                        div.p-50
                           | Commande N° {{ data.documentReference }}
            .document-table(v-if="orderFormCustomer != null")
                label.pl-1 Sélectionnez des composants
                .text-center.flex-center.w-100(v-if='!headerLoaded')
                    .loading-bg-inner
                        .loader
                            .outer
                            .middle
                            .inner
                    .mt-5.mb-3
                        br 
                        br
                        br
                        | Chargement de la liste des ligne de produit/services....
                p.w-100.text-center.py-50.m-0(v-else-if="orderFormCustomerDetails.length == 0") Cette commande client ne dispose d'aucune ligne
                sync-grid#overviewgrid(v-else ref="overviewgrid" :gridData="orderFormCustomerDetails" :name="'orderFormCustomerDetails'" :headerData="headerData" :allowGrouping="allowGrouping" :allowTextWrap="true" :selectionSettings="selectionSettings" @queryCellInfo='queryCellInfoEvent' @actionBegin="actionBegin" @setSelectedRows="setSelectedRows")
            .d-flex.justify-content-between.p-1(v-if="orderFormCustomer != null" style='border-top: 1px solid #e2e2e2; padding-top: 0.5rem;')
                div
                  b-button(variant='outline-primary' @click='toggleModal')
                    feather-icon(icon='ArrowLeftIcon')
                      | Annuler
                b-button(variant='primary' @click='newOrderFormProvider')
                  | Créer le bon de commande fournisseur  
</template>
<script>
import vSelect from "vue-select";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";

import SyncGrid from "@/components/global/grid/Grid.vue";

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    SyncGrid,
    "v-select": vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    submittedNames: [],
    provider: null,
    orderFormCustomer: null,
    orderFormCustomerDetails: [],
    orderFormCustomerDetailsSelected: [],
    tabActive: "blank",
    headerData: [],
    allowGrouping: false,
    headerLoaded: false,
    selectionSettings: {
      type: "Multiple",
      enableSimpleMultiRowSelection: true,
    },
  }),
  props: {
    affairId: {
      type: Number,
      default: null,
    },
    companyId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      "institutionSelected",
      "institutionSettingsActive",
      "isLoadingInstitutionsList",
      "isLoadingOrderFormCustomersList",
      "isLoadingCompaniesList",
      "institutionsList",
      "workspaceSelected",
      "orderFormProvidersList",
      "orderFormCustomersList",
      "companiesList",
    ]),
    document: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
  },
  created() {
    this.initialize();
    this.setHeaderData();
    this.getOrderForms({ dateFrom: null, dateTo: null });
    this.getOrderFormCustomers({ dateFrom: null, dateTo: null });
  },
  methods: {
    ...mapActions([
      "addLineOrderFormProvider",
      "getOrderFormEmpty",
      "getOrderForms",
      "getOrderFormCustomers",
      "getOrderFormCustomerDetailsByOrderFormCustomerId",
    ]),
    initialize() {
      this.headerLoaded = false;
      this.orderFormCustomerDetails = [];
      this.orderFormCustomerDetailsSelected = [];
      this.provider = null;
    },
    closeDialog() {
      this.editorShown = false;
      this.$emit("close");
    },
    setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
        },
        {
          type: "string",
          field: "index",
          headerText: "",
          width: 60,
          minWidth: 60,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: false,
          visible: true,
        },
        {
          type: "string",
          field: "description",
          headerText: "Description",
          minWidth: 300,
          width: 300,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: false,
          visible: true,
          clipMode: "EllipsisWithTooltip",
          template: "stripHtmlTemplate",
        },
        {
          type: "number",
          format: "formatNumber",
          field: "quantity",
          headerText: "Qté",
          minWidth: 90,
          width: 90,
          maxWidth: 90,
          textAlign: "center",
          allowEditing: false,
          allowFiltering: false,
          allowSorting: false,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 1,
          editType: "numericedit",
          edit: { params: { decimals: 2 } },
          validationRules: { required: true },
          customCss: "e-column-border-left-light",
        },
      ];
    },
    queryCellInfoEvent(args) {
      if (
        [0, 1, 2, 11, 12, 13].includes(args.data.type) &&
        args.column.field == "description"
      ) {
        args.colSpan = 11;
        args.column.customCss = "col-title";
      }
    },
    setSelectedRows(val) {
      this.orderFormCustomerDetailsSelected = val;
    },
    actionBegin(args) {},
    setProvider(item) {
      this.provider = item;
    },
    setOrderFormCustomer(item) {
      this.headerLoaded = false;
      this.orderFormCustomerDetails = [];
      this.orderFormCustomerDetailsSelected = [];
      this.getOrderFormCustomerDetailsByOrderFormCustomerId({
        orderFormCustomerId: item,
        updateState: false,
        notStored: true,
      }).then((orderFormCustomerDetails) => {
        this.orderFormCustomerDetails = orderFormCustomerDetails;
        this.headerLoaded = true;
      });
      // this.orderFormCustomer = value;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async newOrderFormProvider() {
      this.$store.commit(
        "SET_IS_GLOBAL_MESSAGE",
        "Création de la commande fournisseur en cours..."
      );
      this.$store.commit("SET_IS_GLOBAL_CREATING", true);
      this.getOrderFormEmpty({
        affairId: null,
        providerId: this.provider ? this.provider : null,
        customerId: null,
        storageLocationId: null,
        institutionId: this.institutionSelected.id,
      })
        .then(async (orderFormProvider) => {
          let allPromises = [];

          this.orderFormCustomerDetailsSelected.forEach((line) => {
            allPromises.push(
              this.addLineOrderFormProvider({
                payload: {
                  id: 0,
                  index: line.index,
                  reference: "",
                  description: line.description,
                  quantity: line.quantity,
                  discount: 0,
                  unitPriceHt: 0,
                  type:
                    line.type == 0
                      ? 1
                      : line.type == 1
                      ? 6
                      : line.type == 2
                      ? 4
                      : line.type == 4
                      ? 7
                      : line.type == 5
                      ? 8
                      : line.type == 6
                      ? 7
                      : line.type == 7
                      ? 8
                      : line.type == 8
                      ? 9
                      : line.type == 9
                      ? 10
                      : line.type == 11
                      ? 2
                      : line.type == 12
                      ? 3
                      : line.type == 13
                      ? 5
                      : line.type == 14
                      ? 7
                      : line.type == 15
                      ? 8
                      : line.type == 16
                      ? 9
                      : line.type == 17
                      ? 8
                      : line.type == 18
                      ? 10
                      : 7,
                  unitId: line.unitId,
                  amountHt: line.disbursedNetHt ? line.disbursedNetHt : 0,
                  referencielTvaId: line.referencielTvaId
                    ? line.referencielTvaId
                    : 0,
                  productProviderId: null,
                  orderFormId: orderFormProvider.id,
                  analyticSettingId: line.analyticSettingId,
                  orderFormProviderId: orderFormProvider.id,
                },
              })
            );
          });
          await Promise.all(allPromises).then(() => {});

          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          this.$router.push({
            name: "new-order-form-provider",
            params: {
              id: orderFormProvider.id,
              title: "Nouvelle commande fournisseur",
              tips: "Nouvelle commande fournisseur",
              routeOrigine: "order-form-providers",
              details: [], //this.orderFormCustomerDetailsSelected
            },
          });
          this.$refs["modal-new-order-form-provider"].toggle(
            "#toggle-btn-new-order-form-provider"
          );
          this.tabActive = "blank";
          this.initialize();
        })
        .catch((err) => {
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
        });
    },
    getSrcIcon() {
      return require("@/assets/icones/icon-doc-standard.svg");
    },
    async handleSubmit() {
      this.$store.commit(
        "SET_IS_GLOBAL_MESSAGE",
        "Création du bon de commande fournisseur en cours..."
      );
      this.$store.commit("SET_IS_GLOBAL_CREATING", true);
      await this.getOrderFormEmpty({
        affairId: null,
        providerId: null,
        customerId: null,
        storageLocationId: null,
      })
        .then((quote) => {
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          this.$router.push({
            name: "new-order-form-provider",
            params: {
              id: quote.id,
              title: "Nouveau bon de commande fournisseur",
              tips: "Nouveau bon de commande fournisseur",
              routeOrigine: "order-form-providers",
            },
          });
          this.$refs["modal-new-order-form-provider"].toggle(
            "#toggle-btn-new-order-form-provider"
          );
          this.tabActive = "blank";
        })
        .catch((error) => {
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
        });
    },
    toggleModal() {
      this.$refs["modal-new-order-form-provider"].toggle(
        "#toggle-btn-new-order-form-provider"
      );
      this.tabActive = "blank";
    },
  },
};
</script>

<style>
#modal-prevent-closing-new-order-form-provider .modal-header {
  padding: 0.8rem 1rem;
  padding-bottom: 0 !important;
}
#modal-prevent-closing-new-order-form-provider .modal-body {
  padding: 0 !important;
}
.document-table .e-headercell {
  background-color: #0c3571 !important;
}
.document-table .e-headertext {
  color: #ffffff !important;
}
.document-table .e-rowcell p,
.document-table .e-rowcell ol {
  margin-bottom: 0px;
}
.document-table .col-title,
.document-table .col-title-total .content-text,
.document-table .col-title textarea,
.document-table .col-title input,
.document-table .col-title select {
  font-size: 1.3rem;
  font-weight: 500;
}
</style>
