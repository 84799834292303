<template lang="pug">
    div
      .border-left.border-right.border-top.rounded-top.content-total
        strong.d-flex.total-row Total net HT
          .ml-auto.price-total
            span.totalWithoutTax {{ formatCurrency(document.totalHt) }}
        div(v-if="documentOptions.isReverseCharge || document.totalTtc == document.totalHt")
          .d-flex Autoliquidation
        div(v-else)
          div(v-if="document.nature != 4")
            strong.d-flex.total-row.total-with-tax Total TVA
              .ml-auto.price-total
                span.totalWithTax {{ formatCurrency((document.totalTtc - document.totalHt).toFixed(2)) }}
      .border-left.border-right
      strong.d-flex.big.px-7.bg-primary.text-white.rounded-bottom.totalInvoice(:class="document.nature == 0 ? 'rounded-bottom' : ''") TOTAL TTC
        .ml-auto.price-total
          span.totalNetToPay.netToPay {{ formatCurrency(document.totalTtc) }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import { ENature } from "@/types/api-orisis/enums/enums";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  props: {
    preview: {
      default: false,
    },
    canEditDiscount: {
      required: true,
      default: true,
      type: Boolean,
    },
  },
  components: {
    "v-select": vSelect,
    popper: Popper,
  },
  methods: {
    formatCurrency,
  },
  computed: {
    ...mapGetters(["document"]),
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
  },
};
</script>

<style>
.totalInvoice {
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}

.total-divider {
  border-top: 1px solid #d9d9d9;
  margin: 7px 0 7px 0;
}

.content-total {
  padding: 7px;
}

.error-margin {
  padding: 10px;
  background-color: #f19e9e;
  display: flex;
  align-items: center;
  border-radius: 0.357rem;
}

.price-total {
  white-space: nowrap;
}

.rest-button {
  margin-left: 10px;
  cursor: pointer;
  color: #0c3571;
}
</style>
