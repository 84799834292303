<template lang="pug">
  b-modal#modal-prevent-closing-new-credit(v-model="isPopupNewCredit" ok-title="Nouvel avoir" hide-footer cancel-title="Annuler" size="lg" ref='modal-new-credit' title='Nouvel avoir' :header-class="'bg-light-primary'" :ok-disabled="!institutionSelected")
    template.d-block(#modal-header="{ close }")
      div.w-100
        button.absolute(type="button" aria-label="Close" class="close" @click="close()") ×
        b-row.my-0
          b-col.py-0.mb-0(cols='12' style="padding:0px !important")
            h3.text-primary Nouvel avoir
        b-row.content-scrollable-sticky.m-0.h-100.justify-content-center
          b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
            ul.d-flex
              li(v-if="$route.name == 'credits'")
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="computedActiveTab == 'blank-credit' ? 'current' : ''" @click="computedActiveTab = 'blank-credit'")
                  span Avoir vierge
              li.d-none
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="computedActiveTab == 'invoice-credit' ? 'current' : ''" @click="computedActiveTab = 'invoice-credit'")
                  span Avoir à partir d'une facture
    .justify-content-center(v-if="isLoadingDocument")
      .content-loader-center.m-0(style="height:250px")
        .text-center.flex-center
          .loading-bg-inner(variant="primary" style="width: 3rem; height: 3rem;")
            .loader
              .outer
              .middle
              .inner
          span(style="margin-top: 8rem")  Chargement des données en cours...
    div(v-else-if="institutionSelected != null")
      #blank-credit(v-if="computedActiveTab == 'blank-credit'")
        .list-buttom-custom.m-1
          div.relative.doc-buttom-custom(@click="handleSubmit")
            img.w-100(:src="getSrcIcon()")
      #lease-invoice(v-if="computedActiveTab == 'invoice-credit'")
        b-row(v-if="institutionSelected != null" :class="selectInvoice ? 'mb-1' : ''")
          b-col(v-if="$route.name != 'lease'" cols='12')
            v-select.w-100.m-0(:disabled="!(invoiceToCredit == null)" v-model="selectInvoice" :get-option-label="(option) => option.documentReference" @input="getInvoiceByIdLocal" placeholder="Rechercher une facture" :clearable="true" :options="invoiceListFiltered")
              template(v-slot:option='{ id, companyName, documentReference }')
                div.content-quote-choice
                  span(style='font-size:13px;white-space:normal') Client : {{ companyName }} -  {{ documentReference }} 
        div.my-0(v-if="selectInvoice != null")
          b-row.my-0
            b-col.py-0.mb-1(cols='12')
              b-form-radio(v-model='newCreditForm.nature' name='radios-popup-new-credit' value=5)
                | Avoir global
          b-row.my-0
            b-col.py-0.mb-1(cols='12')
              b-form-radio(disabled v-model='newCreditForm.nature' name='radios-popup-new-credit' value=6)
                | Avoir détaillé
        div(v-if="newCreditForm.nature == 5 && selectInvoice != null")
          b-row.my-0 
            b-col.py-0(cols='6')
              b-form-group.pr-1(style='flex:1')
                validation-provider(#default='{ errors }')
                  div.d-flex.align-items-center(style="height:37.99px") Montant de la facture
            b-col.py-0(cols='6')
              b-form-group.pr-1(style='flex:1')
                validation-provider.d-flex.justify-content-end(#default='{ errors }')
                  div.d-flex.align-items-center.justify-content-end(style="height:37.99px") {{ formatCurrency(document.totalTtc) }} TTC
                  div(v-if="initialInvoice && initialInvoice.payments && initialInvoice.payments.length > 0")
            vs-divider.m-1
          b-row.my-0 
            b-col.py-0.mb-1(cols='6')
              b-form-group(style='flex:1')
                validation-provider(#default='{ errors }')
                  div.d-flex.align-items-center(style="height:37.99px") Montant de l'avoir
            b-col.mb-1.padding-r-5(cols='3')
              b-form-group(style='flex:1')
                validation-provider(#default='{ errors }' rules="required")
                  b-form-input(@blur='isNegative' :max="document.totalTtc" type="number" v-model="newCreditForm.value")
            b-col.mb-1.no-padding(cols='3')
              b-form-group.pr-1(style='flex:1')
                validation-provider(#default='{ errors }' rules="required")
                  v-select#amountType(:clearable="false" :deselectFromDropdown='true' :closeOnSelect='true' :state='errors.length > 0 ? false : null' v-model='newCreditForm.type' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="\
                    amountTypeListLocal\
                    " :reduce='elem => elem.value')
                        template(v-slot:no-options='')
                          template  Aucun r&eacute;sultat trouv&eacute;
          b-row(v-if="newCreditForm.type != 2 && newCreditForm.value != 0").my-0 
            b-col.py-0.mb-1(cols='6')
              b-form-group.pr-1(style='flex:1')
                validation-provider(#default='{ errors }')
                  div.d-flex.align-items-center(style="height:37.99px") Montant de l'avoir
            b-col.py-0.mb-1(cols='6')
              b-form-group.pr-1(style='flex:1')
                validation-provider.d-flex.justify-content-end(#default='{ errors }')
                  div.d-flex.align-items-center.justify-content-end(style="height:37.99px") {{ invoiceRemains }} € TTC
        b-row.my-0
          b-col.py-0(cols='12')
            .d-flex.justify-content-between(style='border-top: 1px solid #e2e2e2; padding-top: 0.5rem;')
              div
                b-button(variant='outline-primary' @click='isPopupNewCredit = false')
                  feather-icon(icon='ArrowLeftIcon')
                    | Annuler
              b-button(variant='primary' @click='createLocalCredit')
                | Créer l'avoir
</template>

<script>
import { statusCheck } from "@/types/api-orisis/enums/enums";
import {
  getPricesByPourcentagePriceTtcByCredit,
  getPricesByPriceHt,
  getPricesByPriceTtc,
} from "@/types/api-orisis/library/DocumentOperations";
import {
  formatCurrency,
  formatDate,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { amountTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { NewCreditFormDefaultProperties } from "@/types/api-orisis/models/NewCreditForm";
import { BForm, BFormGroup, BFormInput, BModal, VBModal } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { CreditOptionModel } from "@/types/api-orisis/models/CreditOptionModel";

export default {
  data() {
    return {
      CreditOptionModel,
      getPricesByPriceHt,
      getPricesByPriceTtc,
      getPricesByPourcentagePriceTtcByCredit,
      NewCreditFormDefaultProperties,
      tvaLine: [],
      selectInvoice: null,
      amountTypeListLocal: [{ label: "€ TTC", value: 2 }],
      invoicesListLocal: [],
    };
  },
  props: {
    activePopupCredit: {
      type: Boolean,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
      default: "blank-credit",
    },
    invoiceToCredit: {
      type: Object,
      required: false,
      default: null,
    },
    affairId: {
      type: Number,
      default: null,
    },
    companyId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    isPopupNewCredit(val) {
      if (val) {
        this.initValues();
      }
      return false;
    },
  },
  computed: {
    ...mapGetters([
      "document",
      "amountTypeList",
      "initialInvoice",
      "natureList",
      "initialDocument",
      "isLoadingDocument",
      "institutionsList",
      "isLoadingInstitutionsList",
      "workspaceSelected",
      "institutionSelected",
    ]),
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
    isPopupNewCredit: {
      get() {
        return this.activePopupCredit;
      },
      set(val) {
        if (!val) {
          this.$emit("toggleModalNewCredit");
        }
      },
    },
    documentDetails: {
      get() {
        return this.$store.getters["getDocumentDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT_DETAILS", value);
      },
    },
    newCreditForm: {
      get() {
        return this.$store.getters["newCreditForm"];
      },
      set(value) {
        return this.$store.commit("SET_NEW_CREDIT_FORM", value);
      },
    },
    isTotalCredit: {
      get() {
        return this.$store.getters["isTotalCredit"];
      },
      set(value) {
        return this.$store.commit("SET_IS_TOTAL_CREDIT", value);
      },
    },
    invoiceRemains() {
      let document = structuredClone(this.document);
      let initialDocument = structuredClone(this.initialDocument);
      let price = 0;
      let deductionInvoicesPrice = null;

      if (initialDocument && initialDocument.invoices) {
        deductionInvoicesPrice = {
          totalHt: 0,
          totalTtc: 0,
        };
        for (let i = 0; i < initialDocument.invoices.length; i++) {
          const invoice = initialDocument.invoices[i];
          if (invoice.status != 5 && invoice.id != document.id) {
            deductionInvoicesPrice.totalHt += invoice.totalHt;
            deductionInvoicesPrice.totalTtc += invoice.totalTtc;
          }
        }
      }
      switch (this.newCreditForm.type) {
        case 0:
          this.creditObject = this.getPricesByPourcentagePriceTtcByCredit(
            document,
            this.newCreditForm,
            "credits",
            deductionInvoicesPrice
          );
          this.newCreditForm.creditHt = this.creditObject.totals.totalHt;
          this.newCreditForm.creditTtc = this.creditObject.totals.totalTtc;
          price += this.creditObject.totals.totalTtc;
          break;
        case 1:
          this.creditObject = this.getPricesByPriceHt(
            document,
            this.newCreditForm,
            "credits",
            deductionInvoicesPrice
          );
          this.newCreditForm.creditHt = this.creditObject.totals.totalHt;
          this.newCreditForm.creditTtc = this.creditObject.totals.totalTtc;
          price += this.creditObject.totals.totalTtc;
          break;
        case 2:
          this.creditObject = this.getPricesByPriceTtc(
            document,
            this.newCreditForm,
            "credits",
            deductionInvoicesPrice,
            this.initialDocument
          );
          this.newCreditForm.creditHt = this.creditObject.totals.totalHt;
          this.newCreditForm.creditTtc = this.creditObject.totals.totalTtc;
          price += this.creditObject.totals.totalTtc;
          break;
        default:
          break;
      }
      return price.toFixed(2);
    },
    invoiceListFiltered: {
      get() {
        return this.invoicesListLocal.filter((el) =>
          statusCheck.invoiceCreditAllowed.includes(el.status)
        );
      },
    },
    computedActiveTab: {
      get() {
        return this.activeTab;
      },
      set(value) {
        this.$emit("activeTabChange", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "createCredit",
      "addLine",
      "createCreditDetail",
      "createDeduction",
      "getCreditEmpty",
      "getInvoiceById",
      "getCreditOptionDefaultByCreditId",
      "createCreditOption",
    ]),
    formatCurrency,
    amountTypeTranslate,
    initValues() {
      this.selectInvoice = this.invoiceToCredit;
      this.newCreditForm = this.NewCreditFormDefaultProperties(
        this.document.totalTtc
      );
    },
    async handleSubmit() {
      this.$store
        .dispatch("getInvoices", {
          updateState: false,
        })
        .then((res) => {
          this.invoicesListLocal = res;
        });
      this.$refs["modal-new-credit"].toggle("#toggle-btn-new-credit");
      this.$store.commit(
        "SET_IS_GLOBAL_MESSAGE",
        "Création de l'avoir en cours..."
      );
      this.$store.commit("SET_IS_GLOBAL_CREATING", true);
      await this.getCreditEmpty({
        affairId: this.affairId,
        companyId: this.companyId,
        updateState: true,
        nature: 6,
      })
        .then((credit) => {
          this.isPopupNewCredit = false;
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          this.$router.push({
            name: "new-credit",
            params: {
              id: credit.id,
              title: "Nouvel avoir",
              tips: "Nouvel avoir",
              routeOrigine: "credits",
            },
          });
        })
        .catch((error) => {
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
        });
    },
    getSrcIcon() {
      return require("@/assets/icones/icon-credit-vierge.svg");
    },
    async getInvoiceByIdLocal(invoice) {
      if (invoice) {
        await this.getInvoiceById({
          invoiceId: invoice.id,
        });
        this.newCreditForm = this.NewCreditFormDefaultProperties(
          this.document.totalTtc
        );
      }
    },
    isNegative(e) {
      let number = Number(e.target.value);
      this.newCreditForm.value = Math.round(number * 100) / 100;
      if (this.newCreditForm.type == 0) {
        if (number < 0) {
          this.newCreditForm.value = 0;
        } else if (number > 100) {
          this.newCreditForm.value = 100;
        }
      } else {
        if (number < 0) {
          this.newCreditForm.value = 0;
        } else if (number > this.document.totalTtc) {
          this.newCreditForm.value = this.document.totalTtc;
        }
      }
    },
    async createLocalCredit() {
      let invoiceDocument = structuredClone(this.document);
      this.$refs["modal-new-credit"].toggle("#toggle-btn-new-credit");
      this.$store.commit(
        "SET_IS_GLOBAL_MESSAGE",
        "Création de l'avoir en cours..."
      );
      this.$store.commit("SET_IS_GLOBAL_CREATING", true);
      this.isTotalCredit = false;
      if (
        this.invoiceRemains ==
        Math.round(this.document.totalTtc * 100) / 100
      ) {
        this.isTotalCredit = true;
      }
      let creditFormFixed = structuredClone(this.newCreditForm);
      let creditObjectFixed = structuredClone(this.creditObject);
      let initialInvoice = structuredClone(this.document);
      let creditOptionsDefault = CreditOptionModel({});
      let creditOptions = CreditOptionModel({});
      let initialDocumentOptions = structuredClone(this.documentOptions);
      await this.getCreditOptionDefaultByCreditId({
        updateState: false,
      }).then((res) => {
        creditOptionsDefault = res;
        creditOptions = {
          creditId: 0,
          ...res,
          showDiscount:
            creditFormFixed.nature == 5
              ? false
              : initialDocumentOptions.showDiscount,
          id: 0,
        };
      });
      let deduction = null;

      invoiceDocument.invoiceId = initialInvoice.id;
      // Mettre à 0 l'id de la facture
      invoiceDocument.id = 0;
      // Mettre la nature à 5 ou 6
      invoiceDocument.nature = creditFormFixed.nature;
      // Ajouter isCounted à false,
      invoiceDocument.isCounted = false;
      // Mettre documentDate à la date du jour
      invoiceDocument.documentDate = new Date().toISOString();
      // Mettre le status à 8
      invoiceDocument.status = 8;
      // Calculer le prix Ht
      invoiceDocument.totalHt =
        invoiceDocument.nature == 5 ? creditFormFixed.creditHt : 0;
      // Calculer le prix Ttc et le reste à payer pour la facture d'acompte
      invoiceDocument.totalTtc = invoiceDocument.remainingToPayTtc =
        invoiceDocument.nature == 5 ? creditFormFixed.creditTtc : 0;
      // Mettre à jour les paiements
      invoiceDocument.payments = [];
      // Ne pas afficher le contact
      invoiceDocument.hideUser = true;
      // Mettre a jour la remise globale si c'est un avoir global
      if (invoiceDocument.nature == 5) {
        invoiceDocument.discountGlobalNumber = 0;
        invoiceDocument.discountGlobalType = 0;
      }

      // Mettre à jour les tvas
      invoiceDocument.documentTvas = [];

      // Mettre à jour path
      invoiceDocument.path = null;

      // Mettre à jour les déductions
      if (initialInvoice.deduction) {
        deduction = structuredClone(initialInvoice.deduction);
      }

      // Mettre à jour le documentDetails et mettre à jours les totaux dans le store
      await this.createCredit({
        credit: invoiceDocument,
      })
        .then(async (res) => {
          invoiceDocument.id = res.data.id;
          await this.createCreditOption({
            creditOption: { ...creditOptions, creditId: res.data.id },
          });
          if (invoiceDocument.nature == 5) {
            this.documentDetails = [];
            await this.insertLineIntoDocumentDetailAndReturnTotal(
              res.data.id,
              initialInvoice,
              creditObjectFixed,
              creditFormFixed
            );

            this.isPopupNewCredit = false;
            this.$store.commit("SET_IS_GLOBAL_CREATING", false);
            this.$router.push({
              name: "new-credit",
              params: {
                id: res.data.id,
                title: "Nouvel avoir",
                tips: "Nouvel avoir",
                routeOrigine: "credits",
              },
            });
          } else {
            let documentDetails;
            documentDetails = structuredClone(this.documentDetails);
            this.documentDetails = [];
            for (let i = 0; i < documentDetails.length; i++) {
              const documentDetail = documentDetails[i];
              await this.createCreditDetail({
                ...documentDetail,
                id: 0,
                creditId: res.data.id,
                total: 0,
                isDisabled: true,
              });
            }
            this.isPopupNewCredit = false;
            this.$store.commit("SET_IS_GLOBAL_CREATING", false);
            this.$router.push({
              name: "new-credit",
              params: {
                id: res.data.id,
                title: "Nouvel avoir",
                tips: "Nouvel avoir",
                routeOrigine: "credits",
              },
            });
          }
        })
        .catch((err) => {
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          this.isPopupNewCredit = false;
        });
    },
    async insertLineIntoDocumentDetailAndReturnTotal(
      creditId,
      initialInvoice,
      creditObjectFixed,
      creditFormFixed
    ) {
      for (let i = 0; i < creditObjectFixed.tvaLine.length; i++) {
        const line = creditObjectFixed.tvaLine[i];
        let newLine = {
          type: 3,
          unit: 1,
          index: i + 1,
          creditId: creditId,
          description:
            "<p><strong>Avoir pour la facture n° " +
            initialInvoice.documentReference +
            " du " +
            formatDate(initialInvoice.documentDate) +
            " </strong></p><p> " +
            creditFormFixed.value.toFixed(2) +
            " " +
            amountTypeTranslate(creditFormFixed.type) +
            " du montant total de " +
            formatCurrency(line.priceTtc) +
            " TTC&nbsp;</p>",
          referencielTvaId: line.referencielTvaId,
          total: line.advancementHt,
          unitPriceHt: line.advancementHt,
        };
        await this.addLine({
          documentDetail: newLine,
          indexArray: i - 1,
          isCredit: true,
          review: false,
        });
      }
    },
    async applyInvoiceDeduction(
      deduction,
      creditId,
      initialInvoice,
      creditFormFixed
    ) {
      let invoiceTotalTtc = initialInvoice.totalTtc;
      let percent = creditFormFixed.creditTtc / invoiceTotalTtc;
      let creditDeduction = {
        creditId: creditId,
        holdbackDate: deduction.holdbackDate,
        holdbackNumber: deduction.holdbackNumber
          ? Math.round(deduction.holdbackNumber * percent * 100) / 100
          : null,
        holdbackPercent: deduction.holdbackPercent,
        id: 0,
        invoiceId: null,
        prorataAccountLabel: deduction.prorataAccountLabel,
        prorataAccountPercent: deduction.prorataAccountPercent,
        prorataAccountPrice: deduction.prorataAccountPrice
          ? Math.round(deduction.prorataAccountPrice * percent * 100) / 100
          : null,
        punctualDeductions: deduction.punctualDeductions
          ? deduction.punctualDeductions.map((item) => {
              return {
                id: 0,
                percent: item.percent,
                number:
                  item.number != null
                    ? Math.round(item.number * percent * 100) / 100
                    : null,
                label: item.label,
              };
            })
          : [],
        quoteId: null,
        orderFormCustomerId: null,
        reviews: deduction.reviews.map((review) => {
          return {
            ...review,
            id: 0,
          };
        }),
      };
      await this.createDeduction({ deduction: creditDeduction });
    },
  },
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>

<style scoped>
.no-padding {
  padding: 0 !important;
}

.padding-r-5 {
  padding: 0px 5px 0px 0px !important;
}
</style>

<style>
#modal-prevent-closing-new-credit .modal-header {
  padding-bottom: 0 !important;
}
</style>
