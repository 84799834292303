var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticStyle:{"z-index":"9999999999 !important"},attrs:{"id":"modal-new-down-payment-invoice","cancel-variant":"outline-secondary","ok-title":"Créer la facture","cancel-title":"Annuler","centered":"","title":"Facture d'acompte"},on:{"ok":_vm.createDownPaymentInvoice},model:{value:(_vm.isPopupNewDownPaymentInvoice),callback:function ($$v) {_vm.isPopupNewDownPaymentInvoice=$$v},expression:"isPopupNewDownPaymentInvoice"}},[(_vm.isLoadingDocument)?_c('div',{staticClass:"text-center flex-center"},[_c('div',{staticClass:"loading-bg-inner",attrs:{"role":"status"}},[_c('div',{staticClass:"loader"},[_c('div',{staticClass:"outer"}),_c('div',{staticClass:"middle"}),_c('div',{staticClass:"inner"})])]),_c('br'),_vm._v("Chargement des données en cours...")]):_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1 font-weight-bold",attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v("Facture d'acompte pour "+_vm._s(_vm.initialDocument.initialDocumentNature == 'quote' ? 'le devis' : 'la commande client')+" "+_vm._s(_vm.initialDocument.documentReference))]}}])})],1)],1)],1),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"height":"37.99px"}},[_vm._v("Montant "+_vm._s(_vm.initialDocument.initialDocumentNature == 'quote' ? 'du devis' : 'de la commande client'))])]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"}},[_c('validation-provider',{staticClass:"d-flex justify-content-end",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-end",staticStyle:{"height":"37.99px"}},[_vm._v(_vm._s(_vm.formatCurrency(_vm.initialDocument.totalTtc))+" TTC")])]}}])})],1)],1)],1),_vm._l((_vm.previousInvoiceNotCanceled),function(item,index){return _c('b-row',{key:index,staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center font-weight-bold",staticStyle:{"height":"37.99px"}},[_vm._v(_vm._s(_vm.natureTranslate(item.nature))+" "+_vm._s((item.documentStep ? ("#" + (item.documentStep)) : ''))+" "+_vm._s(item.documentReference)+" ")])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"}},[_c('validation-provider',{staticClass:"d-flex justify-content-end",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-end",staticStyle:{"height":"37.99px"}},[_vm._v(_vm._s(_vm.formatCurrency(item.totalTtc))+" TTC")])]}}],null,true)})],1)],1),_c('vs-divider')],1)}),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"6"}},[_c('b-form-group',{staticStyle:{"flex":"1"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"height":"37.99px"}},[_vm._v("Montant de l'acompte")])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1 padding-r-5",attrs:{"cols":"3"}},[_c('b-form-group',{staticStyle:{"flex":"1"}},[_c('validation-provider',{attrs:{"name":"validityQuoteDefault","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},on:{"input":_vm.isNegative},model:{value:(_vm.newDownPaymentInvoiceForm.value),callback:function ($$v) {_vm.$set(_vm.newDownPaymentInvoiceForm, "value", $$v)},expression:"newDownPaymentInvoiceForm.value"}})]}}])})],1)],1),_c('b-col',{staticClass:"mb-1 no-padding",attrs:{"cols":"3"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"}},[_c('validation-provider',{attrs:{"name":"validityQuoteDefault","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"amountType","clearable":false,"loading":_vm.isLoadingAmountTypeList,"deselectFromDropdown":true,"closeOnSelect":true,"state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.amountTypeList,"reduce":function (elem) { return elem.value; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [[_vm._v(" Aucun résultat trouvé")]]},proxy:true}],null,true),model:{value:(_vm.newDownPaymentInvoiceForm.type),callback:function ($$v) {_vm.$set(_vm.newDownPaymentInvoiceForm, "type", $$v)},expression:"newDownPaymentInvoiceForm.type"}})]}}])})],1)],1)],1),(_vm.newDownPaymentInvoiceForm.type != 2 && _vm.newDownPaymentInvoiceForm.value != 0)?_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"height":"37.99px"}},[_vm._v("Total de l'acompte")])]}}],null,false,1924162354)})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"}},[_c('validation-provider',{staticClass:"d-flex justify-content-end",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-end",staticStyle:{"height":"37.99px"}},[_vm._v(_vm._s(_vm.formatCurrency(_vm.newDownPaymentInvoiceForm.downPaymentTtc))+" TTC")])]}}],null,false,657281605)})],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"my-0"},[_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center font-weight-bold",staticStyle:{"height":"37.99px"}},[_vm._v("Reste à facturer")])]}}])})],1)],1),_c('b-col',{staticClass:"py-0 mb-1",attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"}},[_c('validation-provider',{staticClass:"d-flex justify-content-end",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-end font-weight-bold",staticStyle:{"height":"37.99px"}},[_vm._v(_vm._s(_vm.formatCurrency(_vm.quoteRemains))+" TTC")])]}}])})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }