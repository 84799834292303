import { render, staticRenderFns } from "./HistoryOrderFormCustomer.vue?vue&type=template&id=7117a1e0&lang=pug&"
import script from "./HistoryOrderFormCustomer.vue?vue&type=script&lang=js&"
export * from "./HistoryOrderFormCustomer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports