<template lang="pug">
div.card-list-wrapper(:class="!isCardsOverflow ? 'no-arrow' : ''")
    .previous-arrow.cursor-pointer(@click="scrollList(-1)") 
        span.e-icons.e-chevron-left-small
    .next-arrow.cursor-pointer(@click="scrollList(1)")
        span.e-icons.e-chevron-right-small
    div.card-list-stats-container
        slot
</template>
<script>
export default {
  data() {
    return {
      isCardsOverflow: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.checkOverflow);
    this.checkOverflow();
  },
  methods: {
    checkOverflow() {
      this.isCardsOverflow =
        document.querySelectorAll(".col-card-list-stats").length * 215 >
        document.getElementsByClassName("card-list-wrapper")[0].clientWidth;
    },
    scrollList(direction) {
      const container = document.querySelector(".card-list-stats-container");
      const scrollAmount = 215; // Ajustez cette valeur selon votre besoin
      container.scrollLeft += direction * scrollAmount;
    },
  },
};
</script>
