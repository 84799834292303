<template lang="pug">
div.h-100
  .h-100(v-if='isLoadingOrderFormProvider || isUpdatingOrderFormProvider || isCreatingOrderFormProvider || isDeletingOrderFormProvider')
    .text-center.flex-center.h-100
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      div(style="margin-top: 7rem;")
        | {{ loadingMsg }}
  .h-100(v-else)
    .sidebar-header.pt-1.px-2
      div
        h3 {{ "Commande : " + (orderFormProvider.documentReference ? orderFormProvider.documentReference : "Numéro en attente") }}
      feather-icon.close-icon.cursor-pointer(icon='XIcon', size='24', @click.stop='closeSidebar')
    .sidebar-header.pb-1.px-2
      .d-flex.align-items-center
        Status(:statusId="orderFormProvider.status" nature="orderFormProvider")
        span {{ orderFormProvider.documentDate ? "En date du : " + dayjs(orderFormProvider.documentDate).format("DD/MM/YYYY") : "" }}
      .end
    vs-divider.m-0
    .sidebar-action.py-05.px-2
      .d-flex.align-items-center.justify-content-between.w-100
        .d-flex
          b-button-group
            b-button.btn-icon.d-flex(variant='primary' @click="editDocument(orderFormProvider.id, 'order-form-provider-pdf')" :style="orderFormProvider.status < 3 || (orderFormProvider.status >= 3 && orderFormProvider.path) ? 'border-right:1px solid #ccc !important;' : ''")
              feather-icon(icon='EyeIcon')
              span(v-if="orderFormProvider.status >=4  && !orderFormProvider.path") Consulter
            b-button.btn-icon.d-flex(v-if='orderFormProvider.status < 3' style='border-left:1px solid #ccc !important;' variant='primary' @click="editDocument(orderFormProvider.id, 'order-form-provider-edit')")
              feather-icon(icon='EditIcon')
              span(style='margin-left:3px') Modifier
            b-button.btn-icon.d-flex(v-if='orderFormProvider.status >= 4 && orderFormProvider.path' @click.stop='forceFileDownload(orderFormProvider.path)' style='border-left:1px solid #ccc !important;' variant='primary')
              feather-icon.align-middle(icon='DownloadIcon')
              span(style='margin-left:3px') Télécharger
          b-dropdown.dropdown-icon-wrapper.ml-50(v-if="orderFormProvider.status == 3 || orderFormProvider.status == 4 || orderFormProvider.status == 6" variant='success')
              template(#button-content)
                feather-icon(icon='TruckIcon')
                span(style='margin-left: 3px') Réceptionner
              b-dropdown-item(@click.stop='toggleAcknowledgmentModal()') Accusé de réception
              b-dropdown-item(@click="newPartialReceiptFormProvider()") Réception de la marchandise {{ initialOrderFormProvider && initialOrderFormProvider.receiptFormProviders && initialOrderFormProvider.receiptFormProviders.length > 0 ? '#' + parseInt(initialOrderFormProvider.receiptFormProviders[initialOrderFormProvider.receiptFormProviders.length-1].receiptFormStep + 1 ) : '#1'}}
        .d-flex.align-items-center.justify-end
          b-button-group
            //- b-button( variant="outline-danger" v-if="orderFormProvider.status < 3" class="btn-icon" @click="deleteDocumentLocal(orderFormProvider.id, orderFormProvider.documentReference)")
            //-   feather-icon.mr-50(icon="TrashIcon") 
            //-   | Supprimer
            b-dropdown.dropdown-icon-wrapper( variant='outline-primary' left='')
              template(#button-content='')
                feather-icon.align-middle(icon='MoreHorizontalIcon')
              b-dropdown-item(@click.stop='duplicateDocument') Dupliquer
              b-dropdown-item(@click.stop='reviseDocument') Réviser
              b-dropdown-divider
              b-dropdown-item(@click.stop='cancelDocument' v-if="orderFormProvider.status > 2 && orderFormProvider.status !== 5") Annuler
              b-dropdown-item(@click.stop='deleteDocumentLocal(orderFormProvider.id, orderFormProvider.documentReference)' v-if="orderFormProvider.status < 3") Supprimer
    vs-divider.m-0
    b-row.content-scrollable-sticky.m-0.justify-content-center
        b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
          ul.d-flex.border-bottom-grey-light.px-1
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'details' ? 'current' : ''" @click="tabActive = 'details'")
                span Détails
            li(v-if="haveGedModule")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'files' ? 'current' : ''" @click="tabActive = 'files'")
                span Documents
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'notes' ? 'current' : ''" @click="tabActive = 'notes'")
                span Notes
                feather-icon.align-middle.ml-50(v-if="orderFormProvider.internalNote" icon='MessageCircleIcon')
    .sidebar-content.with-tabs
      div(v-if='tabActive == "details"')
        div(v-if='orderFormProvider.companyProviderId')
          .d-flex.justify-content-between
            h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@click="editCompany(orderFormProvider.companyProviderName, 'provider')" @mouseover="showCompanyProviderEdit=true" @mouseleave="showCompanyProviderEdit=false" style="flex: 1;")
              span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                feather-icon(icon='ChevronRightIcon') 
                | {{ orderFormProvider.companyProviderName }}
                feather-icon.ml-50(v-show="showCompanyProviderEdit" icon='ExternalLinkIcon')
            .d-flex
              div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
              div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                div.px-50
                  small Fournisseur
          div
            span(v-show='orderFormProvider.companyProviderAddress') {{ orderFormProvider.companyProviderAddress }}
              br
            span(v-show='orderFormProvider.companyProviderAddressComplement') {{ orderFormProvider.companyProviderAddressComplement }}
              br
            span(v-show='orderFormProvider.companyProviderZipCode || orderFormProvider.companyProviderCity') {{ orderFormProvider.companyProviderZipCode }} {{ orderFormProvider.companyProviderCity }}
              br
            span(v-show='orderFormProvider.companyProviderCountry') {{ orderFormProvider.companyProviderCountry }}
          ul.listTab.mt-1
            li(v-if='orderFormProvider.companyProviderEmail')
              span Email
              span {{ orderFormProvider.companyProviderEmail }}
            li(v-if='orderFormProvider.companyProviderPhoneNumber')
              span Téléphone
              span {{ orderFormProvider.companyProviderPhoneNumber }}
          vs-divider
        div(v-if='orderFormProvider.storageLocationId || orderFormProvider.deliverToAffairAddress')
          .d-flex.justify-content-between
              h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@click="editStorageLocation(orderFormProvider.storageLocationId)" @mouseover="showStorageLocationEdit=true" @mouseleave="showStorageLocationEdit=false" style="flex: 1;")
                .d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ orderFormProvider.storageLocationLabel }}
                  feather-icon.ml-50(v-show="showStorageLocationEdit" icon='ExternalLinkIcon')
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Livraison
          div
            span(v-show='orderFormProvider.storageLocationAddress') {{ orderFormProvider.storageLocationAddress }}
              br
            span(v-show='orderFormProvider.storageLocationAddressComplement') {{ orderFormProvider.storageLocationAddressComplement }}
              br
            span(v-show='orderFormProvider.storageLocationZipCode || orderFormProvider.storageLocationCity') {{ orderFormProvider.storageLocationZipCode }} {{ orderFormProvider.storageLocationCity }}
              br
            span(v-show='orderFormProvider.storageLocationCountry') {{ orderFormProvider.storageLocationCountry }}
          ul.listTab.mt-1(v-if='orderFormProvider.storageLocationId')
            li(v-if='orderFormProvider.storageLocationEmail')
              span Email
              span {{ orderFormProvider.storageLocationEmail }}
            li(v-if='orderFormProvider.storageLocationPhoneNumber')
              span Téléphone
              span {{ orderFormProvider.storageLocationPhoneNumber }}
            li(v-if='orderFormProvider.deliverToAffairAddress')
              span Adresse
              span Livrer sur le lieu de l&apos;affaire
            li(v-if='orderFormProvider.deliveryDate')
              span Date souhaitée 
              span {{ dayjs(orderFormProvider.deliveryDate).format("DD/MM/YYYY")  }}
          vs-divider 
        div(v-if='initialOrderFormProvider && initialOrderFormProvider.receiptFormProviders && initialOrderFormProvider.receiptFormProviders.length > 0')
          h5.mb-1.d-flex.align-items-center.mb-0.text-primary
            span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
              feather-icon(icon='ChevronRightIcon') 
              | Documents liés
          .d-flex.mb-50.align-items-center(v-for="(item, index) in initialOrderFormProvider.receiptFormProviders" :key="item.id")
            div.d-flex.align-items-center.w-100.justify-content-between
              .d-flex
                span.material-icons.text-gray(style="font-size:20px") description
                div
                  span.ml-50 
                    | Bon de récéption {{ item.receiptFormStep ? '#' + item.receiptFormStep : ''}} {{ item.documentReference }} du 
                    span.font-weight-bold {{ formatDate(item.documentDate) }} 
                    span.font-weight-bold ({{ orderFormProviderStatusTranslate(item.status) }})
              ejs-dropdownbutton.dropdownbutton-redirect(:select="onSelectReceiptForm" :items="itemsComputed({id: item.id, documentReference: item.documentReference})" iconCss='e-icons e-chevron-down' cssClass='e-caret-hide')
          vs-divider
        div(v-if='orderFormProvider.companyCustomerId')
          .d-flex.justify-content-between.mb-1
              h5.mb-0.d-flex.mb-0.text-primary.cursor-pointer(@click="editCompany(orderFormProvider.companyCustomerId, 'client')" @mouseover="showCompanyCustomerEdit=true" @mouseleave="showCompanyCustomerEdit=false" style="flex: 1;")
                span(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ orderFormProvider.companyCustomerName }}
                  feather-icon.ml-50(v-show="showCompanyCustomerEdit" icon='ExternalLinkIcon')
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Client lié
        div(v-if='orderFormProvider.affairId')
          .d-flex.justify-content-between.mb-1
              h5.mb-0.d-flex.mb-0.text-primary.cursor-pointer(@click="editAffair(orderFormProvider.affairId)" @mouseover="showAffairEdit=true" @mouseleave="showAffairEdit=false" style="flex: 1;")
                span(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ orderFormProvider.affairName }}
                  feather-icon.ml-50(v-show="showAffairEdit" icon='ExternalLinkIcon')
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Affaire liée
        vs-divider(v-if='orderFormProvider.affairId || orderFormProvider.companyCustomerId')
        div
          TotalOrderFormProvider(:canEditDiscount="false")
          vs-divider
        div
          HistoryOrderFormProvider          
      div.h-100(v-if='tabActive == "files"')
        ged-viewer-sidebar(:parentFolder="orderFormProvider.folderId" :fromModule="'orderFormProvider'")
      div.h-100(v-if='tabActive == "notes"')
        quill-editor(id="text-area-designation" v-model="orderFormProvider.internalNote" toolbar="essential" @blur="updateOrderFormProviderWithoutLoading" class="editor quill-fixed" :options="editorOption" style="height: calc(100% - 55px);")

    b-modal(ref='acknowledgment-receipt-modal' :title="'Accusé de réception de commande : '+orderFormProvider.documentReference")
      div
        p.mb-1 Indiquer ici les informations retournées par votre fournisseur.
        b-row.mb-50
          b-col.py-0(cols='12')
            b-form-group(label="Numéro d'accusé de réception" label-for="orderFormProviderAcknowledgmentNumber")
              b-form-input(id="orderFormProviderAcknowledgmentNumber" v-model="orderFormProvider.acknowledgmentNumber")
        b-row.mb-50
          b-col.py-0(cols='12')
            b-form-group(label="Date de livraison estimée" label-for="orderFormProviderEstimatedDateReceipt")
              date-picker(v-model="estimatedDateReceiptFormProviderat" :show-time-panel="false" type="date" :clearable="true" format="DD/MM/YYYY")
      //.mt-1.cancel-modal-buttons
      template(#modal-footer="{ ok, cancel }")
        .w-100.m-0.py-50
          b-button(variant="primary" class="float-right" @click="saveAcknowledgmentReceipt()") Enregistrer
          b-button(variant="outline-primary" class="float-left mr-1" @click="toggleAcknowledgmentModal()") Annuler


</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";

import dayjs from "dayjs";
import axios from "axios";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/fr";

import Status from "@/components/global/status/Status";
import TotalOrderFormProvider from "@/components/purchase/order-form-provider/builder/TotalOrderFormProvider";
import HistoryOrderFormProvider from "@/components/purchase/order-form-provider/HistoryOrderFormProvider";
import GedViewerSidebar from "@/components/ged/GedViewerSidebar";
import { orderFormProviderStatusTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

import {
  formatCurrency,
  formatNumber,
  formatDate,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { checkFunctionUser } from "@/auth/utils.ts";

export default {
  data() {
    return {
      tabActive: "details",
      dayjs: dayjs,
      loadingTitle: "Chargement",
      loadingMessage: "Chargement des détails de la commande fournisseur...",
      showStorageLocationEdit: false,
      showCompanyProviderEdit: false,
      showCompanyCustomerEdit: false,
      showAffairEdit: false,
      editorOption: {
        placeholder: "Ecrivez une note interne liée à ce document ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
          ],
        },
      },
      dataOrigine: {},
      estimatedDateReceiptFormProviderat: new Date(),
      partialNumber: 1,
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingOrderFormProvider",
      "isCreatingOrderFormProvider",
      "isLoadingOrderFormProvider",
      "isDeletingOrderFormProvider",
      "isLoadingDuplicatingDocument",
      "workspaceSelected",
      "initialOrderFormProvider",
    ]),
    orderFormProvider: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
    documentDetails: {
      get() {
        return this.$store.getters["getOrderFormProviderDetails"];
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_DETAILS", value);
      },
    },
    isLoadingDuplicatingDocument: {
      get() {
        return this.$store.getters["isLoadingDuplicatingOrderFormProvider"];
      },
      set(value) {
        return this.$store.commit(
          "SET_IS_LOADING_DUPLICATING_ORDER_FORM",
          value
        );
      },
    },
    loadingMsg: {
      get() {
        return this.$store.getters["loadingMsg"];
      },
      set(value) {
        return this.$store.commit("SET_LOADING_MSG", value);
      },
    },
    getStatusAvailable() {
      return this.statusAvailableOrderFormProvider();
    },
    haveGedModule() {
      return (
        this.checkFunctionUser(42, "all") && this.checkFunctionUser(43, "all")
      );
    },
  },
  methods: {
    ...mapActions([
      "getOrderFormById",
      "updateOrderForm",
      "updateOrderForm",
      "duplicateOrderFormProvider",
      "deleteOrderFormProviders",
      "createReceiptFormProvider",
      "getReceiptFormOrderFormId",
      "createReceiptFormFromPreviousReceiptForm",
    ]),
    checkFunctionUser,
    orderFormProviderStatusTranslate,
    formatCurrency,
    formatNumber,
    formatDate,
    async updateOrderFormProviderWithoutLoading() {
      if (
        this.dataOrigine.internalNote !== this.orderFormProvider.internalNote
      ) {
        await this.updateOrderForm({
          orderFormProvider: this.orderFormProvider,
          loading: false,
        });
      }
    },
    async newReceiptFormProvider() {
      //create empty order form
      await this.getReceiptFormOrderFormId({
        orderFormProviderId: this.orderFormProvider.id,
      }).then((receiptFormProvider) => {
        this.$router.push({
          name: "new-receipt-form-provider",
          params: {
            id: receiptFormProvider.id,
            title: "Nouveau bon de réception",
            tips: "Nouveau bon de réception",
            routeOrigine: "receipt-form-providers",
          },
        });
        this.closeSidebar();
      });
    },
    itemsComputed(args) {
      return [
        {
          text: "Visualiser",
          id: args.id,
          documentReference: args.documentReference,
        },
        // {
        //   text: 'Télécharger',
        //   id: args.id,
        //   documentReference: args.documentReference,
        //   nature: args.nature ? args.nature : null
        // },
      ];
    },
    onSelectReceiptForm(args) {
      this.closeSidebar();
      if (args.item.text == "Visualiser") {
        this.$router.push({
          name: "edit-receipt-form-provider",
          params: {
            mode: "document-pdf",
            id: args.item.id,
            title: args.item.documentReference
              ? args.item.documentReference
              : "Editer un bon de réception",
            tips:
              "Editer le bon de réception" + args.item.documentReference
                ? " : " + args.item.documentReference
                : "",
            routeOrigine: "quotes",
          },
        });
      } else {
      }
    },
    async newPartialReceiptFormProvider() {
      if (this.initialOrderFormProvider.receiptFormProviders.length === 0) {
        let orderFormProvider = structuredClone(this.orderFormProvider);
        await this.getReceiptFormOrderFormId({
          orderFormProviderId: orderFormProvider.id,
        }).then((receiptFormProvider) => {
          this.$router.push({
            name: "new-receipt-form-provider",
            params: {
              id: receiptFormProvider.id,
              title: "Nouveau bon de réception",
              tips: "Nouveau bon de réception",
              routeOrigine: "receipt-form-providers",
            },
          });
          this.closeSidebar();
        });
      } else {
        await this.createReceiptFormFromPreviousReceiptForm({
          receiptFormId:
            this.initialOrderFormProvider.receiptFormProviders[
              this.initialOrderFormProvider.receiptFormProviders.length - 1
            ].id,
        }).then((res) => {
          this.$router.push({
            name: "new-receipt-form-provider",
            params: {
              id: res.data.id,
              title: "Nouveau bon de réception",
              tips: "Nouveau bon de réception",
              routeOrigine: "receipt-form-providers",
            },
          });
          this.closeSidebar();
        });
      }
    },

    async editDocument(id, mode) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-order-form-provider",
        params: {
          mode: mode,
          id: id,
          title: this.orderFormProvider.documentReference
            ? this.orderFormProvider.documentReference
            : "Editer une commande fournisseur",
          tips:
            "Editer la commande fournisseur" +
            this.orderFormProvider.documentReference
              ? " : " + this.orderFormProvider.documentReference
              : "",
          routeOrigine: "order-form-providers",
        },
      });
    },
    async showAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "affair",
        params: {
          id: id,
          title: "Affaire : " + this.affair.name,
          tips: "Affaire : " + this.affair.name,
          routeOrigine: "affairs",
        },
      });
    },
    async editStorageLocation(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-storage-location",
        params: {
          id: id,
          title: "Edit : " + this.orderFormProvider.storageLocationLabel,
          tips:
            "Editer le point de stockage : " +
            this.orderFormProvider.storageLocationLabel,
          routeOrigine: "stock/storage-locations",
        },
      });
    },
    async editAffair() {
      this.closeSidebar();
      this.$router.push({
        name: "edit-affair",
        params: {
          id: id,
          title: "Edit : " + this.orderFormProvider.affairName,
          tips: "Editer l'affaire : " + this.orderFormProvider.affairName,
          routeOrigine: "affairs",
        },
      });
    },
    async editCompany(id, type) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-company",
        params: {
          id: id,
          title:
            "Edit : " + type == "client"
              ? this.orderFormProvider.companyCustomerName
              : this.orderFormProvider.companyCustomerName,
          tips:
            "Editer l'entreprise : " + type == "client"
              ? this.orderFormProvider.companyCustomerName
              : this.orderFormProvider.companyProviderName,
          routeOrigine: "companies",
        },
      });
    },
    statusAvailableOrderFormProvider() {
      switch (this.orderFormProvider.status) {
        case 0:
          return [
            { label: "À valider", value: 1 },
            { label: "Sous-ordre", value: 2 },
          ];
        case 1:
          return [{ label: "Sous-ordre", value: 2 }];
        case 6:
          return [
            { label: "Brouillon", value: 0 },
            { label: "À valider", value: 1 },
          ];
        default:
          return [];
      }
    },
    async getDocumentDetails(id) {
      if (id != "") {
        await this.getOrderFormById({ orderFormId: id }).then((res) => {
          this.dataOrigine = JSON.parse(JSON.stringify(res));
        });
        this.tabActive = "details";
      }
    },
    duplicateDocument() {
      this.loadingMsg = "Duplication en cours...";
      this.isLoadingDuplicatingDocument = true;
      this.duplicateOrderFormProvider(this.orderFormProvider.id).then(
        async (res) => {
          this.closeSidebar();
          this.isLoadingDuplicatingDocument = false;
          this.loadingMsg = "Chargement en cours...";
          this.$router.push({
            name: "new-order-form-provider",
            params: {
              id: res.data.id,
              title: "Nouvelle commande fournisseur",
              tips: "Nouvelle commande fournisseur",
              routeOrigine: "order-form-providers",
            },
          });
        }
      );
    },

    toggleAcknowledgmentModal() {
      this.estimatedDateReceiptFormProviderat = this.orderFormProvider
        .estimatedDateReceipt
        ? new Date(this.orderFormProvider.estimatedDateReceipt)
        : new Date();
      this.$refs["acknowledgment-receipt-modal"].toggle("#toggle-btn");
    },
    saveAcknowledgmentReceipt() {
      this.updateOrderForm({
        orderFormProvider: {
          ...this.orderFormProvider,
          estimatedDateReceipt: this.estimatedDateReceiptFormProviderat,
        },
        loading: false,
      });
      this.toggleAcknowledgmentModal();
    },
    async reviseDocument() {
      this.$bvModal
        .msgBoxConfirm(
          'En validant, votre document sera deverrouillé et repassera au statut "A valider" vous permettant son édition.',
          {
            title: "Attention",
            size: "sm",
            okVariant: "success",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            await this.updateOrderForm({
              orderFormProvider: {
                ...this.orderFormProvider,
                status: 2,
                path: null,
              },
              loading: false,
            });
            this.editDocument(
              this.orderFormProvider.id,
              "order-form-provider-edit"
            );
          }
        });
    },
    async cancelDocument() {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            "Êtes-vous sûr de vouloir supprimer cette commande fournisseur ?",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.updateOrderForm({
              orderFormProvider: {
                ...this.orderFormProvider,
                status: 5,
                path: null,
              },
              loading: false,
            }).then(() => {
              this.closeSidebar();
              this.$emit("refreshGrid");
            });
          }
        });
    },
    deleteDocumentLocal(id, reference) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            "Êtes-vous sûr de vouloir supprimer cette commande fournisseur ?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteOrderFormProviders({ orderFormProviderIds: [id] }).then(
              () => {
                this.closeSidebar();
                this.$emit("refreshGrid");
              }
            );
          }
        });
    },
    forceFileDownload(donwload) {
      // TODO : ATTENTION FONCTIONNE PAS LOCAL, EXIGE CORS UNCLOCK
      axios({
        url: donwload,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fullUri = document.createElement("a");
        fullUri.href = fileURL;
        fullUri.setAttribute(
          "download",
          this.orderFormProvider.documentReference
        );
        document.body.appendChild(fullUri);
        fullUri.click();
        URL.revokeObjectURL(fullUri.href);
      });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  mounted() {
    this.tabActive = "details";
    this.loadingTitle = "Chargement";
    this.loadingMessage =
      "Chargement des détails de la commande fournisseur...";
  },
  components: {
    VuePerfectScrollbar,
    TotalOrderFormProvider,
    HistoryOrderFormProvider,
    quillEditor,
    DatePicker,
    Status,
    GedViewerSidebar,
  },
};
</script>

<style lang="scss" scoped>
.sidebar-action {
  flex-direction: column;
}

.cancel-modal-buttons {
  display: flex;
  justify-content: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;

    .dropdown-content-item {
      padding: 10px 10px 10px 10px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdownbutton-redirect {
  background-color: #0000;
  border-color: #0000;
  color: #5c636a;
}
</style>
