import store from "@/store";

export function applyDeduction() {
  let totalHt = Math.round(store.getters.document.totalBaseNetHt * 100) / 100;
  let totalTtc = Math.round(store.getters.document.totalTtc * 100) / 100;
  if (
    (store.getters.document.nature == 0 ||
      store.getters.document.nature == 1 ||
      store.getters.document.nature == 4 ||
      store.getters.document.nature == 6) &&
    (store.getters.deduction.holdbackPercent ||
      store.getters.deduction.prorataAccountPercent ||
      store.getters.deduction.punctualDeductions?.length > 0 ||
      store.getters.deduction.reviews?.length > 0)
  ) {
    store.getters.deduction.holdbackNumber = store.getters.deduction
      .holdbackPercent
      ? Math.round(
          totalTtc * (store.getters.deduction.holdbackPercent / 100) * 100
        ) / 100
      : null;
    store.getters.deduction.prorataAccountPrice = store.getters.deduction
      .prorataAccountPercent
      ? Math.round(
          totalTtc * (store.getters.deduction.prorataAccountPercent / 100) * 100
        ) / 100
      : null;
    store.getters.deduction.punctualDeductions = store.getters.deduction
      .punctualDeductions
      ? store.getters.deduction.punctualDeductions.map((item) => {
          return {
            id: item.id ? item.id : 0,
            percent: item.percent,
            number:
              item.number != null
                ? Math.round(totalTtc * (item.percent / 100) * 100) / 100
                : null,
            label: item.label,
          };
        })
      : [];
    store.getters.deduction.reviews = store.getters.deduction.reviews
      ? store.getters.deduction.reviews.map((item) => {
          return {
            id: item.id ? item.id : 0,
            percent: item.percent,
            number:
              item.number != null
                ? Math.round(totalHt * (item.percent / 100) * 100) / 100
                : null,
            referencielTvaId: item.referencielTvaId,
            label: item.label,
            includePrestation: item.includePrestation,
          };
        })
      : [];
    store.dispatch("updateDeduction", { deduction: store.getters.deduction });
  }
}

export function applyOrderFormCustomerDeduction() {
  let totalHt =
    Math.round(store.getters.orderFormCustomer.totalBaseNetHt * 100) / 100;
  let totalTtc =
    Math.round(store.getters.orderFormCustomer.totalTtc * 100) / 100;
  if (
    store.getters.deduction.holdbackPercent ||
    store.getters.deduction.prorataAccountPercent ||
    store.getters.deduction.punctualDeductions?.length > 0
  ) {
    store.getters.deduction.holdbackNumber = store.getters.deduction
      .holdbackPercent
      ? Math.round(
          totalTtc * (store.getters.deduction.holdbackPercent / 100) * 100
        ) / 100
      : null;
    store.getters.deduction.prorataAccountPrice = store.getters.deduction
      .prorataAccountPercent
      ? Math.round(
          totalTtc * (store.getters.deduction.prorataAccountPercent / 100) * 100
        ) / 100
      : null;
    store.getters.deduction.punctualDeductions = store.getters.deduction
      .punctualDeductions
      ? store.getters.deduction.punctualDeductions.map((item) => {
          return {
            id: item.id ? item.id : 0,
            percent: item.percent,
            number:
              item.number != null
                ? Math.round(totalTtc * (item.percent / 100) * 100) / 100
                : null,
            label: item.label,
          };
        })
      : [];
    store.getters.deduction.reviews = store.getters.deduction.reviews
      ? store.getters.deduction.reviews.map((item) => {
          return {
            id: item.id ? item.id : 0,
            percent: item.percent,
            number:
              item.number != null
                ? Math.round(totalHt * (item.percent / 100) * 100) / 100
                : null,
            referencielTvaId: item.referencielTvaId,
            label: item.label,
            includePrestation: item.includePrestation,
          };
        })
      : [];
    store.dispatch("updateDeduction", { deduction: store.getters.deduction });
  }
}
