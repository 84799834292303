<template lang="pug">
div
  .d-flex.justify-content-between
    h5.mb-1.d-flex.align-items-center.mb-0.text-primary
      span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
        feather-icon(icon='ChevronRightIcon') 
        | Historique
  div(v-if="isLoadingDocumentHistory")
    ejs-skeleton.mb-1(height='15px')
    ejs-skeleton.mb-1(height='15px')
    ejs-skeleton.mb-1(height='15px')
  app-timeline(v-else="documentHistoriesList.length>0")
    app-timeline-item(v-for="(item, index) in documentHistoriesList" :key="index" :title="item.comments" :time="formatDate(item.date)" variant="secondary")
  vs-divider
</template>

<script>
import { mapGetters } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts"

import { SkeletonComponent } from "@syncfusion/ej2-vue-notifications";

export default {
  computed: {
    ...mapGetters([
      "documentHistoriesList",
      "isLoadingDocumentHistory"
    ]),
  },
  methods: {
    formatDate
  },
  components: {
    'ejs-skeleton': SkeletonComponent,
    AppTimeline,
    AppTimelineItem,
  },
}
</script>