import { INewCreditForm } from "../interfaces/invoices/document/INewCreditForm";

export function NewCreditFormDefaultProperties(value = 0) : INewCreditForm {
  return {
    creditHt: 0,
    creditTtc: 0,
    type: 2,
    value: Math.round(value * 100) / 100,
    nature: 5,
  }
}