<template lang="pug">
div
  div(style="page-break-inside: avoid;")
    .border-left.border-right.border-top.rounded-top.content-total(v-if="documentOptions")
      div
        //- Gestion de la remise
        div(v-if="documentOptions.showDiscount && document.nature != 4")
          //- Sous-total HT (avant remise)
          strong.d-flex.total-row Sous-total HT 
            .ml-auto.price-total
              span.totalWithoutTax {{ formatCurrency(totalDocument.subTotalHt) }}
          .d-flex.align-items-center(v-if="!preview")
            | Remise globale
            .ml-auto.d-flex(v-if="canEditDiscount && !disabled && (document.nature == 0 || document.nature == 1 || (document.nature == 6 && !document.invoiceId))" style="padding-top: 5px; padding-bottom: 5px;")
              input.form-control.form-control-sm(:disabled="document.nature == 4" v-model="document.discountGlobalNumber" type="number" min='0' @blur="saveAction" @input='isNegative' autocomplete='off' style='width: 65px;' name='discount')
              select.form-control.form-control-sm.ml-1(:disabled="document.nature == 4" @input="saveAction" v-model="document.discountGlobalType" name='discount_type' style='width: 80px;')
                option.discount-option-percentage(value=0 selected='selected') %
                option.discount-option-no-tax(value=1) &euro; HT
                //- TODO Faire la remise TTC
                //- option.discount-option-with-tax(value=2) &euro; TTC
            .ml-auto.d-flex(v-else)
              span {{ document.discountGlobalType == '1' ? '-' : '' }}{{ document.discountGlobalType == '0' ? formatPercent(document.discountGlobalNumber) : formatCurrency(document.discountGlobalNumber) + ' HT' }}
          .discount-total(v-else)
            .d-flex.total-row
              | {{ document.discountGlobalType == '0' ? 'Remise globale' : 'Remise HT' }}
              span.ml-auto.price-total {{ document.discountGlobalType == '1' ? '-' : '' }}{{ document.discountGlobalType == '0' ?  formatPercent(document.discountGlobalNumber) : formatCurrency(document.discountGlobalNumber) }}
          .discount-total(v-show="document.discountGlobalType != 1 && documentOptions.showDiscount")
            .d-flex.total-row
              | Remise HT
              span.ml-auto.price-total -{{ formatCurrency(totalDocument.discountAmount) }}
          .total-divider
        
        //- Gestion des factures d'acompte
        div(v-if="document.nature == 1 && ((totalDocument.previousInvoices.downPaymentInvoices && totalDocument.previousInvoices.downPaymentInvoices.length>0) || (totalDocument.previousInvoices.invoices && totalDocument.previousInvoices.invoices.length>0))") 
          div
            strong.d-flex.total-row(v-if="!documentOptions.showDiscount") Sous-total HT
              .ml-auto.price-total
                span.totalWithoutTax {{ formatCurrency(totalDocument.subTotalHt) }}
            div(v-for='(item, index) in totalDocument.previousInvoices.downPaymentInvoices' :key='index')
              .d-flex.total-row Acompte {{(item.documentStep ? `#${item.documentStep}` : '') }} {{ item.documentReference }}
                .ml-auto.price-total
                  span.totalWithoutTax -{{ formatCurrency(item.totalHt) }}
            div(v-for='(item, index) in totalDocument.previousInvoices.invoices' :key='index')
              .d-flex.total-row Situation {{(item.documentStep ? `#${item.documentStep}` : '') }} {{ item.documentReference }}
                .ml-auto.price-total
                  span.totalWithoutTax -{{ formatCurrency(item.totalHt) }}
          .total-divider

        //- Rappel du marché initial
        //- Gestion du Total TTC
        //- Attention en dessous premier v-if passé en v-show parce que on a besoin que les fonctions s'exécute
        div(v-if="document.nature == 2 || document.nature == 3 || document.nature == 4" v-show="documentOptions.showSituationDetails") 
          strong.d-flex.total-row Total net HT de la prestation
            .ml-auto.price-total
              span.totalWithoutTax {{ document.nature == 4 ? formatCurrency(totalDocument.totalNetHtPrestation) : initialDocument && initialDocument.totalHt ? formatCurrency(initialDocument.totalHt) : '' }}
          .total-divider

          div(v-if="totalDocument.previousInvoices.downPaymentInvoices.length > 0 || totalDocument.previousInvoices.invoices.length > 0")
            .d-flex.total-row(v-for="(item, index) in totalDocument.previousInvoices.downPaymentInvoices") {{ natureTranslate(item.nature) }} {{ (item.documentStep ? `#${item.documentStep}` : '') }} - {{ item.documentReference }}
              .ml-auto.price-total
                span.totalWorks {{ formatCurrency(item.totalHt.toFixed(2)) }}
            .d-flex.total-row(v-for="(item, index) in totalDocument.previousInvoices.invoices") {{ item.nature == 4 || item.nature == 3 ? 'Situation' : natureTranslate(item.nature)}} {{ (item.documentStep ? `#${item.documentStep}` : '') }} - {{ item.documentReference }}
              .ml-auto.price-total
                span.totalWorks {{ formatCurrency(item.totalHt.toFixed(2)) }}
            strong.d-flex.total-row Total HT des factures précédentes
              .ml-auto.price-total
                span.totalWorks {{ formatCurrency(totalDocument.previousInvoices.downPaymentInvoicesHt + totalDocument.previousInvoices.invoicesHt) }}
            .d-flex.total-row {{ 'Situation en cours'}}
              .ml-auto.price-total
                span.totalWorks {{ formatCurrency(totalDocument.totalNetHt) }}
            strong.d-flex.total-row Cumul HT d'avancement
              .ml-auto.price-total
                span.totalWorks {{ formatCurrency(calculateCumulAvancement) }}
            
            .total-divider(v-if="!isLastSituation")

          div(v-if="isLastSituation")
            strong.d-flex.total-row Reste HT à facturer
              .ml-auto.price-total
                span.totalWithoutTax {{ formatCurrency(remainingToBillHt) }}
                
            .total-divider

        div(v-if="haveReview && document.nature != 4")
          strong.d-flex.total-row.justify-content-between Base total HT
            .ml-auto.price-total
              span.totalWithoutTax {{ formatCurrency(totalDocument.totalBaseNetHt) }}
          div(v-for='(item, index) in deduction.reviews' :key='index')
            .d-flex.total-row {{ item.label }}
              .ml-auto.price-total
                span.totalWorks {{ formatCurrency(item.number.toFixed(2)) }}

        //- Total HT (après remise si remise)
        //- Gestion du Total TTC
        strong.d-flex.total-row.justify-content-between(v-if="document.nature != 4") Total HT
          .ml-auto.price-total
            span.totalWithoutTax {{ formatCurrency(totalDocument.totalNetHt) }}

    
        div(v-if="documentOptions.showDiscount && document.nature == 4")
          //- Sous-total HT (avant remise)
          strong.d-flex.total-row Sous-total brut HT
            .ml-auto.price-total
              span.totalWithoutTax {{ formatCurrency(totalDocument.subTotalHt) }}
          //- .d-flex.align-items-center(v-if="!preview")
          .d-flex.align-items-center
            | {{ document.discountGlobalType == '0' ? 'Remise globale' : 'Remise HT' }}
            .ml-auto.d-flex
              span.ml-auto.price-total {{ document.discountGlobalType == '0' ?  formatPercent(document.discountGlobalNumber) : '-' + formatCurrency(totalDocument.discountAmount) }}
          //-   .ml-auto.d-flex(v-if="canEditDiscount && !disabled" style="padding-top: 5px; padding-bottom: 5px;")
          //-     input.form-control.form-control-sm(v-model="document.discountGlobalNumber" type="number" min='0' @blur="saveAction" @input='isNegative' autocomplete='off' style='width: 65px;' name='discount')
          //-     select.form-control.form-control-sm.ml-1(@input="saveAction" v-model="document.discountGlobalType" name='discount_type' style='width: 80px;')
          //-       option.discount-option-percentage(value=0 selected='selected') %
          //-       option.discount-option-no-tax(value=1) &euro; HT
          //-       //- TODO Faire la remise TTC
          //-       //- option.discount-option-with-tax(value=2) &euro; TTC
          //-   .ml-auto.d-flex(v-else)
          //-     span {{ document.discountGlobalType == '1' ? '-' : '' }}{{ document.discountGlobalType == '0' ? formatPercent(document.discountGlobalNumber) : formatCurrency(document.discountGlobalNumber) + ' HT' }}
          //- .discount-total(v-else)
          //-   .d-flex.total-row
          //-     | {{ document.discountGlobalType == '0' ? 'Remise globale' : 'Remise HT' }}
              span.ml-auto.price-total {{ document.discountGlobalType == '1' ? '-' : '' }}{{ document.discountGlobalType == '0' ?  formatPercent(document.discountGlobalNumber) : formatCurrency(document.discountGlobalNumber) }}
          .discount-total(v-show="document.discountGlobalType != 1 && documentOptions.showDiscount")
            .d-flex.total-row
              | Remise HT
              span.ml-auto.price-total -{{ formatCurrency(totalDocument.discountAmount) }}
          .total-divider
          
      //- CASE SITUATION
      //- Gestion des Situation
      div(v-if="document.nature == 4")
          div(v-if="initialDocument && initialDocument.invoices && initialDocument.invoices.length > 0 && initialDocument.invoices.filter((el) => (el.nature == 2 && el.status != 5)).length > 0 && document.nature == 4")
            strong.d-flex.total-row Sous-total net HT
              .ml-auto.price-total
                span.totalWithoutTax {{ formatCurrency(totalDocument.totalNetHtBeforeDeductDownPaymentInvoice) }}
            .deduction-down-payment-invoices
              .d-flex.deduction-down-payment-invoices-row
                | Déduction acompte à l'avancement 
                popper.rest-button(v-show="canEditDiscount" trigger='clickToOpen' :options="{\placement: 'top',\modifiers: { offset: { offset: '0,10px' } },\}")
                  .py-1.popper.text-left
                    .d-flex.align-items-center.justify-start.text-left.mb-0.my-0
                      b-col(cols="7" style='margin-bottom:0 !important; padding-bottom:0px !important; margin-top:0 !important; padding-top:0px !important')
                        h5.text-left.mb-0 Acompte restant à déduire
                      b-col.pb-0(cols="5" style='margin-bottom:0 !important; padding-bottom:0px !important; margin-top:0 !important; padding-top:0px !important').d-flex
                        .w-50.text-right
                          .fw-500.w-100.text-right Initial
                        .w-50.text-right
                          .fw-500.w-100.text-right Restant
                    div(v-for='(item, index) in totalDocument.previousInvoices.downPaymentInvoices' :key='index' :class="totalDocument.previousInvoices.downPaymentInvoices.length > 1 ? 'mb-1' : ''")
                        .d-flex.align-items-center.justify-start.text-left.mb-0.my-0
                          b-col.pb-0(cols="7")
                            .fw-500.text-left Acompte {{(item.documentStep ? `#${item.documentStep}` : '') }} {{ item.documentReference }}
                          b-col.pb-0(cols="5").d-flex
                            .w-50.text-right
                              .fw-500.w-100.text-right {{ formatCurrency(item.totalHt) }}
                            .w-50.text-right
                              .fw-500.w-100.text-right {{ formatCurrency(item.totalHtRemaining ) }}
                        .d-flex.align-items-center.justify-start.text-left.my-0(v-for='(itemTva, indexTva) in item.tvas' :key='indexTva' style="height:20.3px")
                          b-col(cols="7").py-0.text-left
                            span > TVA {{ itemTva.tvaValue }} %
                          b-col.pb-0(cols="5").d-flex
                            .w-50.text-right
                              .w-100.text-right {{ formatCurrency(itemTva.amount) }}
                            .w-50.text-right
                              .w-100.text-right {{ formatCurrency(itemTva.amountRemaining) }}
                  span.cursor-pointer.fw-500.text-primary(v-show="!preview" @click.prevent='' slot='reference')
                    | Reste
                span.ml-auto.price-total -{{ formatCurrency(totalDocument.previousInvoices.downPaymentInvoicesDeductedHt) }}
            .total-divider
            
          // J'ai reculler ça, à voir ce que ca donne avec des factures avant celle de situation
          div(v-if="haveReview && document.nature == 4")
            strong.d-flex.total-row.justify-content-between Base total HT
              .ml-auto.price-total
                span.totalWithoutTax {{ formatCurrency(totalDocument.totalBaseNetHt) }}
            div(v-for='(item, index) in deduction.reviews' :key='index')
              .d-flex.total-row {{ item.label }}
                .ml-auto.price-total
                  span.totalWorks {{ formatCurrency(item.number.toFixed(2)) }}

          strong.d-flex.total-row Total HT
            .ml-auto.price-total
              span.totalWithoutTax {{ formatCurrency(totalDocument.totalNetHt) }}

      // Gestion de la TVA
      div(v-if="documentOptions.isReverseCharge")
        .d-flex Autoliquidation
      div(v-else)
        div(v-for="(item, index) in totalDocument.tvaDetails")
          .d-flex.total-row.justify-content-between TVA {{ referencielTvaTranslate(item.referencielTvaId) }}
            span.ml-auto.price-total {{ formatCurrency(item.priceTva) }}
        strong.d-flex.total-row.total-with-tax(v-if='totalDocument.tvaDetails.length > 1') Total TVA
          .ml-auto.price-total
            span.totalWithTax {{ formatCurrency(totalDocument.totalTva.toFixed(2)) }}
      
      // Gestion du total TTC
      div
        strong.d-flex.total-row.justify-content-between Total TTC
          .ml-auto.price-total
            span.price-total {{ formatCurrency(document.totalTtc) }} 
            
      // Gestion de la Marge
      div(v-if="documentOptions.calculateMargin && document.nature != 4 && !preview")
        .total-divider
        .d-flex.margin-row Marge brute HT
          span.ml-auto.price-total {{formatCurrency(document.marginBrutHt) }}
        .d-flex.margin-percent-row.align-items-center(:style="!marginStatus ? 'color:red;' : ''") Taux de marge
          span.ml-auto.price-total {{ !marginStatus ? "Taux de marge non calculable" : percentMarginRate + ' %' }}
          feather-icon.ml-1(v-if="!marginStatus" icon="InfoIcon" size="16" v-b-tooltip.hover.top="'Le taux de marge n’est pas calculable car au moins une ligne du document n’a pas de taux de marge renseigné, veuillez les compléter pour voir apparaitre le taux de marge.'") 

      // Gestion des Déductions
      div(v-if="deduction")
        .total-divider(v-if="(deduction.punctualDeduction && deduction.punctualDeductions.length > 0) || deduction.prorataAccountPrice != null || deduction.prorataAccountPercent != null || deduction.holdbackNumber != null || deduction.holdbackPercent != null")
        div(v-if="deduction.punctualDeductions" v-for="(item, index) in deduction.punctualDeductions")
          .d-flex.total-row {{ item.label }} {{'(' + item.percent + ' %)' }}
            span.ml-auto.price-total {{ formatCurrency(item.number) }}
        div(v-if="deduction.prorataAccountPrice != null || deduction.prorataAccountPercent != null")
          .d-flex.total-row Compte de prorata {{ deduction.prorataAccountLabel }} {{'(' + deduction.prorataAccountPercent + ' %)' }}
            span.ml-auto.price-total -{{ formatCurrency(deduction.prorataAccountPrice) }}
        div(v-if="deduction.holdbackNumber != null || deduction.holdbackPercent != null")
          .d-flex.total-row Retenue de garantie ({{deduction.holdbackPercent}} %)
            span.ml-auto.price-total -{{ formatCurrency(deduction.holdbackNumber) }}

    // Gestion du NET A PAYER
    strong.d-flex.big.bg-primary.text-white.totalInvoice.justify-content-between(:style="preview ? 'background:'+document.institutionColor+' !important; color:'+document.institutionSecondaryColor+' !important' : ''" :class="!preview && calcPriceTotalRemainingToPay()!==totalNetToPay ? '' : 'rounded-bottom'") {{ document.nature == 5 || document.nature == 6 ? "NET À CRÉDITER" : "NET À PAYER" }}
      .ml-auto.price-total
        span.totalWithTax {{ formatCurrency(totalNetToPay) }}
    .border-left.border-right.border-bottom.content-total(:class="document.nature != 0 ? 'rounded-bottom' : ''" v-if="!preview && document.nature != 0 && calcPriceTotalRemainingToPay()!==totalNetToPay")
      div
        div(v-for='(itemPayment, indexPayment) in document.payments' :key="'payment-'+indexPayment")
          .d-flex.total-row Règlement du {{ formatDate(itemPayment.date) }} par {{ itemPayment.paymentMethod }}
            .ml-auto.price-total
              span.totalWithoutTax -{{ formatCurrency(itemPayment.value) }}
        div(v-if="document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4")
          div(v-for='(itemCredit, indexCredit) in document.credits' :key="'credit-'+indexCredit")
            .d-flex.total-row Avoir {{ itemCredit.documentReference }}
              .ml-auto.price-total
                span.totalWithoutTax -{{ formatCurrency(itemCredit.value) }}
        div(v-if="document.nature == 5 || document.nature == 6")
          div(v-for='(itemInvoice, indexInvoice) in document.invoices' :key="'credit-'+indexInvoice")
            .d-flex.total-row Facture {{ itemInvoice.documentReference }}
              .ml-auto.price-total
                span.totalWithoutTax -{{ formatCurrency(itemInvoice.value) }}
        strong.d-flex.total-row {{ document.nature == 5 || document.nature == 6 ? "Reste à créditer" : "Reste à payer" }}
          span.ml-auto.price-total {{ formatCurrency(calcPriceTotalRemainingToPay()) }}
 
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  calcNetToPay,
  calcTotalMarginBrutHt,
} from "@/types/api-orisis/library/DocumentOperations";
import vSelect from "vue-select";
import { VBPopover, BPopover, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
import dayjs from "dayjs";
import {
  formatCurrency,
  formatDate,
  formatPercent,
  formatNumber,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { natureTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { referencielTvaTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { applyDeduction } from "@/types/api-orisis/library/DeductionOperations.ts";

export default {
  data() {
    return {
      calcNetToPay,
      calcTotalMarginBrutHt,
      totalNetToPay: 0,
    };
  },
  props: {
    preview: {
      default: false,
    },
    canEditDiscount: {
      required: true,
      default: true,
      type: Boolean,
    },
    disabled: {
      default: false,
    },
  },
  components: {
    "v-select": vSelect,
    popper: Popper,
  },
  methods: {
    ...mapActions(["updateQuote", "updateInvoice"]),
    formatCurrency,
    formatDate,
    formatPercent,
    formatNumber,
    natureTranslate,
    referencielTvaTranslate,
    applyDeduction,
    setPreviousInvoices() {
      let downPaymentInvoices = [];
      let downPaymentInvoicesHt = 0;
      let downPaymentInvoicesDeductedHt = 0;
      let invoices = [];
      let invoicesHt = 0;
      if (this.initialDocument && this.initialDocument.invoices) {
        for (let i = 0; i < this.initialDocument.invoices.length; i++) {
          const invoice = this.initialDocument.invoices[i];
          invoice = this.initialDocument.invoices[i];
          if (this.document.id !== invoice.id && invoice.status != 5) {
            if (
              this.document.nature == 2 &&
              invoice.nature == 2 &&
              invoice.documentStep < this.document.documentStep
            ) {
              invoice.totalHtRemaining = invoice.totalHt;
              downPaymentInvoices.push(invoice);
              downPaymentInvoicesHt += invoice.totalHt;
            } else if (
              (this.document.nature == 3 || this.document.nature == 4) &&
              invoice.nature == 2
            ) {
              invoice.totalHtRemaining = invoice.totalHt;
              downPaymentInvoices.push(invoice);
              downPaymentInvoicesHt += invoice.totalHt;
            } else if (
              ((this.document.nature == 3 && invoice.nature == 3) ||
                (this.document.nature == 4 && invoice.nature == 4)) &&
              invoice.documentStep < this.document.documentStep
            ) {
              invoices.push(invoice);
              invoicesHt += invoice.totalHt;
            } else if (this.document.nature == 1 && invoice.nature == 2) {
              invoice.totalHtRemaining = invoice.totalHt;
              downPaymentInvoices.push(invoice);
              downPaymentInvoicesHt += invoice.totalHt;
            } else if (this.document.nature == 1 && invoice.nature == 3) {
              invoice.totalHtRemaining = invoice.totalHt;
              invoices.push(invoice);
              invoicesHt += invoice.totalHt;
            }
          }
        }
      }
      return {
        invoices: invoices,
        invoicesHt: formatNumber(invoicesHt),
        downPaymentInvoices: downPaymentInvoices,
        downPaymentInvoicesHt: formatNumber(downPaymentInvoicesHt),
        downPaymentInvoicesDeductedHt: downPaymentInvoicesDeductedHt,
      };
    },
    calcPriceTotalRemainingToPay() {
      let payment = this.document.payments
        ? this.document.payments.reduce((total, el) => {
            return (total += Math.round(el.value * 100) / 100);
          }, 0)
        : 0;
      let credits = this.document.credits
        ? this.document.credits.reduce((total, el) => {
            return (total += Math.round(el.value * 100) / 100);
          }, 0)
        : 0;
      let invoices = this.document.invoices
        ? this.document.invoices.reduce((total, el) => {
            return (total += Math.round(el.value * 100) / 100);
          }, 0)
        : 0;
      let priceTotalRemainingToPay = (this.document.remainingToPayTtc =
        Math.round(
          Number(
            this.totalNetToPay -
              payment -
              (this.document.nature == 5 || this.document.nature == 6
                ? invoices
                : credits)
          ) * 100
        ) / 100);
      return priceTotalRemainingToPay;
    },
    // Enregistrement du document
    saveAction() {
      setTimeout(() => {
        if (this.document.nature == 0) {
          this.applyDeduction();
          this.updateQuote({ quote: this.document, updateState: false });
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          this.applyDeduction();
          this.updateInvoice({ invoice: this.document, updateState: false });
        }
      }, 200);
    },
    // Actions globales liées aux str
    isNegative(e) {
      setTimeout(() => {
        if (this.document.discountGlobalType == 0) {
          if (e.target.value < 0) {
            this.document.discountGlobalNumber = 0;
          } else if (e.target.value > 100) {
            this.document.discountGlobalNumber = 100;
          } else if (!e.target.value) {
            this.document.discountGlobalNumber = 0;
          }
        } else {
          if (e.target.value < 0) {
            this.document.discountGlobalNumber = 0;
          } else if (!e.target.value) {
            this.document.discountGlobalNumber = 0;
          }
        }
      }, 200);
    },
    referencielTvaValue(referencielTvaId) {
      if (referencielTvaId != null) {
        return formatNumber(
          this.institutionSettingsActive.referencielTvas.find(
            (item) => item.referencielTva.id == referencielTvaId
          ).referencielTva.value
        );
      } else {
        return 0;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getDocumentDetails",
      "deduction",
      "marginStatus",
      "initialDocument",
      "institutionSettingsActive",
    ]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
    referencielTvas() {
      return this.institutionSettingsActive.referencielTvas;
    },
    remainingToBillHt() {
      let remainingToBillHt =
        this.document.nature == 4
          ? this.totalDocument.totalNetHtPrestation -
            this.calculateCumulAvancement
          : this.initialDocument && this.initialDocument.totalHt
          ? this.initialDocument.totalHt - this.calculateCumulAvancement
          : 0;
      this.document.remainingToBillHt = remainingToBillHt;
      return remainingToBillHt;
    },
    haveReview() {
      return this.deduction?.reviews && this.deduction.reviews.length > 0;
    },
    percentMarginRate() {
      let totalMarginBrutHt = (this.document.marginBrutHt =
        this.calcTotalMarginBrutHt(
          this.totalDocument.discountAmount,
          this.document.nature
        ));
      if (this.document.totalHt - totalMarginBrutHt == 0) {
        this.document.marginRate = 100;
        return (100).toFixed(2);
      } else {
        let marginRate = (this.document.marginRate = this.totalDocument
          .subTotalHt
          ? formatNumber(
              (this.document.marginBrutHt / this.totalDocument.subTotalHt) * 100
            )
          : 0);
        return marginRate.toFixed(2);
      }
    },
    isLastSituation() {
      if (
        this.initialDocument &&
        this.document.id &&
        this.initialDocument.nature
      ) {
        let find = this.initialDocument.invoices.find(
          (item) => item.id == this.document.id
        );
        let situationInvoices = this.initialDocument.invoices.filter(
          (item) => item.nature == 4 && item.status != 5
        );
        for (let i = 0; i < situationInvoices.length; i++) {
          const situationInvoice = situationInvoices[i];
          if (situationInvoice.documentStep > find.documentStep) {
            return false;
          }
        }
      }
      // Le document est la dernière situation connu et est validé alors on met à jour le restant dù de la commande.

      return true;
    },
    calculateCumulAvancement() {
      let cumulAvancement = 0;
      cumulAvancement =
        this.totalDocument.previousInvoices.downPaymentInvoicesHt +
        this.totalDocument.previousInvoices.invoicesHt +
        this.totalDocument.totalNetHt;
      return cumulAvancement;
    },
    totalDocument() {
      let totalDocument = {
        subTotalHt: 0,
        discountAmount: 0,
        totalNetHtPrestation: 0,
        totalNetHtBeforeDeductDownPaymentInvoice: 0,
        totalNetHt: 0,
        totalBaseNetHt: 0,
        tvaDetails: [],
        totalTva: 0,
        totalTtc: 0,
        previousInvoices: [],
      };

      let documentTvas = [];
      // On affecte les factures d'acompte
      totalDocument.previousInvoices = this.setPreviousInvoices();

      // On filtre sur les lignes devant être prises en compte
      // On génère le tableau TVA
      // On calcule les totaux
      totalDocument.tvaDetails = this.getDocumentDetails
        .filter(
          (el) =>
            el.type != 0 &&
            el.type != 1 &&
            el.type != 2 &&
            el.type != 11 &&
            el.type != 12 &&
            el.type != 13 &&
            el.type != 18 &&
            el.type != 17 &&
            el.type != 16 &&
            el.type != 15 &&
            el.type != 14 &&
            !el.isOption
        )
        .reduce((acc, obj) => {
          // On crée un object par taux de TVA
          let findIndex = acc.findIndex(
            (el) => el.referencielTvaId === obj.referencielTvaId
          );

          // On calcule les montant selon les quantités et remise par ligne
          let priceBrutHt = 0;
          let priceBrutHtAvced = 0;
          let priceBrutHtPrevious = 0;
          let priceBrutHtPrestation =
            Math.round(obj.unitPriceHt * obj.quantity * 100) / 100;
          let roundedValue = 0;

          if (this.document.nature == 6) {
            priceBrutHt =
              Math.round(obj.unitPriceHt * obj.quantityDeducted * 100) / 100;
          } else if (this.document.nature == 4) {
            if (!this.document.situationType) {
              priceBrutHt =
                Math.round(
                  ((obj.unitPriceHt *
                    obj.quantity *
                    (obj.situationProgress - obj.previousSituationProgress)) /
                    100) *
                    100
                ) / 100;
              priceBrutHtPrevious =
                Math.round(
                  ((obj.unitPriceHt *
                    obj.quantity *
                    obj.previousSituationProgress) /
                    100) *
                    100
                ) / 100;
              priceBrutHtAvced =
                Math.round(
                  ((obj.unitPriceHt * obj.quantity * obj.situationProgress) /
                    100) *
                    100
                ) / 100;
            } else {
              priceBrutHt =
                Math.round(
                  obj.unitPriceHt *
                    (obj.situationProgressQuantity -
                      obj.previousSituationProgressQuantity) *
                    100
                ) / 100;
              priceBrutHtPrevious =
                Math.round(
                  obj.unitPriceHt * obj.previousSituationProgressQuantity * 100
                ) / 100;
              priceBrutHtAvced =
                Math.round(
                  obj.unitPriceHt * obj.situationProgressQuantity * 100
                ) / 100;
            }
          } else {
            priceBrutHt =
              Math.round(obj.unitPriceHt * obj.quantity * 100) / 100;
          }
          let discount = (100 - obj.discount) / 100;
          let priceNetHtPrestation =
            Math.round(priceBrutHtPrestation * discount * 100) / 100;
          let priceNetHtPrevious =
            Math.round(priceBrutHtPrevious * discount * 100) / 100;
          let priceNetHtAvced =
            Math.round(priceBrutHtAvced * discount * 100) / 100;
          let priceNetHt = Math.round(priceBrutHt * discount * 100) / 100;

          if (findIndex < 0) {
            // On instancie un nouveau de TVA
            acc.push({
              priceHtPrestation: priceNetHtPrestation,
              priceHtPrevious: priceNetHtPrevious,
              priceTvaPrevious: 0,
              priceTtcPrevious: 0,
              priceHtAvced: priceNetHtAvced,
              priceTvaAvced: 0,
              priceTtcAvced: 0,
              priceHt: priceNetHt,
              priceTva: 0,
              priceTtc: 0,
              referencielTvaId: obj.referencielTvaId,
              roundedValue: roundedValue,
            });
          } else {
            // On additionne un taux de TVA
            acc[findIndex].priceHtPrestation += priceNetHtPrestation;
            acc[findIndex].priceHtPrevious += priceNetHtPrevious;
            acc[findIndex].priceHtAvced += priceNetHtAvced;
            acc[findIndex].priceHt += priceNetHt;
            acc[findIndex].roundedValue += roundedValue;
          }

          totalDocument.subTotalHt += Math.round(priceNetHt * 100) / 100;
          totalDocument.totalNetHtBeforeDeductDownPaymentInvoice +=
            Math.round(priceNetHt * 100) / 100;
          totalDocument.totalNetHt += Math.round(priceNetHt * 100) / 100;
          totalDocument.totalNetHtPrestation +=
            Math.round(priceNetHtPrestation * 100) / 100;
          return acc;
        }, []);

      // Ajoute à totalDocument.totalNetHtPrestation les montants des intialDocument.invoices.reviews si review à le champ includePrestation à true
      if (this.initialDocument && this.initialDocument.invoices) {
        for (let i = 0; i < this.initialDocument.invoices.length; i++) {
          const invoice = this.initialDocument.invoices[i];
          if (
            invoice.deduction?.reviews.length > 0 &&
            this.document.id !== invoice.id
          ) {
            for (let j = 0; j < invoice.deduction.reviews.length; j++) {
              const review = invoice.deduction.reviews[j];
              if (review.includePrestation) {
                totalDocument.totalNetHtPrestation +=
                  Math.round(review.number * 100) / 100;
              }
            }
          }
        }
      }

      // On calcule le prix de la TVA et le prix TTC
      for (let index = 0; index < totalDocument.tvaDetails.length; index++) {
        const lineTva = totalDocument.tvaDetails[index];
        lineTva.priceTvaAvced =
          Math.round(
            ((lineTva.priceHtAvced *
              this.referencielTvaValue(lineTva.referencielTvaId)) /
              100) *
              100
          ) / 100;
        lineTva.priceTtcAvced = lineTva.priceTvaAvced + lineTva.priceHtAvced;
        lineTva.priceTvaPrevious =
          Math.round(
            ((lineTva.priceHtPrevious *
              this.referencielTvaValue(lineTva.referencielTvaId)) /
              100) *
              100
          ) / 100;
        lineTva.priceTtcPrevious =
          lineTva.priceTvaPrevious + lineTva.priceHtPrevious;
        lineTva.priceTva =
          Math.round(
            ((lineTva.priceHt *
              this.referencielTvaValue(lineTva.referencielTvaId)) /
              100) *
              100
          ) / 100;
        lineTva.priceTtc = lineTva.priceTva + lineTva.priceHt;
      }

      // On calcule le prix total de la TVA
      totalDocument.totalTva = totalDocument.tvaDetails.reduce((acc, line) => {
        return acc + line.priceTva;
      }, 0);

      // On calcule le prix total TTC
      totalDocument.totalTtc = totalDocument.tvaDetails.reduce((acc, line) => {
        return acc + line.priceTtc;
      }, 0);

      if (
        this.documentOptions.showDiscount ||
        (this.document.nature == 1 &&
          ((totalDocument.previousInvoices.invoices &&
            totalDocument.previousInvoices.invoices.length > 0) ||
            (totalDocument.previousInvoices.downPaymentInvoices &&
              totalDocument.previousInvoices.downPaymentInvoices.length > 0)))
      ) {
        // On affecte la remise
        if (this.documentOptions.showDiscount) {
          let totalNetHtPrestationBeforeDiscountGlobal = structuredClone(
            totalDocument.totalNetHtPrestation
          );
          if (
            this.documentOptions.showDiscount &&
            this.document.discountGlobalType == 1
          ) {
            totalDocument.discountAmount =
              totalNetHtPrestationBeforeDiscountGlobal == 0
                ? 0
                : Math.round(
                    (totalDocument.subTotalHt /
                      totalNetHtPrestationBeforeDiscountGlobal) *
                      this.document.discountGlobalNumber *
                      100
                  ) / 100;
          } else if (
            this.documentOptions.showDiscount &&
            this.document.discountGlobalType == 0
          ) {
            totalDocument.discountAmount =
              Math.round(
                totalDocument.subTotalHt *
                  (formatNumber(this.document.discountGlobalNumber) / 100) *
                  100
              ) / 100;
          } else {
            totalDocument.discountAmount = 0;
          }
          totalDocument.totalNetHt = 0;
          totalDocument.totalNetHtPrestation =
            totalNetHtPrestationBeforeDiscountGlobal -
            (this.document.discountGlobalType == 0
              ? totalNetHtPrestationBeforeDiscountGlobal *
                (this.document.discountGlobalNumber / 100)
              : this.document.discountGlobalNumber);
          totalDocument.totalNetHtBeforeDeductDownPaymentInvoice = 0;
          totalDocument.totalTva = 0;
          totalDocument.totalTtc = 0;
        }

        for (let index = 0; index < totalDocument.tvaDetails.length; index++) {
          const lineTva = totalDocument.tvaDetails[index];
          lineTva.coeff =
            lineTva.priceHt /
            (totalDocument.subTotalHt == 0 ? 1 : totalDocument.subTotalHt);
          // On affecte la remise
          if (this.documentOptions.showDiscount) {
            let priceNetHt = 0;
            let priceNetHtAvced = 0;
            let priceNetHtPrevious = 0;
            let priceNetHtPrestation = 0;

            if (
              this.documentOptions.showDiscount &&
              this.document.discountGlobalType == 1
            ) {
              let deductionAssociatedHt =
                lineTva.coeff * totalDocument.discountAmount;
              priceNetHt = lineTva.priceHt - deductionAssociatedHt;
              priceNetHtAvced = lineTva.priceHtAvced - deductionAssociatedHt;
              priceNetHtPrevious = lineTva.priceHtPrevious; // OK quand c'est une premiere situation détaillé avec une remise globale en € HT
              priceNetHtPrestation =
                lineTva.priceHtPrestation - deductionAssociatedHt;
            } else {
              let discountLine =
                Math.round(
                  ((lineTva.priceHt * this.document.discountGlobalNumber) /
                    100) *
                    100
                ) / 100;
              priceNetHt =
                Math.round((lineTva.priceHt - discountLine) * 100) / 100;
              priceNetHtAvced =
                Math.round((lineTva.priceHtAvced - discountLine) * 100) / 100;
              priceNetHtPrevious =
                Math.round(lineTva.priceHtPrevious * 100) / 100;
              priceNetHtPrestation =
                Math.round((lineTva.priceHtPrestation - discountLine) * 100) /
                100;
            }

            lineTva.coeffLines = priceNetHt / totalDocument.subTotalHt;
            lineTva.priceHtPrestation = priceNetHtPrestation;
            lineTva.priceHtPrevious = structuredClone(priceNetHtPrevious);

            lineTva.priceHtAvced = structuredClone(priceNetHtAvced);

            lineTva.priceHt = structuredClone(priceNetHt);
            totalDocument.totalNetHt += Math.round(lineTva.priceHt * 100) / 100;
            totalDocument.totalNetHtBeforeDeductDownPaymentInvoice +=
              Math.round(lineTva.priceHt * 100) / 100;
          }

          // On déduit les acomptes sur les factures simple
          if (
            this.document.nature == 1 &&
            totalDocument.previousInvoices.downPaymentInvoices &&
            totalDocument.previousInvoices.downPaymentInvoices.length > 0
          ) {
            for (
              let j = 0;
              j < totalDocument.previousInvoices.downPaymentInvoices.length;
              j++
            ) {
              const downPaymentInvoice =
                totalDocument.previousInvoices.downPaymentInvoices[j];
              downPaymentInvoice.tvas.forEach((tva) => {
                if (tva.referencielTvaId == lineTva.referencielTvaId) {
                  lineTva.priceHt -= tva.totalHt;
                  totalDocument.totalNetHt -=
                    Math.round(tva.totalHt * 100) / 100;
                }
              });
            }
          }
          if (
            this.document.nature == 1 &&
            totalDocument.previousInvoices.invoices &&
            totalDocument.previousInvoices.invoices.length > 0
          ) {
            for (
              let j = 0;
              j < totalDocument.previousInvoices.invoices.length;
              j++
            ) {
              const invoice = totalDocument.previousInvoices.invoices[j];
              invoice.tvas.forEach((tva) => {
                if (tva.referencielTvaId == lineTva.referencielTvaId) {
                  lineTva.priceHt -= tva.totalHt;
                  totalDocument.totalNetHt -=
                    Math.round(tva.totalHt * 100) / 100;
                }
              });
            }
          }
        }

        // On calcule le prix de la TVA et le prix TTC
        for (let index = 0; index < totalDocument.tvaDetails.length; index++) {
          const lineTva = totalDocument.tvaDetails[index];
          lineTva.priceTvaAvced =
            Math.round(
              ((lineTva.priceHtAvced *
                this.referencielTvaValue(lineTva.referencielTvaId)) /
                100) *
                100
            ) / 100;
          lineTva.priceTtcAvced = lineTva.priceTvaAvced + lineTva.priceHtAvced;
          lineTva.priceTvaPrevious =
            Math.round(
              ((lineTva.priceHtPrevious *
                this.referencielTvaValue(lineTva.referencielTvaId)) /
                100) *
                100
            ) / 100;
          lineTva.priceTtcPrevious =
            lineTva.priceTvaPrevious + lineTva.priceHtPrevious;
          lineTva.priceTva =
            Math.round(
              ((lineTva.priceHt *
                this.referencielTvaValue(lineTva.referencielTvaId)) /
                100) *
                100
            ) / 100;
          lineTva.priceTtc = lineTva.priceTva + lineTva.priceHt;
        }

        // On calcule le prix total de la TVA
        totalDocument.totalTva = totalDocument.tvaDetails.reduce(
          (acc, line) => {
            return acc + line.priceTva;
          },
          0
        );

        // On calcule le prix total TTC
        totalDocument.totalTtc = totalDocument.tvaDetails.reduce(
          (acc, line) => {
            return acc + line.priceTtc;
          },
          0
        );
      }

      // On recalcule tout si c'est une facture de situation détaillée
      if (
        this.document.nature == 4 &&
        totalDocument.previousInvoices &&
        totalDocument.previousInvoices.downPaymentInvoices
      ) {
        for (let index = 0; index < totalDocument.tvaDetails.length; index++) {
          const lineTva = totalDocument.tvaDetails[index];
          // On calcule la répartition des factures d'acompte sur les situation
          for (
            let k = 0;
            k < totalDocument.previousInvoices.downPaymentInvoices.length;
            k++
          ) {
            const downPaymentInvoice =
              totalDocument.previousInvoices.downPaymentInvoices[k];
            const acompteCoef = Number.parseFloat(
              downPaymentInvoice.totalHt /
                totalDocument.previousInvoices.downPaymentInvoicesHt
            );
            downPaymentInvoice.tvas.forEach((tva) => {
              let tvaAmount = Math.round(tva.amount * 100) / 100;
              if (tva.referencielTvaId == lineTva.referencielTvaId) {
                // On calcule l'avancement précédent
                let previousSituationHt = lineTva.priceHtPrevious * acompteCoef;
                let previousSituationTva =
                  lineTva.priceTvaPrevious * acompteCoef;
                let previousSituationTtc =
                  lineTva.priceTtcPrevious * acompteCoef;
                let previousTotalHtDeducted =
                  previousSituationHt - tva.totalHt > 0
                    ? tva.totalHt
                    : previousSituationHt;
                let previousTotalTvaDeducted =
                  previousSituationTva - tvaAmount > 0
                    ? tvaAmount
                    : previousSituationTva;
                let previousTotalTtcDeducted =
                  previousSituationTtc - (tvaAmount + tva.totalHt) > 0
                    ? tvaAmount + tva.totalHt
                    : previousSituationTtc;

                // On calcule l'avancement global
                let globalSituationHt = lineTva.priceHtAvced * acompteCoef;
                let globalSituationTva = lineTva.priceTvaAvced * acompteCoef;
                let globalSituationTtc = lineTva.priceTtcAvced * acompteCoef;
                let totalHtDeducted =
                  globalSituationHt < tva.totalHt
                    ? globalSituationHt
                    : tva.totalHt;
                let totalTvaDeducted =
                  globalSituationTva - tvaAmount > 0
                    ? tvaAmount
                    : globalSituationTva;
                let totalTtcDeducted =
                  globalSituationTtc - (tvaAmount + tva.totalHt) > 0
                    ? tvaAmount + tva.totalHt
                    : globalSituationTtc;

                // On calcule l'avancement actuel
                let currentTotalHtDeducted =
                  totalHtDeducted - previousTotalHtDeducted;
                let currentTotalTvaDeducted =
                  totalTvaDeducted - previousTotalTvaDeducted;
                let currentTotalTtcDeducted =
                  totalTtcDeducted - previousTotalTtcDeducted;
                if (currentTotalHtDeducted >= 0) {
                  totalDocument.previousInvoices.downPaymentInvoicesDeductedHt +=
                    currentTotalHtDeducted;
                  totalDocument.totalNetHt =
                    totalDocument.totalNetHt - currentTotalHtDeducted;
                  totalDocument.totalTva =
                    totalDocument.totalTva - currentTotalTvaDeducted;
                  totalDocument.totalTtc =
                    totalDocument.totalTtc - currentTotalTtcDeducted;
                  lineTva.priceHt = lineTva.priceHt - currentTotalHtDeducted;
                  lineTva.priceTva = lineTva.priceTva - currentTotalTvaDeducted;
                  lineTva.priceTtc = lineTva.priceTtc - currentTotalTtcDeducted;
                }

                if (totalHtDeducted >= 0) {
                  tva.amountRemaining = tva.amount - totalTvaDeducted;
                  downPaymentInvoice.totalHtRemaining -= totalHtDeducted;
                }
              }
            });
          }
        }
      }

      for (let index = 0; index < totalDocument.tvaDetails.length; index++) {
        const lineTva = totalDocument.tvaDetails[index];
        if (
          documentTvas.findIndex(
            (el) =>
              el.referencielTvaId == lineTva.referencielTvaId ||
              (el.referencielTva
                ? el.referencielTva.id == lineTva.referencielTvaId
                : false)
          ) >= 0
        ) {
          let index = documentTvas.findIndex(
            (el) => el.referencielTvaId == lineTva.referencielTvaId
          );
          documentTvas[index].value = lineTva.priceHt;
          documentTvas[index].valueTva = lineTva.priceTva;
        } else {
          documentTvas.push({
            id: 0,
            value: lineTva.priceHt,
            valueTva: lineTva.priceTva,
            referencielTvaId: lineTva.referencielTvaId,
            quoteId: this.document.nature == 0 ? this.document.id : null,
            invoiceId:
              this.document.nature == 1 ||
              this.document.nature == 2 ||
              this.document.nature == 3 ||
              this.document.nature == 4
                ? this.document.id
                : null,
            creditId:
              this.document.nature == 5 || this.document.nature == 6
                ? this.document.id
                : null,
          });
        }
      }

      this.document.totalBaseNetHt = totalDocument.totalBaseNetHt =
        totalDocument.totalNetHt;

      if (this.deduction?.reviews && this.deduction.reviews.length > 0) {
        for (let index = 0; index < this.deduction.reviews.length; index++) {
          const review = this.deduction.reviews[index];
          let findIndexDocumentTvas = documentTvas.findIndex(
            (el) => el.referencielTvaId === review.referencielTvaId
          );
          let findIndextvaDetails = totalDocument.tvaDetails.findIndex(
            (el) => el.referencielTvaId === review.referencielTvaId
          );
          if (findIndexDocumentTvas >= 0) {
            let priceNetHt = Math.round(review.number * 100) / 100;
            let priceTva =
              Math.round(
                ((priceNetHt *
                  this.referencielTvaValue(review.referencielTvaId)) /
                  100) *
                  100
              ) / 100;
            documentTvas[findIndexDocumentTvas].value += priceNetHt;
            documentTvas[findIndexDocumentTvas].valueTva += priceTva;
            totalDocument.tvaDetails[findIndextvaDetails].priceHt += priceNetHt;
            totalDocument.tvaDetails[findIndextvaDetails].priceTva += priceTva;
            let priceTtc = priceNetHt + priceTva;
            if (review.includePrestation)
              totalDocument.totalNetHtPrestation +=
                Math.round(review.number * 100) / 100;
            totalDocument.tvaDetails[findIndextvaDetails].priceTtc += priceTtc;
            totalDocument.totalNetHt += Math.round(review.number * 100) / 100;
            totalDocument.totalTva += Math.round(priceTva * 100) / 100;
            totalDocument.totalTtc += Math.round(priceTtc * 100) / 100;
          }
        }
      }

      // On affectre les nouvelles valeurs calculées au document
      this.document.totalHt = Math.round(totalDocument.totalNetHt * 100) / 100;

      this.document.documentTvas = documentTvas;

      let _this = this;
      if (totalDocument.tvaDetails && totalDocument.tvaDetails.length > 0) {
        totalDocument.tvaDetails.sort(function (a, b) {
          return (
            _this.referencielTvaValue(a.referencielTvaId) -
            _this.referencielTvaValue(b.referencielTvaId)
          );
        });
      }
      this.$emit("updateTvaDetails", totalDocument.tvaDetails);
      // this.document.tvaDetails = totalDocument.tvaDetails
      this.document.totalTtc =
        Number(Math.round(totalDocument.totalNetHt * 100) / 100) +
        Number(Math.round(totalDocument.totalTva * 100) / 100);

      // On calcule le net à payer
      this.totalNetToPay = structuredClone(
        formatNumber(
          this.calcNetToPay(totalDocument.totalNetHt, this.document.totalTtc)
        )
      );
      return totalDocument;
    },
  },
};
</script>

<style>
.popper {
  background-color: #ffffff;
  text-align: center;
  padding: 2px;
  border-radius: 3px;
  border: 1px #ebe9f1 solid;
  box-shadow: rgb(220 220 220) 0 0 15px 0;
  color: #6e6b7b;
  min-width: 500px;
}
.totalInvoice {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 7px;
  padding-right: 7px;
  position: relative;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}

.total-divider {
  border-top: 1px solid #d9d9d9;
  margin: 7px 0 7px 0;
}

.content-total {
  padding: 7px;
}

.error-margin {
  padding: 10px;
  background-color: #f19e9e;
  display: flex;
  align-items: center;
  border-radius: 0.357rem;
}

.price-total {
  white-space: nowrap;
}

.rest-button {
  margin-left: 10px;
  cursor: pointer;
  color: #0c3571;
}
</style>
