<template lang="pug"> 
  div
    // modal
    b-modal#modal-prevent-closing-new-invoice(ok-title="Nouvelle facture" hide-footer cancel-title="Annuler" size="lg" ref='modal-new-invoice' title='Nouvelle facture' @ok='handleOk' :header-class="'bg-light-primary'" :ok-disabled="!institutionSelected")
      template.d-block(#modal-header="{ close }")
        div.w-100
          button.absolute(type="button" aria-label="Close" class="close" @click="close()") ×
          b-row.my-0
            b-col.py-0.mb-0(cols='12' style="padding:0px !important")
              h3.text-primary Nouvelle facture
          b-row.content-scrollable-sticky.m-0.h-100.justify-content-center
            b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
              ul.d-flex
                li(v-if="$route.name != 'lease' && institutionSettingsActive != null && institutionSettingsActive.institutionTypes.find(el=>el.id < 4)")
                  a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'blank-invoice' ? 'current' : ''" @click="tabActive = 'blank-invoice'")
                    span Facture vierge
                li(v-if="institutionSettingsActive != null && institutionSettingsActive.institutionTypes.find(el=>el.id==4)")
                  a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'lease-invoice' ? 'current' : ''" @click="tabActive = 'lease-invoice'")
                    span À partir d'un bail
                li(v-if="invoicesListTemplates.length > 0")
                  a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'templates-invoice' ? 'current' : ''" @click="tabActive = 'templates-invoice'")
                    span À partir d'un modèle
                //- li
                //-   a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'quote-invoice' ? 'current' : ''" @click="tabActive = 'quote-invoice'")
                //-     span Facture sur devis
      .justify-content-center(v-if="isLoadingDocument")
        .content-loader-center.m-0(style="height:250px")
          .text-center.flex-center
            .loading-bg-inner(variant="primary" style="width: 3rem; height: 3rem;")
              .loader
                .outer
                .middle
                .inner
            span(style="margin-top: 8rem")  Chargement des données en cours...
      div(v-else-if="institutionSettingsActive != null && institutionSettingsActive.institutionTypes.length > 0")
        #blank-invoice.d-flex(v-if="tabActive == 'blank-invoice'")
          .list-buttom-custom.m-1(v-for="(institution, index) of institutionSettingsActive.institutionTypes")
            div.relative.doc-buttom-custom(v-if="institution.id != 4" @click="handleSubmit(institution.id)")
              img.w-100(:src="getSrcIcon(institution.id)")
        #lease-invoice.d-flex(v-if="tabActive == 'lease-invoice'")
          b-row.w-100(v-if="institutionSettingsActive != null && institutionSettingsActive.institutionTypes.find(el=> el.id == 4)")
            b-col(v-if="$route.name != 'lease'" cols='12')
              ejs-dropdownlist(id='selectLease' :fields="{ text: 'label', value: 'id' }" :valueTemplate="valueTemplate" :itemTemplate="itemTemplate" :dataSource='leaseListFiltered' v-model='selectLease')
            b-col.d-flex(v-if="selectLease != null" style="padding:0 !important" cols='12')
              .list-buttom-custom.m-1(v-for="(institution, index) of institutionSettingsActive.institutionTypes")
                div.relative.doc-buttom-custom(@click="handleSubmitByLease(institution.id)")
                  img.w-100(:src="getSrcIcon(institution.id)")
        #templates-invoice.d-flex(v-if="tabActive == 'templates-invoice'")
          div.d-flex.justify-content-between(v-if="invoicesListTemplates.length > 0" style="flex-wrap: wrap; width: 100%; align-items:start;")
            .list-buttom-custom.listflex.m-1(v-for="(invoicesListTemplate, index) of invoicesListTemplatesSorted")
              ejs-tooltip.relative.doc-buttom-custom(@click.native="newInvoiceFromTemplate(invoicesListTemplate)" ref="tooltip" :content="invoicesListTemplate.description")
                img.w-100(src="@/assets/icones/icon-doc-vierge.svg")
                div.pl-1
                  span(v-html="formatStripHtml(invoicesListTemplate.label)" style="font-size: 13px; text-align:left")
            .list-buttom-custom.m-1(style="flex:1")
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BListGroup,
  BListGroupItem,
  BModal,
  VBModal,
} from "bootstrap-vue";

import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";
import dayjs from "dayjs";
import Vue from "vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";

var itemVue = Vue.component("itemTemplate", {
  template: `<div class="content-quote-choice h-100 d-flex align-items-center ml-1"><span style='font-size:13px;white-space:normal'> Bail {{ data.label }} - Locataire : {{ data.tenantName }}</span></div>`,
  data() {
    return {
      data: {},
    };
  },
});

var valueVue = Vue.component("valueTemplate", {
  template: `<div class="content-quote-choice h-100 d-flex align-items-center ml-1"><span style='font-size:13px;white-space:normal'> Bail {{ data.label }} - Locataire : {{ data.tenantName }}</span></div>`,
  data() {
    return {
      data: {},
    };
  },
});

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    "v-select": vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    submittedNames: [],
    selectLease: null,
    tabActive: "blank-invoice",
    itemTemplate: function () {
      return { template: itemVue };
    },
    valueTemplate: function () {
      return { template: valueVue };
    },
  }),
  props: {
    affairId: {
      type: Number,
      default: null,
    },
    companyId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      "invoicesListTemplates",
      "isLoadingDocument",
      "institutionSelected",
      "isLoadingInstitutionsList",
      "institutionsList",
      "lease",
      "invoicesListByAffair",
      "periodicitiesList",
      "workspaceSelected",
      "institutionSettingsActive",
    ]),
    invoicesListTemplatesSorted() {
      let _this = this;
      return this.invoicesListTemplates.sort(function (a, b) {
        return (
          _this.formatStripHtml(a.description) -
          _this.formatStripHtml(b.description)
        );
      });
    },
    leaseListFiltered: {
      get() {
        return this.$store.getters.leasesList;
      },
    },
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    documentDetails: {
      get() {
        return this.$store.getters["getDocumentDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT_DETAILS", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "getInvoiceEmpty",
      "addLineInvoiceLease",
      "updateInvoice",
      "duplicateQuote",
      "duplicateInvoice",
      "leaseGroupFunctions",
      "getLeases",
    ]),
    formatDate,
    getSrcIcon(type) {
      switch (type) {
        case 1:
          return require("@/assets/icones/icon-doc-standard.svg");
          break;
        case 2:
          return require("@/assets/icones/icon-doc-cee.svg");
          break;
        case 3:
          return require("@/assets/icones/icon-doc-btp.svg");
          break;
        case 4:
          return require("@/assets/icones/icon-doc-loyer.svg");
          break;
        default:
          return require("@/assets/icones/icon-doc-vierge.svg");
          break;
      }
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit(invoiceType = null) {
      if (invoiceType != null) {
        this.$refs["modal-new-invoice"].toggle("#toggle-btn-new-invoice");
        this.$store.commit(
          "SET_IS_GLOBAL_MESSAGE",
          "Création de la facture en cours..."
        );
        if (invoiceType == 4) {
          this.$store.commit("SET_IS_GLOBAL_CREATING", true);
          await this.newInvoiceByLease(4);
        } else {
          this.$store.commit("SET_IS_GLOBAL_CREATING", true);
          await this.getInvoiceEmpty({
            documentType: invoiceType,
            updateState: true,
            affairId: this.affairId,
            companyId: this.companyId,
          })
            .then((invoice) => {
              this.$store.commit("SET_IS_GLOBAL_CREATING", false);
              this.$router.push({
                name: "new-invoice",
                params: {
                  id: invoice.id,
                  title: "Nouvelle facture",
                  tips: "Nouvelle facture",
                  routeOrigine: "invoices",
                },
              });
            })
            .catch((error) => {
              this.$store.commit("SET_IS_GLOBAL_CREATING", false);
            });
        }
      }
    },
    async handleSubmitByLease(invoiceType = null) {
      if (invoiceType != null) {
        this.$store.commit(
          "SET_IS_GLOBAL_MESSAGE",
          "Création de la facture de loyer en cours..."
        );
        this.$refs["modal-new-invoice"].toggle("#toggle-btn-new-invoice");

        const lastInvoiceByDateEnd = this.invoicesListByAffair
          .sort((a, b) => {
            return (
              new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
            );
          })
          .filter((el) => el.type == 4 && el.status != 5)[0];

        if (
          lastInvoiceByDateEnd &&
          dayjs(lastInvoiceByDateEnd.endDate).format("DD/MM/YYYY") ===
            dayjs(this.lease.endDate).format("DD/MM/YYYY")
        ) {
          this.$bvToast.toast(
            "Toutes les factures de loyer ont déjà été créées pour ce bail.",
            {
              title: `Erreur`,
              variant: "danger",
              solid: true,
            }
          );
        } else {
          this.$store.commit("SET_IS_GLOBAL_CREATING", true);
          await this.newInvoiceByLease(invoiceType);
        }
      }
    },

    async newInvoiceByLease(documentType) {
      // Lancer la fonction getPeriodicity
      let selectLease = structuredClone(
        this.leaseListFiltered.find((el) => el.id == this.selectLease)
      );
      if (documentType == 4) {
        await this.leaseGroupFunctions({
          lease: selectLease,
          includeCharge: true,
        }).then(async (result) => {
          // Créer la facture
          await this.getInvoiceEmpty({
            documentType: documentType,
            updateState: true,
            affairId: this.affairId ? this.affairId : selectLease.affairId,
            companyId: this.companyId ? this.companyId : selectLease.tenantId,
          })
            .then(async (invoice) => {
              invoice.paymentConditionId = invoice.paymentCondition
                ? invoice.paymentCondition.id
                : null;
              invoice.companyId = selectLease.tenantId;
              invoice.companyName = selectLease.tenantName;
              invoice.companyAddress = selectLease.tenantAddress;
              invoice.companyAddressComplement =
                selectLease.tenantAddressComplement;
              invoice.companyZipCode = selectLease.tenantZipCode;
              invoice.companyCity = selectLease.tenantCity;
              invoice.companyCountry = selectLease.tenantCountry;
              invoice.affairId = selectLease.affairId;
              invoice.affairName = selectLease.label;
              invoice.affairAddress = selectLease.propertyAddress;
              invoice.affairAddressComplement =
                selectLease.propertyAddressComplement;
              invoice.affairCity = selectLease.propertyCity;
              invoice.affairZipCode = selectLease.propertyZipCode;
              invoice.affairCountry = selectLease.propertyCountry;
              invoice.affairCode = selectLease.codeCRO;

              invoice.description =
                "Loyer du " +
                dayjs(result.invoiceDateRangeLease.startDate).format(
                  "DD/MM/YYYY"
                ) +
                " au " +
                dayjs(result.invoiceDateRangeLease.endDate).format(
                  "DD/MM/YYYY"
                );
              invoice.startDate = result.invoiceDateRangeLease.startDate;
              invoice.endDate = result.invoiceDateRangeLease.endDate;

              this.documentDetails = [];

              // if (documentType == 4) {
              invoice.calculateMargin = false;
              invoice.calculateUnitPriceHtByUnitPurchasePriceHt = false;
              invoice.showDiscountColumn = false;
              invoice.hideUnitColumn = false;
              invoice.hideQuantityColumn = false;
              invoice.showReferenceColumn = false;
              invoice.hideReferencielTvaColumn = false;
              invoice.showDescription = true;
              for (let i = 0; i < result.documentDetails.length; i++) {
                const documentDetail = result.documentDetails[i];
                this.addLineInvoiceLease({
                  documentDetail: {
                    ...documentDetail,
                    description: documentDetail.description,
                    unitPriceHt: documentDetail.unitPriceHt,
                    quoteId: null,
                    invoiceId: invoice.id,
                    creditId: null,
                    index: (i + 1).toString(),
                    total: documentDetail.unitPriceHt,
                  },
                  indexArray: i,
                  isCredit: false,
                });
              }
              invoice.totalHt = result.totalDocument.totalNetHt;
              invoice.totalTtc = result.totalDocument.totalTtc;
              // }
              await this.updateInvoice({
                invoice: invoice,
                updateState: false,
              });
              this.selectLease = null;
              this.$store.commit("SET_IS_GLOBAL_CREATING", false);
              this.$router.push({
                name: "new-invoice",
                params: {
                  id: invoice.id,
                  title: "Nouvelle facture",
                  tips: "Nouvelle facture",
                  routeOrigine: "invoices",
                },
              });
            })
            .catch((error) => {
              console.error(error);
            });
        });
      } else {
        await this.getInvoiceEmpty({
          documentType: documentType,
          updateState: true,
          affairId: this.affairId ? this.affairId : selectLease.affairId,
          companyId: this.companyId ? this.companyId : selectLease.tenantId,
        })
          .then(async (invoice) => {
            invoice.paymentConditionId = invoice.paymentCondition
              ? invoice.paymentCondition.id
              : null;
            invoice.companyId = selectLease.tenantId;
            invoice.companyName = selectLease.tenantName;
            invoice.companyAddress = selectLease.tenantAddress;
            invoice.companyAddressComplement =
              selectLease.tenantAddressComplement;
            invoice.companyZipCode = selectLease.tenantZipCode;
            invoice.companyCity = selectLease.tenantCity;
            invoice.companyCountry = selectLease.tenantCountry;
            invoice.affairId = selectLease.affairId;
            invoice.affairName = selectLease.label;
            invoice.affairAddress = selectLease.propertyAddress;
            invoice.affairAddressComplement =
              selectLease.propertyAddressComplement;
            invoice.affairCity = selectLease.propertyCity;
            invoice.affairZipCode = selectLease.propertyZipCode;
            invoice.affairCountry = selectLease.propertyCountry;
            invoice.affairCode = selectLease.codeCRO;

            this.documentDetails = [];

            this.selectLease = null;
            this.$store.commit("SET_IS_GLOBAL_CREATING", false);
            this.$router.push({
              name: "new-invoice",
              params: {
                id: invoice.id,
                title: "Nouvelle facture",
                tips: "Nouvelle facture",
                routeOrigine: "invoices",
              },
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    toggleModal() {
      if (this.$route.name == "lease") {
        this.getLeases({});
        this.tabActive = "lease-invoice";
        this.selectLease = structuredClone(this.lease.id);
      } else {
        this.selectLease = null;
      }
      this.$refs["modal-new-invoice"].toggle("#toggle-btn-new-invoice");
    },
    newInvoiceFromTemplate(document) {
      this.$refs["modal-new-invoice"].hide();
      this.$store.commit(
        "SET_IS_GLOBAL_MESSAGE",
        "Création de la facture en cours..."
      );
      this.$store.commit("SET_IS_GLOBAL_CREATING", true);
      this.duplicateInvoice({
        invoiceId: document.id,
      }).then(async (res) => {
        this.$store.commit("SET_IS_GLOBAL_CREATING", false);
        this.loadingMsg = "Chargement en cours...";
        this.$router.push({
          name: "new-invoice",
          params: {
            id: res.data.id,
            title: "Nouvelle facture",
            tips: "Nouvelle facture",
            routeOrigine: "invoices",
          },
        });
      });
    },
    formatStripHtml(str) {
      if (str === null || str === "") return "";
      else str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>

<style>
#modal-prevent-closing-new-invoice .modal-header {
  padding-bottom: 0 !important;
}
</style>
