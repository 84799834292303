<template lang="pug">
  b-modal#modal-new-down-payment-invoice(v-model="isPopupNewDownPaymentInvoice" cancel-variant='outline-secondary' ok-title='Créer la facture' cancel-title='Annuler' centered='' title="Facture d'acompte" @ok="createDownPaymentInvoice" style="z-index:9999999999 !important")
    .text-center.flex-center(v-if='isLoadingDocument')
      .loading-bg-inner(role='status')
        .loader
          .outer
          .middle
          .inner
      br
      | Chargement des données en cours...
    b-row.my-0(v-else)
      b-col.py-0.mb-1.font-weight-bold(cols='12')
        b-form-group.pr-1(style='flex:1')
          validation-provider(#default='{ errors }')
            | Facture d'acompte pour {{ initialDocument.initialDocumentNature == 'quote' ? 'le devis' : 'la commande client' }} {{ initialDocument.documentReference}}
    b-row.my-0 
      b-col.py-0.mb-1(cols='6')
        b-form-group.pr-1(style='flex:1')
          validation-provider(#default='{ errors }')
            div.d-flex.align-items-center(style="height:37.99px") Montant {{ initialDocument.initialDocumentNature == 'quote' ? 'du devis' : 'de la commande client' }}
      b-col.py-0.mb-1(cols='6')
        b-form-group.pr-1(style='flex:1')
          validation-provider.d-flex.justify-content-end(#default='{ errors }')
            div.d-flex.align-items-center.justify-content-end(style="height:37.99px") {{ formatCurrency(initialDocument.totalTtc) }} TTC
    b-row.my-0(v-for="(item, index) in previousInvoiceNotCanceled" :key="index")
      b-col.py-0.mb-1(cols='6')
        b-form-group.pr-1(style='flex:1')
          validation-provider(#default='{ errors }')
            div.d-flex.align-items-center.font-weight-bold(style="height:37.99px") {{ natureTranslate(item.nature) }} {{ (item.documentStep ? `#${item.documentStep}` : '') }} {{ item.documentReference }} 
      b-col.py-0.mb-1(cols='6')
        b-form-group.pr-1(style='flex:1')
          validation-provider.d-flex.justify-content-end(#default='{ errors }')
            div.d-flex.align-items-center.justify-content-end(style="height:37.99px") {{ formatCurrency(item.totalTtc) }} TTC
      vs-divider
    b-row.my-0 
      b-col.py-0.mb-1(cols='6')
        b-form-group(style='flex:1')
          validation-provider(#default='{ errors }')
            div.d-flex.align-items-center(style="height:37.99px") Montant de l'acompte
      b-col.mb-1.padding-r-5(cols='3')
        b-form-group(style='flex:1')
          validation-provider(#default='{ errors }' name='validityQuoteDefault' rules="required")
            b-form-input(@input='isNegative' type="number" v-model="newDownPaymentInvoiceForm.value")
      b-col.mb-1.no-padding(cols='3')
        b-form-group.pr-1(style='flex:1')
          validation-provider(#default='{ errors }' name='validityQuoteDefault' rules="required")
            v-select#amountType(:clearable="false" :loading='isLoadingAmountTypeList' :deselectFromDropdown='true' :closeOnSelect='true' :state='errors.length > 0 ? false : null' v-model='newDownPaymentInvoiceForm.type' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="\
              amountTypeList\
              " :reduce='elem => elem.value')
                  template(v-slot:no-options='')
                    template  Aucun r&eacute;sultat trouv&eacute;
    b-row(v-if="newDownPaymentInvoiceForm.type != 2 && newDownPaymentInvoiceForm.value != 0").my-0 
      b-col.py-0.mb-1(cols='6')
        b-form-group.pr-1(style='flex:1')
          validation-provider(#default='{ errors }')
            div.d-flex.align-items-center(style="height:37.99px") Total de l'acompte
      b-col.py-0.mb-1(cols='6')
        b-form-group.pr-1(style='flex:1')
          validation-provider.d-flex.justify-content-end(#default='{ errors }')
            div.d-flex.align-items-center.justify-content-end(style="height:37.99px") {{ formatCurrency(newDownPaymentInvoiceForm.downPaymentTtc) }} TTC
    b-row.my-0 
      b-col.py-0.mb-1(cols='6')
        b-form-group.pr-1(style='flex:1')
          validation-provider(#default='{ errors }')
            div.d-flex.align-items-center.font-weight-bold(style="height:37.99px") Reste à facturer
      b-col.py-0.mb-1(cols='6')
        b-form-group.pr-1(style='flex:1')
          validation-provider.d-flex.justify-content-end(#default='{ errors }')
            div.d-flex.align-items-center.justify-content-end.font-weight-bold(style="height:37.99px") {{ formatCurrency(quoteRemains) }} TTC
</template>

<script>
import {
  getPricesByPourcentagePriceTtc,
  getPricesByPriceHt,
  getPricesByPriceTtc,
} from "@/types/api-orisis/library/DocumentOperations";
import {
  formatCurrency,
  formatDate,
} from "@/types/api-orisis/library/FormatOperations.ts";
import {
  amountTypeTranslate,
  natureTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";
import { BForm, BFormGroup, BFormInput, BModal, VBModal } from "bootstrap-vue";
import dayjs from "dayjs";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { InvoiceOptionModel } from "@/types/api-orisis/models/InvoiceOptionModel";

export default {
  data() {
    return {
      InvoiceOptionModel,
      getPricesByPriceHt,
      getPricesByPriceTtc,
      getPricesByPourcentagePriceTtc,
      newDownPaymentInvoiceForm: {
        downPaymentHt: 0,
        downPaymentTtc: 0,
        remains: 0,
        type: 0,
        value: 0,
      },
      tvaLine: [],
      deduction: null,
    };
  },
  props: {
    activePopupDownPaymentInvoice: {
      type: Boolean,
      required: true,
    },
    preRenderingData: {
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  watch: {
    isPopupNewDownPaymentInvoice(val) {
      if (val) {
        this.initValues();
      }
      return false;
    },
  },
  computed: {
    ...mapGetters([
      "document",
      "orderFormCustomer",
      "isLoadingAmountTypeList",
      "amountTypeList",
      "institutionSettingsActive",
      "initialDocument",
      "natureList",
      "isLoadingDocument",
      "orderFormCustomerOption",
      "paymentConditionsList",
    ]),
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
    isPopupNewDownPaymentInvoice: {
      get() {
        return this.activePopupDownPaymentInvoice;
      },
      set(val) {
        if (!val) {
          this.$emit("toggleModalNewDownPaymentInvoice");
        }
      },
    },
    previousInvoiceNotCanceled() {
      if (this.initialDocument) {
        return this.initialDocument.invoices
          ? this.initialDocument.invoices.filter((el) => el.status != 5)
          : [];
      }
      return [];
    },
    documentDetails: {
      get() {
        return this.$store.getters["getDocumentDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT_DETAILS", value);
      },
    },
    quoteRemains() {
      let initialDocument = structuredClone(this.initialDocument);
      let price = structuredClone(initialDocument.totalTtc);
      let deductionInvoicesPrice = null;

      if (initialDocument.invoices) {
        deductionInvoicesPrice = {
          totalHt: 0,
          totalTtc: 0,
        };
        for (let i = 0; i < initialDocument.invoices.length; i++) {
          const invoice = initialDocument.invoices[i];
          if (invoice.status != 5) {
            price -= invoice.totalTtc;
          }
        }
      }
      switch (this.newDownPaymentInvoiceForm.type) {
        case 0:
          this.downPaymentObject = this.getPricesByPourcentagePriceTtc(
            initialDocument,
            this.newDownPaymentInvoiceForm,
            this.$route.name,
            deductionInvoicesPrice
          );
          this.newDownPaymentInvoiceForm.downPaymentHt =
            this.downPaymentObject.totals.totalHt;
          this.newDownPaymentInvoiceForm.downPaymentTtc =
            this.downPaymentObject.totals.totalTtc;
          price -= this.downPaymentObject.totals.totalTtc;
          break;
        case 1:
          this.downPaymentObject = this.getPricesByPriceHt(
            initialDocument,
            this.newDownPaymentInvoiceForm,
            this.$route.name,
            deductionInvoicesPrice
          );
          this.newDownPaymentInvoiceForm.downPaymentHt =
            this.downPaymentObject.totals.totalHt;
          this.newDownPaymentInvoiceForm.downPaymentTtc =
            this.downPaymentObject.totals.totalTtc;
          price -= this.downPaymentObject.totals.totalTtc;
          break;
        case 2:
          this.downPaymentObject = this.getPricesByPriceTtc(
            initialDocument,
            this.newDownPaymentInvoiceForm,
            this.$route.name,
            deductionInvoicesPrice,
            this.initialDocument
          );
          this.newDownPaymentInvoiceForm.downPaymentHt =
            this.downPaymentObject.totals.totalHt;
          this.newDownPaymentInvoiceForm.downPaymentTtc =
            this.downPaymentObject.totals.totalTtc;
          price -= this.downPaymentObject.totals.totalTtc;
          break;
        default:
          break;
      }
      return price.toFixed(2);
    },
  },
  methods: {
    ...mapActions([
      "createInvoice",
      "addLine",
      "createDeduction",
      "getInvoiceOptionDefaultByInvoiceId",
      "createInvoiceOption",
    ]),
    formatCurrency,
    natureTranslate,
    formatDate,
    amountTypeTranslate,
    initValues() {
      this.newDownPaymentInvoiceForm = {
        downPaymentHt: 0,
        downPaymentTtc: 0,
        type: this.preRenderingData
          ? this.preRenderingData.downPaymentRequestType
          : 0,
        remains: 0,
        value: this.preRenderingData ? this.preRenderingData.number : 0,
      };
    },
    isNegative(e) {
      this.newDownPaymentInvoiceForm.value = Math.round(e * 100) / 100;
      if (this.newDownPaymentInvoiceForm.type == 0) {
        if (e < 0) {
          this.newDownPaymentInvoiceForm.value = 0;
        } else if (e > 100) {
          this.newDownPaymentInvoiceForm.value = 100;
        }
      } else {
        if (e < 0) {
          this.newDownPaymentInvoiceForm.value = 0;
        }
      }
    },
    async createDownPaymentInvoice() {
      this.$store.commit(
        "SET_IS_GLOBAL_MESSAGE",
        "Création de la facture d'acompte en cours..."
      );
      this.$store.commit("SET_IS_GLOBAL_CREATING", true);
      let documentInvoice =
        this.type == "document"
          ? structuredClone(this.document)
          : structuredClone(this.orderFormCustomer);
      let downPaymentInvoiceFormFixed = structuredClone(
        this.newDownPaymentInvoiceForm
      );
      let downPaymentInvoiceObjectFixed = structuredClone(
        this.downPaymentObject
      );
      let initialDocument = structuredClone(this.initialDocument);
      let invoiceOptionsDefault = InvoiceOptionModel({});
      let invoiceOptions = InvoiceOptionModel({});
      let initialDocumentOptions =
        this.type == "document"
          ? structuredClone(this.documentOptions)
          : structuredClone(this.orderFormCustomerOption);
      await this.getInvoiceOptionDefaultByInvoiceId({
        updateState: false,
      }).then((res) => {
        invoiceOptionsDefault = res;
        invoiceOptions = {
          invoiceId: 0,
          ...res,
          calculateMargin: false,
          calculateUnitPriceByPurchasePrice: false,
          showDiscount: false,
          showDescription: initialDocumentOptions.showDescription,
          id: 0,
        };
      });

      // Le document généré vient d'ORISIS
      documentInvoice.usedUrlGed = false;

      let deduction = null;
      // Stocker l'id du devis
      documentInvoice.quoteId =
        initialDocument.initialDocumentNature == "quote"
          ? initialDocument.id
          : initialDocument.quote?.id;
      // Stocker l'id du bon de commande
      documentInvoice.orderFormCustomerId =
        initialDocument.initialDocumentNature == "quote"
          ? initialDocument.orderFormCustomer
            ? initialDocument.orderFormCustomer.id
            : null
          : initialDocument.id;
      // Mettre à 0 l'id de la facture
      documentInvoice.id = 0;
      // Mettre la paymentConditionId
      let paymentCondition = this.paymentConditionsList.find(
        (item) => item.id == invoiceOptionsDefault.paymentConditionDefaultId
      );

      documentInvoice.paymentConditionId = paymentCondition
        ? paymentCondition.id
        : 1;
      // Aller chercher la date de validité dans limitDate
      documentInvoice.limitDate = paymentCondition
        ? dayjs().add(paymentCondition.days, "day").toISOString()
        : dayjs().toISOString();
      // Mettre la nature à 2
      documentInvoice.nature = 2;
      // Ajouter isCounted à false,
      documentInvoice.isCounted = false;
      // Mettre documentDate à la date du jour
      documentInvoice.documentDate = new Date().toISOString();
      // Mettre le status à 8
      documentInvoice.status = 8;
      // Mettre le path à null
      documentInvoice.path = null;
      // Calculer le prix Ht
      documentInvoice.totalHt = downPaymentInvoiceFormFixed.downPaymentHt;
      // Calculer le prix Ttc et le reste à payer pour la facture d'acompte
      documentInvoice.totalTtc = documentInvoice.remainingToPayTtc =
        downPaymentInvoiceFormFixed.downPaymentTtc;
      // Mettre à jour le documentStep
      documentInvoice.documentStep = initialDocument.invoices
        ? initialDocument.invoices.filter(
            (item) => item.nature == 2 && item.status != 5
          ).length + 1
        : 1;
      // Mettre à jour les paiements
      documentInvoice.payments = [];

      // Mettre a jour la remise globale
      documentInvoice.discountGlobalNumber = 0;
      documentInvoice.discountGlobalType = 0;

      // Mettre à jour invoiceBTP
      if (initialDocument.initialDocumentNature == "quote") {
        if (initialDocument.quoteBTP != null) {
          documentInvoice.invoiceBTP = {
            id: 0,
            workStartDate: initialDocument.quoteBTP.workStartDate,
            estimatedDurationNumber:
              initialDocument.quoteBTP.estimatedDurationNumber,
            estimatedDurationType:
              initialDocument.quoteBTP.estimatedDurationType,
          };
        } else {
          documentInvoice.invoiceBTP = null;
        }
      } else {
        if (initialDocument.orderFormCustomerBTP != null) {
          documentInvoice.invoiceBTP = {
            id: 0,
            workStartDate: initialDocument.orderFormCustomerBTP.workStartDate,
            estimatedDurationNumber:
              initialDocument.orderFormCustomerBTP.estimatedDurationNumber,
            estimatedDurationType:
              initialDocument.orderFormCustomerBTP.estimatedDurationType,
          };
        } else {
          documentInvoice.invoiceBTP = null;
        }
      }

      // Mettre à jour invoiceCEE
      if (initialDocument.initialDocumentNature == "quote") {
        if (initialDocument.quoteCEE != null) {
          documentInvoice.invoiceCEE = {
            id: 0,
            technicalVisitDate: initialDocument.quoteCEE.technicalVisitDate,
            amountPremium: initialDocument.quoteCEE.amountPremium,
            precarityType: initialDocument.quoteCEE.precarityType,
            parcelNumber: initialDocument.quoteCEE.parcelNumber,
          };
        } else {
          documentInvoice.invoiceCEE = null;
        }
      } else {
        if (initialDocument.orderFormCustomerCEE != null) {
          documentInvoice.invoiceCEE = {
            id: 0,
            technicalVisitDate:
              initialDocument.orderFormCustomerCEE.technicalVisitDate,
            amountPremium: initialDocument.orderFormCustomerCEE.amountPremium,
            precarityType: initialDocument.orderFormCustomerCEE.precarityType,
            parcelNumber: initialDocument.orderFormCustomerCEE.parcelNumber,
          };
        } else {
          documentInvoice.invoiceCEE = null;
        }
      }

      // Mettre à jour les déductions
      if (initialDocument.deduction) {
        deduction = structuredClone(initialDocument.deduction);
      }

      if (initialDocumentOptions) {
        let baseMessage =
          initialDocument.initialDocumentNature == "quote"
            ? "<p>Notes du devis :</p>"
            : "<p>Notes du bon de commande :</p>";
        let tempDivInvoiceOptionsDefault = document.createElement("div");
        tempDivInvoiceOptionsDefault.innerHTML = invoiceOptionsDefault?.comment;
        tempDivInvoiceOptionsDefault = tempDivInvoiceOptionsDefault.innerText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s+/g, "")
          .replace(/[^a-zA-Z0-9]/g, "")
          .toLowerCase();

        let tempDivDocumentInvoice = document.createElement("div");
        tempDivDocumentInvoice.innerHTML = documentInvoice.comments;
        tempDivDocumentInvoice = tempDivDocumentInvoice.innerText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s+/g, "")
          .replace(/[^a-zA-Z0-9]/g, "")
          .toLowerCase();

        if (tempDivDocumentInvoice.includes(tempDivInvoiceOptionsDefault)) {
          documentInvoice.comments = documentInvoice.comments;
        } else {
          documentInvoice.comments =
            (documentInvoice?.comments ? baseMessage : "") +
            (invoiceOptionsDefault?.comment
              ? invoiceOptionsDefault?.comment + "<br>"
              : "") +
            (documentInvoice?.comments ? documentInvoice?.comments : "");
          if (structuredClone(documentInvoice.comments).length === 0) {
            documentInvoice.comments = invoiceOptionsDefault.comment;
          }
        }
      }

      // Mettre à jour le documentDetails et mettre à jours les totaux dans le store
      await this.createInvoice({ invoice: { ...documentInvoice, path: null } })
        .then(async (res) => {
          documentInvoice.id = res.data.id;
          this.documentDetails = [];
          await this.insertLineIntoDocumentDetailAndReturnTotal(
            res.data.id,
            initialDocument,
            downPaymentInvoiceObjectFixed,
            downPaymentInvoiceFormFixed
          );
          await this.createInvoiceOption({
            invoiceOption: { ...invoiceOptions, invoiceId: res.data.id },
          });
          if (deduction)
            await this.applyQuoteDeduction(
              deduction,
              res.data.id,
              initialDocument,
              downPaymentInvoiceFormFixed
            );
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          this.$router.push({
            name: "new-invoice",
            params: {
              id: res.data.id,
              title: "Nouvelle facture",
              tips: "Nouvelle facture",
              routeOrigine: "invoices",
            },
          });
        })
        .catch((err) => {
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
        });
    },
    async insertLineIntoDocumentDetailAndReturnTotal(
      invoiceId,
      initialDocument,
      downPaymentInvoiceObjectFixed,
      downPaymentInvoiceFormFixed
    ) {
      for (let i = 0; i < downPaymentInvoiceObjectFixed.tvaLine.length; i++) {
        const line = downPaymentInvoiceObjectFixed.tvaLine[i];
        let newLine = {
          type: 3,
          unit: 1,
          index: i + 1,
          quoteId: null,
          invoiceId: invoiceId,
          description:
            "<p><strong>Acompte pour " +
            (this.initialDocument.initialDocumentNature == "quote"
              ? "le devis"
              : "la commande client") +
            " n° " +
            initialDocument.documentReference +
            " du " +
            formatDate(initialDocument.documentDate) +
            " </strong><br> " +
            (downPaymentInvoiceFormFixed.type == 0
              ? downPaymentInvoiceFormFixed.value.toFixed(2) +
                " " +
                amountTypeTranslate(downPaymentInvoiceFormFixed.type)
              : formatCurrency(line.advancementHt) + " HT") +
            " du montant total de " +
            formatCurrency(line.priceHt) +
            " HT&nbsp;</p>",
          referencielTvaId: line.referencielTvaId,
          total: line.advancementHt,
          unitPriceHt: line.advancementHt,
        };
        await this.addLine({
          documentDetail: newLine,
          indexArray: i - 1,
          isCredit: false,
          review: false,
        });
      }
    },
    async applyQuoteDeduction(
      deduction,
      invoiceId,
      initialDocument,
      downPaymentInvoiceFormFixed
    ) {
      let quoteTotalTtc = initialDocument.totalTtc;
      let percent = downPaymentInvoiceFormFixed.downPaymentTtc / quoteTotalTtc;
      let invoiceDeduction = {
        creditId: null,
        holdbackDate: deduction.holdbackDate
          ? dayjs(initialDocument.documentDate).add(1, "year").toISOString()
          : null,
        holdbackNumber: deduction.holdbackNumber
          ? Math.round(deduction.holdbackNumber * percent * 100) / 100
          : null,
        holdbackPercent: deduction.holdbackPercent,
        id: 0,
        invoiceId: invoiceId,
        prorataAccountLabel: deduction.prorataAccountLabel,
        prorataAccountPercent: deduction.prorataAccountPercent,
        prorataAccountPrice: deduction.prorataAccountPrice
          ? Math.round(deduction.prorataAccountPrice * percent * 100) / 100
          : null,
        punctualDeductions: deduction.punctualDeductions
          ? deduction.punctualDeductions.map((item) => {
              return {
                id: 0,
                percent: item.percent,
                number:
                  item.number != null
                    ? Math.round(item.number * percent * 100) / 100
                    : null,
                label: item.label,
              };
            })
          : [],
        quoteId: null,
        orderFormCustomerId: null,
        reviews: deduction.reviews.map((review) => {
          return {
            ...review,
            id: 0,
          };
        }),
      };
      await this.createDeduction({ deduction: invoiceDeduction });
    },
  },
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>

<style scoped>
.no-padding {
  padding: 0 !important;
}

.padding-r-5 {
  padding: 0px 5px 0px 0px !important;
}
</style>
