<template lang="pug">
  div(style="page-break-inside: avoid;")
    .border-left.border-right.border-top.rounded-top.content-total
      div
        //- Gestion de la remise
        div(v-if="orderFormOption.showDiscount")
          //- Sous-total HT (avant remise)
          strong.d-flex.total-row Sous-total HT 
            .ml-auto.price-total
              span.totalWithoutTax {{ formatCurrency(totalDocument.subTotalHt) }}
          .d-flex.align-items-center(v-if="!preview")
            | Remise globale
            .ml-auto.d-flex(v-if="canEditDiscount" style="padding-top: 5px; padding-bottom: 5px;")
              input.form-control.form-control-sm( v-model="document.discountGlobalNumber" type="number" min='0' @blur="saveAction" @input='isNegative' autocomplete='off' style='width: 65px;' name='discount')
              select.form-control.form-control-sm.ml-1( @input="saveAction" v-model="document.discountGlobalType" name='discount_type' style='width: 80px;')
                option.discount-option-percentage(value=0 selected='selected') %
                option.discount-option-no-tax(value=1) &euro; HT
                //- TODO Faire la remise TTC
                //- option.discount-option-with-tax(value=2) &euro; TTC
            .ml-auto.d-flex(v-else)
              span {{ document.discountGlobalType == '1' ? '-' : '' }}{{ document.discountGlobalType == '0' ? formatPercent(document.discountGlobalNumber) : formatCurrency(document.discountGlobalNumber) + ' HT' }}
          .discount-total(v-else)
            .d-flex.total-row
              | {{ document.discountGlobalType == '0' ? 'Remise globale' : 'Remise globale HT' }}
              span.ml-auto.price-total {{ document.discountGlobalType == '1' ? '-' : '' }}{{ document.discountGlobalType == '0' ?  formatPercent(document.discountGlobalNumber) : formatCurrency(document.discountGlobalNumber) }}
          .discount-total(v-show="document.discountGlobalType != 1 && document.showDiscount")
            .d-flex.total-row
              | Remise HT
              span.ml-auto.price-total -{{ formatCurrency(totalDocument.discountAmount) }}
          .total-divider

        //- Total HT (après remise si remise)
        //- Gestion du Total TTC
        strong.d-flex.total-row Total HT
          .ml-auto.price-total
            span.totalWithoutTax {{ formatCurrency(totalDocument.totalNetHt) }}
          
      // Gestion de la TVA
      div(v-if="orderFormOption.isReverseCharge")
        .d-flex Autoliquidation
      div(v-else)
        div(v-for="(item, index) in totalDocument.tvaDetails")
          .d-flex.total-row TVA {{ referencielTvaTranslate(item.referencielTvaId) }}
            span.ml-auto.price-total {{ formatCurrency(item.priceTva) }}
        strong.d-flex.total-row.total-with-tax(v-if='totalDocument.tvaDetails.length > 1') Total TVA
          .ml-auto.price-total
            span.totalWithTax {{ formatCurrency(totalDocument.totalTva.toFixed(2)) }}
      
    // Gestion du total TTC
    strong.d-flex.big.bg-primary.text-white.totalInvoice.rounded-bottom(:style="preview ? 'background:'+document.institutionColor+' !important' : ''")  TOTAL TTC
      .ml-auto.price-total
        span(class="totalWithTax") {{ formatCurrency(document.totalTtc) }}
 
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { calcNetToPay } from "@/types/api-orisis/library/DocumentOperations";
import vSelect from "vue-select";

import {
  formatCurrency,
  formatDate,
  formatPercent,
  formatNumber,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { natureTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { referencielTvaTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  data() {
    return {
      calcNetToPay,
      totalNetToPay: 0,
    };
  },
  props: {
    preview: {
      default: false,
    },
    canEditDiscount: {
      required: true,
      default: true,
      type: Boolean,
    },
  },
  components: {
    "v-select": vSelect,
  },
  methods: {
    ...mapActions(["updateOrderForm"]),
    formatCurrency,
    formatDate,
    formatPercent,
    formatNumber,
    natureTranslate,
    referencielTvaTranslate,
    // Enregistrement du document
    saveAction() {
      setTimeout(() => {
        this.updateOrderForm({
          orderFormProvider: this.document,
        });
      }, 200);
    },
    // Actions globales liées aux str
    isNegative(e) {
      setTimeout(() => {
        if (this.document.discountGlobalType == 0) {
          if (e.target.value < 0) {
            this.document.discountGlobalNumber = 0;
          } else if (e.target.value > 100) {
            this.document.discountGlobalNumber = 100;
          }
        } else {
          if (e.target.value < 0) {
            this.document.discountGlobalNumber = 0;
          }
        }
      }, 200);
    },
    referencielTvaValue(referencielTvaId) {
      return formatNumber(
        this.institutionSettingsActive.referencielTvas.find(
          (item) => item.referencielTva.id == referencielTvaId
        ).referencielTva.value
      );
    },
  },
  computed: {
    ...mapGetters([
      "getOrderFormProviderDetails",
      "deduction",
      "orderFormOption",
      "institutionSettingsActive",
    ]),
    document: {
      get() {
        return this.$store.getters.orderFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM", value);
      },
    },
    totalDocument() {
      let totalDocument = {
        subTotalHt: 0,
        discountAmount: 0,
        totalNetHt: 0,
        tvaDetails: [],
        totalTva: 0,
        totalTtc: 0,
      };

      let documentTvas = [];
      // On génère le tableau TVA
      // On calcule les totaux
      totalDocument.tvaDetails = this.getOrderFormProviderDetails
        .filter(
          (elem) =>
            elem.type != 1 &&
            elem.type != 2 &&
            elem.type != 3 &&
            elem.type != 4 &&
            elem.type != 5 &&
            elem.type != 6
        )
        .reduce((acc, obj) => {
          // On crée un object par taux de TVA
          let findIndex = acc.findIndex(
            (el) => el.referencielTvaId === obj.referencielTvaId
          );

          // On calcule les montant selon les quantités et remise par ligne
          let priceBrutHt = 0;
          priceBrutHt = Math.round(obj.unitPriceHt * obj.quantity * 100) / 100;
          let discount = (100 - obj.discount) / 100;
          let priceNetHt = Math.round(priceBrutHt * discount * 100) / 100;

          if (findIndex < 0) {
            // On instancie un nouveau de TVA
            acc.push({
              priceHt: priceNetHt,
              // priceTva: priceTva,
              // priceTtc: priceTtc,
              priceTva: 0,
              priceTtc: 0,
              referencielTvaId: obj.referencielTvaId,
            });
          } else {
            // On additionne un taux de TVA
            acc[findIndex].priceHt += priceNetHt;
          }

          totalDocument.subTotalHt += Math.round(priceNetHt * 100) / 100;
          totalDocument.totalNetHt += Math.round(priceNetHt * 100) / 100;
          return acc;
        }, []);

      // On calcule le prix de la TVA et le prix TTC
      for (let index = 0; index < totalDocument.tvaDetails.length; index++) {
        const lineTva = totalDocument.tvaDetails[index];
        lineTva.priceTva =
          Math.round(
            ((lineTva.priceHt *
              this.referencielTvaValue(lineTva.referencielTvaId)) /
              100) *
              100
          ) / 100;
        lineTva.priceTtc = lineTva.priceTva + lineTva.priceHt;
      }

      // On calcule le prix total de la TVA
      totalDocument.totalTva = totalDocument.tvaDetails.reduce((acc, line) => {
        return acc + line.priceTva;
      }, 0);

      // On calcule le prix total TTC
      totalDocument.totalTtc = totalDocument.tvaDetails.reduce((acc, line) => {
        return acc + line.priceTtc;
      }, 0);

      if (this.document.showDiscount) {
        // On affecte la remise
        let totalNetHtBeforeDiscountGlobal = structuredClone(
          totalDocument.totalNetHt
        );
        totalDocument.discountAmount =
          this.document.showDiscount && this.document.discountGlobalType == 1
            ? (totalDocument.subTotalHt / totalNetHtBeforeDiscountGlobal) *
              this.document.discountGlobalNumber
            : this.document.showDiscount &&
              this.document.discountGlobalType == 0
            ? Math.round(
                totalDocument.subTotalHt *
                  (formatNumber(this.document.discountGlobalNumber) / 100) *
                  100
              ) / 100
            : 0;
        totalDocument.totalNetHt = 0;
        totalDocument.totalTva = 0;
        totalDocument.totalTtc = 0;

        for (let index = 0; index < totalDocument.tvaDetails.length; index++) {
          const lineTva = totalDocument.tvaDetails[index];
          lineTva.coeff =
            lineTva.priceHt /
            (totalDocument.subTotalHt == 0 ? 1 : totalDocument.subTotalHt);
          // On affecte la remise
          if (this.document.showDiscount) {
            let priceNetHt = 0;

            if (
              this.document.showDiscount &&
              this.document.discountGlobalType == 1
            ) {
              let deductionAssociatedHt =
                lineTva.coeff * totalDocument.discountAmount;
              priceNetHt = lineTva.priceHt - deductionAssociatedHt;
            } else {
              let discountLine = 1 - this.document.discountGlobalNumber / 100;
              priceNetHt = lineTva.priceHt * discountLine;
            }
            lineTva.coeffLines = priceNetHt / totalDocument.subTotalHt;

            lineTva.priceHt = structuredClone(priceNetHt);

            totalDocument.totalNetHt += Math.round(lineTva.priceHt * 100) / 100;
          }
        }

        // On calcule le prix de la TVA et le prix TTC
        for (let index = 0; index < totalDocument.tvaDetails.length; index++) {
          const lineTva = totalDocument.tvaDetails[index];
          lineTva.priceTva =
            Math.round(
              ((lineTva.priceHt *
                this.referencielTvaValue(lineTva.referencielTvaId)) /
                100) *
                100
            ) / 100;
          lineTva.priceTtc = lineTva.priceTva + lineTva.priceHt;
        }

        // On calcule le prix total de la TVA
        totalDocument.totalTva = totalDocument.tvaDetails.reduce(
          (acc, line) => {
            return acc + line.priceTva;
          },
          0
        );

        // On calcule le prix total TTC
        totalDocument.totalTtc = totalDocument.tvaDetails.reduce(
          (acc, line) => {
            return acc + line.priceTtc;
          },
          0
        );
      }

      for (let index = 0; index < totalDocument.tvaDetails.length; index++) {
        const lineTva = totalDocument.tvaDetails[index];
        if (
          documentTvas.findIndex(
            (el) =>
              el.referencielTvaId == lineTva.referencielTvaId ||
              (el.referencielTva
                ? el.referencielTva.id == lineTva.referencielTvaId
                : false)
          ) >= 0
        ) {
          let index = documentTvas.findIndex(
            (el) => el.referencielTvaId == lineTva.referencielTvaId
          );
          documentTvas[index].value = lineTva.priceHt;
          documentTvas[index].valueTva = lineTva.priceTva;
        } else {
          documentTvas.push({
            id: 0,
            value: lineTva.priceHt,
            valueTva: lineTva.priceTva,
            referencielTvaId: lineTva.referencielTvaId,
            orderFormId: this.document.id,
          });
        }
      }

      // On affectre les nouvelles valeurs calculées au document
      this.document.totalHt = Math.round(totalDocument.totalNetHt * 100) / 100;

      this.document.orderFormProviderTvas = documentTvas;

      let _this = this;
      if (totalDocument.tvaDetails && totalDocument.tvaDetails.length > 0) {
        totalDocument.tvaDetails.sort(function (a, b) {
          return (
            _this.referencielTvaValue(a.referencielTvaId) -
            _this.referencielTvaValue(b.referencielTvaId)
          );
        });
      }
      this.$emit("updateTvaDetails", totalDocument.tvaDetails);
      // this.document.tvaDetails = totalDocument.tvaDetails
      this.document.totalTtc =
        Number(Math.round(totalDocument.totalNetHt * 100) / 100) +
        Number(Math.round(totalDocument.totalTva * 100) / 100);

      // On calcule le net à payer
      this.totalNetToPay = structuredClone(
        formatNumber(
          this.calcNetToPay(totalDocument.totalNetHt, this.document.totalTtc)
        )
      );
      return totalDocument;
    },
  },
};
</script>

<style>
.totalInvoice {
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}

.total-divider {
  border-top: 1px solid #d9d9d9;
  margin: 7px 0 7px 0;
}

.content-total {
  padding: 7px;
}

.error-margin {
  padding: 10px;
  background-color: #f19e9e;
  display: flex;
  align-items: center;
  border-radius: 0.357rem;
}

.price-total {
  white-space: nowrap;
}

.rest-button {
  margin-left: 10px;
  cursor: pointer;
  color: #0c3571;
}
</style>
