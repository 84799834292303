<template lang="pug">
div.h-100
  .h-100(v-if='isLoadingFile || isUpdatingFile || isDeletingFile')
    .text-center.flex-center.h-100
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      div(style='margin-top: 7rem;')
        |  Chargement des détails du fichier...
  .h-100(v-else)
    .sidebar-header.py-1.px-2
      div
        h3 {{file.name}}
      feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' @click.stop='closeSidebar')
    vs-divider.m-0
    .d-flex.justify-content-between.sidebar-action.py-05.px-2.w-100
      .d-flex
        b-button.p-50.d-flex.align-items-center(variant='primary' @click.prevent="downloadFile(file.fullPath, file.name)")
          span.material-icons.text-white(style="font-size:20px") file_download
          | Télécharger
      .d-flex
        b-button-group
          b-button.btn-icon(variant='outline-danger' @click='deleteFileLocal(file.id, file.name)')
            feather-icon(icon='TrashIcon')
              | Supprimer
    vs-divider.m-0
    .sidebar-content.d-flex.flex-column
        .w-full.d-flex.align-items-center(v-if="needIFrame(file.fullPath)" style="flex: 1;")
            iframe.w-100(frameBorder="0" :title="'Aperçu '+file.name" width="100%" height="400" style="height: 100%;" :src="file.fullPath")
        .w-full.d-flex.align-items-center(v-else)
            div(style="background:#EEF1F6; background-size:contain; background-repeat:no-repeat; background-position:center; width:100%; height:400px" :style="'background-image:url('+file.fullPath+')'")
        .w-full.d-flex.mt-1.flex-column
            span.text-primary.h3 {{file.name}}
            .table-financial-data.column.w-100.d-flex.justify-content-between
                span Poids
                span {{sizeFormat(file.fileSize)}}
            .table-financial-data.column.w-100.d-flex.justify-content-between
                span Date de création
                span(style="text-transform : capitalize;")  {{formatDate(file.createdAt)}}
            .table-financial-data.column.w-100.d-flex.justify-content-between
                span Date de modification
                span(style="text-transform : capitalize;") {{formatDate(file.updatedAt)}}
</template>


<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters, mapActions } from 'vuex'
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts"

var dayjs = require('dayjs')
var quarterOfYear = require('dayjs/plugin/quarterOfYear')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var advanced = require('dayjs/plugin/advancedFormat')
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Paris')
dayjs.extend(advanced)
dayjs.extend(quarterOfYear)

export default {
  data () {
    return {
      file:{}
    }
  },
  computed: {
    ...mapGetters([
      "isUpdatingFile",
      "isLoadingFile",
      "isDeletingFile",
    ]),
  },
  methods: {
    ...mapActions(["getFileById", "deleteFiles"]),
    formatDate,
    getFileDetails(id) {
      if (id !== '') {
        this.getFileById({
          fileId: id,
          action: true,
        }).then(res => {
          this.file = res
        })
      } else {
        this.file={}
      }
    },
    deleteFileLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir supprimer le fichier "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Supprimer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteFiles({
              fileIds: [id]
            })
            this.closeSidebar();
          }
        })
    },
    needIFrame(url){
      if (url && url!=="")// si le champ fichier n'est pas vide
      {
        let extension=this.get_url_extension(url)
        if(extension=="pdf" || extension=="xls" || extension=="xlsx" ||extension=="doc" ||extension=="docx" || extension=="ages" || extension=="heet" || extension=="ppt" || extension=="pttx"){
            return true;
        }else{
            return false
        }
      }
    },
    get_url_extension( url ) {
      return url.split(/[#?]/)[0].split('.').pop().trim();
    },
    downloadFile (url, label) {
        let mineType = "application/pdf";
        const urlExtension = this.get_url_extension(url)
          switch (urlExtension) {
            case "aac":
              mineType = "audio/aac";
              break;
            case "abw":
              mineType = "application/x-abiword";
              break;
            case "arc":
              mineType = "application/octet-stream";
              break;
            case "avi":
              mineType = "video/x-msvideo";
              break;
            case "azw":
              mineType = "application/vnd.amazon.ebook";
              break;
            case "bin":
              mineType = "application/octet-stream";
              break;
            case "bmp":
              mineType = "image/bmp";
              break;
            case "bz":
              mineType = "application/x-bzip";
              break;
            case "bz2":
              mineType = "application/x-bzip2";
              break;
            case "csh":
              mineType = "application/x-csh";
              break;
            case "css":
              mineType = "text/css";
              break;
            case "csv":
              mineType = "text/csv";
              break;
            case "doc":
              mineType = "application/msword";
              break;
            case "docx":
              mineType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
              break;
            case "eot":
              mineType = "application/vnd.ms-fontobject";
              break;
            case "epub":
              mineType = "application/epub+zip";
              break;
            case "gif":
              mineType = "image/gif";
              break;
            case "htm":
              mineType = "text/html";
              break;
            case "html":
              mineType = "text/html";
              break;
            case "ico":
              mineType = "image/x-icon";
              break;
            case "ics":
              mineType = "text/calendar";
              break;
            case "har":
              mineType = "application/java-archive";
              break;
            case "jpeg":
              mineType = "image/jpeg";
              break;
            case "jpg":
              mineType = "image/jpeg";
              break;
            case "js":
              mineType = "application/javascript";
              break;
            case "json":
              mineType = "application/json";
              break;
            case "mid":
              mineType = "audio/midi";
              break;
            case "midi":
              mineType = "audio/midi";
              break;
            case "mpeg":
              mineType = "video/mpeg";
              break;
            case "mpkg":
              mineType = "application/vnd.apple.installer+xml";
              break;
            case "opd":
              mineType = "application/vnd.oasis.opendocument.presentation";
              break;
            case "ods":
              mineType = "application/vnd.oasis.opendocument.spreadsheet";
              break;
            case "odt":
              mineType = "application/vnd.oasis.opendocument.text";
              break;
            case "ogg":
              mineType = "audio/ogg";
              break;
            case "ogv":
              mineType = "video/ogg";
              break;
            case "ogx":
              mineType = "application/ogg";
              break;
            case "otf":
              mineType = "font/otf";
              break;
            case "png":
              mineType = "image/png";
              break;
            case "pdf":
              mineType = "application/pdf";
              break;
            case "ppt":
              mineType = "application/vnd.ms-powerpoint";
              break;
            case "pptx":
              mineType = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
              break;
            case "rar":
              mineType = "application/x-rar-compressed";
              break;
            case "rtf":
              mineType = "application/rtf";
              break;
            case "sh":
              mineType = "application/x-sh";
              break;
            case "svg":
              mineType = "image/svg+xml";
              break;
            case "swf":
              mineType = "application/x-shockwave-flash";
              break;
            case "tar":
              mineType = "application/x-tar";
              break;
            case "tiff":
              mineType = "image/tiff";
              break;
            case "tif":
              mineType = "image/tiff";
              break;
            case "ts":
              mineType = "application/typescript";
              break;
            case "ttf":
              mineType = "font/ttf";
              break;
            case "vsd":
              mineType = "application/vnd.visio";
              break;
            case "wav":
              mineType = "audio/x-wav";
              break;
            case "weba":
              mineType = "audio/webm";
              break;
            case "webm":
              mineType = "video/webm";
              break;
            case "webp":
              mineType = "	image/webp";
              break;
            case "woff":
              mineType = "font/woff";
              break;
            case "woff2":
              mineType = "font/woff2";
              break;
            case "xhtml":
              mineType = "application/xhtml+xml";
              break;
            case "xls":
              mineType = "application/vnd.ms-excel";
              break;
            case "xlsx":
              mineType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              break;
            case "xml":
              mineType = "application/xml";
              break;
            case "xul":
              mineType = "application/vnd.mozilla.xul+xml";
              break;
            case "zip":
              mineType = "application/zip";
              break;
            case "3gp":
              mineType = "video/3gpp";
              break;
            case "3g2":
              mineType = "video/3gpp";
              break;
            case "7z":
              mineType = "application/x-7z-compressed";
              break;
            default:
              mineType = "text/plain"; 
          }

        axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: mineType })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    sizeFormat(number){
        let size = Math.ceil(number/1000)
        if(size>1000){
            return (size/1000).toFixed(2).replace(",",".")+" Mo"
        }else{
            return size+" Ko"
        }
    },
    closeSidebar(){
      this.$emit('closeSidebar');
    }
  },
  components: {
    VuePerfectScrollbar
  }
}
</script>

<style>
  .no-border-radius-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .no-border-radius-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .no-border-right{
    border-right: none !important;
  }
  .h3{
    margin:0
  }
  .flex-column > .table-financial-data{
       padding:5px 10px 5px 10px;
  }
  .flex-column > .table-financial-data:nth-child(odd){
    background-color: #EEF1F6;
  }
</style>
