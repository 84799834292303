<template lang="pug"> 
div
    b-modal#modal-prevent-closing-new-quote(ok-title="Nouveau devis" hide-footer cancel-title="Annuler" size="lg" ref='modal-new-quote' title='Nouveau devis' @ok='handleOk' :header-class="'bg-light-primary'" :ok-disabled="!institutionSettingsActive")
      template.d-block(#modal-header="{ close }")
        div.w-100
          button.absolute(type="button" aria-label="Close" class="close" @click="close()") ×
          b-row.my-0
            b-col.py-0.mb-0(cols='12' style="padding:0px !important")
              h3.text-primary Nouveau devis
          b-row.content-scrollable-sticky.m-0.h-100.justify-content-center
            b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
              ul.d-flex
                li
                  a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'blank-quote' ? 'current' : ''" @click="tabActive = 'blank-quote'")
                    span Devis vierge
                li(v-if="quotesListTemplates.length > 0")
                  a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'templates-quote' ? 'current' : ''" @click="tabActive = 'templates-quote'")
                    span À partir d'un modèle
      div.d-flex(v-if="institutionSettingsActive != null && institutionSettingsActive.institutionTypes && institutionSettingsActive.institutionTypes.length > 0 && tabActive == 'blank-quote'")
        .list-buttom-custom.m-1(v-for="(institution, index) of institutionSettingsActive.institutionTypes")
          div.relative.doc-buttom-custom(@click="handleSubmit(institution.id)")
            img.w-100(:src="getSrcIcon(institution.id)") 
      div.d-flex.justify-content-between(v-else-if="tabActive == 'templates-quote'" style="flex-wrap: wrap; width: 100%; align-items:start;")
        .list-buttom-custom.listflex.m-1(v-for="(quotesListTemplate, index) of quotesListTemplatesSorted")
          ejs-tooltip.relative.doc-buttom-custom(@click.native="newQuoteFromTemplate(quotesListTemplate)" ref="tooltip" :content="quotesListTemplate.description")
            img.w-100(src="@/assets/icones/icon-doc-vierge.svg")
            div.pl-1
              span(v-html="formatStripHtml(quotesListTemplate.label)" style="font-size: 13px; text-align:left")
        .list-buttom-custom.m-1(style="flex:1")
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    "v-select": vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    tabActive: "blank-quote",
    submittedNames: [],
  }),
  props: {
    affairId: {
      type: Number,
      default: null,
      required: false,
    },
    companyId: {
      type: Number,
      default: null,
      required: false,
    },
  },
  computed: {
    ...mapGetters([
      "quotesListTemplates",
      "institutionSelected",
      "institutionSettingsActive",
      "isLoadingInstitutionsList",
      "institutionsList",
      "workspaceSelected",
    ]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    quotesListTemplatesSorted() {
      let _this = this;
      return this.quotesListTemplates.sort(function (a, b) {
        return (
          _this.formatStripHtml(a.description) -
          _this.formatStripHtml(b.description)
        );
      });
    },
  },
  methods: {
    ...mapActions(["getQuoteEmpty", "duplicateQuote", "duplicateInvoice"]),
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    getSrcIcon(type) {
      switch (type) {
        case 1:
          return require("@/assets/icones/icon-doc-standard.svg");
          break;
        case 2:
          return require("@/assets/icones/icon-doc-cee.svg");
          break;
        case 3:
          return require("@/assets/icones/icon-doc-btp.svg");
          break;
        case 4:
          return require("@/assets/icones/icon-doc-loyer.svg");
          break;
        default:
          return require("@/assets/icones/icon-doc-vierge.svg");
          break;
      }
    },
    async handleSubmit(quoteType = null) {
      if (quoteType != null) {
        this.$refs["modal-new-quote"].hide();
        this.$store.commit(
          "SET_IS_GLOBAL_MESSAGE",
          "Création du devis en cours..."
        );
        this.$store.commit("SET_IS_GLOBAL_CREATING", true);
        await this.getQuoteEmpty({
          documentType: quoteType,
          affairId: this.affairId,
          companyId: this.companyId,
        })
          .then((quote) => {
            this.$store.commit("SET_IS_GLOBAL_CREATING", false);
            this.$router.push({
              name: "new-quote",
              params: {
                id: quote.id,
                title: "Nouveau devis",
                tips: "Nouveau devis",
                routeOrigine: "quotes",
              },
            });
          })
          .catch((error) => {
            this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          });
      }
    },
    toggleModal() {
      this.$refs["modal-new-quote"].show();
    },
    newQuoteFromTemplate(document) {
      this.$refs["modal-new-quote"].hide();
      this.$store.commit(
        "SET_IS_GLOBAL_MESSAGE",
        "Création du devis en cours..."
      );
      this.$store.commit("SET_IS_GLOBAL_CREATING", true);
      this.duplicateQuote({
        quoteId: document.id,
      }).then(async (res) => {
        this.$store.commit("SET_IS_GLOBAL_CREATING", false);
        this.loadingMsg = "Chargement en cours...";
        this.$router.push({
          name: "new-quote",
          params: {
            id: res.data.id,
            title: "Nouveau devis",
            tips: "Nouveau devis",
            routeOrigine: "quotes",
          },
        });
      });
    },
    formatStripHtml(str) {
      if (str === null || str === "") return "";
      else str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>
<style>
#modal-prevent-closing-new-quote .modal-header {
  padding-bottom: 0 !important;
}
</style>
