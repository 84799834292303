import { CreditOptionRequestDto } from "Api";

export function CreditOptionModel({
  showSituationDetails = false,
  calculateMargin = false,
  calculateUnitPriceHtByUnitPurchasePriceHt = false,
  showDiscountColumn = false,
  isReverseCharge = false,
  hideQuantityColumn = false,
  hideReferencielTvaColumn = false,
  hideLinesQuantityEmpty = false,
  hideUnitPriceHtColumn = false,
  hidePriceHtColumn = false,
  hideTotal = false,
  showDiscount = false,
  hidePaymentConditions = false,
  hideCompany = false,
  showCompanySiret = false,
  showCompanyAddressLabel = false,
  showCompanyMail = false,
  showCompanyPhoneNumber = false,
  showDetailWorkLine = false,
  showCompanyCode = false,
  showProductPicturesAttached = false,
  showCompanyTva = false,
  showWaterMark = false,
  showDescription = false,
  showThumbnail = false,
  showReferenceColumn = false,
  showAffairCode = false,
  hideUser = false,
  hideInstitutionLogo = false,
  hideInstitutionName = false,
  hideInstitutionAddress = false,
  hideAffair = false,
  hideIndexColumn = false,
  hideUnitColumn = false,
  creditId = 0,
}): CreditOptionRequestDto {
  return {
    id: 0,
    showSituationDetails: showSituationDetails,
    calculateMargin: calculateMargin,
    calculateUnitPriceHtByUnitPurchasePriceHt:
      calculateUnitPriceHtByUnitPurchasePriceHt,
    showDiscountColumn: showDiscountColumn,
    isReverseCharge: isReverseCharge,
    hideQuantityColumn: hideQuantityColumn,
    hideReferencielTvaColumn: hideReferencielTvaColumn,
    hideLinesQuantityEmpty: hideLinesQuantityEmpty,
    hideUnitPriceHtColumn: hideUnitPriceHtColumn,
    hidePriceHtColumn: hidePriceHtColumn,
    hideTotal: hideTotal,
    showDiscount: showDiscount,
    hidePaymentConditions: hidePaymentConditions,
    hideCompany: hideCompany,
    showCompanySiret: showCompanySiret,
    showCompanyAddressLabel: showCompanyAddressLabel,
    showCompanyMail: showCompanyMail,
    showCompanyPhoneNumber: showCompanyPhoneNumber,
    showDetailWorkLine: showDetailWorkLine,
    showCompanyCode: showCompanyCode,
    showProductPicturesAttached: showProductPicturesAttached,
    showCompanyTva: showCompanyTva,
    showWaterMark: showWaterMark,
    showDescription: showDescription,
    showThumbnail: showThumbnail,
    showReferenceColumn: showReferenceColumn,
    showAffairCode: showAffairCode,
    hideUser: hideUser,
    hideInstitutionLogo: hideInstitutionLogo,
    hideInstitutionName: hideInstitutionName,
    hideInstitutionAddress: hideInstitutionAddress,
    hideAffair: hideAffair,
    hideIndexColumn: hideIndexColumn,
    hideUnitColumn: hideUnitColumn,
    creditId: creditId,
  };
}
