<template lang="pug">
  div
      div(v-if="isLoadingOrderFormCustomer")
        ejs-skeleton(height='116px' style="border-radius:8px")
      div(style="page-break-inside: avoid;")
          .border-left.border-right.border-top.rounded-top.content-total
            div
              div(v-if="orderFormCustomerOption.showDiscount")
                strong.d-flex.total-row Sous-total HT 
                  .ml-auto.price-total
                    span.totalWithoutTax {{ formatCurrency(totalDocument.subTotalHt) }}
                .discount-total
                  .d-flex.total-row
                    | {{ orderTrackingCustomer.discountGlobalType == '0' ? 'Remise globale' : 'Remise globale HT' }}
                    span.ml-auto.price-total {{ orderTrackingCustomer.discountGlobalType == '1' ? '-' : '' }} {{ orderTrackingCustomer.discountGlobalType == '0' ?  formatPercent(orderTrackingCustomer.discountGlobalNumber) : formatCurrency(orderTrackingCustomer.discountGlobalNumber) }}
                .discount-total(v-show="orderTrackingCustomer.discountGlobalType != 1 && orderFormCustomerOption.showDiscount")
                  .d-flex.total-row
                    | Remise HT
                    span.ml-auto.price-total -{{ formatCurrency(totalDocument.discountAmount) }}
                .total-divider
              div
                strong.d-flex.total-row Total net HT de la prestation
                  .ml-auto.price-total
                    span.totalWithoutTax {{ formatCurrency(totalDocument.totalNetHtPrestation) }}
                .total-divider
                // On affiche les factures émises
                div(v-if="totalDocument.previousInvoices.invoices.length > 0")
                  .d-flex.total-row(v-for="(item, index) in totalDocument.previousInvoices.invoices") {{ item.nature == 4 || item.nature == 3 ? 'Situation' : natureTranslate(item.nature)}} {{ item.documentStep ? `#${item.documentStep}` : '' }} - {{ item.documentReference }}
                    .ml-auto.price-total
                      span.totalWorks {{ formatCurrency(item.totalHt.toFixed(2)) }}
                  strong.d-flex.total-row Total HT des factures émises
                    .ml-auto.price-total 
                      span.totalWorks {{ formatCurrency(totalDocument.previousInvoices.invoicesHt) }}
          // Gestion du NET A PAYER
          strong.d-flex.big.bg-primary.text-white.totalInvoice.justify-content-between.rounded-bottom(:style="preview ? 'background:'+orderTrackingCustomer.institutionColor+' !important' : ''") RESTE A FACTURER HT
            .ml-auto.price-total
              span.totalWithTax {{ formatCurrency(remainingToBillHt) }} 
       
  </template>

<script>
import { mapGetters } from "vuex";
import {
  formatCurrency,
  formatPercent,
  formatNumber,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { natureTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  data() {
    return {};
  },
  props: {
    preview: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    formatCurrency,
    formatPercent,
    formatNumber,
    natureTranslate,
    setPreviousInvoices() {
      let invoices = [];
      let invoicesHt = 0;
      if (
        this.initialDocument &&
        this.initialDocument.invoices &&
        this.initialDocument.invoices.filter((document) =>
          [2, 6, 7, 9].includes(document.status)
        ).length > 0
      ) {
        for (
          let i = 0;
          i <
          JSON.parse(JSON.stringify(this.initialDocument)).invoices.filter(
            (document) => [2, 6, 7, 9].includes(document.status)
          ).length;
          i++
        ) {
          const invoice = JSON.parse(
            JSON.stringify(
              this.initialDocument.invoices.filter((document) =>
                [2, 6, 7, 9].includes(document.status)
              )[i]
            )
          );
          if (invoice.status != 5) {
            invoice.totalHtRemaining = invoice.totalHt;
            invoices.push(invoice);
            invoicesHt += invoice.totalHt;
          }
        }
      }
      return {
        invoices: invoices,
        invoicesHt: formatNumber(invoicesHt),
      };
    },
    referencielTvaValue(referencielTvaId) {
      return formatNumber(
        this.institutionSettingsActive.referencielTvas.find(
          (item) => item.referencielTva.id == referencielTvaId
        ).value
      );
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingOrderFormCustomer",
      "orderTrackingCustomer",
      "orderFormCustomerOption",
      "getOrderTrackingCustomerDetails",
      "initialDocument",
      "institutionSettingsActive",
      "orderFormCustomer",
    ]),
    remainingToBillHt() {
      let remainingToBillHt =
        this.orderFormCustomer.status == 6
          ? 0
          : this.totalDocument.previousInvoices.invoices.length > 0
          ? (
              this.totalDocument.totalNetHtPrestation -
              this.totalDocument.previousInvoices.invoicesHt
            ).toFixed(2)
          : this.totalDocument.totalNetHtPrestation.toFixed(2);
      return remainingToBillHt;
    },
    totalDocument() {
      let totalDocument = {
        subTotalHt: 0,
        discountAmount: 0,
        totalNetHtPrestation: 0,
        totalNetHt: 0,
        tvaDetails: [],
        totalTva: 0,
        totalTtc: 0,
        previousInvoices: [],
      };

      // On affecte les factures d'acompte
      totalDocument.previousInvoices = this.setPreviousInvoices();

      // On filtre sur les lignes devant être prises en compte
      // On génère le tableau TVA
      // On calcule les totaux
      totalDocument.tvaDetails = this.getOrderTrackingCustomerDetails
        .filter(
          (el) =>
            el.type != 0 &&
            el.type != 1 &&
            el.type != 2 &&
            el.type != 11 &&
            el.type != 12 &&
            el.type != 13 &&
            el.type != 18 &&
            el.type != 17 &&
            el.type != 16 &&
            el.type != 15 &&
            el.type != 14 &&
            !el.isOption
        )
        .reduce((acc, obj) => {
          // On crée un object par taux de TVA
          let findIndex = acc.findIndex(
            (el) => el.referencielTvaId === obj.referencielTvaId
          );
          let progress = 0;
          const isSituationTypeQuantity = this.initialDocument?.invoices?.find(
            (elem) => elem.situationType == true && elem.status != 5
          );
          if (!isSituationTypeQuantity) {
            progress = obj.previousSituationProgress
              ? obj.previousSituationProgress
              : obj.situationProgress
              ? obj.situationProgress
              : 0;
          } else {
            progress = obj.previousSituationProgressQuantity
              ? obj.previousSituationProgressQuantity
              : obj.situationProgressQuantity
              ? obj.situationProgressQuantity
              : 0;
          }

          // On calcule les montant selon les quantités et remise par ligne
          let priceBrutHtPrestation =
            Math.round(obj.unitPriceHt * obj.quantity * 100) / 100;
          let priceBrutHtAvced =
            Math.round(
              ((obj.unitPriceHt * obj.quantity * progress) / 100) * 100
            ) / 100;

          // On applique la remise par ligne
          let discount = (100 - obj.discount) / 100;
          let priceNetHtPrestation =
            Math.round(priceBrutHtPrestation * discount * 100) / 100;
          let priceNetHtAvced =
            Math.round(priceBrutHtAvced * discount * 100) / 100;

          if (findIndex < 0) {
            // On instancie un nouveau de TVA
            acc.push({
              priceHtPrestation: priceNetHtPrestation,
              priceTva: 0,
              priceTtc: 0,
              priceHtAvced: priceNetHtAvced,
              priceTvaAvced: 0,
              priceTtcAvced: 0,
              referencielTvaId: obj.referencielTvaId,
            });
          } else {
            // On additionne un taux de TVA
            acc[findIndex].priceHtPrestation += priceNetHtPrestation;
            acc[findIndex].priceHtAvced += priceNetHtAvced;
          }

          totalDocument.subTotalHt +=
            Math.round(priceNetHtPrestation * 100) / 100;
          totalDocument.totalNetHtPrestation +=
            Math.round(priceNetHtPrestation * 100) / 100;
          return acc;
        }, []);

      // Ajoute à totalDocument.totalNetHtPrestation les montants des intialDocument.invoices.reviews si review à le champ includePrestation à true
      if (this.initialDocument && this.initialDocument.invoices) {
        for (let i = 0; i < this.initialDocument.invoices.length; i++) {
          const invoice = this.initialDocument.invoices[i];
          if (invoice.deduction?.reviews.length > 0) {
            for (let j = 0; j < invoice.deduction.reviews.length; j++) {
              const review = invoice.deduction.reviews[j];
              if (review.includePrestation) {
                totalDocument.totalNetHtPrestation +=
                  Math.round(review.number * 100) / 100;
              }
            }
          }
        }
      }
      // On refait les calcules si on a une remise globale
      if (
        this.orderFormCustomerOption.showDiscount ||
        (this.orderTrackingCustomer.nature == 1 &&
          totalDocument.previousInvoices.invoices &&
          totalDocument.previousInvoices.invoices.length > 0)
      ) {
        // On affecte la remise
        if (this.orderFormCustomerOption.showDiscount) {
          let totalNetHtPrestationBeforeDiscountGlobal = structuredClone(
            totalDocument.totalNetHtPrestation
          );
          if (
            this.orderFormCustomerOption.showDiscount &&
            this.orderTrackingCustomer.discountGlobalType == 1
          ) {
            totalDocument.discountAmount =
              totalNetHtPrestationBeforeDiscountGlobal == 0
                ? 0
                : Math.round(
                    (totalDocument.subTotalHt /
                      totalNetHtPrestationBeforeDiscountGlobal) *
                      this.orderTrackingCustomer.discountGlobalNumber *
                      100
                  ) / 100;
          } else if (
            this.orderFormCustomerOption.showDiscount &&
            this.orderTrackingCustomer.discountGlobalType == 0
          ) {
            totalDocument.discountAmount =
              Math.round(
                totalDocument.subTotalHt *
                  (formatNumber(
                    this.orderTrackingCustomer.discountGlobalNumber
                  ) /
                    100) *
                  100
              ) / 100;
          } else {
            totalDocument.discountAmount = 0;
          }
          totalDocument.totalNetHtPrestation = 0;
          totalDocument.totalTva = 0;
          totalDocument.totalTtc = 0;
        }
        for (let index = 0; index < totalDocument.tvaDetails.length; index++) {
          const lineTva = totalDocument.tvaDetails[index];
          lineTva.coeff =
            lineTva.priceHtPrestation /
            (totalDocument.subTotalHt == 0 ? 1 : totalDocument.subTotalHt);
          // On affecte la remise
          if (this.orderFormCustomerOption.showDiscount) {
            let priceNetHtPrestation = 0;
            let priceNetHtAvced = 0;

            if (
              this.orderFormCustomerOption.showDiscount &&
              this.orderTrackingCustomer.discountGlobalType == 1
            ) {
              let deductionAssociatedHt =
                lineTva.coeff * totalDocument.discountAmount;
              priceNetHtPrestation =
                lineTva.priceHtPrestation - deductionAssociatedHt;
              priceNetHtAvced = lineTva.priceHtAvced - deductionAssociatedHt;
            } else {
              let discountLine =
                Math.round(
                  ((lineTva.priceHtPrestation *
                    this.orderTrackingCustomer.discountGlobalNumber) /
                    100) *
                    100
                ) / 100;
              priceNetHtPrestation =
                Math.round((lineTva.priceHtPrestation - discountLine) * 100) /
                100;
              priceNetHtAvced =
                Math.round((lineTva.priceHtAvced - discountLine) * 100) / 100;
            }
            lineTva.priceHtPrestation = structuredClone(priceNetHtPrestation);
            lineTva.priceHtAvced = structuredClone(priceNetHtAvced);
            totalDocument.totalNetHtPrestation +=
              Math.round(lineTva.priceHtPrestation * 100) / 100;
          }
        }
      }

      // On calcule le prix de la TVA et le prix TTC
      for (let index = 0; index < totalDocument.tvaDetails.length; index++) {
        const lineTva = totalDocument.tvaDetails[index];
        lineTva.priceTvaAvced =
          Math.round(
            ((lineTva.priceHtAvced *
              this.referencielTvaValue(lineTva.referencielTvaId)) /
              100) *
              100
          ) / 100;
        lineTva.priceTtcAvced = lineTva.priceTvaAvced + lineTva.priceHtAvced;
        lineTva.priceTva =
          Math.round(
            ((lineTva.priceHtPrestation *
              this.referencielTvaValue(lineTva.referencielTvaId)) /
              100) *
              100
          ) / 100;
        lineTva.priceTtc = lineTva.priceTva + lineTva.priceHtPrestation;
      }

      // On calcule le prix total de la TVA
      totalDocument.totalTva = totalDocument.tvaDetails.reduce((acc, line) => {
        return acc + line.priceTva;
      }, 0);

      // On calcule le prix total TTC
      totalDocument.totalTtc = totalDocument.tvaDetails.reduce((acc, line) => {
        return acc + line.priceTtc;
      }, 0);
      // On affectre les nouvelles valeurs calculées au orderTrackingCustomer
      this.orderTrackingCustomer.totalHt =
        Math.round(totalDocument.totalNetHtPrestation * 100) / 100;
      this.orderTrackingCustomer.totalTtc =
        Number(Math.round(totalDocument.totalNetHtPrestation * 100) / 100) +
        Number(Math.round(totalDocument.totalTva * 100) / 100);

      return totalDocument;
    },
  },
};
</script>

<style>
.totalInvoice {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 7px;
  padding-right: 7px;
  position: relative;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}

.total-divider {
  border-top: 1px solid #d9d9d9;
  margin: 7px 0 7px 0;
}

.content-total {
  padding: 7px;
}

.error-margin {
  padding: 10px;
  background-color: #f19e9e;
  display: flex;
  align-items: center;
  border-radius: 0.357rem;
}

.price-total {
  white-space: nowrap;
}

.rest-button {
  margin-left: 10px;
  cursor: pointer;
  color: #0c3571;
}
</style>
