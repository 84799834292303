<template lang="pug">
    div
      .border-left.border-right.border-top.rounded-top.content-total
        strong.d-flex.total-row Total net HT
          .ml-auto.price-total
            span.totalWithoutTax {{ formatCurrency(invoiceProvider.totalHT) }}
        div(v-for="(tva, index) in invoiceProvider.referentielTvas" :key="index")
          .d-flex.total-row.total-with-tax TVA {{ referencielTvaTranslate(tva.referentielTvaId) }}
            .ml-auto.price-total
              span.totalWithTax {{ formatCurrency(tva.valueTva) }}
        div(v-if="invoiceProvider.referentielTvas.length > 1")
          strong.d-flex.total-row.total-with-tax Total TVA
            .ml-auto.price-total
              span.totalWithTax {{ formatCurrency((invoiceProvider.totalTTC - invoiceProvider.totalHT)) }}
      .border-left.border-right
      strong.d-flex.big.bg-primary.text-white.totalInvoice(:class="calcPriceTotalRemainingToPay()!==invoiceProvider.totalTTC ? '' : 'rounded-bottom'") TOTAL TTC
        .ml-auto.price-total
          span.totalNetToPay.netToPay {{ formatCurrency(invoiceProvider.totalTTC) }}
      .border-left.border-right.border-bottom.content-total.rounded-bottom(v-if="calcPriceTotalRemainingToPay()!==invoiceProvider.totalTTC")
        div
          div(v-for='(itemPayment, indexPayment) in invoiceProvider.paymentProviders' :key="'payment-'+indexPayment")
            .d-flex.total-row Règlement du {{ formatDate(itemPayment.date) }} par {{ itemPayment.paymentMethod }}
              .ml-auto.price-total
                span.totalWithoutTax -{{ formatCurrency(itemPayment.amount) }}
          div
            div(v-for='(itemCreditProvider, indexCreditProvider) in invoiceProvider.creditProviders' :key="'creditProvider-'+indexCreditProvider")
              .d-flex.total-row Avoir fournisseur {{ itemCreditProvider.documentReference }}
                .ml-auto.price-total
                  span.totalWithoutTax -{{ formatCurrency(itemCreditProvider.value) }}
          strong.d-flex.total-row {{ "Reste à payer" }}
            span.ml-auto.price-total {{ formatCurrency(calcPriceTotalRemainingToPay()) }}
</template>

<script>
 
import { mapGetters, mapActions } from 'vuex'
import { formatCurrency, formatDate } from "@/types/api-orisis/library/FormatOperations.ts"
import { referencielTvaTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  computed: {
    ...mapGetters(['invoiceProvider']),
  },
  methods: {
    formatCurrency,
    formatDate,
    referencielTvaTranslate,
    calcPriceTotalRemainingToPay() {
      let paymentProviders = this.invoiceProvider.paymentProviders
        ? this.invoiceProvider.paymentProviders.reduce((total, el) => {
            return (total += Math.round(el.amount * 100) / 100);
          }, 0)
        : 0;
      let creditProviders = this.invoiceProvider.creditProviders
        ? this.invoiceProvider.creditProviders.reduce((total, el) => {
            return (total += Math.round(el.value * 100) / 100);
          }, 0)
        : 0;
      let priceTotalRemainingToPay = this.invoiceProvider.remainingToPayTtc =
        Math.round(
          Number(
            this.invoiceProvider.totalTTC -
            paymentProviders - creditProviders)
         * 100) / 100;
      return priceTotalRemainingToPay;
    },
  }
}
</script>

<style>
.totalInvoice {
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}

.total-divider {
  border-top: 1px solid #d9d9d9;
  margin: 7px 0 7px 0;
}

.content-total {
  padding: 7px;
}

.error-margin {
  padding: 10px;
  background-color: #f19e9e;
  display: flex;
  align-items: center;
  border-radius: 0.357rem;
}

.price-total {
  white-space: nowrap;
}

.rest-button {
  margin-left: 10px;
  cursor: pointer;
  color: #0c3571;
}
</style>