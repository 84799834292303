<template lang="pug">
  div
    // modal
    b-modal#modal-prevent-closing-new-order-form-customer(ok-title="Nouvelle commande client" hide-footer cancel-title="Annuler" size="lg" ref='modal-new-order-form-customer' title='Nouvelle commande client' @ok='handleOk' :header-class="'bg-light-primary'" :ok-disabled="!institutionSettingsActive")
      template.d-block(#modal-header="{ close }")
        div.w-100
          button.absolute(type="button" aria-label="Close" class="close" @click="close()") ×
          b-row.my-0
            b-col.py-0.mb-0(cols='12' style="padding:0px !important")
              h3.text-primary Nouvelle commande client
          b-row.content-scrollable-sticky.m-0.h-100.justify-content-center
            b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
              ul.d-flex
                li(v-if="$route.name != 'lease' && institutionSettingsActive != null && institutionSettingsActive.institutionTypes.find(el=>el.id < 4)")
                  a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'blank-order-form-customer' ? 'current' : ''" @click="tabActive = 'blank-order-form-customer'")
                    span Bon de commande client vierge
                li
                  a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'quote-order-form-customer' ? 'current' : ''" @click="tabActive = 'quote-order-form-customer'")
                    span Bon de commande client sur devis
                li(v-if="orderFormCustomersListTemplates.length > 0")
                  a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'templates-order-form-customer' ? 'current' : ''" @click="tabActive = 'templates-order-form-customer'")
                    span À partir d'un modèle
      .justify-content-center(v-if="isLoadingOrderFormCustomer")
        .content-loader-center.m-0(style="height:250px")
          .text-center.flex-center
            .loading-bg-inner(variant="primary" style="width: 3rem; height: 3rem;")
              .loader
                .outer
                .middle
                .inner
            span(style="margin-top: 8rem")  Chargement des données en cours...
      div(v-else-if="institutionSettingsActive != null && institutionSettingsActive.institutionTypes.length > 0")
        #blank-order-form-customer.d-flex(v-if="tabActive == 'blank-order-form-customer'")
          .list-buttom-custom.m-1(v-for="(institution, index) of institutionSettingsActive.institutionTypes")
            div.relative.doc-buttom-custom(v-if="institution.id != 4" @click="handleSubmit(institution.id)")
              img.w-100(:src="getSrcIcon(institution.id)")
        #quote-order-form-customer.d-flex(v-if="tabActive == 'quote-order-form-customer'")
          b-row(v-if="institutionSettingsActive != null && institutionSettingsActive.institutionTypes")
            b-col( cols='12')
              v-select.w-100.m-0(v-model="selectQuote" :get-option-label="(option) => option.documentReference" @input="" placeholder="Rechercher un devis (numéro, client)" :clearable="true" :options="quoteListFiltered")
                template(v-slot:option='{ id, documentReference, companyName }')
                  div.content-quote-choice
                    span(style='font-size:13px;white-space:normal') Devis {{ documentReference }} - Client : {{ companyName }}
            b-col.d-flex(v-if="selectQuote != null" style="padding:0 !important" cols='12')
              .list-buttom-custom.m-1(v-for="(institution, index) of institutionSettingsActive.institutionTypes")
                div.relative.doc-buttom-custom(@click="handleSubmitByQuote(institution.id)")
                  img.w-100(:src="getSrcIcon(institution.id)")
        #templates-order-form-customer.d-flex(v-if="tabActive == 'templates-order-form-customer'")
          div.d-flex.justify-content-between(v-if="orderFormCustomersListTemplates.length > 0" style="flex-wrap: wrap; width: 100%; align-items:start;")
            .list-buttom-custom.listflex.m-1(v-for="(orderFormCustomersListTemplate, index) of orderFormCustomersListTemplatesSorted")
              ejs-tooltip.relative.doc-buttom-custom(@click.native="newOrderFormCustomerFromTemplate(orderFormCustomersListTemplate)" ref="tooltip" :content="orderFormCustomersListTemplate.description")
                img.w-100(src="@/assets/icones/icon-doc-vierge.svg")
                div.pl-1
                  span(v-html="formatStripHtml(orderFormCustomersListTemplate.label)" style="font-size: 13px; text-align:left")
            .list-buttom-custom.m-1(style="flex:1")
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import dayjs from "dayjs";
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    "v-select": vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    selectQuote: null,
    tabActive: "blank-order-form-customer",
  }),
  props: {
    affairId: {
      type: Number,
      default: null,
    },
    companyId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      "orderFormCustomersListTemplates",
      "isLoadingOrderFormCustomer",
      "institutionSelected",
      "institutionSettingsActive",
      "isLoadingInstitutionsList",
      "institutionsList",
      "invoicesListByAffair",
      "periodicitiesList",
      "workspaceSelected",
    ]),
    orderFormCustomersListTemplatesSorted() {
      let _this = this;
      return this.orderFormCustomersListTemplates.sort(function (a, b) {
        return (
          _this.formatStripHtml(a.description) -
          _this.formatStripHtml(b.description)
        );
      });
    },
    quoteListFiltered: {
      get() {
        return this.$store.getters.quotesList.filter(
          (quote) =>
            quote.status == 1 ||
            quote.status == 2 ||
            quote.staus == 9 ||
            (quote.status == 3 && quote.orderFormCustomerId == null)
        );
      },
    },
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "getOrderFormCustomerEmpty",
      "updateQuote",
      "createOrderFormCustomerFromQuote",
      "duplicateOrderFormCustomer",
    ]),
    formatDate,
    getSrcIcon(type) {
      switch (type) {
        case 1:
          return require("@/assets/icones/icon-doc-standard.svg");
          break;
        case 2:
          return require("@/assets/icones/icon-doc-cee.svg");
          break;
        case 3:
          return require("@/assets/icones/icon-doc-btp.svg");
          break;
        default:
          return require("@/assets/icones/icon-doc-vierge.svg");
          break;
      }
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit(invoiceType = null) {
      if (invoiceType != null) {
        this.$refs["modal-new-order-form-customer"].toggle(
          "#toggle-btn-new-order-form-customer"
        );
        this.$store.commit(
          "SET_IS_GLOBAL_MESSAGE",
          "Création de la commande client en cours..."
        );
        this.$store.commit("SET_IS_GLOBAL_CREATING", true);
        await this.getOrderFormCustomerEmpty({
          type: invoiceType,
          affairId: this.affairId,
          companyId: this.companyId,
        })
          .then((orderFormCustomer) => {
            this.$store.commit("SET_IS_GLOBAL_CREATING", false);
            this.$router.push({
              name: "new-order-form-customer",
              params: {
                id: orderFormCustomer.id,
                title: "Nouvelle commande client",
                tips: "Nouvelle commande client",
                routeOrigine: "order-form-customers",
              },
            });
          })
          .catch((error) => {
            this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          });
      }
    },
    async handleSubmitByQuote(invoiceType = null) {
      if (invoiceType != null) {
        if (this.selectQuote.status < 3) {
          this.$bvModal
            .msgBoxConfirm(
              'Pour génerer le bon de commande client, vous devez le passer en statut "Signé"',
              {
                title: "Attention",
                size: "sm",
                okVariant: "success",
                okTitle: "Signer et commander",
                cancelTitle: "Annuler",
                cancelVariant: "outline-primary",
                hideHeaderClose: true,
                centered: true,
              }
            )
            .then(async (value) => {
              if (value) {
                this.updateQuote({
                  quote: { ...this.selectQuote, status: 3 },
                  updateState: false,
                }).then(async (res) => {
                  this.$emit("refreshRow", res);
                  await this.createOrderFormCustomerFromQuote({
                    quoteId: this.selectQuote.id,
                    status: 0,
                  }).then((res) => {
                    this.$router.push({
                      name: "new-order-form-customer",
                      params: {
                        id: res.data.id,
                        title: "Nouvelle commande client",
                        tips: "Nouvelle commande client",
                        routeOrigine: "order-form-customers",
                      },
                    });
                  });
                });
              }
            });
        } else {
          await this.createOrderFormCustomerFromQuote({
            quoteId: this.selectQuote.id,
            status: 0,
          }).then((res) => {
            this.$router.push({
              name: "new-order-form-customer",
              params: {
                id: res.data.id,
                title: "Nouvelle commande client",
                tips: "Nouvelle commande client",
                routeOrigine: "order-form-customers",
              },
            });
          });
        }
      }
    },
    toggleModal() {
      this.tabActive = "blank-order-form-customer";
      this.$refs["modal-new-order-form-customer"].toggle(
        "#toggle-btn-new-order-form-customer"
      );
    },
    newOrderFormCustomerFromTemplate(orderFormCustomer) {
      this.$refs["modal-new-order-form-customer"].hide();
      this.$store.commit(
        "SET_IS_GLOBAL_MESSAGE",
        "Création du bon de commande client en cours..."
      );
      this.$store.commit("SET_IS_GLOBAL_CREATING", true);
      this.duplicateOrderFormCustomer({
        orderFormCustomerId: orderFormCustomer.id,
      }).then(async (res) => {
        this.$store.commit("SET_IS_GLOBAL_CREATING", false);
        this.loadingMsg = "Chargement en cours...";
        this.$router.push({
          name: "new-order-form-customer",
          params: {
            id: res.data.id,
            title: "Nouvelle commande client",
            tips: "Nouvelle commande client",
            routeOrigine: "order-form-customers",
          },
        });
      });
    },
    formatStripHtml(str) {
      if (str === null || str === "") return "";
      else str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>

<style>
#modal-prevent-closing-new-order-form-customer .modal-header {
  padding-bottom: 0 !important;
}
</style>
