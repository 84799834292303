<template lang="pug">
div(v-if="orderFormCustomerHistoriesList && orderFormCustomerHistoriesList.length>0")
  .d-flex.justify-content-between
    h5.mb-1.d-flex.align-items-center.mb-0.text-primary
      span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
        feather-icon(icon='ChevronRightIcon') 
        | Historique
  app-timeline
    app-timeline-item(v-for="(item, index) in orderFormCustomerHistoriesList" :key="index" :title="item.comments" :time="formatDate(item.date)" variant="secondary")
  vs-divider
</template>

<script>
import { mapGetters } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import dayjs from 'dayjs'
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts"

export default {
  computed: {
    ...mapGetters(["orderFormCustomerHistoriesList"]),
  },
  methods: {
    formatDate
  },
  components: {
    AppTimeline,
    AppTimelineItem,
  },
}
</script>