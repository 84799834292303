<template>
  <b-modal
    id="modal-edit-folder"
    ref="modal-edit-folder"
    centered="centered"
    :title="(folder.id ? 'Modifier' : 'Créer') + ` un dossier`"
    @ok="create"
  >
    <div v-if="isCreatingFolder">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement des détails du dossier...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formCreateFolder">
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-form-group
            label="Nom du dossier *"
            label-for="Nom du dossier"
            style="flex: 1"
          >
            <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="folder.name"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
                v-on:keyup.enter="create"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 mx-0">
        <!-- <b-button class="float-left" v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-if="folder.id" variant="outline-danger" @click="archiveFolderLocal(folder.id, folder.label)"><feather-icon icon="ArchiveIcon" /></b-button> -->
        <b-button
          class="float-right"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="ok()"
          >{{ folder.id ? "Modifier" : "Ajouter" }}</b-button
        >
        <b-button
          class="float-right mr-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          @click="cancel()"
          >Annuler</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  props: {
    fromModule: {
      default: null,
      require: false,
    },
    fromId: {
      default: 0,
      require: false,
    },
    parentId: {
      default: 0,
      require: false,
    },
  },
  data() {
    return {
      required,
      url,
      email,
      folder: {
        name: "",
      },
      unitList: [
        "day",
        "week",
        "quarter",
        "month",
        "year",
        "hour",
        "minute",
        "second",
        "millisecond",
      ],
    };
  },
  methods: {
    async popupEditFolderEvent(id) {
      this.folder = {
        name: null,
      };

      if (id > 0) {
        await this.getFolderById({ folderId: id, updateState: false }).then(
          (res) => {
            this.folder = res;
            this.folder.parentId = this.parentId;
          }
        );
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.folder = {
        id: 0,
        name: "",
        folderApplication: false,
        parentId: this.parentId ? this.parentId : 0,
      };
    },
    async create(bvModalEvt) {
      if (bvModalEvt) bvModalEvt.preventDefault();
      this.$refs.formCreateFolder.validate().then(async (success1) => {
        if (success1) {
          if (this.folder.id) {
            this.updateFolder({
              folder: this.folder,
            });
          } else {
            this.folder.parentId = this.parentId;
            if (
              this.fromModule !== null &&
              (this.parentId == null || this.parentId == 0)
            ) {
              // On créer alors le dossier de l'affaire (c'est à dire on passe l'affaire en useForlder, le back fait le reste et au retour on récupère alors l'id du dossier de l'affaire)
              if (this.fromModule == "affair") {
                await this.createFolderAffair(this.affair).then(async (res) => {
                  let dataFolder = structuredClone(this.folder);
                  dataFolder.parentId = res.folderId;
                  await this.createFolder({
                    folder: dataFolder,
                  });
                });
              }
            } else {
              await this.createFolder({
                folder: this.folder,
              });
            }
          }
          this.$nextTick(() => {
            this.$refs["modal-edit-folder"].toggle("#toggle-btn");
          });
        }
      });
    },
    ...mapActions([
      "createFolder",
      "updateFolder",
      "getFolderById",
      "getFolderById",
      "createFolderAffair",
    ]),
  },
  computed: {
    ...mapGetters(["isCreatingFolder", "affair"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
};
</script>
