<template lang="pug">
div.d-flex.flex-column.h-100
  // ALERT BANNER
  //- b-alert.m-1.pr-2.p-1(:show="true" :dismissible="true" :fade="true" variant="warning" style="font-size: .9rem; margin-bottom: 0 !important; padding-top: 1.3rem !important;") Désormais, lorsque vous validez un document tel qu'un devis, une facture ou autre, celui-ci passera en statut "validé" au lieu de "envoyé" comme c'était le cas précédemment. Vous aurez la possibilité de passer le statut à "envoyé" manuellement lorsque vous le jugerez approprié dans votre organisation.
  HeaderGrid(v-if="showHeaderGrid" userFilter="invoiceFilters" :name="name" :uid="uid" :dateRanger="dateRanger" :templateMode="templateMode" :template="template"  :dateRangeDefault="dateRangeDefault" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :tvaMode="tvaMode" :selectedRows="selectedRows" @setDates="setDates" @updateTvaMode="updateTvaMode" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @refreshRow="refreshRow" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
  StatCards
      div.cursor-pointer.pl-1.col-card-list-stats(@click="changeTab('all')")
        .card-list-stats(:class="tabActive == 'all' ? 'current' : ''")
          .card-list-stats-title
            div
              span.card-list-stats-title-badge.material-icons.text-primary.mr-50 list
              span.card-list-stats-title-text TOUT
            .card-list-stats-title-badge.bg-primary.text-white {{ getCountDataByStatus("all") }}
          .card-sum
            | {{ getSumDataByStatus("all") }} 
            span {{ tvaMode }}
      div.cursor-pointer.pl-1.col-card-list-stats(v-for="(status, index) of statusList" :key="index" @click="changeTab(status.value)")
        .card-list-stats(:class="[tabActive == status.value ? 'current' : '', 'border-'+status.class]")
          .card-list-stats-title
            div
              span.card-list-stats-title-badge.material-icons.mr-25(:class="'text-'+status.class") {{ status.icon }}
              span.card-list-stats-title-text {{ status.label }}
            .card-list-stats-title-badge.text-white(:class="'bg-'+status.class") {{ getCountDataByStatus(status.value, status.type) }}
          .card-sum
            | {{ getSumDataByStatus(status.value, status.type) }} 
            span {{ status.type == 'remaining' || status.value == 6 || status.value == 7 ? 'TTC' : tvaMode }}
  .d-flex.w-100.h-100(style="overflow: hidden;")
    .text-center.flex-center.w-100(v-if='isLoadingDocumentsList || !headerLoaded')
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement de la liste des factures...
    sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :tagguerButton="tagguerButton" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows" :allowGrouping="allowGrouping")
  ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
    sidebar-document-content(ref='sidebarDocumentContent' @refreshRow="refreshRow" @closeSidebar="closeSidebar")
  popup-new-invoice(ref='popupNewInvoice')
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { invoiceStatus } from "@/types/api-orisis/enums/enums";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";
import StatCards from "@/components/global/grid/StatCards.vue";

import PopupNewInvoice from "@/components/invoice/PopupNewInvoice.vue";
import SidebarDocumentContent from "@/components/invoice/SidebarDocumentContent.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    PopupNewInvoice,
    SyncGrid,
    HeaderGrid,
    StatCards,
    SidebarDocumentContent,
    "ejs-sidebar": SidebarComponent,
  },
  props: {
    origine: {
      type: String,
      default: "",
    },
    loadData: {
      type: Boolean,
      default: true,
    },
    showHeaderGrid: {
      type: Boolean,
      default: true,
    },
    showCardStats: {
      type: Boolean,
      default: true,
    },
    tab: {
      default: "all",
    },
    dateRange: {
      type: Array,
      default: () => [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
    },
    filterAffairs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      tabActive: "all",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "invoice",
      uid: "invoices",
      statusList: invoiceStatus.filter((el) => !el.notUse),
      headerData: [],
      headerLoaded: false,
      tagsSelected: [],
      selectedRows: [],
      documentSelected: {},
      tvaMode: "HT",
      dateRanger: true,
      tvaModeButton: true,
      addButton: true,
      searchButton: true,
      tagguerButton: true,
      searchPlaceholder: "Rechercher une facture",
      template: false,
      templateMode: true,
      allowGrouping: true,
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "status",
        headerText: "Statut",
        width: 110,
        maxWidth: 110,
        template: "statusTemplate",
        nature: "invoiceStatus",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filterTemplate: "statusFilterTemplate",
      },
      {
        type: "string",
        field: "nature",
        headerText: "Nature",
        filterTemplate: "natureFilterTemplate",
        width: 220,
        maxWidth: 220,
        template: "natureTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "documentReference",
        headerText: "Numéro",
        width: 160,
        maxWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        allowGrouping: false,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "documentDate",
        headerText: "Date",
        template: "dateYmdTemplate",
        width: 95,
        maxWidth: 95,
        allowFiltering: true,
        allowSorting: true,
        allowGrouping: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "limitDate",
        headerText: "Date d'échéance",
        template: "dateYmdTemplate",
        width: 95,
        maxWidth: 95,
        allowFiltering: true,
        allowSorting: true,
        allowGrouping: true,
        showInColumnChooser: true,
        visible: false,
      },
      {
        type: "string",
        field: "companyName",
        headerText: "Client",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "affairCode",
        headerText: "Code Affaire",
        width: 100,
        maxWidth: 100,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "affairName",
        headerText: "Affaire",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "description",
        headerText: "Objet",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowGrouping: false,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filter: { type: "Menu", operator: "contains" },
        clipMode: "EllipsisWithTooltip",
        template: "stripTagsTemplate",
      },
      {
        type: "string",
        field: "invoiceTags",
        headerText: "Tags",
        width: "auto",
        minWidth: 200,
        allowFiltering: false,
        allowSorting: false,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "tagsTemplate",
        unavailableExport: true,
      },
      {
        type: "number",
        field: "totalHt",
        headerText: "Total HT",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        allowFiltering: true,
        allowGrouping: false,
        allowAggregate: true,
      },
      {
        type: "number",
        field: "documentTva",
        headerText: "Total TVA",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        showInColumnChooser: true,
        visible: false,
        template: "tvaTemplate",
        format: "formatCurrency",
        allowGrouping: false,
        allowFiltering: true,
        allowAggregate: true,
      },
      {
        type: "number",
        field: "totalTtc",
        headerText: "Total TTC",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        showFooterSum: true,
        allowGrouping: false,
        allowFiltering: true,
        allowAggregate: true,
      },
      {
        type: "number",
        field: "remainingToPayTtc",
        headerText: "Restant dû TTC",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        showFooterSum: true,
        allowGrouping: false,
        allowFiltering: true,
        allowAggregate: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "isLoadingDocumentsList",
      "invoicesList",
      "invoicesListTemplates",
      "institutionsList",
      "workspaceSelected",
      "institutionSelected",
      "userParametersList",
    ]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    dataInvoicesList() {
      if (this.template) {
        return this.invoicesListTemplates;
      } else {
        return this.invoicesList;
      }
    },
    dataOrigine() {
      if (this.tagsSelected.length) {
        return this.dataInvoicesList.filter(
          (elem) =>
            elem[this.name + "Tags"].find((tag) =>
              this.tagsSelected.includes(tag.id)
            ) &&
            (this.filterAffairs.length > 0
              ? this.filterAffairs.includes(elem.affairId)
              : true)
        );
      } else {
        return this.dataInvoicesList.filter((elem) =>
          this.filterAffairs.length > 0
            ? this.filterAffairs.includes(elem.affairId)
            : true
        );
      }
    },
    dataFiltered() {
      if (this.statusList) {
        if (this.tabActive == "all") {
          if (this.tagsSelected.length) {
            return this.dataInvoicesList.filter(
              (elem) =>
                elem[this.name + "Tags"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                ) &&
                (this.filterAffairs.length > 0
                  ? this.filterAffairs.includes(elem.affairId)
                  : true)
            );
          } else {
            return this.dataInvoicesList.filter((elem) =>
              this.filterAffairs.length > 0
                ? this.filterAffairs.includes(elem.affairId)
                : true
            );
          }
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          if (this.tagsSelected.length) {
            return this.dataInvoicesList.filter(
              (elem) =>
                elem[this.name + "Tags"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                ) &&
                (statusInfos.query
                  ? statusInfos.query.includes(elem.status)
                  : elem.status === this.tabActive) &&
                (this.filterAffairs.length > 0
                  ? this.filterAffairs.includes(elem.affairId)
                  : true)
            );
          } else {
            return this.dataInvoicesList.filter(
              (elem) =>
                (statusInfos.query
                  ? statusInfos.query.includes(elem.status)
                  : elem.status === this.tabActive) &&
                (this.filterAffairs.length > 0
                  ? this.filterAffairs.includes(elem.affairId)
                  : true)
            );
          }
        }
      } else {
        return [];
      }
    },
  },
  async created() {
    await this.getCompanies({});
    await this.getCollaborators({});
    this.getInvoiceTags({});
  },
  async activated() {
    this.activated();
  },
  async mounted() {
    if (this.origine == "dashbord-gestion") {
      this.activated();
    }
  },
  methods: {
    ...mapActions([
      "getCompanies",
      "getCollaborators",
      "getInvoices",
      "getInvoiceTags",
    ]),
    formatCurrency,
    setColumnsByObject,
    updateTvaMode(val) {
      this.tvaMode = val;
    },

    async activated() {
      this.tabActive = this.tab;
      this.headerLoaded = false;
      let filter = this.userParametersList.find(
        (el) => el.key == "invoiceFilters"
      );

      if (!this.loadData) {
        if (this.invoicesList.length) {
          await this.setColumnsByObject(this.invoicesList[0], null, this);
        }
        this.headerLoaded = true;
      } else if (filter) {
        if (JSON.parse(filter.value).dateRange) {
          this.setDates(JSON.parse(filter.value).dateRange);
        } else {
          this.setDates({
            startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
            endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
          });
        }
      } else {
        await this.setDates({
          startDate: dayjs()
            .subtract(3, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
        });
      }
      this.$emit("setStatusList", this.statusList);
      this.getInvoices({
        isTemplate: true,
        updateState: false,
      });
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    toggleModal() {
      this.selectInstitution = this.institutionSelected;
      this.$refs["modal-new-invoice"].toggle("#toggle-btn-new-invoice");
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    async setDates(dates) {
      this.headerLoaded = false;
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getInvoices({
          dateFrom: dateRange.startDate,
          dateTo: dateRange.endDate,
        }).then(async (res) => {
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    clickAction() {
      this.$refs.popupNewInvoice.toggleModal();
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "template") {
        this.getInvoices({
          isTemplate: true,
        });

        this.template = true;
      } else if (args.item.type == "current") {
        this.template = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status, statusType = null) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        if (statusType == "remaining") {
          return this.dataOrigine.filter(
            (elem) =>
              elem.remainingToPayTtc > 0 &&
              statusInfos.query.includes(elem.status)
          ).length;
        } else {
          return this.dataOrigine.filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          ).length;
        }
      }
    },
    getSumDataByStatus(status = null, statusType = null) {
      let sum = 0;
      if (status == "all") {
        this.dataOrigine.forEach((obj) => {
          if (this.tvaMode == "TTC") {
            sum += obj["totalTtc"];
          } else {
            sum += obj["totalHt"];
          }
        });
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        this.dataOrigine
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          )
          .forEach((obj) => {
            if (this.tvaMode == "TTC") {
              if (statusType == "remaining" && obj["remainingToPayTtc"] > 0) {
                sum += obj["remainingToPayTtc"];
              } else if (status == 6) {
                sum += obj["totalTtc"];
              } else if (status == 7) {
                sum += obj["totalTtc"] - obj["remainingToPayTtc"];
              } else {
                sum += obj["totalTtc"];
              }
            } else {
              if (statusType == "remaining" && obj["remainingToPayTtc"] > 0) {
                sum += obj["remainingToPayTtc"];
              } else if (status == 6) {
                sum += obj["totalTtc"];
              } else if (status == 7) {
                sum += obj["totalTtc"] - obj["remainingToPayTtc"];
              } else {
                sum += obj["totalHt"];
              }
            }
          });
      }
      return this.formatCurrency(sum);
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
      this.$emit("setTagsSelected", val);
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      if (this.origine == "dashbord-gestion") {
        this.$emit("rowClicked", event);
      } else {
        this.isOpen = true;
        this.$refs["sidebarDocumentContent"].getDocumentDetails(
          event.rowData.id,
          event.rowData.nature
        );
        this.$refs.sidebar.toggle();
      }
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
