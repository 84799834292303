<template lang="pug">

.d-flex.align-items-center.status-selection
  ejs-dropdownlist(v-if="status && status.class && !staticComputed" @open="onOpen" valueTemplate='valueTemplate' itemTemplate="itemTemplate" ref='dropdownStatusList' :fields="customFields" :dataSource='statusList' :value='status.value' @change="statusAction($event)")
    template(v-slot:itemTemplate="{data}")
      span(:id="'status_'+data.value" class='chip h-100 w-100' :class="data.value == status.value ? 'd-none m-0 p-0 h-0' : 'd-flex'" style='padding: 4px 8px')
        span(:class="`point bg-${data.class}`")
        span(:class='`label text-${data.class}`') {{ data.label }}
    template(v-slot:valueTemplate="{data}")
      span(class='chip h-100 w-100 rounded' style='padding: 4px 8px')
        span(:class="`point bg-${data.class}`")
        span(:class='`label text-${data.class}`') {{ data.label }}
  span(v-else-if="status && status.class && staticComputed" :class="`chip h-100 w-100 text-${status.class}`" style="padding: 4px 8px 4px 0px")
    span(:class="`point bg-${status.class}`")
    span(:class="`label text-${status.class}`") {{ status.label }}
  span.mr-50(v-if="displayPipe")
    ||
</template>

<script>
import {
  quoteStatus,
  invoiceStatus,
  creditStatus,
  orderFormProviderStatus,
  receiptFormProviderStatus,
  deliveryFormStatus,
  inventoryFormStatus,
  invoiceProviderStatus,
  creditProviderStatus,
  orderFormCustomerStatus,
  interventionStatus,
  holdbackStatus,
  reportingStatus,
} from "@/types/api-orisis/enums/enums";
import { CommonTitleSettings } from "@syncfusion/ej2-vue-treemap";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    statusId: {
      default: 0,
    },
    nature: {
      default: null,
      required: true,
    },
    displayPipe: {
      default: true,
    },
    isConnected: {
      default: false,
    },
    static: {
      default: false,
    },
  },
  data() {
    return {
      status: null,
      statusList: [],
      customFields: { text: "label", value: "value", color: "class" },
      // itemTemplate:
      //   "<span class='chip h-100 bg-white w-100 rounded' style='padding: 4px 8px'><span class='point bg-${class}'></span><span class='label text-${class}'>${label}</span></span>",
    };
  },
  computed: {
    ...mapGetters(["document"]),
    staticComputed: {
      get() {
        if (this.statusList.length == 1) {
          return true;
        } else {
          return this.static;
        }
      },
    },
  },
  beforeMount() {
    switch (this.nature) {
      case 0:
        this.status = quoteStatus.find((elem) => elem.value == this.statusId);
        // Brouillon
        if ([0, 8].includes(this.statusId)) {
          this.statusList = [this.status];
          // A valider
        } else if (this.statusId == 1) {
          this.statusList = quoteStatus.filter((elem) =>
            [this.statusId, 4, 5].includes(elem.value)
          );
          // Validé
        } else if (this.statusId == 2) {
          this.statusList = quoteStatus.filter((elem) =>
            [this.statusId, 9, 3, 4, 5].includes(elem.value)
          );
          // Envoyé
        } else if (this.statusId == 9) {
          this.statusList = quoteStatus.filter((elem) =>
            [this.statusId, 3, 4, 5].includes(elem.value)
          );
          // En retard
        } else if (this.statusId == 10) {
          this.statusList = quoteStatus.filter((elem) =>
            [this.statusId, 3, 4, 5].includes(elem.value)
          );
          // Signé
        } else if (this.statusId == 3) {
          if (!this.isConnected) {
            this.statusList = quoteStatus.filter((elem) =>
              [this.statusId, 5].includes(elem.value)
            );
          } else {
            this.statusList = quoteStatus.filter((elem) =>
              [this.statusId].includes(elem.value)
            );
          }
          // Refusé
        } else if (this.statusId == 4) {
          this.statusList = [this.status];
          // Annulé
        } else if (this.statusId == 5) {
          this.statusList = [this.status];
        }
        break;
      case 1:
      case 2:
      case 3:
      case 4:
        this.status = invoiceStatus.find((elem) => elem.value == this.statusId);
        if ([0, 8, 1, 9, 10, 7, 6, 5, 4].includes(this.statusId)) {
          this.statusList = [this.status];
        } else if (this.statusId == 2) {
          this.statusList = invoiceStatus.filter((elem) =>
            [this.statusId, 9].includes(elem.value)
          );
        }
        break;
      case 5:
      case 6:
        this.status = creditStatus.find((elem) => elem.value == this.statusId);
        if ([0, 8, 1, 9, 10, 7, 6, 5, 4].includes(this.statusId)) {
          this.statusList = [this.status];
        } else if (this.statusId == 2) {
          this.statusList = creditStatus.filter((elem) =>
            [this.statusId, 9].includes(elem.value)
          );
        }
        break;
      case "orderFormProvider":
        this.status = orderFormProviderStatus.find(
          (elem) => elem.value == this.statusId
        );
        this.statusList = [this.status];
        break;
      case "receiptFormProvider":
        this.status = receiptFormProviderStatus.find(
          (elem) => elem.value == this.statusId
        );
        this.statusList = [this.status];
        break;
      case "deliveryForm":
        this.status = deliveryFormStatus.find(
          (elem) => elem.value == this.statusId
        );
        this.statusList = [this.status];
        break;
      case "inventoryForm":
        this.status = inventoryFormStatus.find(
          (elem) => elem.value == this.statusId
        );
        this.statusList = [this.status];
        break;
      case "invoiceProvider":
        this.status = invoiceProviderStatus.find(
          (elem) => elem.value == this.statusId
        );
        this.statusList = [this.status];
        break;
      case "creditProvider":
        this.status = creditProviderStatus.find(
          (elem) => elem.value == this.statusId
        );
        this.statusList = [this.status];
        break;
      case "orderFormCustomer":
        this.status = orderFormCustomerStatus.find(
          (elem) => elem.value == this.statusId
        );
        if ([0, 1, 4].includes(this.statusId)) {
          this.statusList = orderFormCustomerStatus.filter((elem) =>
            [this.statusId].includes(elem.value)
          );
        } else if (this.statusId == 2) {
          this.statusList = orderFormCustomerStatus.filter((elem) =>
            [this.statusId, 5, 6].includes(elem.value)
          );
        } else if (this.statusId == 3) {
          this.statusList = orderFormCustomerStatus.filter((elem) =>
            [this.statusId, 7].includes(elem.value)
          );
        } else if ([5, 6].includes(this.statusId)) {
          this.statusList = orderFormCustomerStatus.filter((elem) =>
            [this.statusId, 2].includes(elem.value)
          );
        }

        break;
      case "intervention":
        this.status = interventionStatus.find(
          (elem) => elem.value == this.statusId
        );
        this.statusList = [this.status];
        break;
      case "holdback":
        this.status = holdbackStatus.find(
          (elem) => elem.value == this.statusId
        );
        this.statusList = [this.status];
        break;
      case "reporting":
        this.status = reportingStatus.find(
          (elem) => elem.value == this.statusId
        );
        this.statusList = [this.status];
        break;
      default:
        this.status = null;
        break;
    }
  },
  methods: {
    ...mapActions([
      "updateQuote",
      "updateInvoice",
      "updateCredit",
      "updateOrderFormCustomer",
    ]),
    onOpen() {
      document.getElementById(
        "status_" + this.status.value
      ).parentElement.style.display = "none";
    },
    statusAction(event) {
      const newStatus = event.itemData.value;
      if (this.statusId == newStatus) return;
      switch (this.nature) {
        case 0:
          if ([9, 1].includes(newStatus)) {
            this.updateQuote({
              quote: { ...this.document, status: newStatus },
              updateState: true,
            });
          } else if (newStatus == 3) {
            this.$emit("signedQuote");
          } else if (newStatus == 4) {
            this.$emit("refusedQuote");
          } else if (newStatus == 5) {
            this.$emit("toggleCancelModal");
          }
          break;
        case 1:
        case 2:
        case 3:
        case 4:
          this.updateInvoice({
            invoice: { ...this.document, status: newStatus },
            updateState: true,
          });
          break;
        case 5:
        case 6:
          this.updateCredit({
            credit: { ...this.document, status: newStatus },
            updateState: true,
          });
          break;
        case "orderFormCustomer":
          this.updateOrderFormCustomer({
            orderFormCustomer: { ...this.document, status: newStatus },
            loading: true,
          });
          break;
        default:
      }
    },
  },
};
</script>

<style>
.status-selection .e-input-group,
.status-selection .e-input-group.e-control-wrapper,
.status-selection .e-input-focus {
  border-bottom: none !important;
  border: none !important;
  width: 170px !important;
  box-shadow: none !important;
}
</style>
