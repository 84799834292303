<template lang="pug">
  div
    div(v-if="isLoadingOrderFormCustomer")
      ejs-skeleton(height='116px' style="border-radius:8px")
    div(style="page-break-inside: avoid;")
      .border-left.border-right.border-top.rounded-top.content-total
        div
          //- Gestion de la remise
          div(v-if="orderFormCustomerOption.showDiscount")
            //- Sous-total HT (avant remise)
            strong.d-flex.total-row Sous-total HT 
              .ml-auto.price-total
                span.totalWithoutTax {{ formatCurrency(totalOrderFormCustomer.subTotalHt) }}
            .d-flex.align-items-center(v-if="!preview")
              | Remise globale
              .ml-auto.d-flex(v-if="canEditDiscount && !disabled" style="padding-top: 5px; padding-bottom: 5px;")
                input.form-control.form-control-sm(v-model="orderFormCustomer.discountGlobalNumber" type="number" min='0' @blur="saveAction" @input='isNegative' autocomplete='off' style='width: 65px;' name='discount')
                select.form-control.form-control-sm.ml-1(@input="saveAction" v-model="orderFormCustomer.discountGlobalType" name='discount_type' style='width: 80px;')
                  option.discount-option-percentage(value=0 selected='selected') %
                  option.discount-option-no-tax(value=1) &euro; HT
                  //- TODO Faire la remise TTC
                  //- option.discount-option-with-tax(value=2) &euro; TTC
              .ml-auto.d-flex(v-else)
                span {{ orderFormCustomer.discountGlobalType == '1' ? '-' : '' }}{{ orderFormCustomer.discountGlobalType == '0' ? formatPercent(orderFormCustomer.discountGlobalNumber) : formatCurrency(orderFormCustomer.discountGlobalNumber) + ' HT' }}
            .discount-total(v-else)
              .d-flex.total-row
                | {{ orderFormCustomer.discountGlobalType == '0' ? 'Remise globale' : 'Remise globale HT' }}
                span.ml-auto.price-total {{ orderFormCustomer.discountGlobalType == '1' ? '-' : '' }}{{ orderFormCustomer.discountGlobalType == '0' ?  formatPercent(orderFormCustomer.discountGlobalNumber) : formatCurrency(orderFormCustomer.discountGlobalNumber) }}
            .discount-total(v-show="orderFormCustomer.discountGlobalType != 1 && orderFormCustomerOption.showDiscount")
              .d-flex.total-row
                | Remise HT
                span.ml-auto.price-total -{{ formatCurrency(totalOrderFormCustomer.discountAmount) }}
            .total-divider
          
          div(v-if="haveReview")
            strong.d-flex.total-row.justify-content-between Base total HT
              .ml-auto.price-total
                span.totalWithoutTax {{ formatCurrency(totalOrderFormCustomer.totalBaseNetHt) }}
            div(v-for='(item, index) in deduction.reviews' :key='index')
              .d-flex.total-row {{ item.label }}
                .ml-auto.price-total
                  span.totalWorks {{ formatCurrency(item.number.toFixed(2)) }}

          //- Total HT (après remise si remise)
          //- Gestion du Total TTC
          strong.d-flex.total-row Total HT
            .ml-auto.price-total
              span.totalWithoutTax {{ formatCurrency(totalOrderFormCustomer.totalNetHt) }}
            
        // Gestion de la TVA
        div(v-if="orderFormCustomerOption.isReverseCharge")
          .d-flex Autoliquidation
        div(v-else)
          div(v-for="(item, index) in totalOrderFormCustomer.tvaDetails")
            .d-flex.total-row TVA {{ referencielTvaTranslate(item.referencielTvaId) }}
              span.ml-auto.price-total {{ formatCurrency(item.priceTva) }}
          strong.d-flex.total-row.total-with-tax(v-if='totalOrderFormCustomer.tvaDetails.length > 1') Total TVA
            .ml-auto.price-total
              span.totalWithTax {{ formatCurrency(totalOrderFormCustomer.totalTva.toFixed(2)) }}
        
        // Gestion du total TTC
        div
          strong.d-flex.total-row Total TTC
            .ml-auto.price-total
              span.price-total {{ formatCurrency(orderFormCustomer.totalTtc) }} 
        
        // Gestion de la Marge
        div(v-if="orderFormCustomerOption.calculateMargin && !preview")
          .total-divider
          .d-flex.margin-row Marge brute HT
            span.ml-auto.price-total {{formatCurrency(orderFormCustomer.marginBrutHt) }}
          .d-flex.margin-percent-row.align-items-center(:style="!orderFormCustomermarginStatus ? 'color:red;' : ''") Taux de marge
            span.ml-auto.price-total {{ !orderFormCustomermarginStatus ? "Taux de marge non calculable" : percentMarginRate + ' %' }}
            feather-icon.ml-1(v-if="!orderFormCustomermarginStatus" icon="InfoIcon" size="16" v-b-tooltip.hover.top="'Le taux de marge n’est pas calculable car au moins une ligne du document n’a pas de taux de marge renseigné, veuillez les compléter pour voir apparaitre le taux de marge.'") 

        // Gestion des Déductions
        div(v-if="deduction")
          .total-divider(v-if="(deduction.punctualDeduction && deduction.punctualDeductions.length > 0) || deduction.prorataAccountPrice != null || deduction.prorataAccountPercent != null || deduction.holdbackNumber != null || deduction.holdbackPercent != null")
          div(v-if="deduction.punctualDeductions" v-for="(item, index) in deduction.punctualDeductions")
            .d-flex.total-row {{ item.label }} {{'(' + item.percent + ' %)' }}
              span.ml-auto.price-total {{ formatCurrency(item.number) }}
          div(v-if="deduction.prorataAccountPrice != null || deduction.prorataAccountPercent != null")
            .d-flex.total-row Compte de prorata {{ deduction.prorataAccountLabel }} {{'(' + deduction.prorataAccountPercent + ' %)' }}
              span.ml-auto.price-total -{{ formatCurrency(deduction.prorataAccountPrice) }}
          div(v-if="deduction.holdbackNumber != null || deduction.holdbackPercent != null")
            .d-flex.total-row Retenue de garantie ({{deduction.holdbackPercent}} %)
              span.ml-auto.price-total -{{ formatCurrency(deduction.holdbackNumber) }}

      // Gestion du NET A PAYER
      strong.d-flex.big.bg-primary.text-white.totalInvoice(:style="preview ? 'background:'+orderFormCustomer.institutionColor+' !important' : ''" :class="!preview && calcPriceTotalRemainingToPay()!==totalNetToPay ? '' : 'rounded-bottom'") NET À PAYER
        .ml-auto.price-total
          span.totalWithTax {{ formatCurrency(totalNetToPay) }}
 
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  calcNetToPay,
  calcTotalMarginBrutHt,
} from "@/types/api-orisis/library/DocumentOperations";
import vSelect from "vue-select";
import { VBPopover, BPopover, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
import dayjs from "dayjs";
import {
  formatCurrency,
  formatDate,
  formatPercent,
  formatNumber,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { natureTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { referencielTvaTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { applyOrderFormCustomerDeduction } from "@/types/api-orisis/library/DeductionOperations.ts";

import { SkeletonComponent } from "@syncfusion/ej2-vue-notifications";

export default {
  data() {
    return {
      calcNetToPay,
      calcTotalMarginBrutHt,
      totalNetToPay: 0,
    };
  },
  props: {
    preview: {
      default: false,
    },
    canEditDiscount: {
      required: true,
      default: true,
      type: Boolean,
    },
    disabled: {
      default: false,
    },
  },
  components: {
    "v-select": vSelect,
    "ejs-skeleton": SkeletonComponent,
    popper: Popper,
  },
  methods: {
    ...mapActions(["updateOrderFormCustomer"]),
    formatCurrency,
    formatDate,
    formatPercent,
    formatNumber,
    natureTranslate,
    referencielTvaTranslate,
    applyOrderFormCustomerDeduction,
    calcPriceTotalRemainingToPay() {
      let priceTotalRemainingToPay = (this.orderFormCustomer.remainingToPayTtc =
        Math.round(Number(this.totalNetToPay) * 100) / 100);
      return priceTotalRemainingToPay;
    },
    // Enregistrement du orderFormCustomer
    saveAction() {
      setTimeout(() => {
        this.applyOrderFormCustomerDeduction();
        this.updateOrderFormCustomer({
          orderFormCustomer: this.orderFormCustomer,
          loading: false,
        });
      }, 200);
    },
    // Actions globales liées aux str
    isNegative(e) {
      setTimeout(() => {
        if (this.orderFormCustomer.discountGlobalType == 0) {
          if (e.target.value < 0) {
            this.orderFormCustomer.discountGlobalNumber = 0;
          } else if (e.target.value > 100) {
            this.orderFormCustomer.discountGlobalNumber = 100;
          } else if (!e.target.value) {
            this.orderFormCustomer.discountGlobalNumber = 0;
          }
        } else {
          if (e.target.value < 0) {
            this.orderFormCustomer.discountGlobalNumber = 0;
          } else if (!e.target.value) {
            this.orderFormCustomer.discountGlobalNumber = 0;
          }
        }
      }, 200);
    },
    referencielTvaValue(referencielTvaId) {
      return formatNumber(
        this.institutionSettingsActive.referencielTvas.find(
          (item) => item.referencielTva.id == referencielTvaId
        ).referencielTva.value
      );
    },
  },
  computed: {
    ...mapGetters([
      "getOrderFormCustomerDetails",
      "deduction",
      "orderFormCustomermarginStatus",
      "institutionSettingsActive",
      "isLoadingOrderFormCustomer",
      "orderFormCustomerOption",
    ]),
    orderFormCustomer: {
      get() {
        return this.$store.getters.orderFormCustomer;
      },
      set(value) {
        return this.$store.commit("SET_ORDER_FORM_CUSTOMER", value);
      },
    },
    haveReview() {
      return this.deduction?.reviews && this.deduction.reviews.length > 0;
    },
    percentMarginRate() {
      let totalMarginBrutHt = (this.orderFormCustomer.marginBrutHt =
        this.calcTotalMarginBrutHt(
          this.totalOrderFormCustomer.discountAmount,
          7
        ));
      if (this.orderFormCustomer.totalHt - totalMarginBrutHt == 0) {
        this.orderFormCustomer.marginRate = 100;
        return (100).toFixed(2);
      } else {
        let marginRate = (this.orderFormCustomer.marginRate = this
          .totalOrderFormCustomer.subTotalHt
          ? formatNumber(
              (this.orderFormCustomer.marginBrutHt /
                this.totalOrderFormCustomer.subTotalHt) *
                100
            )
          : 0);
        return marginRate.toFixed(2);
      }
    },
    totalOrderFormCustomer() {
      let totalOrderFormCustomer = {
        subTotalHt: 0,
        discountAmount: 0,
        totalNetHtPrestation: 0,
        totalNetHt: 0,
        totalBaseNetHt: 0,
        tvaDetails: [],
        totalTva: 0,
        totalTtc: 0,
      };

      let documentTvas = [];
      // On affecte les factures d'acompte
      // On filtre sur les lignes devant être prises en compte
      // On génère le tableau TVA
      // On calcule les totaux
      totalOrderFormCustomer.tvaDetails = this.getOrderFormCustomerDetails
        .filter(
          (el) =>
            el.type != 0 &&
            el.type != 1 &&
            el.type != 2 &&
            el.type != 11 &&
            el.type != 12 &&
            el.type != 13 &&
            el.type != 18 &&
            el.type != 17 &&
            el.type != 16 &&
            el.type != 15 &&
            el.type != 14 &&
            !el.isOption
        )
        .reduce((acc, obj) => {
          // On crée un object par taux de TVA
          let findIndex = acc.findIndex(
            (el) => el.referencielTvaId === obj.referencielTvaId
          );

          // On calcule les montant selon les quantités et remise par ligne
          let priceBrutHtAvced = 0;
          let priceBrutHtPrevious = 0;
          let priceBrutHtPrestation =
            Math.round(obj.unitPriceHt * obj.quantity * 100) / 100;
          let priceBrutHt =
            Math.round(obj.unitPriceHt * obj.quantity * 100) / 100;

          let discount = (100 - obj.discount) / 100;
          let priceNetHtAvced =
            Math.round(priceBrutHtAvced * discount * 100) / 100;
          let priceNetHtPrevious =
            Math.round(priceBrutHtPrevious * discount * 100) / 100;
          let priceNetHtPrestation =
            Math.round(priceBrutHtPrestation * discount * 100) / 100;
          let priceNetHt = Math.round(priceBrutHt * discount * 100) / 100;

          if (findIndex < 0) {
            // On instancie un nouveau de TVA
            acc.push({
              priceHtPrestation: priceNetHtPrestation,
              priceHtPrevious: priceNetHtPrevious,
              priceTvaPrevious: 0,
              priceTtcPrevious: 0,
              priceHtAvced: priceNetHtAvced,
              priceTvaAvced: 0,
              priceTtcAvced: 0,
              priceHt: priceNetHt,
              priceTva: 0,
              priceTtc: 0,
              referencielTvaId: obj.referencielTvaId,
            });
          } else {
            // On additionne un taux de TVA
            acc[findIndex].priceHtPrestation += priceNetHtPrestation;
            acc[findIndex].priceHtPrevious += priceNetHtPrevious;
            acc[findIndex].priceHtAvced += priceNetHtAvced;
            acc[findIndex].priceHt += priceNetHt;
          }

          totalOrderFormCustomer.subTotalHt +=
            Math.round(priceNetHt * 100) / 100;
          totalOrderFormCustomer.totalNetHt +=
            Math.round(priceNetHt * 100) / 100;
          totalOrderFormCustomer.totalNetHtPrestation +=
            Math.round(priceNetHtPrestation * 100) / 100;
          return acc;
        }, []);

      // On calcule le prix de la TVA et le prix TTC
      for (
        let index = 0;
        index < totalOrderFormCustomer.tvaDetails.length;
        index++
      ) {
        const lineTva = totalOrderFormCustomer.tvaDetails[index];
        lineTva.priceTvaAvced =
          Math.round(
            ((lineTva.priceHtAvced *
              this.referencielTvaValue(lineTva.referencielTvaId)) /
              100) *
              100
          ) / 100;
        lineTva.priceTtcAvced = lineTva.priceTvaAvced + lineTva.priceHtAvced;
        lineTva.priceTvaPrevious =
          Math.round(
            ((lineTva.priceHtPrevious *
              this.referencielTvaValue(lineTva.referencielTvaId)) /
              100) *
              100
          ) / 100;
        lineTva.priceTtcPrevious =
          lineTva.priceTvaPrevious + lineTva.priceHtPrevious;
        lineTva.priceTva =
          Math.round(
            ((lineTva.priceHt *
              this.referencielTvaValue(lineTva.referencielTvaId)) /
              100) *
              100
          ) / 100;
        lineTva.priceTtc = lineTva.priceTva + lineTva.priceHt;
      }

      // On calcule le prix total de la TVA
      totalOrderFormCustomer.totalTva =
        totalOrderFormCustomer.tvaDetails.reduce((acc, line) => {
          return acc + line.priceTva;
        }, 0);

      // On calcule le prix total TTC
      totalOrderFormCustomer.totalTtc =
        totalOrderFormCustomer.tvaDetails.reduce((acc, line) => {
          return acc + line.priceTtc;
        }, 0);

      if (this.orderFormCustomerOption.showDiscount) {
        // On affecte la remise
        let totalNetHtPrestationBeforeDiscountGlobal = structuredClone(
          totalOrderFormCustomer.totalNetHtPrestation
        );
        if (
          this.orderFormCustomerOption.showDiscount &&
          this.orderFormCustomer.discountGlobalType == 1
        ) {
          totalOrderFormCustomer.discountAmount =
            totalNetHtPrestationBeforeDiscountGlobal == 0
              ? 0
              : Math.round(
                  (totalOrderFormCustomer.subTotalHt /
                    totalNetHtPrestationBeforeDiscountGlobal) *
                    this.orderFormCustomer.discountGlobalNumber *
                    100
                ) / 100;
        } else if (
          this.orderFormCustomerOption.showDiscount &&
          this.orderFormCustomer.discountGlobalType == 0
        ) {
          totalOrderFormCustomer.discountAmount =
            Math.round(
              totalOrderFormCustomer.subTotalHt *
                (formatNumber(this.orderFormCustomer.discountGlobalNumber) /
                  100) *
                100
            ) / 100;
        } else {
          totalOrderFormCustomer.discountAmount = 0;
        }
        totalOrderFormCustomer.totalNetHt = 0;
        totalOrderFormCustomer.totalNetHtPrestation =
          totalNetHtPrestationBeforeDiscountGlobal -
          (this.orderFormCustomer.discountGlobalType == 0
            ? totalNetHtPrestationBeforeDiscountGlobal *
              (this.orderFormCustomer.discountGlobalNumber / 100)
            : this.orderFormCustomer.discountGlobalNumber);
        totalOrderFormCustomer.totalTva = 0;
        totalOrderFormCustomer.totalTtc = 0;

        for (
          let index = 0;
          index < totalOrderFormCustomer.tvaDetails.length;
          index++
        ) {
          const lineTva = totalOrderFormCustomer.tvaDetails[index];
          lineTva.coeff =
            lineTva.priceHt /
            (totalOrderFormCustomer.subTotalHt == 0
              ? 1
              : totalOrderFormCustomer.subTotalHt);
          // On affecte la remise
          let priceNetHt = 0;
          let priceNetHtAvced = 0;
          let priceNetHtPrevious = 0;
          let priceNetHtPrestation = 0;

          if (
            this.orderFormCustomerOption.showDiscount &&
            this.orderFormCustomer.discountGlobalType == 1
          ) {
            let deductionAssociatedHt =
              lineTva.coeff * totalOrderFormCustomer.discountAmount;
            priceNetHt = lineTva.priceHt - deductionAssociatedHt;
            priceNetHtAvced = lineTva.priceHtAvced - deductionAssociatedHt;
            priceNetHtPrevious = lineTva.priceHtPrevious; // OK quand c'est une premiere situation détaillé avec une remise globale en € HT
            priceNetHtPrestation =
              lineTva.priceHtPrestation - deductionAssociatedHt;
          } else {
            let discountLine =
              Math.round(
                ((lineTva.priceHt *
                  this.orderFormCustomer.discountGlobalNumber) /
                  100) *
                  100
              ) / 100;
            priceNetHt =
              Math.round((lineTva.priceHt - discountLine) * 100) / 100;
            priceNetHtAvced =
              Math.round((lineTva.priceHtAvced - discountLine) * 100) / 100;
            priceNetHtPrevious =
              Math.round((lineTva.priceHtPrevious - discountLine) * 100) / 100;
            priceNetHtPrestation =
              Math.round((lineTva.priceHtPrestation - discountLine) * 100) /
              100;
          }

          lineTva.coeffLines = priceNetHt / totalOrderFormCustomer.subTotalHt;
          lineTva.priceHtPrestation = priceNetHtPrestation;
          lineTva.priceHtPrevious = structuredClone(priceNetHtPrevious);

          lineTva.priceHtAvced = structuredClone(priceNetHtAvced);

          lineTva.priceHt = structuredClone(priceNetHt);
          totalOrderFormCustomer.totalNetHt +=
            Math.round(lineTva.priceHt * 100) / 100;
        }

        // On calcule le prix de la TVA et le prix TTC
        for (
          let index = 0;
          index < totalOrderFormCustomer.tvaDetails.length;
          index++
        ) {
          const lineTva = totalOrderFormCustomer.tvaDetails[index];
          lineTva.priceTvaAvced =
            Math.round(
              ((lineTva.priceHtAvced *
                this.referencielTvaValue(lineTva.referencielTvaId)) /
                100) *
                100
            ) / 100;
          lineTva.priceTtcAvced = lineTva.priceTvaAvced + lineTva.priceHtAvced;
          lineTva.priceTvaPrevious =
            Math.round(
              ((lineTva.priceHtPrevious *
                this.referencielTvaValue(lineTva.referencielTvaId)) /
                100) *
                100
            ) / 100;
          lineTva.priceTtcPrevious =
            lineTva.priceTvaPrevious + lineTva.priceHtPrevious;
          lineTva.priceTva =
            Math.round(
              ((lineTva.priceHt *
                this.referencielTvaValue(lineTva.referencielTvaId)) /
                100) *
                100
            ) / 100;
          lineTva.priceTtc = lineTva.priceTva + lineTva.priceHt;
        }

        // On calcule le prix total de la TVA
        totalOrderFormCustomer.totalTva =
          totalOrderFormCustomer.tvaDetails.reduce((acc, line) => {
            return acc + line.priceTva;
          }, 0);

        // On calcule le prix total TTC
        totalOrderFormCustomer.totalTtc =
          totalOrderFormCustomer.tvaDetails.reduce((acc, line) => {
            return acc + line.priceTtc;
          }, 0);
      }

      for (
        let index = 0;
        index < totalOrderFormCustomer.tvaDetails.length;
        index++
      ) {
        const lineTva = totalOrderFormCustomer.tvaDetails[index];
        if (
          documentTvas.findIndex(
            (el) =>
              el.referencielTvaId == lineTva.referencielTvaId ||
              (el.referencielTva
                ? el.referencielTva.id == lineTva.referencielTvaId
                : false)
          ) >= 0
        ) {
          let index = documentTvas.findIndex(
            (el) => el.referencielTvaId == lineTva.referencielTvaId
          );
          documentTvas[index].value = lineTva.priceHt;
          documentTvas[index].valueTva = lineTva.priceTva;
        } else {
          documentTvas.push({
            id: 0,
            value: lineTva.priceHt,
            valueTva: lineTva.priceTva,
            referencielTvaId: lineTva.referencielTvaId,
            quoteId: null,
            invoiceId: null,
            creditId: null,
            orderFormCustomerId: this.orderFormCustomer.id,
          });
        }
      }

      this.orderFormCustomer.totalBaseNetHt =
        totalOrderFormCustomer.totalBaseNetHt =
          totalOrderFormCustomer.totalNetHt;

      if (this.deduction?.reviews && this.deduction.reviews.length > 0) {
        for (let index = 0; index < this.deduction.reviews.length; index++) {
          const review = this.deduction.reviews[index];
          let findIndexDocumentTvas = documentTvas.findIndex(
            (el) => el.referencielTvaId === review.referencielTvaId
          );
          let findIndextvaDetails = totalOrderFormCustomer.tvaDetails.findIndex(
            (el) => el.referencielTvaId === review.referencielTvaId
          );
          if (findIndexDocumentTvas >= 0) {
            let priceNetHt = Math.round(review.number * 100) / 100;
            let priceTva =
              Math.round(
                ((priceNetHt *
                  this.referencielTvaValue(review.referencielTvaId)) /
                  100) *
                  100
              ) / 100;
            documentTvas[findIndexDocumentTvas].value += priceNetHt;
            documentTvas[findIndexDocumentTvas].valueTva += priceTva;
            totalOrderFormCustomer.tvaDetails[findIndextvaDetails].priceHt +=
              priceNetHt;
            totalOrderFormCustomer.tvaDetails[findIndextvaDetails].priceTva +=
              priceTva;
            let priceTtc = priceNetHt + priceTva;
            if (review.includePrestation)
              totalOrderFormCustomer.totalNetHtPrestation +=
                Math.round(review.number * 100) / 100;
            totalOrderFormCustomer.tvaDetails[findIndextvaDetails].priceTtc +=
              priceTtc;
            totalOrderFormCustomer.totalNetHt +=
              Math.round(review.number * 100) / 100;
            totalOrderFormCustomer.totalTva += Math.round(priceTva * 100) / 100;
            totalOrderFormCustomer.totalTtc += Math.round(priceTtc * 100) / 100;
          }
        }
      }

      // On affectre les nouvelles valeurs calculées au document
      this.orderFormCustomer.totalHt =
        Math.round(totalOrderFormCustomer.totalNetHt * 100) / 100;

      this.orderFormCustomer.documentTvas = documentTvas;

      let _this = this;
      if (
        totalOrderFormCustomer.tvaDetails &&
        totalOrderFormCustomer.tvaDetails.length > 0
      ) {
        totalOrderFormCustomer.tvaDetails.sort(function (a, b) {
          return (
            _this.referencielTvaValue(a.referencielTvaId) -
            _this.referencielTvaValue(b.referencielTvaId)
          );
        });
      }

      this.$emit("updateTvaDetails", totalOrderFormCustomer.tvaDetails);
      this.orderFormCustomer.totalTtc =
        Number(Math.round(totalOrderFormCustomer.totalNetHt * 100) / 100) +
        Number(Math.round(totalOrderFormCustomer.totalTva * 100) / 100);
      this.orderFormCustomer.totalNetHtPrestation =
        totalOrderFormCustomer.totalNetHtPrestation;
      // On calcule le net à payer
      this.totalNetToPay = structuredClone(
        formatNumber(
          this.calcNetToPay(
            totalOrderFormCustomer.totalNetHt,
            this.orderFormCustomer.totalTtc
          )
        )
      );
      return totalOrderFormCustomer;
    },
  },
};
</script>

<style>
.popper {
  background-color: #ffffff;
  text-align: center;
  padding: 2px;
  border-radius: 3px;
  border: 1px #ebe9f1 solid;
  box-shadow: rgb(220 220 220) 0 0 15px 0;
  color: #6e6b7b;
  min-width: 500px;
}
.totalInvoice {
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  bottom: 0px;
  width: 100%;
  z-index: 1;
}

.total-divider {
  border-top: 1px solid #d9d9d9;
  margin: 7px 0 7px 0;
}

.content-total {
  padding: 7px;
}

.error-margin {
  padding: 10px;
  background-color: #f19e9e;
  display: flex;
  align-items: center;
  border-radius: 0.357rem;
}

.price-total {
  white-space: nowrap;
}

.rest-button {
  margin-left: 10px;
  cursor: pointer;
  color: #0c3571;
}
</style>
