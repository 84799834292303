<template lang="pug">
  b-modal#modal-new-situation-invoice(v-model="isPopupNewSituationInvoice" cancel-variant='outline-secondary' ok-title='Créer la facture' cancel-title='Annuler' centered='' title="Facture de situation" @ok="createSituationInvoice()")
    .text-center.flex-center(v-if='isLoadingDocument')
      .loading-bg-inner(role='status')
        .loader
          .outer
          .middle
          .inner
      br
      | Chargement des données en cours...
    div(v-else)
      b-row.my-0 
        b-col.py-0(cols='12')
          b-alert(variant='warning' show='')
            .alert-body
              span
                |  Vous ne pourrez plus changer le type de situation par la suite 
      b-row.my-0 
        b-col.py-0.mb-1.font-weight-bold(cols='12')
          b-form-group.pr-1(style='flex:1')
            validation-provider(#default='{ errors }')
              | Facture de situation pour {{ initialDocument.initialDocumentNature == 'quote' ? 'le devis' : 'la commande client' }} {{ initialDocument.documentReference }}
      div.my-0
        b-row.my-0
          b-col.py-0.mb-1(cols='12')
            b-form-radio(v-model='newSituationInvoiceForm.nature' name='radios-popup-new-situation' value=3)
              | Situation globale
        b-row.my-0(v-if="!isIncludesSituationGlobalInvoice")
          b-col.py-0.mb-1(cols='12')
            b-form-radio(v-model='newSituationInvoiceForm.nature' name='radios-popup-new-situation' value=4)
              | Situation détaillée
      .my-0.ml-3(v-if="includeDownPaymentInvoices.length > 0 && newSituationInvoiceForm.nature == 4")
        b-row.my-0
          b-col.py-0.mb-1(cols='12')
              | Déduction des acomptes
        b-row.my-0
          b-col.py-0.mb-1(cols='12')
            b-form-radio(v-model='newSituationInvoiceForm.downPaymentInvoicesDeduction' name='radios-popup-new-situation-down-payment-invoices-deduction' value=0)
              | Sur la première situation
      div(v-if="newSituationInvoiceForm.nature == 3")
        b-row.my-0 
          b-col.py-0(cols='6')
            b-form-group.pr-1(style='flex:1')
              validation-provider(#default='{ errors }')
                div.d-flex.align-items-center(style="height:37.99px") Montant du devis
          b-col.py-0(cols='6')
            b-form-group.pr-1(style='flex:1')
              validation-provider.d-flex.justify-content-end(#default='{ errors }')
                div.d-flex.align-items-center.justify-content-end(style="height:37.99px") {{ formatCurrency(initialDocument.totalTtc) }} TTC
          vs-divider
        b-row.my-0(v-for="(item, index) in previousInvoiceNotCanceled" :key="index")
          b-col.py-0(cols='6')
            b-form-group.pr-1(style='flex:1')
              validation-provider(#default='{ errors }')
                div.d-flex.align-items-center.font-weight-bold(style="height:37.99px") {{ natureTranslate(item.nature) }} {{ (item.documentStep ? `#${item.documentStep}` : '') }} {{ item.documentReference }} 
          b-col.py-0(cols='6')
            b-form-group.pr-1(style='flex:1')
              validation-provider.d-flex.justify-content-end(#default='{ errors }')
                div.d-flex.align-items-center.justify-content-end(style="height:37.99px") {{ formatCurrency(item.totalTtc) }} TTC
          vs-divider
        b-row.my-0 
          b-col.py-0.mb-1(cols='6')
            b-form-group(style='flex:1')
              validation-provider(#default='{ errors }')
                div.d-flex.align-items-center(style="height:37.99px") Montant de la facture
          b-col.mb-1.padding-r-5(cols='3')
            b-form-group(style='flex:1')
              validation-provider(#default='{ errors }' name='validityQuoteDefault' rules="required")
                b-form-input(@input='isNegative' type="number" v-model="newSituationInvoiceForm.value")
          b-col.mb-1.no-padding(cols='3')
            b-form-group.pr-1(style='flex:1')
              validation-provider(#default='{ errors }' name='validityQuoteDefault' rules="required")
                v-select#amountType(:clearable="false" :loading='isLoadingAmountTypeList' :deselectFromDropdown='true' :closeOnSelect='true' :state='errors.length > 0 ? false : null' v-model='newSituationInvoiceForm.type' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="\
                  amountTypeList\
                  " :reduce='elem => elem.value')
                      template(v-slot:no-options='')
                        template  Aucun r&eacute;sultat trouv&eacute;
        b-row(v-if="newSituationInvoiceForm.type != 2 && newSituationInvoiceForm.value != 0").my-0 
          b-col.py-0.mb-1(cols='6')
            b-form-group.pr-1(style='flex:1')
              validation-provider(#default='{ errors }')
                div.d-flex.align-items-center(style="height:37.99px") Total facturé
          b-col.py-0.mb-1(cols='6')
            b-form-group.pr-1(style='flex:1')
              validation-provider.d-flex.justify-content-end(#default='{ errors }')
                div.d-flex.align-items-center.justify-content-end(style="height:37.99px") {{ formatCurrency(newSituationInvoiceForm.situationTtc) }} TTC
        b-row.my-0 
          b-col.py-0.mb-1(cols='6')
            b-form-group.pr-1(style='flex:1')
              validation-provider(#default='{ errors }')
                div.d-flex.align-items-center.font-weight-bold(style="height:37.99px") Reste à facturer
          b-col.py-0.mb-1(cols='6')
            b-form-group.pr-1(style='flex:1')
              validation-provider.d-flex.justify-content-end(#default='{ errors }')
                div.d-flex.align-items-center.justify-content-end.font-weight-bold(style="height:37.99px") {{ formatCurrency(quoteRemains) }} TTC
      div(v-else-if="newSituationInvoiceForm.nature == 4")
        div.my-0
          b-row.my-0.justify-content-end
            b-col.py-0.mb-1(cols='11')
              b-form-radio(v-model='newSituationInvoiceForm.situationType' name='radios-popup-new-situation-type' :value="false")
                | Avancement au pourcentage
          b-row.my-0.justify-content-end
            b-col.py-0.mb-1(cols='11')
              b-form-radio(v-model='newSituationInvoiceForm.situationType' name='radios-popup-new-situation-type' :value="true")
                | Avancement à la quantité
</template>

<script>
import {
  getPricesByPourcentagePriceTtc,
  getPricesByPriceHt,
  getPricesByPriceTtc,
} from "@/types/api-orisis/library/DocumentOperations";
import {
  formatCurrency,
  formatDate,
} from "@/types/api-orisis/library/FormatOperations.ts";
import {
  amountTypeTranslate,
  natureTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";
import { BForm, BFormGroup, BFormInput, BModal, VBModal } from "bootstrap-vue";
import dayjs from "dayjs";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { InvoiceOptionModel } from "@/types/api-orisis/models/InvoiceOptionModel";

export default {
  data() {
    return {
      InvoiceOptionModel,
      getPricesByPriceHt,
      getPricesByPriceTtc,
      getPricesByPourcentagePriceTtc,
      newSituationInvoiceForm: {
        situationHt: 0,
        situationTtc: 0,
        remains: 0,
        type: 0,
        value: 0,
        nature: 3,
        downPaymentInvoicesDeduction: 0,
        situationType: false,
      },
      tvaLine: [],
      deduction: null,
    };
  },
  props: {
    activePopupSituationInvoice: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  watch: {
    isPopupNewSituationInvoice(val) {
      if (val) {
        this.initValues();
      }
      return false;
    },
  },
  computed: {
    ...mapGetters([
      "document",
      "orderFormCustomer",
      "isLoadingAmountTypeList",
      "amountTypeList",
      "institutionSettingsActive",
      "initialDocument",
      "lastInvoice",
      "natureList",
      "isLoadingDocument",
      "orderFormCustomerOption",
      "paymentConditionsList",
    ]),
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
    isPopupNewSituationInvoice: {
      get() {
        return this.activePopupSituationInvoice;
      },
      set(val) {
        if (!val) {
          this.$emit("toggleModalNewSituationInvoice");
        }
      },
    },
    documentDetails: {
      get() {
        return this.$store.getters["getDocumentDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT_DETAILS", value);
      },
    },
    quoteRemains() {
      let initialDocument = structuredClone(this.initialDocument);
      let price = structuredClone(initialDocument.totalTtc);
      if (initialDocument.invoices) {
        for (let i = 0; i < initialDocument.invoices.length; i++) {
          const invoice = initialDocument.invoices[i];
          if (invoice.status != 5) {
            price -= invoice.totalTtc;
          }
        }
      }
      switch (this.newSituationInvoiceForm.type) {
        case 0:
          this.situationObject = this.getPricesByPourcentagePriceTtc(
            initialDocument,
            this.newSituationInvoiceForm,
            this.$route.name
          );
          this.newSituationInvoiceForm.situationHt =
            this.situationObject.totals.totalHt;
          this.newSituationInvoiceForm.situationTtc =
            this.situationObject.totals.totalTtc;
          price -= this.situationObject.totals.totalTtc;
          break;
        case 1:
          this.situationObject = this.getPricesByPriceHt(
            initialDocument,
            this.newSituationInvoiceForm,
            this.$route.name
          );
          this.newSituationInvoiceForm.situationHt =
            this.situationObject.totals.totalHt;
          this.newSituationInvoiceForm.situationTtc =
            this.situationObject.totals.totalTtc;
          price -= this.situationObject.totals.totalTtc;
          break;
        case 2:
          this.situationObject = this.getPricesByPriceTtc(
            initialDocument,
            this.newSituationInvoiceForm,
            this.$route.name
          );
          this.newSituationInvoiceForm.situationHt =
            this.situationObject.totals.totalHt;
          this.newSituationInvoiceForm.situationTtc =
            this.situationObject.totals.totalTtc;
          price -= this.situationObject.totals.totalTtc;
          break;
        default:
          break;
      }
      return price.toFixed(2);
    },
    includeDownPaymentInvoices() {
      if (this.initialDocument) {
        return this.initialDocument.invoices
          ? this.initialDocument.invoices.filter(
              (el) => el.nature == 2 && el.status != 5
            )
          : [];
      }
      return [];
    },
    isIncludesSituationGlobalInvoice() {
      return this.lastInvoice && this.lastInvoice.nature == 3;
    },
    previousInvoiceNotCanceled() {
      if (this.initialDocument) {
        return this.initialDocument.invoices
          ? this.initialDocument.invoices.filter((el) => el.status != 5)
          : [];
      }
      return [];
    },
  },
  methods: {
    ...mapActions([
      "createInvoice",
      "addLine",
      "getDocumentDetailsByQuoteId",
      "getDocumentDetailsByInvoiceId",
      "createDocumentDetailsToSitutationFromPreviousSituation",
      "createDeduction",
      "createDocumentDetailToInvoiceIdFromQuoteId",
      "createDocumentDetailsToInvoiceIdFromOrderFormCustomerId",
      "getInvoiceOptionDefaultByInvoiceId",
      "createInvoiceOption",
    ]),
    formatCurrency,
    natureTranslate,
    formatDate,
    amountTypeTranslate,
    initValues() {
      this.newSituationInvoiceForm = {
        situationHt: 0,
        situationTtc: 0,
        type: 0,
        remains: 0,
        value: 0,
        nature: 3,
        downPaymentInvoicesDeduction: 0,
        situationType: false,
      };
    },
    isNegative(e) {
      this.newSituationInvoiceForm.value = Math.round(e * 100) / 100;
      if (this.newSituationInvoiceForm.type == 0) {
        if (e < 0) {
          this.newSituationInvoiceForm.value = 0;
        } else if (e > 100) {
          this.newSituationInvoiceForm.value = 100;
        }
      } else {
        if (e < 0) {
          this.newSituationInvoiceForm.value = 0;
        }
      }
    },
    async createSituationInvoice(nature = null) {
      this.$store.commit(
        "SET_IS_GLOBAL_MESSAGE",
        "Création de la facture de situation en cours..."
      );
      this.$store.commit("SET_IS_GLOBAL_CREATING", true);

      let documentInvoice =
        this.type == "document"
          ? structuredClone(this.document)
          : structuredClone(this.orderFormCustomer);
      let situationInvoiceFormFixed = structuredClone(
        this.newSituationInvoiceForm
      );
      let situationObjectFixed = structuredClone(this.situationObject);
      let initialDocument = structuredClone(this.initialDocument);
      let invoiceOptionsDefault = InvoiceOptionModel({});
      let invoiceOptions = InvoiceOptionModel({});
      let initialDocumentOptions =
        this.type == "document"
          ? structuredClone(this.documentOptions)
          : structuredClone(this.orderFormCustomerOption);
      await this.getInvoiceOptionDefaultByInvoiceId({
        updateState: false,
      }).then((res) => {
        invoiceOptionsDefault = res;
        invoiceOptions = {
          invoiceId: 0,
          ...res,
          calculateMargin:
            documentInvoice.nature == 3
              ? false
              : initialDocumentOptions.calculateMargin,
          showDiscount:
            documentInvoice.nature == 3
              ? false
              : initialDocumentOptions.showDiscount,
          showDescription: initialDocumentOptions.showDescription,
          id: 0,
        };
      });

      // Le document généré vient d'ORISIS
      documentInvoice.usedUrlGed = false;

      let deduction = null;

      // Stocker l'id du devis
      documentInvoice.quoteId =
        initialDocument.initialDocumentNature == "quote"
          ? initialDocument.id
          : initialDocument.quote?.id;
      // Stocker l'id du bon de commande
      documentInvoice.orderFormCustomerId =
        initialDocument.initialDocumentNature == "quote"
          ? initialDocument.orderFormCustomer
            ? initialDocument.orderFormCustomer.id
            : null
          : initialDocument.id;
      // Mettre à 0 l'id de la facture
      documentInvoice.id = 0;
      // Mettre la paymentConditionId
      let paymentCondition = this.paymentConditionsList.find(
        (item) => item.id == invoiceOptionsDefault.paymentConditionDefaultId
      );

      documentInvoice.paymentConditionId = paymentCondition
        ? paymentCondition.id
        : 1;
      // Aller chercher la date de validité dans limitDate
      documentInvoice.limitDate = paymentCondition
        ? dayjs().add(paymentCondition.days, "day").toISOString()
        : dayjs().toISOString();
      // Garder d'où est créé la facture
      let initialNature = JSON.stringify(
        documentInvoice.nature ? documentInvoice.nature : null
      );
      // Mettre la nature à 3 ou 4
      documentInvoice.nature =
        nature == null ? this.newSituationInvoiceForm.nature : nature;
      // Mettre le type de situation
      const situationTypeFromQuote = initialDocument.invoices.find(
        (invoice) => invoice.status !== 5 && invoice.nature != 2
      );
      documentInvoice.situationType = nature
        ? situationTypeFromQuote.situationType
        : this.newSituationInvoiceForm.situationType;
      // Ajouter isCounted à false,
      documentInvoice.isCounted = false;
      // Mettre documentDate à la date du jour
      documentInvoice.documentDate = new Date().toISOString();
      // Mettre le status à 8
      documentInvoice.status = 8;
      documentInvoice.path = null;
      // Calculer le prix Ht
      documentInvoice.totalHt =
        documentInvoice.nature == 3 ? situationInvoiceFormFixed.situationHt : 0;
      // Calculer le prix Ttc et le reste à payer pour la facture d'acompte
      documentInvoice.totalTtc = documentInvoice.remainingToPayTtc =
        documentInvoice.nature == 3
          ? situationInvoiceFormFixed.situationTtc
          : 0;
      // Mettre à jour le documentStep
      let situationInvoice = initialDocument.invoices
        .filter(
          (item) => (item.nature == 3 || item.nature == 4) && item.status != 5
        )
        .sort((a, b) => a.documentStep > b.documentStep);

      if (situationInvoice.length > 0) {
        documentInvoice.documentStep =
          situationInvoice[situationInvoice.length - 1].documentStep + 1;
      } else {
        documentInvoice.documentStep = 1;
      }
      // Mettre à jour les paiements
      documentInvoice.payments = [];

      // Mettre a jour la remise globale si c'est une facture de situation globale
      if (documentInvoice.nature == 3) {
        documentInvoice.discountGlobalNumber = 0;
        documentInvoice.discountGlobalType = 0;
      } else {
        documentInvoice.discountGlobalNumber =
          initialDocument.discountGlobalNumber;
        documentInvoice.discountGlobalType = initialDocument.discountGlobalType;
      }

      // Mettre à jour invoiceBTP
      if (initialDocument.initialDocumentNature == "quote") {
        if (initialDocument.quoteBTP != null) {
          documentInvoice.invoiceBTP = {
            id: 0,
            workStartDate: initialDocument.quoteBTP.workStartDate,
            estimatedDurationNumber:
              initialDocument.quoteBTP.estimatedDurationNumber,
            estimatedDurationType:
              initialDocument.quoteBTP.estimatedDurationType,
          };
        } else {
          documentInvoice.invoiceBTP = null;
        }
      } else if (
        initialDocument.initialDocumentNature == "order-form-customer"
      ) {
        if (initialDocument.orderFormCustomerBTP != null) {
          documentInvoice.invoiceBTP = {
            id: 0,
            workStartDate: initialDocument.orderFormCustomerBTP.workStartDate,
            estimatedDurationNumber:
              initialDocument.orderFormCustomerBTP.estimatedDurationNumber,
            estimatedDurationType:
              initialDocument.orderFormCustomerBTP.estimatedDurationType,
          };
        } else {
          documentInvoice.invoiceBTP = null;
        }
      }

      // Mettre à jour invoiceCEE
      if (initialDocument.initialDocumentNature == "quote") {
        if (initialDocument.quoteCEE != null) {
          documentInvoice.invoiceCEE = {
            id: 0,
            technicalVisitDate: initialDocument.quoteCEE.technicalVisitDate,
            amountPremium: initialDocument.quoteCEE.amountPremium,
            precarityType: initialDocument.quoteCEE.precarityType,
            parcelNumber: initialDocument.quoteCEE.parcelNumber,
          };
        } else {
          documentInvoice.invoiceCEE = null;
        }
      } else if (
        initialDocument.initialDocumentNature == "order-form-customer"
      ) {
        if (initialDocument.orderFormCustomerCEE != null) {
          documentInvoice.invoiceCEE = {
            id: 0,
            technicalVisitDate:
              initialDocument.orderFormCustomerCEE.technicalVisitDate,
            amountPremium: initialDocument.orderFormCustomerCEE.amountPremium,
            precarityType: initialDocument.orderFormCustomerCEE.precarityType,
            parcelNumber: initialDocument.orderFormCustomerCEE.parcelNumber,
          };
        } else {
          documentInvoice.invoiceCEE = null;
        }
      }

      // Mettre à jour les déductions
      if (initialDocument.deduction) {
        deduction = structuredClone(initialDocument.deduction);
      }

      if (initialDocumentOptions) {
        let baseMessage =
          initialDocument.initialDocumentNature == "quote"
            ? "<p>Notes du devis :</p>"
            : "<p>Notes du bon de commande :</p>";
        let tempDivInvoiceOptionsDefault = document.createElement("div");
        tempDivInvoiceOptionsDefault.innerHTML = invoiceOptionsDefault?.comment;
        tempDivInvoiceOptionsDefault = tempDivInvoiceOptionsDefault.innerText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s+/g, "")
          .replace(/[^a-zA-Z0-9]/g, "")
          .toLowerCase();

        let tempDivDocumentInvoice = document.createElement("div");
        tempDivDocumentInvoice.innerHTML = documentInvoice.comments;
        tempDivDocumentInvoice = tempDivDocumentInvoice.innerText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s+/g, "")
          .replace(/[^a-zA-Z0-9]/g, "")
          .toLowerCase();

        if (tempDivDocumentInvoice.includes(tempDivInvoiceOptionsDefault)) {
          documentInvoice.comments = documentInvoice.comments;
        } else {
          documentInvoice.comments =
            (documentInvoice?.comments ? baseMessage : "") +
            (invoiceOptionsDefault?.comment
              ? invoiceOptionsDefault?.comment + "<br>"
              : "") +
            (documentInvoice?.comments ? documentInvoice?.comments : "");
          if (structuredClone(documentInvoice.comments).length === 0) {
            documentInvoice.comments = invoiceOptionsDefault.comment;
          }
        }
      }

      // Mettre à jour le documentDetails et mettre à jours les totaux dans le store
      await this.createInvoice({
        invoice: { ...documentInvoice, path: "" },
      }).then(async (res) => {
        documentInvoice.id = res.data.id;
        await this.createInvoiceOption({
          invoiceOption: { ...invoiceOptions, invoiceId: res.data.id },
        });
        if (documentInvoice.nature == 3) {
          this.documentDetails = [];
          await this.insertLineIntoDocumentDetailAndReturnTotal(
            res.data.id,
            initialDocument,
            situationObjectFixed,
            situationInvoiceFormFixed
          );

          if (deduction)
            await this.applyQuoteDeduction(
              deduction,
              res.data.id,
              initialDocument,
              situationInvoiceFormFixed
            );
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          this.$router.push({
            name: "new-invoice",
            params: {
              id: res.data.id,
              title: "Nouvelle facture",
              tips: "Nouvelle facture",
              routeOrigine: "invoices",
            },
          });
        } else if (documentInvoice.nature == 4) {
          if (this.lastInvoice && this.lastInvoice.nature == 4) {
            await this.createDocumentDetailsToSitutationFromPreviousSituation({
              toInvoiceId: documentInvoice.id,
              fromInvoiceId: this.lastInvoice.id,
              resetSubtotal: true,
            });
          } else {
            if (
              initialNature == 0 ||
              initialDocument.initialDocumentNature == "quote"
            ) {
              await this.createDocumentDetailToInvoiceIdFromQuoteId({
                invoiceId: documentInvoice.id,
                quoteId: initialDocument.id,
                resetSubtotal: true,
              });
            } else if (
              initialDocument.initialDocumentNature == "order-form-customer"
            ) {
              await this.createDocumentDetailsToInvoiceIdFromOrderFormCustomerId(
                {
                  invoiceId: documentInvoice.id,
                  orderFormCustomerId: initialDocument.id,
                  resetSubtotal: true,
                }
              );
            }
          }
          if (deduction)
            await this.applyQuoteDeduction(
              deduction,
              res.data.id,
              initialDocument,
              situationInvoiceFormFixed
            );
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          await this.$router.push({
            name: "new-invoice",
            params: {
              id: res.data.id,
              title: "Nouvelle facture",
              tips: "Nouvelle facture",
              routeOrigine: "invoices",
            },
          });
        }
      });
    },
    async insertLineIntoDocumentDetailAndReturnTotal(
      invoiceId,
      initialDocument,
      situationObjectFixed,
      situationInvoiceFormFixed
    ) {
      for (let i = 0; i < situationObjectFixed.tvaLine.length; i++) {
        const line = situationObjectFixed.tvaLine[i];
        let newLine = {
          type: 3,
          unit: 1,
          index: i + 1,
          quoteId: null,
          invoiceId: invoiceId,
          description:
            "<p><strong>Situation pour " +
            (this.initialDocument.initialDocumentNature == "quote"
              ? "le devis"
              : "la commande client") +
            " n° " +
            initialDocument.documentReference +
            " du " +
            formatDate(initialDocument.documentDate) +
            " </strong></p><p> " +
            (situationInvoiceFormFixed.type == 0
              ? situationInvoiceFormFixed.value.toFixed(2) +
                " " +
                amountTypeTranslate(situationInvoiceFormFixed.type)
              : formatCurrency(line.advancementHt) + " HT") +
            " du montant total de " +
            formatCurrency(line.priceHt) +
            " HT&nbsp;</p>",
          referencielTvaId: line.referencielTvaId,
          total: line.advancementHt,
          unitPriceHt: line.advancementHt,
        };
        await this.addLine({
          documentDetail: newLine,
          indexArray: i - 1,
          isCredit: false,
          review: false,
        });
      }
    },
    async applyQuoteDeduction(
      deduction,
      invoiceId,
      initialDocument,
      situationInvoiceFormFixed
    ) {
      let quoteTotalTtc = initialDocument.totalTtc;
      let percent = situationInvoiceFormFixed.situationTtc / quoteTotalTtc;
      let invoiceDeduction = {
        creditId: null,
        holdbackDate: deduction.holdbackDate,
        holdbackNumber: deduction.holdbackNumber
          ? Math.round(deduction.holdbackNumber * percent * 100) / 100
          : null,
        holdbackPercent: deduction.holdbackPercent,
        id: 0,
        invoiceId: invoiceId,
        prorataAccountLabel: deduction.prorataAccountLabel,
        prorataAccountPercent: deduction.prorataAccountPercent,
        prorataAccountPrice: deduction.prorataAccountPrice
          ? Math.round(deduction.prorataAccountPrice * percent * 100) / 100
          : null,
        punctualDeductions: deduction.punctualDeductions
          ? deduction.punctualDeductions.map((item) => {
              return {
                id: 0,
                percent: item.percent,
                number:
                  item.number != null
                    ? Math.round(item.number * percent * 100) / 100
                    : null,
                label: item.label,
              };
            })
          : [],
        quoteId: null,
        orderFormCustomerId: null,
        reviews: deduction.reviews.map((review) => {
          return {
            ...review,
            id: 0,
          };
        }),
      };
      await this.createDeduction({ deduction: invoiceDeduction });
    },
  },
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>

<style scoped>
.no-padding {
  padding: 0 !important;
}

.padding-r-5 {
  padding: 0px 5px 0px 0px !important;
}
</style>

<style lang="scss">
#modal-new-situation-invoice .modal-content {
  height: fit-content !important;
}
</style>
