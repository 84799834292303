<template lang="pug">
div.h-100
    .h-100(v-if='isLoadingDocument || isUpdatingDocument || isLoadingDocumentPDF || isCreatingDocument')
      .text-center.flex-center.h-100
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement des détails du document...
    .h-100(v-else)
      b-modal(ref="modalSendEmail" :title="`Envoyer votre ${ natureTranslate(document.nature).toLowerCase() } par email`" centered ok-title="Envoyer" @ok="sendEmail" cancel-title="Annuler")
        label(for="selectRecipients") Envoyer à :
        ejs-multiselect( id="selectRecipients" placeholder='Selectionnez des destinataires' popupHeight='300px' :allowCustomValue='true' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :itemTemplate="itemTemplate" :fields='customFields' :dataSource='companyContacts' :hideSelectedItem="true" v-model="emailForm.mailTos")
        label.mt-1(for="selectCC") Envoyer en copie à :
        ejs-multiselect( id="selectCC" placeholder='Selectionnez des copies' popupHeight='300px' :allowCustomValue='true' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :itemTemplate="itemTemplate" :fields='customFields' :dataSource='companyContacts' :hideSelectedItem="true" v-model="emailForm.ccs")
        label.mt-1(for="selectCC") Envoyer en copie cachée à :
        ejs-multiselect( id="selectBCC" placeholder='Selectionnez des copies cachées' popupHeight='300px' :allowCustomValue='true' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :itemTemplate="itemTemplate" :fields='customFields' :dataSource='companyContacts' :hideSelectedItem="true" v-model="emailForm.bccs")
      .sidebar-header.pt-1.px-2
        div
          h3 {{ natureTranslate(document.nature) }}{{ document.nature == 2 || document.nature == 3 || document.nature == 4 ? (document.documentStep ? ` #${document.documentStep}` : '') : '' }} {{ document.documentReference ? ': ' + document.documentReference : 'Numéro en attente' }}
        feather-icon.close-icon.cursor-pointer(icon='XIcon' size='24' @click.stop='closeSidebar')
      .sidebar-header.pb-1.px-2
        .d-flex.align-items-center
          Status(:statusId="document.status" :isConnected="isConnected" :nature="document.nature" :key="document.id+'_'+document.status" @toggleCancelModal="toggleCancelModal" @signedQuote="signedQuote" @refusedQuote="refusedQuote")
          span {{ document.documentDate ? "En date du : " + dayjs(document.documentDate).format("DD/MM/YYYY") : "" }}
        .end
      vs-divider.m-0
      .sidebar-action.py-05.px-2
        .d-flex.align-items-center.justify-content-between.w-100
          .d-flex
            b-button-group
              b-button.btn-icon.d-flex(variant='primary' @click="editDocument(document.id, 'document-pdf')" :style="'border-right:1px solid #ffffff !important;'")
                feather-icon(icon='EyeIcon') 
              b-button.btn-icon.d-flex(v-if="(document.nature == 0 && statusCheck.documentEditable.includes(document.status)) || (document.nature == 1 && statusCheck.documentEditable.includes(document.status)) || (document.nature == 4 && statusCheck.documentEditable.includes(document.status) || (document.nature == 6 && !document.invoiceId && statusCheck.documentEditable.includes(document.status)) && isLastSituation(document.id))" style='border-left:1px solid #ffffff !important;' variant='primary' @click="editDocument(document.id, 'document-edit')")
                feather-icon(icon='EditIcon')
                span(style='margin-left:3px') Modifier
              b-button.btn-icon.d-flex(v-if='!statusCheck.documentEditable.includes(document.status) && document.path' @click.stop='forceFileDownload(document.path)' style='border-left:1px solid #ccc !important;' variant='primary')
                feather-icon.align-middle(icon='DownloadIcon')
                span(style='margin-left:3px') Télécharger
            b-button-group.ml-50
              b-dropdown.dropdown-icon-wrapper(v-if='document.nature == 0 && ([2, 3, 9, 10]).includes(document.status) && initialDocument && initialDocument.invoices && initialDocument.invoices.length == 0 && !orderFormCustomerExist' variant='success' left='' :style="'border-right:1px solid #ffffff !important;'")
                template(#button-content)
                  span.material-icons-outlined.text-primary(style="font-size:14px; color: white !important;")
                    | checklist_rtl
                b-dropdown-item(@click="generateOrderFormCustomer(0)") Créer la commande client éditable
                b-dropdown-item(@click="generateOrderFormCustomer(2)") Créer la commande client à valider
                b-dropdown-item(@click="generateOrderFormCustomer(3)") Créer la commande client validé
              ejs-tooltip.tooltipcontainer(v-if="!isLoadingDocument && orderFormCustomerExist && document.nature == 0" :content="'Veuillez facturer depuis la commande client ' + (initialDocument.orderFormCustomer.documentReference ? initialDocument.orderFormCustomer.documentReference : 'en brouillon') + ' lié à ce devis'" position='TopCenter' target="#buttonInvoice")
                b-button#buttonInvoice.btn-icon.d-flex.ml-50(disabled variant='success' style='border-left:1px solid #ffffff !important;') Facturer
                  feather-icon.align-middle.ml-50(icon='FileIcon')
              b-dropdown.dropdown-icon-wrapper(v-else-if='!isLoadingDocument && !statusCheck.documentEditable.concat(statusCheck.documentCanceledOrRefused).includes(document.status) && document.nature != 1 && document.nature != 5 && document.nature != 6 && finaleInvoiceExist == 0' variant='success' style='border-left:1px solid #ffffff !important;' left='')
                template(#button-content)
                  feather-icon.align-middle(icon='FileIcon')
                  span(style='margin-left:3px') Facturer
                div(v-if='initialDocument && initialDocument.quoteDownPaymentsRequests && situationInvoiceExist <= 1')
                  b-dropdown-item(@click='generateDownPaymentInvoice(item)' v-for='(item, index) in initialDocument.quoteDownPaymentsRequests' :key='index')
                    | Acompte de {{ formatNumber(item.number) }} {{ downPaymentRequestTypeTranslate(item.downPaymentRequestType) }} {{ item.downPaymentRequestDue == 3 ? item.label : downPaymentRequestDueTranslate(item.downPaymentRequestDue) }}
                b-dropdown-item(:disabled="situationInvoiceExist > 1" @click="generateDownPaymentInvoice()") Facture d'acompte {{ downPaymentInvoiceExist <= 1 ? '' : '#' + downPaymentInvoiceExist }}
                b-dropdown-item(:disabled="lastInvoice ? statusCheck.documentEditable.includes(lastInvoice.status) || lastInvoice.allWorkBilled : false" @click="generateSituationInvoice") Facture de situation {{ situationInvoiceExist <= 1 ? '' : '#' + situationInvoiceExist }}
                b-dropdown-item(:disabled="lastInvoice ? lastInvoice.nature == 4 : false" @click="generateFinaleInvoice") Facture finale
              b-dropdown.dropdown-icon-wrapper.ml-50(v-else-if='isLoadingDocument && !statusCheck.documentEditable.concat(statusCheck.documentCanceledOrRefused).includes(document.status) && document.nature != 1 && document.nature != 5 && document.nature != 6 && finaleInvoiceExist == 0' variant='success' style='border-left:1px solid #ffffff !important;' left='')
                template(#button-content)
                  span(style='margin-left:3px') Facturer
                  b-spinner(variant="white" small label="Spinning")
          .d-flex.align-items-center.justify-end
            b-button-group
              b-dropdown.dropdown-icon-wrapper(variant='outline-primary' left)
                template(#button-content)
                  feather-icon.align-middle(icon='MoreHorizontalIcon')
                // ON PEUT PAYER UNE FACTURE LORSQUE CELLE CI EST AU STATUT BROUILLON, FINALISEE, ENVOYEE, PARTIELLEMENT PAYEE
                b-dropdown-item(v-if='!isLoadingDocument && (document.nature != 0) && statusCheck.invoiceCreditAvailableForPayment.includes(document.status)' @click.stop='payedDocument')
                  | {{ document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4 ? 'Ajouter un r&egrave;glement' : 'Solder mon avoir' }}
                // ON PEUT UTILISER UN AVOIR SUE UNE FACTURE SI IL EXISTE UN AVOIR AU MÊME ID CLIENT
                b-dropdown-item(v-if='!isLoadingDocument && (document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4) && customerHaveCredits && document.remainingToPayTtc > 0' @click.stop='deductCredits') Déduire un avoir
                // ON PEUT DUPLIQUER UNIQUEMENT UNE FACTURE ET UN DEVIS
                b-dropdown-item(v-if='document.nature == 0 || document.nature == 1' @click.stop='duplicateDocument')
                  | {{ document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4 ? 'Dupliquer la facture' : 'Dupliquer le devis' }}
                // JE PEUX METTRE MON DEVIS EN SIGNE LORSQUE CELUI CI EST AU STATUT ENVOYE
                //- b-dropdown-item(v-if='(document.nature == 0 && ([2, 9]).includes(document.status))' @click.stop='signedQuote')
                //-   | Signer le devis
                // JE PEUX MODIFIER MA DATE DE SIGNATURE LORSQUE MON DEVIS EST SIGNE
                b-dropdown-item(v-if='(document.nature == 0 && document.status == 3)' @click.stop='signedQuote')
                  | Modifier la date de signature du devis
                // JE PEUX CREER UN AVOIR SUR MA FACTURE LORSQUE CELLE CI EST FINALISEE, ENVOYEE, PAYEE
                b-dropdown-item(v-if='(document.nature == 1 || document.nature == 2 || (document.nature == 3 && isLastSituation(document.id)) || (document.nature == 4 && isLastSituation(document.id))) && (statusCheck.invoiceCreditAllowed.includes(document.status)) && !creditByInvoice.length' @click.stop='generateCredit')
                  | Générer un avoir
                // REVISER LE DOCUMENT
                b-dropdown-item(v-if='(document.nature == 0 && (([2, 4, 5, 9, 10]).includes(document.status) || (downPaymentInvoiceOnlyExist && document.status == 3 && !initialDocument.orderFormCustomer))) || (document.nature == 1 && ([2, 9, 10]).includes(document.status)) || (document.nature == 4 && ([2, 9, 10]).includes(document.status) && isLastSituation(document.id))' @click.stop='reviseDocument')
                  | {{ document.nature == 0  ? 'Réviser le devis' : 'Réviser la facture' }}
                // JE PEUX METTRE MON DEVIS EN REFUSE LORSQUE CELUI CI EST AU STATUT FINALISE OU ENVOYE
                //- b-dropdown-item(v-if='(document.nature == 0 && ([1, 2, 9, 10]).includes(document.status))' @click.stop='refusedQuote')
                //-   | Refuser le devis
                //
                  JE PEUX ANNULER MON DEVIS LORSQUE CELUI CI EST AU STATUT EST SIGNE NON FACTURE
                  JE PEUX ANNULER MA FACTURE LORSQUE CELLE CI EST AU STATUT FINALISEE (2), ENVOYEE (2), PARTIELLEMENT PAYEE (7), PAYEE (6)
                  IL NE FAUT PAS QUE MON DOCUMENT SOIT LIE AVEC UNE FACTURE SINON L'ANNULATION EST IMPOSSIBLE
                //- b-dropdown-item(v-if='((document.nature == 0 && ([0, 1, 2, 3, 8, 9, 10]).includes(document.status)) && !isConnected)' @click.stop='toggleCancelModal')
                //-   | Annuler le devis
                //
                 SI WORKSPACEID DE LHOTELLIER (14)
                 SI NATURE 1, 2, 3, 4
                b-dropdown-item(v-if='[1, 2, 3, 4].includes(document.nature) && ![0,8,1].includes(document.status) && workspaceAuthorized' @click.stop='openEmailModal')
                  | Envoyer par email
                //
                  JE PEUX SUPPRIMER MON DEVIS LORSQUE CELUI CI EST AU STATUT BROUILLON, FINALISE, ENVOYE
                  JE PEUX SUPPRIMER MA FACTURE LORSQUE CELLE CI EST AU STATUT BROUILLON
                  JE NE PEUX PAS SUPPRIMER MES AVOIRS SAUF SI CELUI CI EST AU STATUT VIDE
                b-dropdown-divider(v-if='statusCheck.documentDraft.includes(document.status)')
                b-dropdown-item(v-if='statusCheck.documentDraft.includes(document.status)' @click.stop='deleteDocumentLocal(document.id, document.documentReference)')
                  | {{ document.nature == 0 ? 'Supprimer le devis' : document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4 ? 'Supprimer la facture' : 'Supprimer l\'avoir' }}
      vs-divider.m-0
      b-row.content-scrollable-sticky.m-0.justify-content-center
          b-col#nav-sticky-horizontal(style="padding: 0 !important; height: 100% !important;" cols='12')
            ul.d-flex.border-bottom-grey-light.px-1
              li
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'details' ? 'current' : ''" @click="tabActive = 'details'")
                  span Détails
              li(v-if="haveGedModule")
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'files' ? 'current' : ''" @click="tabActive = 'files'")
                  span Documents
              li
                a.d-flex.align-center.align-content-center.align-items-center.w-100(:class="tabActive == 'notes' ? 'current' : ''" @click="tabActive = 'notes'")
                  span Notes
                  feather-icon.align-middle.ml-50(v-if="document.internalNote" icon='MessageCircleIcon')
      .sidebar-content.with-tabs
        div(v-if='tabActive == "details"')
          div(v-if='document.companyId')
            .d-flex.justify-content-between
              h5.mb-0.d-flex.align-items-center.mb-0.text-primary.cursor-pointer(@mouseover="showCompanyEdit=true" @mouseleave="showCompanyEdit=false" style="flex: 1;")
                span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | {{ document.companyName }}
                  feather-icon.ml-50(@click="editCompany(document.companyId, 'client')" v-show="showCompanyEdit" icon='ExternalLinkIcon')
              .d-flex
                div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                  div.px-50
                    small Client
            div
              span(v-show='document.companyAddress') {{ document.companyAddress }}
                br
              span(v-show='document.companyAddressComplement') {{ document.companyAddressComplement }}
                br
              span(v-show='document.companyZipCode || document.companyCity') {{ document.companyZipCode }} {{ document.companyCity }}
                br
              span(v-show='document.companyCountry') {{ document.companyCountry }}
            ul.listTab.mt-1
              li(v-if='document.companyEmail')
                span Email
                span {{ document.companyEmail }}
              li(v-if='document.companyPhoneNumber')
                span Téléphone
                span {{ document.companyPhoneNumber }}
            vs-divider
          
          div(v-if='document.affairId')
            .d-flex.justify-content-between.mb-1
                h5.mb-0.d-flex.mb-0.text-primary.cursor-pointer(@mouseover="showAffairEdit=true" @mouseleave="showAffairEdit=false" style="flex: 1;")
                  span(style='min-width: fit-content; margin-right: -5px')
                    feather-icon(icon='ChevronRightIcon') 
                    | {{ document.affairName }}
                    feather-icon.ml-50(@click="editAffair(document.affairId)" v-show="showAffairEdit" icon='ExternalLinkIcon')
                .d-flex
                  div(style='width: 10px; height:20px; background-color: #eef1f6; clip-path: polygon(100% 100%, 100% 0, 0 50%);')
                  div(style="background:#eef1f6 !important; height: 20px; min-width: fit-content;")
                    div.px-50
                      small Affaire liée
          vs-divider(v-if='document.affairId')
          div
            TotalDocumentFromImport(v-if='document.usedUrlGed' :canEditDiscount='false')
            TotalDocument(v-else :canEditDiscount='false')
            vs-divider
          div(v-if="initialDocument && initialDocument.initialDocumentNature")
            div(v-if="((initialDocument.initialDocumentNature == 'quote' ? initialDocument : initialDocument.quote) && (document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4)) || (initialInvoice && (document.nature == 5 || document.nature == 6))")
              .d-flex.justify-content-between.align-items-center(v-if="initialDocument.initialDocumentNature == 'quote' ? initialDocument && (document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4) : initialDocument.quote && (document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4)")
                div.d-flex
                  span.material-icons.text-gray(style="font-size:20px") description
                  div
                    span.ml-50
                      | Sur devis {{ initialDocument.initialDocumentNature == 'quote' ? initialDocument.documentReference : initialDocument.quote.documentReference }}
                ejs-dropdownbutton.dropdownbutton-redirect(:select="onSelectQuote" :items="itemsComputed(initialDocument.initialDocumentNature == 'quote' ? {id: initialDocument.id, documentReference: initialDocument.documentReference} : {id: initialDocument.quote.id, documentReference: initialDocument.quote.documentReference})" iconCss='e-icons e-chevron-down' cssClass='e-caret-hide')
              .d-flex.justify-content-between.align-items-center(v-else-if='initialInvoice && (document.nature == 5 || document.nature == 6)')
                div.d-flex
                  span.material-icons.text-gray(style="font-size:20px") description
                  div
                    span.ml-50
                      | Sur facture {{ initialInvoice.documentReference }}
                ejs-dropdownbutton.dropdownbutton-redirect(:select="onSelectInvoice" :items="itemsComputed({id: initialInvoice.id, documentReference: initialInvoice.documentReference})" iconCss='e-icons e-chevron-down' cssClass='e-caret-hide')
              vs-divider
            div(v-if="initialDocument && initialDocument.initialDocumentNature == 'order-form-customer' && (document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4)")
              .d-flex.justify-content-between.align-items-center
                div.d-flex
                  span.material-icons.text-gray(style="font-size:20px") description
                  div
                    span.ml-50
                      | Sur commande client {{ initialDocument.documentReference }}
                ejs-dropdownbutton.dropdownbutton-redirect(:select="onSelectOrderFormCustomer" :items="itemsComputed({id: initialDocument.id, documentReference: initialDocument.documentReference})" iconCss='e-icons e-chevron-down' cssClass='e-caret-hide')
              vs-divider
          div(v-if='((document.nature == 0 || document.nature == 2 || document.nature == 3) && initialDocumentWithoutItself && initialDocumentWithoutItself.length > 0) || ((document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4) && creditByInvoice.length) || (initialDocument && initialDocument.initialDocumentNature == `quote` && document.nature == 0 && initialDocument.orderFormCustomer)')
            .d-flex.justify-content-between
              h5.mb-1.d-flex.align-items-center.mb-0.text-primary
                span.d-flex.align-items-center(style='min-width: fit-content; margin-right: -5px')
                  feather-icon(icon='ChevronRightIcon') 
                  | Documents liés
            .d-flex.mb-50.justify-content-between.align-items-center(v-if="initialDocument && initialDocument.initialDocumentNature == `quote` && initialDocument.orderFormCustomer && document.nature == 0")
              div.d-flex
                span.material-icons.text-gray(style="font-size:20px") description
                div
                  span.ml-50 
                    | Commande client {{ initialDocument.orderFormCustomer.documentReference }} du 
                    span.font-weight-bold {{ formatDate(initialDocument.orderFormCustomer.documentDate) }} 
                    | de 
                    span.font-weight-bold {{ formatCurrency(initialDocument.orderFormCustomer.totalTtc) }} TTC ({{ orderFormCustomerStatusTranslate(initialDocument.orderFormCustomer.status) }})
              ejs-dropdownbutton.dropdownbutton-redirect(:select="onSelectOrderFormCustomer" :items="itemsComputed({id: initialDocument.orderFormCustomer.id, documentReference: initialDocument.orderFormCustomer.documentReference})" iconCss='e-icons e-chevron-down' cssClass='e-caret-hide')
            div(v-if="(document.nature == 0 || document.nature == 2 || document.nature == 3) && initialDocumentWithoutItself && initialDocumentWithoutItself.length > 0")
              div.mb-50.d-flex.justify-content-between.align-items-center(v-for='(item, index) in initialDocumentWithoutItself' :key='index')
                div.d-flex
                  span.material-icons.text-gray(style="font-size:20px") description
                  div
                    span.ml-50 
                      | {{ natureTranslate(item.nature) }}{{ item.nature == 2 || item.nature == 3 || item.nature == 4 ? (item.documentStep ? ` #${item.documentStep}` : '') : '' }} {{ item.documentReference }} du 
                      span.font-weight-bold {{ formatDate(item.documentDate) }} 
                      | de 
                      span.font-weight-bold {{ formatCurrency(item.totalTtc) }} TTC ({{ statusTranslate(item.status) }})
                ejs-dropdownbutton.dropdownbutton-redirect(:select="onSelectDocument" :items="itemsComputed({id: item.id, documentReference: item.documentReference, nature: item.nature})" iconCss='e-icons e-chevron-down' cssClass='e-caret-hide')
            div(v-if="(document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4) && creditByInvoice.length")
              div.mb-50.d-flex.justify-content-between.align-items-center(v-for='(itemCreditByInvoice, index) in creditByInvoice' :key='index')
                div.d-flex
                  span.material-icons.text-gray(style="font-size:20px") description
                  div
                    span.ml-50 
                      | Avoir {{ itemCreditByInvoice.documentReference }} du 
                      span.font-weight-bold {{ formatDate(itemCreditByInvoice.documentDate) }} 
                      | de 
                      span.font-weight-bold {{ formatCurrency(itemCreditByInvoice.totalTtc) }} TTC ({{ statusTranslate(itemCreditByInvoice.status) }})
                ejs-dropdownbutton.dropdownbutton-redirect(:select="onSelectCredit" :items="itemsComputed({id: itemCreditByInvoice.id, documentReference: itemCreditByInvoice.documentReference})" iconCss='e-icons e-chevron-down' cssClass='e-caret-hide')
            vs-divider
          HistoryDocument
        div.h-100(v-if='tabActive == "files"')
          ged-viewer-sidebar(:parentFolder="document.folderId" :fromModule="document.nature == 0 ? 'quote' : (document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4) ? 'invoice' : 'credit'")
        div.h-100(v-if='tabActive == "notes"')
          quill-editor(id="text-area-designation" v-model="document.internalNote" toolbar="essential" @blur="updateDocumentWithoutLoading" class="editor quill-fixed" :options="editorOption" style="height: calc(100% - 55px);")               
    popup-new-down-payment-invoice(:activePopupDownPaymentInvoice='activePopupDownPaymentInvoice' @toggleModalNewDownPaymentInvoice='toggleModalNewDownPaymentInvoice' :preRenderingData='preRenderingData' type="document")
    popup-new-situation-invoice(ref='popup-new-situation-invoice' :activePopupSituationInvoice='activePopupSituationInvoice' @toggleModalNewSituationInvoice='toggleModalNewSituationInvoice' type="document")
    popup-new-credit(:invoiceToCredit='document' :activeTab='activeTab' :activePopupCredit='activePopupCredit' @toggleModalNewCredit='toggleModalNewCredit')
    b-modal(ref='cancel-modal' hide-footer title="Confirmer l'annulation")
      .d-block.text-center
        h3 Confirmer l'annulation
        p L'annulation de ce devis est définitive, souhaitez-vous continuer ?
      .mt-1.cancel-modal-buttons
        b-button.mr-1(variant='outline-primary' @click='toggleCancelModal') Non
        b-button(v-if='document.nature == 0' variant='danger' @click='canceledQuote') Oui
    b-modal(ref='signed-date-quote-modal' :title="'Devis : '+document.documentReference")
      div
        h4.mb-2 {{ document.status  == 3 ? 'Modifier la date de signature du devis' : 'Passer le devis au statut signé' }}
        //- p.mb-1 Passer le devis en statut "signé"
        b-row.mb-50
          b-col.py-0(cols='12')
            b-form-group(label="Date de signature" label-for="orderFormProviderEstimatedDateReceipt")
              ejs-datepicker(:format="'dd/MM/yyyy'" locale='fr' placeholder="JJ/MM/AAAA" v-model="signedDate")
              //- date-picker(v-model="signedDate" :show-time-panel="false" type="date" :clearable="true" format="DD/MM/YYYY")
      //.mt-1.cancel-modal-buttons
      template(#modal-footer="{ ok, cancel }")
        .w-100.m-0.py-50
          b-button(variant="primary" class="float-right" @click="saveSignedDateQuote()") Enregistrer
          b-button(variant="outline-primary" class="float-left mr-1" @click="toggleSignedDateQuoteModal()") Annuler
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapGetters } from "vuex";

import axios from "axios";
import dayjs from "dayjs";

import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/fr";

import GedViewerSidebar from "@/components/ged/GedViewerSidebar";
import Status from "@/components/global/status/Status";
import HistoryDocument from "@/components/invoice/HistoryDocument";
import PopupNewCredit from "@/components/invoice/PopupNewCredit";
import PopupNewDownPaymentInvoice from "@/components/invoice/PopupNewDownPaymentInvoice";
import PopupNewSituationInvoice from "@/components/invoice/PopupNewSituationInvoice";
import TotalDocument from "@/components/invoice/builder/TotalDocument";
import TotalDocumentFromImport from "@/components/invoice/builder/TotalDocumentFromImport";
import { statusCheck } from "@/types/api-orisis/enums/enums";

import {
  formatCurrency,
  formatDate,
  formatNumber,
} from "@/types/api-orisis/library/FormatOperations.ts";
import {
  downPaymentRequestDueTranslate,
  downPaymentRequestTypeTranslate,
  natureFolderTranslate,
  natureTranslate,
  orderFormCustomerStatusTranslate,
  statusTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";
import Vue from "vue";
import { InvoiceOptionModel } from "@/types/api-orisis/models/InvoiceOptionModel";
import { checkFunctionUser } from "@/auth/utils.ts";
var itemVue = Vue.component("itemTemplate", {
  template:
    "<div style='display: flex; flex-direction: column; min-height: 30px;'><span style='font-weight: 600'>{{ data.contact.lastName}} {{ data.contact.firstName}} &lt{{ data.contact.email}}&gt</span><span>{{ data.label}}</span></div>",
  data() {
    return {
      data: {},
    };
  },
});

export default {
  data() {
    return {
      InvoiceOptionModel,
      dayjs: dayjs,
      tabActive: "details",
      statusCheck,
      companyContacts: [],
      emailForm: {
        invoiceId: null,
        mailTos: [],
        ccs: [],
        bccs: [],
      },
      customFields: {
        text: "contact.email",
        value: "contact.email",
      },
      itemTemplate: function (e) {
        return {
          template: itemVue,
        };
      },
      showCompanyEdit: false,
      showAffairEdit: false,
      activePopupDownPaymentInvoice: false,
      activePopupSituationInvoice: false,
      activePopupCredit: false,
      loadingTitle: "Chargement",
      loadingMessage: "Chargement des détails du document...",
      customerHaveCredits: false,
      preRenderingData: null,
      activeTab: "invoice-credit",
      editorOption: {
        placeholder: "Ecrivez une note interne lié à ce document ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
          ],
        },
      },
      dataOrigine: {},
      signedDate: new Date(),
    };
  },
  computed: {
    ...mapGetters([
      "isUpdatingDocument",
      "isCreatingDocument",
      "quotesList",
      "isLoadingDocument",
      "isDeletingDocument",
      "natureList",
      "lastInvoice",
      "documentPDF",
      "isLoadingDocumentPDF",
      "initialInvoice",
      "creditsList",
      "isLoadingDuplicatingDocument",
      "institutionSettingsActive",
      "workspaceSelected",
      "isCreatingOrderFormCustomer",
      "initialDocument",
      "creditByInvoice",
      "paymentConditionsList",
    ]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    documentDetails: {
      get() {
        return this.$store.getters["getDocumentDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT_DETAILS", value);
      },
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
    isLoadingDuplicatingDocument: {
      get() {
        return this.$store.getters["isLoadingDuplicatingDocument"];
      },
      set(value) {
        return this.$store.commit("SET_IS_LOADING_DUPLICATING_DOCUMENT", value);
      },
    },
    loadingMsg: {
      get() {
        return this.$store.getters["loadingMsg"];
      },
      set(value) {
        return this.$store.commit("SET_LOADING_MSG", value);
      },
    },
    downPaymentInvoiceOnlyExist() {
      if (this.initialDocument) {
        return this.initialDocument.invoices &&
          this.initialDocument.invoices.length > 0
          ? this.initialDocument.invoices
              .map((elem) =>
                elem.nature === 2 || elem.status === 5 ? true : false
              )
              .every((elem) => elem === true)
          : true;
      }
      return false;
    },
    downPaymentInvoiceExist() {
      if (this.initialDocument) {
        return this.initialDocument.invoices &&
          this.initialDocument.invoices.length > 0
          ? this.initialDocument.invoices.filter(
              (item) => item.nature == 2 && item.status != 5
            ).length + 1
          : 0;
      }
      return 0;
    },
    invoicesExist() {
      if (this.initialDocument && this.initialDocument.invoices) {
        return this.initialDocument.invoices &&
          this.initialDocument.invoices.length > 0
          ? this.initialDocument.invoices.filter((item) => item.status != 5)
              .length
          : 0;
      }
      return 0;
    },
    situationInvoiceExist() {
      if (this.initialDocument && this.initialDocument.invoices) {
        let situationInvoice = this.initialDocument.invoices
          .filter(
            (item) => (item.nature == 3 || item.nature == 4) && item.status != 5
          )
          .sort((a, b) => a.documentStep > b.documentStep);
        if (situationInvoice.length > 0) {
          situationInvoice =
            situationInvoice[situationInvoice.length - 1].documentStep + 1;
        } else {
          situationInvoice = 1;
        }
        return situationInvoice;
      }
      return 0;
    },
    finaleInvoiceExist() {
      if (this.initialDocument) {
        return this.initialDocument.invoices &&
          this.initialDocument.invoices.length > 0
          ? this.initialDocument.invoices.filter(
              (item) => item.nature == 1 && item.status != 5
            ).length
          : 0;
      }
      return 0;
    },
    orderFormCustomerExist() {
      if (this.initialDocument?.initialDocumentNature == "quote") {
        return this.initialDocument.orderFormCustomer ? true : false;
      }
      return false;
    },
    isConnected() {
      if (
        this.initialDocument &&
        this.initialDocument.invoices &&
        this.initialDocument.invoices.length > 0
      ) {
        if (this.document.nature == 0) {
          return (
            this.initialDocument.invoices.filter((el) => el.status != 5)
              .length > 0 && this.initialDocument.orderFormCustomer?.status != 6
          );
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          let index = this.initialDocument.invoices.findIndex(
            (el) => el.id == this.document.id
          );
          let invoices = structuredClone(this.initialDocument.invoices);
          let newArray = invoices.splice(
            index + 1,
            this.initialDocument.invoices.length - 1
          );
          return newArray.filter((el) => el.status != 5).length > 0;
        } else {
          return false;
        }
      }
      return false;
    },
    isSituationInvoiceWithDownPayment() {
      if (
        this.initialDocument &&
        this.initialDocument.invoices &&
        this.initialDocument.invoices.length > 0
      ) {
        return (
          this.initialDocument.invoices.filter((el) => el.nature == 2).length >
            0 && this.document.nature == 4
        );
      }
      return false;
    },
    haveGedModule() {
      return (
        this.checkFunctionUser(42, "all") && this.checkFunctionUser(43, "all")
      );
    },
    initialDocumentWithoutItself() {
      return this.initialDocument &&
        this.initialDocument.invoices &&
        this.initialDocument.invoices.length > 0
        ? this.initialDocument.invoices.filter(
            (item) =>
              item.id != this.document.id && item.nature != this.document.nature
          )
        : [];
    },
    workspaceAuthorized() {
      return this.workspaceSelected.id == 14 || this.workspaceSelected.id == 3;
    },
  },
  methods: {
    ...mapActions([
      "getQuoteById",
      "getQuoteHistoryByQuoteId",
      "getInvoiceById",
      "getInvoiceHistoryByInvoiceId",
      "getCreditById",
      "getCreditHistoryByCreditId",
      "getLeasesByAffairId",
      "getDocumentDetailsByQuoteId",
      "createDocumentDetailToInvoiceIdFromQuoteId",
      "createDocumentDetailsToInvoiceIdFromOrderFormCustomerId",
      "createInvoice",
      "updateQuote",
      "updateInvoice",
      "updateCredit",
      "deleteQuotes",
      "deleteInvoices",
      "deleteCredits",
      "getCreditsByCompanyIds",
      "createDeduction",
      "duplicateQuote",
      "duplicateInvoice",
      "createOrderFormCustomerFromQuote",
      "sendInvoice",
      "getCompanyById",
      "getInvoiceOptionDefaultByInvoiceId",
      "createInvoiceOption",
    ]),
    checkFunctionUser,
    formatCurrency,
    formatNumber,
    formatDate,
    natureTranslate,
    downPaymentRequestTypeTranslate,
    downPaymentRequestDueTranslate,
    natureFolderTranslate,
    statusTranslate,
    orderFormCustomerStatusTranslate,
    async updateDocumentWithoutLoading() {
      if (this.dataOrigine.internalNote !== this.document.internalNote) {
        if (this.document.nature == 0) {
          this.updateQuote({ quote: this.document, updateState: false }).then(
            (res) => {
              this.$emit("refreshRow", res);
            }
          );
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          this.updateInvoice({
            invoice: this.document,
            updateState: false,
          }).then((res) => {
            this.$emit("refreshRow", res);
          });
        } else {
          this.updateCredit({
            credit: this.document,
            updateState: false,
          }).then((res) => {
            this.$emit("refreshRow", res);
          });
        }
      }
    },
    itemsComputed(args) {
      return [
        {
          text: "Visualiser",
          id: args.id,
          documentReference: args.documentReference,
          nature: args.nature ? args.nature : null,
        },
        // {
        //   text: 'Télécharger',
        //   id: args.id,
        //   documentReference: args.documentReference,
        //   nature: args.nature ? args.nature : null
        // },
      ];
    },
    onSelectQuote(args) {
      this.closeSidebar();
      if (args.item.text == "Visualiser") {
        this.$router.push({
          name: "edit-quote",
          params: {
            mode: "document-pdf",
            id: args.item.id,
            title: args.item.documentReference
              ? args.item.documentReference
              : "Editer un devis",
            tips:
              "Editer le devis" + args.item.documentReference
                ? " : " + args.item.documentReference
                : "",
            routeOrigine: "quotes",
          },
        });
      } else {
      }
    },
    onSelectOrderFormCustomer(args) {
      this.closeSidebar();
      if (args.item.text == "Visualiser") {
        this.$router.push({
          name: "edit-order-form-customer",
          params: {
            mode: "order-form-customer-pdf",
            id: args.item.id,
            title: args.item.documentReference
              ? args.item.documentReference
              : "Editer une commande client",
            tips:
              "Editer la commande client" + args.item.documentReference
                ? " : " + args.item.documentReference
                : "",
            routeOrigine: "order-form-customers",
          },
        });
      } else {
      }
    },
    onSelectInvoice(args) {
      this.closeSidebar();
      if (args.item.text == "Visualiser") {
        this.$router.push({
          name: "edit-invoice",
          params: {
            mode: "document-pdf",
            id: args.item.id,
            title: args.item.documentReference
              ? args.item.documentReference
              : "Editer une facture",
            tips:
              "Editer la facture" + args.item.documentReference
                ? " : " + args.item.documentReference
                : "",
            routeOrigine: "invoices",
          },
        });
      } else {
      }
    },
    onSelectCredit(args) {
      this.closeSidebar();
      if (args.item.text == "Visualiser") {
        this.$router.push({
          name: "edit-credit",
          params: {
            mode: "document-pdf",
            id: args.item.id,
            title: args.item.documentReference
              ? args.item.documentReference
              : "Editer un avoir",
            tips:
              "Editer l'avoir" + args.item.documentReference
                ? " : " + args.item.documentReference
                : "",
            routeOrigine: "credits",
          },
        });
      } else {
      }
    },
    onSelectDocument(args) {
      this.closeSidebar();
      if (args.item.text == "Visualiser") {
        if (args.item.nature == 0) {
          this.$router.push({
            name: "edit-quote",
            params: {
              mode: "document-pdf",
              id: args.item.id,
              title: args.item.documentReference
                ? args.item.documentReference
                : "Editer un devis",
              tips:
                "Editer le devis" + args.item.documentReference
                  ? " : " + args.item.documentReference
                  : "",
              routeOrigine: "quotes",
            },
          });
        } else if (
          args.item.nature == 1 ||
          args.item.nature == 2 ||
          args.item.nature == 3 ||
          args.item.nature == 4
        ) {
          this.$router.push({
            name: "edit-invoice",
            params: {
              mode: "document-pdf",
              id: args.item.id,
              title: args.item.documentReference
                ? args.item.documentReference
                : "Editer une facture",
              tips:
                "Editer la facture" + args.item.documentReference
                  ? " : " + args.item.documentReference
                  : "",
              routeOrigine: "invoices",
            },
          });
        } else if (args.item.nature == 5 || args.item.nature == 6) {
          this.$router.push({
            name: "edit-credit",
            params: {
              mode: "document-pdf",
              id: args.item.id,
              title: args.item.documentReference
                ? args.item.documentReference
                : "Editer un avoir",
              tips:
                "Editer l'avoir" + args.item.documentReference
                  ? " : " + args.item.documentReference
                  : "",
              routeOrigine: "credits",
            },
          });
        }
      } else {
      }
    },
    async editDocument(id, mode) {
      this.closeSidebar();
      if (this.document.nature == 0) {
        this.$router.push({
          name: "edit-quote",
          params: {
            mode: mode,
            id: id,
            title: this.document.documentReference
              ? this.document.documentReference
              : "Editer un devis",
            tips:
              "Editer le devis" + this.document.documentReference
                ? " : " + this.document.documentReference
                : "",
            routeOrigine: "quotes",
          },
        });
      } else if (
        this.document.nature == 1 ||
        this.document.nature == 2 ||
        this.document.nature == 3 ||
        this.document.nature == 4
      ) {
        this.$router.push({
          name: "edit-invoice",
          params: {
            mode: mode,
            id: id,
            title: this.document.documentReference
              ? this.document.documentReference
              : "Editer une facture",
            tips:
              "Editer la facture" + this.document.documentReference
                ? " : " + this.document.documentReference
                : "",
            routeOrigine: "invoices",
          },
        });
      } else {
        this.$router.push({
          name: "edit-credit",
          params: {
            mode: mode,
            id: id,
            title: this.document.documentReference
              ? this.document.documentReference
              : "Editer un avoir",
            tips:
              "Editer l'avoir" + this.document.documentReference
                ? " : " + this.document.documentReference
                : "",
            routeOrigine: "credits",
          },
        });
      }
    },
    async showAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "affair",
        params: {
          id: id,
          title: "Affaire : " + this.document.affairName,
          tips: "Affaire : " + this.document.affairName,
          routeOrigine: "affairs",
        },
      });
    },
    async editCompany(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-company",
        params: {
          id: id,
          title: "Edit : " + this.document.companyName,
          tips: "Editer l'entreprise : " + this.document.companyName,
          //routeOrigine: "companies",
        },
      });
    },
    async editAffair(id) {
      this.closeSidebar();
      this.$router.push({
        name: "edit-affair",
        params: {
          id: id,
          title: "Edit : " + this.document.affairName,
          tips: "Editer l'affaire : " + this.document.affairName,
          //routeOrigine: "affairs",
        },
      });
    },
    isLastSituation(id) {
      if (this.initialDocument && id) {
        let find = this.initialDocument.invoices.find((item) => item.id == id);
        let situationInvoices = this.initialDocument.invoices.filter(
          (item) => item.nature == 4 && item.status != 5
        );
        for (let i = 0; i < situationInvoices.length; i++) {
          const situationInvoice = situationInvoices[i];
          if (situationInvoice.documentStep > find?.documentStep) {
            return false;
          }
        }
      }
      return true;
    },
    async getDocumentDetails(id, nature) {
      if (id != "") {
        if (nature == 0) {
          this.getQuoteById({
            quoteId: id,
          });
          this.getQuoteHistoryByQuoteId({ quoteId: id });
        } else if (nature == 1 || nature == 2 || nature == 3 || nature == 4) {
          this.getInvoiceById({
            invoiceId: id,
          });
          this.getInvoiceHistoryByInvoiceId({
            invoiceId: id,
          });
          if (this.document.companyId) {
            this.getCreditsByCompanyIds({
              companyIds: [this.document.companyId],
              status: [2, 7, 9],
            }).then((res) => {
              if (res.filter((el) => el.remainingToPayTtc > 0).length > 0) {
                this.customerHaveCredits = true;
              } else {
                this.customerHaveCredits = false;
              }
            });
          } else {
            this.customerHaveCredits = false;
          }
        } else {
          this.getCreditById({
            creditId: id,
          });
          this.getCreditHistoryByCreditId({
            creditId: id,
          });
        }
        this.tabActive = "details";
      }
    },
    signedQuote() {
      this.toggleSignedDateQuoteModal();
      // AVANT EN DESSOUS
      // this.$bvModal
      //   .msgBoxConfirm('En validant, votre devis passera au statut signé', {
      //     title:
      //       'Attention',
      //     size: 'sm',
      //     okVariant: 'success',
      //     okTitle: 'Confirmer',
      //     cancelTitle: 'Annuler',
      //     cancelVariant: 'outline-primary',
      //     hideHeaderClose: true,
      //     centered: true
      //   })
      //   .then(async(value) => {
      //     if (value) {
      //       this.document.status = 3;
      //       await this.updateQuote({ quote: this.document, loading: true }).then((res) => {
      //         this.$emit('refreshRow', res);
      //       })
      //     }
      //   })
    },
    toggleSignedDateQuoteModal() {
      this.signedDate = new Date();
      this.$refs["signed-date-quote-modal"].toggle("#toggle-btn");
    },
    saveSignedDateQuote() {
      this.updateQuote({
        quote: { ...this.document, signedDate: this.signedDate, status: 3 },
        updateState: false,
      }).then((res) => {
        this.$emit("refreshRow", res);
        this.toggleSignedDateQuoteModal();
      });
    },
    toggleCancelModal() {
      this.$refs["cancel-modal"].toggle("#toggle-btn");
    },
    sendEmail() {
      this.emailForm.invoiceId = this.document.id;
      this.sendInvoice({
        data: this.emailForm,
      })
        .then(() => {
          this.$bvModal.hide("modalSendEmail");
          this.$bvToast.toast("Email envoyé avec succès", {
            title: "Succès",
            variant: "success",
            solid: true,
          });
          if (this.document.status == 2) {
            // Si le document est validé
            this.updateInvoice({
              invoice: { ...this.document, status: 9 },
              updateState: true,
            });
          }
        })
        .catch((error) => {
          this.$bvToast.toast("Erreur lors de l'envoi de l'email", {
            title: "Erreur",
            variant: "danger",
            solid: true,
          });
        });
    },
    openEmailModal() {
      this.getCompanyById({ companyId: this.document.companyId }).then(
        (res) => {
          let newContactsList = [
            {
              contact: {
                displayLabel: null,
                email: res.email,
                id: 0,
                lastName: res.name,
              },
              label: res.name,
            },
          ].concat(res.contacts); // Concat de l'email de la company et des contacts
          this.companyContacts = newContactsList.filter(
            (el) => el.contact.email
          );
          this.$refs.modalSendEmail.show();
          this.getAffair({ affairId: this.document.affairId }).then(
            (affair) => {
              if (affair.affairType.id == 1) {
                this.getLeasesByAffairId({ affairId: affair.id }).then(
                  (lease) => {
                    if (lease.landlordContactEmail) {
                      this.companyContacts.push({
                        contact: {
                          displayLabel: null,
                          email: lease.landlordContactEmail,
                          id: lease.landlordId,
                          lastName: lease.landlordContactLastName,
                        },
                        label: "Propriétaire",
                      });
                    }
                    if (lease.tenantContactEmail) {
                      this.companyContacts.push({
                        contact: {
                          displayLabel: null,
                          email: lease.tenantContactEmail,
                          id: lease.tenantId,
                          lastName: lease.tenantContactLastName,
                        },
                        label: "Locataire",
                      });
                    }
                  }
                );
              }
            }
          );
        }
      );
    },
    canceledQuote() {
      this.updateQuote({
        quote: { ...this.document, status: 5 },
        updateState: false,
      }).then((res) => {
        this.$emit("refreshRow", res);
        this.toggleCancelModal();
      });
    },
    refusedQuote() {
      this.$bvModal
        .msgBoxConfirm("En validant, votre devis passera au statut refusé", {
          title: "Attention",
          size: "sm",
          okVariant: "success",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            await this.updateQuote({
              quote: { ...this.document, status: 4 },
              updateState: false,
            }).then((res) => {
              this.$emit("refreshRow", res);
            });
          }
        });
    },
    async reviseDocument() {
      this.closeSidebar();
      this.$bvModal
        .msgBoxConfirm(
          'En validant, votre document sera deverrouillé et repassera au statut "A valider" vous permettant son édition.',
          {
            title: "Attention",
            size: "sm",
            okVariant: "success",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            if (this.document.nature == 0) {
              await this.updateQuote({
                quote: { ...this.document, status: 1, path: null },
                updateState: false,
              }).then((res) => {
                this.$emit("refreshRow", res);
              });
            } else if (
              this.document.nature == 1 ||
              this.document.nature == 2 ||
              this.document.nature == 3 ||
              this.document.nature == 4
            ) {
              await this.updateInvoice({
                invoice: { ...this.document, status: 1, path: null },
                updateState: false,
              }).then((res) => {
                this.$emit("refreshRow", res);
              });
            } else {
              await this.updateCredit({
                credit: { ...this.document, status: 1, path: null },
                updateState: false,
              }).then((res) => {
                this.$emit("refreshRow", res);
              });
            }
            this.editDocument(this.document.id, "document-edit");
          }
        });
    },
    duplicateDocument() {
      this.closeSidebar();
      this.loadingMsg = "Duplication en cours...";
      this.isLoadingDuplicatingDocument = true;
      if (this.document.nature == 0) {
        this.duplicateQuote({
          quoteId: this.document.id,
        }).then(async (res) => {
          this.isLoadingDuplicatingDocument = false;
          this.loadingMsg = "Chargement en cours...";
          this.$router.push({
            name: "new-quote",
            params: {
              id: res.data.id,
              title: "Nouveau devis",
              tips: "Nouveau devis",
              routeOrigine: "quotes",
            },
          });
        });
      } else if (this.document.nature == 1) {
        this.duplicateInvoice({
          invoiceId: this.document.id,
        }).then(async (res) => {
          this.isLoadingDuplicatingDocument = false;
          this.loadingMsg = "Chargement en cours...";
          this.$router.push({
            name: "new-invoice",
            params: {
              id: res.data.id,
              title: "Nouvelle facture",
              tips: "Nouvelle facture",
              routeOrigine: "invoices",
            },
          });
        });
      }
    },
    deleteDocumentLocal(id, reference) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            'Êtes-vous sûr de vouloir supprimer le document "' +
            reference +
            '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            if (this.document.nature == 0) {
              this.deleteQuotes({ quoteIds: [id], route: this.$route.name });
            } else if (
              this.document.nature == 1 ||
              this.document.nature == 2 ||
              this.document.nature == 3 ||
              this.document.nature == 4
            ) {
              this.deleteInvoices({
                invoiceIds: [id],
                route: this.$route.name,
              });
            } else {
              this.deleteCredits({ creditIds: [id], route: this.$route.name });
            }
            this.closeSidebar();
          }
        });
    },
    payedDocument() {
      this.closeSidebar();
      this.$router.push({
        name: "new-payment",
        params: {
          id: 0,
          documentData: structuredClone(this.document),
          title: "Nouveau règlement",
          routeOrigine: "invoices",
          disabled: true,
        },
      });
    },
    deductCredits() {
      this.closeSidebar();
      this.$router.push({
        name: "deduct-credits",
        params: {
          documentData: structuredClone(this.document),
          title: "Déduire un avoir",
          routeOrigine: "invoices",
        },
      });
    },
    async generateOrderFormCustomer(orderFormCustomerStatus) {
      this.closeSidebar();
      if ([0, 1, 2, 8, 9, 10].includes(this.document.status)) {
        this.$bvModal
          .msgBoxConfirm(
            'Pour génerer le bon de commande client, vous devez le passer en statut "Signé"',
            {
              title: "Attention",
              size: "sm",
              okVariant: "success",
              okTitle: "Signer et commander",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then(async (value) => {
            if (value) {
              this.updateQuote({
                quote: { ...this.document, signedDate: new Date(), status: 3 },
                updateState: false,
              }).then(async (res) => {
                this.$emit("refreshRow", res);
                await this.createOrderFormCustomerFromQuote({
                  quoteId: this.document.id,
                  status: orderFormCustomerStatus,
                }).then((orderFormCustomerRes) => {
                  if (orderFormCustomerRes.data.status == 0) {
                    this.$router.push({
                      name: "new-order-form-customer",
                      params: {
                        id: orderFormCustomerRes.data.id,
                        title: "Nouvelle commande client",
                        tips: "Nouvelle commande client",
                        routeOrigine: "order-form-customers",
                      },
                    });
                  } else {
                    this.$emit("refreshRow", {
                      ...res,
                      orderFormCustomerId: orderFormCustomerRes.data.id,
                    });
                  }
                });
              });
            }
          });
      } else {
        await this.createOrderFormCustomerFromQuote({
          quoteId: this.document.id,
          status: orderFormCustomerStatus,
        }).then((orderFormCustomerRes) => {
          if (orderFormCustomerRes.data.status == 0) {
            this.$router.push({
              name: "new-order-form-customer",
              params: {
                id: orderFormCustomerRes.data.id,
                title: "Nouvelle commande client",
                tips: "Nouvelle commande client",
                routeOrigine: "order-form-customers",
              },
            });
          } else {
            this.$emit("refreshRow", {
              ...this.document,
              orderFormCustomerId: orderFormCustomerRes.data.id,
            });
          }
        });
      }
    },
    generateDownPaymentInvoice(preRenderingData = null) {
      this.closeSidebar();
      this.preRenderingData = preRenderingData;
      if (
        [0, 1, 2, 8, 9, 10].includes(this.document.status) &&
        this.document.nature == 0
      ) {
        this.$bvModal
          .msgBoxConfirm(
            'Pour facturer ce devis, vous devez le passer en statut "Signé"',
            {
              title: "Attention",
              size: "sm",
              okVariant: "success",
              okTitle: "Signer et facturer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.updateQuote({
                quote: { ...this.document, signedDate: new Date(), status: 3 },
                updateState: false,
              }).then((res) => {
                this.$emit("refreshRow", res);
                this.toggleModalNewDownPaymentInvoice(true);
              });
            }
          });
      } else {
        this.toggleModalNewDownPaymentInvoice(true);
      }
    },
    generateCredit() {
      this.closeSidebar();
      this.activeTab = "invoice-credit";
      this.toggleModalNewCredit(true);
    },
    generateSituationInvoice() {
      this.closeSidebar();
      this.$store.commit(
        "SET_LOADING_MSG",
        "Création de la facture de situation en cours..."
      );
      if (this.document.nature == 0) {
        if ([0, 1, 2, 8, 9, 10].includes(this.document.status)) {
          this.$bvModal
            .msgBoxConfirm(
              'Pour facturer ce devis, vous devez le passer en statut "Signé"',
              {
                title: "Attention",
                size: "sm",
                okVariant: "success",
                okTitle: "Signer et facturer",
                cancelTitle: "Annuler",
                cancelVariant: "outline-primary",
                hideHeaderClose: true,
                centered: true,
              }
            )
            .then((value) => {
              if (value) {
                this.updateQuote({
                  quote: {
                    ...this.document,
                    signedDate: new Date(),
                    status: 3,
                  },
                  updateState: false,
                }).then((res) => {
                  this.$emit("refreshRow", res);
                  this.toggleModalNewSituationInvoice(true);
                });
              }
            });
        } else {
          if (this.lastInvoice && this.lastInvoice.nature == 4) {
            this.$refs["popup-new-situation-invoice"].createSituationInvoice(4);
          } else {
            this.toggleModalNewSituationInvoice(true);
          }
        }
      } else {
        if (this.lastInvoice && this.lastInvoice.nature == 4) {
          this.$refs["popup-new-situation-invoice"].createSituationInvoice(4);
        } else {
          this.toggleModalNewSituationInvoice(true);
        }
      }
    },
    async generateFinaleInvoice() {
      this.closeSidebar();
      if (
        [0, 1, 2, 8, 9, 10].includes(this.document.status) &&
        this.document.nature == 0
      ) {
        this.$bvModal
          .msgBoxConfirm(
            'Pour facturer ce devis, vous devez le passer en statut "Signé"',
            {
              title: "Attention",
              size: "sm",
              okVariant: "success",
              okTitle: "Signer et facturer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then(async (value) => {
            if (value) {
              await this.updateQuote({
                quote: { ...this.document, signedDate: new Date(), status: 3 },
                updateState: false,
              }).then(async (res) => {
                this.$emit("refreshRow", res);
                await this.createFinaleInvoice();
              });
            }
          });
      } else {
        await this.createFinaleInvoice();
      }
    },
    toggleModalNewDownPaymentInvoice(bool = false) {
      this.activePopupDownPaymentInvoice = bool;
    },
    toggleModalNewSituationInvoice(bool = false) {
      this.activePopupSituationInvoice = bool;
    },
    toggleModalNewCredit(bool = false) {
      this.activePopupCredit = bool;
    },
    async createFinaleInvoice() {
      let invoiceOptionsDefault = InvoiceOptionModel({});
      let invoiceOptions = InvoiceOptionModel({});
      let documentInvoice = structuredClone(this.document);
      let initialDocument = structuredClone(this.initialDocument);
      let initialDocumentOptions = structuredClone(this.documentOptions);
      await this.getInvoiceOptionDefaultByInvoiceId({
        updateState: false,
      }).then((res) => {
        invoiceOptionsDefault = res;
        invoiceOptions = {
          invoiceId: 0,
          ...res,
          calculateMargin: initialDocumentOptions.calculateMargin,
          showDiscount: initialDocumentOptions.showDiscount,
          showDescription: initialDocumentOptions.showDescription,
          id: 0,
        };
      });

      // Le document généré vient d'ORISIS
      documentInvoice.usedUrlGed = false;

      let deduction = null;
      // Stocker l'id du devis
      documentInvoice.quoteId =
        this.initialDocument.initialDocumentNature == "quote"
          ? structuredClone(initialDocument.id)
          : structuredClone(initialDocument.quote?.id);
      // Stocker l'id du bon de commande
      documentInvoice.orderFormCustomerId =
        this.initialDocument.initialDocumentNature == "quote"
          ? structuredClone(initialDocument.quote?.id)
          : structuredClone(initialDocument.id);
      // Mettre à 0 l'id de la facture
      documentInvoice.id = 0;
      // Mettre la paymentConditionId
      let paymentCondition = this.paymentConditionsList.find(
        (item) => item.id == invoiceOptionsDefault.paymentConditionDefaultId
      );

      documentInvoice.paymentConditionId = paymentCondition
        ? paymentCondition.id
        : 1;
      // Aller chercher la date de validité dans limitDate
      documentInvoice.limitDate = paymentCondition
        ? dayjs().add(paymentCondition.days, "day").toISOString()
        : dayjs().toISOString();
      // Garder d'où est créé la facture
      let initialNature = JSON.stringify(documentInvoice.nature);
      // Mettre la nature à 1
      documentInvoice.nature = 1;
      // Ajouter isCounted à false,
      documentInvoice.isCounted = false;
      // Mettre documentDate à la date du jour
      documentInvoice.documentDate = new Date().toISOString();
      // Mettre le status à 8
      documentInvoice.status = 8;
      // Calculer le prix Ht
      documentInvoice.totalHt = initialDocument.totalHt;
      // Calculer le prix Ttc et le reste à payer pour la facture d'acompte
      documentInvoice.totalTtc = documentInvoice.remainingToPayTtc =
        initialDocument.totalTtc;
      // Mettre à jour le documentStep
      documentInvoice.documentStep = 1;
      // Mettre à jour les paiements
      documentInvoice.path = null;
      documentInvoice.payments = [];
      // Mettre a jour la remise globale si le devis avait une remise globale
      documentInvoice.discountGlobalNumber =
        initialDocument.discountGlobalNumber;
      documentInvoice.discountGlobalType = initialDocument.discountGlobalType;

      // Mettre à jour invoiceBTP
      if (initialDocument.initialDocumentNature == "quote") {
        if (initialDocument.quoteBTP != null) {
          documentInvoice.invoiceBTP = {
            id: 0,
            workStartDate: initialDocument.quoteBTP.workStartDate,
            estimatedDurationNumber:
              initialDocument.quoteBTP.estimatedDurationNumber,
            estimatedDurationType:
              initialDocument.quoteBTP.estimatedDurationType,
          };
        } else {
          documentInvoice.invoiceBTP = null;
        }
      } else {
        if (initialDocument.orderFormCustomerBTP != null) {
          documentInvoice.invoiceBTP = {
            id: 0,
            workStartDate: initialDocument.orderFormCustomerBTP.workStartDate,
            estimatedDurationNumber:
              initialDocument.orderFormCustomerBTP.estimatedDurationNumber,
            estimatedDurationType:
              initialDocument.orderFormCustomerBTP.estimatedDurationType,
          };
        } else {
          documentInvoice.invoiceBTP = null;
        }
      }

      // Mettre à jour invoiceCEE
      if (initialDocument.initialDocumentNature == "quote") {
        if (initialDocument.quoteCEE != null) {
          documentInvoice.invoiceCEE = {
            id: 0,
            technicalVisitDate: initialDocument.quoteCEE.technicalVisitDate,
            amountPremium: initialDocument.quoteCEE.amountPremium,
            precarityType: initialDocument.quoteCEE.precarityType,
            parcelNumber: initialDocument.quoteCEE.parcelNumber,
          };
        } else {
          documentInvoice.invoiceCEE = null;
        }
      } else {
        if (initialDocument.orderFormCustomerCEE != null) {
          documentInvoice.invoiceCEE = {
            id: 0,
            technicalVisitDate:
              initialDocument.orderFormCustomerCEE.technicalVisitDate,
            amountPremium: initialDocument.orderFormCustomerCEE.amountPremium,
            precarityType: initialDocument.orderFormCustomerCEE.precarityType,
            parcelNumber: initialDocument.orderFormCustomerCEE.parcelNumber,
          };
        } else {
          documentInvoice.invoiceCEE = null;
        }
      }

      // Mettre à jour les déductions
      if (initialDocument.deduction) {
        deduction = structuredClone(initialDocument.deduction);
      }
      if (initialDocumentOptions) {
        let baseMessage = "<p>Notes du devis :</p>";
        let tempDivInvoiceOptionsDefault = document.createElement("div");
        tempDivInvoiceOptionsDefault.innerHTML = invoiceOptionsDefault?.comment;
        tempDivInvoiceOptionsDefault = tempDivInvoiceOptionsDefault.innerText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s+/g, "")
          .replace(/[^a-zA-Z0-9]/g, "")
          .toLowerCase();

        let tempDivDocumentInvoice = document.createElement("div");
        tempDivDocumentInvoice.innerHTML = documentInvoice.comments;
        tempDivDocumentInvoice = tempDivDocumentInvoice.innerText
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s+/g, "")
          .replace(/[^a-zA-Z0-9]/g, "")
          .toLowerCase();

        if (tempDivDocumentInvoice.includes(tempDivInvoiceOptionsDefault)) {
          documentInvoice.comments = documentInvoice.comments;
        } else {
          documentInvoice.comments =
            (documentInvoice?.comments ? baseMessage : "") +
            (invoiceOptionsDefault?.comment
              ? invoiceOptionsDefault?.comment + "<br>"
              : "") +
            (documentInvoice?.comments ? documentInvoice?.comments : "");
          if (structuredClone(documentInvoice.comments).length === 0) {
            documentInvoice.comments = invoiceOptionsDefault.comment;
          }
        }
      }

      // Mettre à jour le documentDetails et mettre à jours les totaux dans le store
      await this.createInvoice({ invoice: documentInvoice }).then(
        async (res) => {
          documentInvoice.id = res.data.id;
          await this.createInvoiceOption({
            invoiceOption: { ...invoiceOptions, invoiceId: res.data.id },
          });
          if (
            initialNature == 0 ||
            initialDocument.initialDocumentNature == "quote"
          ) {
            await this.createDocumentDetailToInvoiceIdFromQuoteId({
              invoiceId: documentInvoice.id,
              quoteId: initialDocument.id,
            });
          } else if (
            initialDocument.initialDocumentNature == "order-form-customer"
          ) {
            await this.createDocumentDetailsToInvoiceIdFromOrderFormCustomerId({
              invoiceId: documentInvoice.id,
              orderFormCustomerId: initialDocument.id,
            });
          }
          if (deduction)
            await this.applyQuoteDeduction(
              deduction,
              res.data.id,
              initialDocument
            );
          this.$router.push({
            name: "new-invoice",
            params: {
              id: res.data.id,
              title: "Nouvelle facture",
              tips: "Nouvelle facture",
              routeOrigine: "invoices",
            },
          });
        }
      );
    },
    async applyQuoteDeduction(deduction, invoiceId, initialDocument) {
      let quoteTotalTtc = initialDocument.totalTtc;
      let invoiceDeduction = {
        creditId: null,
        holdbackDate: deduction.holdbackDate,
        holdbackNumber: deduction.holdbackNumber,
        holdbackPercent: deduction.holdbackPercent,
        id: 0,
        invoiceId: invoiceId,
        prorataAccountLabel: deduction.prorataAccountLabel,
        prorataAccountPercent: deduction.prorataAccountPercent,
        prorataAccountPrice: deduction.prorataAccountPrice,
        punctualDeductions: deduction.punctualDeductions.map((deduction) => {
          return {
            ...deduction,
            id: 0,
          };
        }),
        quoteId: null,
        orderFormCustomerId: null,
        reviews: deduction.reviews.map((review) => {
          return {
            ...review,
            id: 0,
          };
        }),
      };
      await this.createDeduction({ deduction: invoiceDeduction });
    },
    forceFileDownload(donwload) {
      // TODO : ATTENTION FONCTIONNE PAS LOCAL, EXIGE CORS UNCLOCK
      axios({
        url: donwload,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fullUri = document.createElement("a");
        fullUri.href = fileURL;
        fullUri.setAttribute("download", this.document.documentReference);
        document.body.appendChild(fullUri);
        fullUri.click();
        URL.revokeObjectURL(fullUri.href);
      });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  mounted() {
    (this.loadingTitle = "Chargement"),
      (this.loadingMessage = "Chargement des détails du document...");
  },
  components: {
    VuePerfectScrollbar,
    quillEditor,
    Status,
    TotalDocument,
    HistoryDocument,
    GedViewerSidebar,
    PopupNewDownPaymentInvoice,
    PopupNewSituationInvoice,
    PopupNewCredit,
    TotalDocumentFromImport,
    DatePicker,
  },
};
</script>

<style lang="scss" scoped>
.sidebar-action {
  flex-direction: column;
}

.cancel-modal-buttons {
  display: flex;
  justify-content: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 15px;
  cursor: pointer;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px;

    .dropdown-content-item {
      padding: 10px 10px 10px 10px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdownbutton-redirect {
  background-color: #0000;
  border-color: #0000;
  color: #5c636a;
}
</style>
